/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useDispatch, useMappedState } from 'redux-react-hook'

import * as actions from '../../../redux/actions/UsersActions'
import { Frame, Text, convertHex, AddSquare, H1, P } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import Dropzone from '../../UIKit/Dropzone'
import Form from '../../UIKit/Form'
import CommonHelper from '../../../helpers/CommonHelper';
import ProjectTab from '../tools/ProjectTab'
import Tags from '../tools/Tags'
import PhotoPreview from '../tools/PhotoPreview'
import useCurrentUser from '../helpers/useCurrentUser'
import SimpleControledUploadPanel from '../../upload/SimpleControledUploadPanel'

let educationFields = [
    { name: `Educational institution`, type: `string`, extra: `width: 694px;` },
    // { name: `Department or specialty`, type: `select`, short: true, extra: { width: `445px` } },
    // { name: `Education level`, type: `select`, short: true, extra: { width: `255px`, marginLeft: `30px`, } },
    { name: `From`, type: `date`, short: true, extra: `width: 253px; margin-right: 30px;`, },
    { name: `To`, type: `date`, short: true, extra: `width: 253px;`, },
]

let workexperienceFields = [
    { name: `Company name`, type: `string`, extra: `width: 694px;` },
    { name: `City`, type: `string`, extra: `width: 314px; margin-right: 30px;`, },
    { name: `Company site`, type: `string`, extra: `width: 314px`, },
    { name: `Job title`, type: `string`, extra: `width: 314px; margin-right: 30px;`, },
    { name: `Company industry`, type: `string`, extra: `width: 314px`, },
    { name: `From`, type: `date`, short: true, extra: `width: 253px; margin-right: 30px;`, },
    { name: `To`, type: `date`, short: true, extra: `width: 253px;`, },
    { name: `Description`, type: `textarea`, extra: `width: calc(730px - 34px);` },
]

let isObject = (object) => {
    return object != null && typeof object === 'object';
}

let deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

    return true;
}

let EditAdminProfile = () => {

    let uploaderRef = useRef(null)

    let { currentUser } = useCurrentUser()

    const [info, setInfo] = useState(currentUser.info);

    let dispatch = useDispatch()
    let onSaveProfile = async () => {
        let new_info = { ...info }
        if (Object.keys(new_info.education[0]).length === 0) { delete new_info.education }
        if (Object.keys(new_info.work_experience[0]).length === 0) { delete new_info.work_experience }
        dispatch(actions.updateUser({ ...currentUser, info: new_info }))
    }

    useEffect(() => {
        if (info.education === undefined || info.education === null || info.education.length === 0) {
            setInfo({ ...info, education: [{}] })
        }
        if (info.work_experience === undefined || info.work_experience === null || info.work_experience.length === 0) {
            setInfo({ ...info, work_experience: [{}] })
        }
    }, [info.education, info.work_experience])

    return (
        <Wrapper>

            <Block>
                <LeftColumn />
                <Workspace>
                    <Text bold extra={`font-size: 48px; line-height: 62px; margin-bottom: 30px;`} >My profile</Text>
                </Workspace>
            </Block>

            <Block>
                <LeftColumn>
                    <NumberWrapper index={0} >{1}</NumberWrapper>
                    <Frame extra={props => `width: 1px; height: 100%; background: ${props.theme.background.secondary};`} />
                </LeftColumn>
                <Workspace>
                    <Frame row>
                        <Frame extra={`align-items: flex-start;`} >
                            <Text extra={props => `font-size: 12px; margin-bottom: 4px; color: ${props.theme.text.secondary};`} >Profile photo</Text>
                            <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.blue, 0.1)}; cursor: pointer; position: relative; overflow: hidden;`} >
                                {
                                    info.avatarUrl
                                        ? <PhotoPreview onDelete={() => { let new_info = { ...info }; delete new_info.avatarUrl; setInfo(new_info) }} >
                                            <Avatar src={info.avatarUrl} />
                                        </PhotoPreview>
                                        : <Dropzone onUploaded={(url) => { setInfo({ ...info, avatarUrl: url }) }} >
                                            <AddSquare />
                                        </Dropzone>
                                }
                            </Frame>
                        </Frame>
                        <Frame extra={`width: 540px; margin: 24px 0px 0px 58px;`} >
                            <Form
                                data={info}
                                onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                                fields={[
                                    { name: `Name`, type: `string`, short: true, },
                                    { name: `Last name`, type: `string`, short: true, },
                                ]}
                            />
                        </Frame>
                    </Frame>

                </Workspace>
            </Block>

            <Button
                extra={`width: 230px; margin: 15px 0 0 80px;`}
                onClick={async () => { await onSaveProfile(); CommonHelper.linkTo(`/`) }}
            >
                Save
            </Button>

        </Wrapper>
    );
}

const DeleteButtonWrapper = styled(Frame)`
    flex-direction: row;
    margin: 0px 0 20px 0;
    cursor: pointer;
    &:hover {
        > * {
            &:first-child {
                transform: scale(1.2);
            }
            &:last-child {
                opacity: 0.8;
            }
        }
    }
`;

let AddButton = (props) => {
    return (
        <AddButtonWrapper
            {...props}
            extra={`cursor: pointer;`}
        >
            <Frame extra={props => `width: 20px; height: 20px; border-radius: 5px; background: ${convertHex(props.theme.blue, 0.3)};`} >
                <Text bold text_color={props => props.theme.blue} extra={`font-size: 12px;`} >+</Text>
            </Frame>
            <Text extra={`font-size: 16px; margin-left: 5px;`} >Add more</Text>
        </AddButtonWrapper>
    )
}

const AddButtonWrapper = styled(Frame)`
    flex-direction: row;
    &:hover {
        > * {
            &:first-child {
                transform: scale(1.2);
                > * {
                    &:first-child {
                        transform: rotate(90deg);
                    }
                }
            }
            &:last-child {
                opacity: 0.8;
            }
        }
    }
`;

const Workspace = styled(Frame)`
    width: 730px;
    align-items: flex-start;
`;

const NumberWrapper = styled(Frame)`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: ${props => convertHex(props.theme[[`red`, `blue`, `green`][props.index % 3]], 0.1)};
    color: ${props => props.theme[[`red`, `blue`, `green`][props.index % 3]]};
    font-family: Bold;
    font-size: 24px;
`;

const LeftColumn = styled(Frame)`
    margin-right: 40px;
    width: 44px;
`;

const Block = styled(Frame)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
`;

// let Avatar = styled.img.attrs((props) => { })` width: 132px; height: 132px; position: absolute;`
const Avatar = styled(Frame)`
    width: 132px;
    height: 132px;
    background-image: url("${props => props.src}");
    background-position: center;
`;

let CheckMark = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/check_mark.svg`) } catch (error) { }
    return ({ src: img, })
})` width: ${props => +props.visible * 8}px; height: ${props => +props.visible * 7}px; margin-right: ${props => +props.visible * 10}px; transition: 0.2s; transform: scale(${props => +props.visible});`

let TrashCan = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/trash_can_red.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin-right: 10px; transition: 0.2s; `

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin-left: 3px; `

const Wrapper = styled(Frame)`
    position: relative;
    width: 815px;
    align-items: flex-start;
`;

export default EditAdminProfile;

/*eslint-enable*/