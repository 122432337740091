/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'

import { Frame, Text, H, P, Link } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import CommonHelper from '../../../helpers/CommonHelper'
import useDictionary from '../helpers/useDictionary'

let AboutUs = () => {

    let { getText } = useDictionary()

    let tabs = [
        {
            image: ``,
            title: `24Hr`,
            description: getText(`Technical support`),
        },
        {
            image: ``,
            title: `700+`,
            description: getText(`Projects complete`),
        },
        {
            image: ``,
            title: `210+`,
            description: getText(`Happy clients`),
        },
    ]

    return (
        <Workspace>
            <Left>
                <PhotoWrapper />
                <TabsWrapper>
                    {
                        tabs.map((tab, index) => {
                            return (
                                <Tab key={index} >
                                    <Frame row>
                                        <Frame extra={props => `width: 37px; height: 37px; border: 1px solid ${props.theme.blue}; border-radius: 50%; margin: 0 12px 5px 0;`} >
                                            <Logo index={index + 1} />
                                        </Frame>
                                        <Text extra={`font-size: 22px;`} >{tab.title}</Text>
                                    </Frame>
                                    <Frame row>
                                        <Text extra={`font-size: 13px;`} >{tab.description}</Text>
                                    </Frame>
                                </Tab>
                            )
                        })
                    }
                </TabsWrapper>
            </Left>
            <Right>
                <H>{getText(`About Us`)}</H>
                <P extra={`margin-bottom: 0;`} >{getText(`about_us_text`)}</P>
                <Frame row extra={`width: 100%; justify-content: space-between;`} >
                    <Link to={`/programs`}>
                        <Button background={props => props.theme.blue} >{getText(`List of programs`)}</Button>
                    </Link>
                </Frame>
            </Right>
        </Workspace>
    )
}

let Logo = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/landing/AboutUs_${props.index}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 23px;
    height: 23px;
`

const CirclesWrapper = styled(Frame)`
    width: 677px;
    height: 677px;
    position: absolute;
    left: 0;
    z-index: 0;
`;

const Tab = styled(Frame)`
    width: 160px;
    height: 90px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    box-shadow: 0 42px 84px rgba(0, 0, 0, 0.1);
    z-index: 1;

    @media only screen and (max-width: 600px) {
        width: 33vw;
        height: 18.75vw;
        border-radius: 2.5vw;
    }
`;

const TabsWrapper = styled(Frame)`
    width: 160px;
    right: -80px;
    justify-content: space-between;
    position: absolute;
    > * {
        margin-top: 27px;
        &:first-child {
            margin-top: 0px;
        }
    }
`;

const PhotoWrapper = styled(Frame)`
    width: 476px;
    height: 503px;
    background-image: url(${props => require(`../../../assets/landing/AboutUs_0.png`)});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 12px;
    position: relative;
    align-items: flex-end;
    z-index: 1;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
        height: 90vw;
        height: 115vw;
    }
`;

const Workspace = styled(Frame)`
    width: 1100px;
    margin-top: 93.48px;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        width: 100vw;
        height: auto;
        margin-top: 0;
    }
`;

const Left = styled(Frame)`
    width: 476px;
    height: 503px;
    align-items: flex-end;
    position: relative;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin-right: 50vw;
    }
`;

const Right = styled(Frame)`
    width: 530px;
    height: 400px;
    align-items: flex-start;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: auto;
    }
`;

export default AboutUs;

/*eslint-enable*/