/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Circles from '../tools/Circles'

import { Frame, Text, H, Button } from '../../UIKit/styled-templates'
import useDictionary from '../helpers/useDictionary'
import { customHandler } from '../helpers/useCustomDispatch'

let { random } = Math

let HowItWorks = () => {

    let { getText } = useDictionary()

    return (
        <Workspace>
            <H extra={`top: 36px; position: absolute;`}>{getText(`our_programs_title`)}</H>
            <Button background={`#FF9D9D`} extra={`margin: 20px 0 100px 0;`} onClick={() => { customHandler(`OPEN_WIZARD_POP_UP`) }} >{getText(`Select program`)}</Button>
            <Wrapper>
                <Circles width={835} rings={3} contentArray={programsTabs} />
                <Logo />
            </Wrapper>
            <MobileContentWrapper>
                {
                    programsTabs.map(i => i.component)
                }
                <Logo />
            </MobileContentWrapper>
        </Workspace>
    );
}

const MobileContentWrapper = styled(Frame)`
    display: none;

    @media only screen and (max-width: 600px) {
        display: flex;

        flex-wrap: wrap;
        flex-direction: row;
        width: 90vw;
        height: 130vw;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;

        > * {
            &:nth-child(5) {
                margin-left: 27vw;
            }
        }
    }
`;

const Wrapper = styled(Frame)`
    width: 835px;
    height: 835px;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

let Logo = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/rtu_logo_green.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 243px;
    height: 243px;
    position: absolute;
    ${props => props.extra}

    @media only screen and (max-width: 600px) {
        width: 30vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`

let Image = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/landing/OurPrograms_${props.index}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 100px;
    height: 150px;
    transition: 0.2s;
    box-shadow: 0 42px 84px rgba(14, 86, 124, 0.1);
    &:hover {
        box-shadow: 0 20px 60px rgba(87, 138, 214, 0.1);
    }
    ${props => props.extra}

    @media only screen and (max-width: 600px) {
        width: 27vw;
        transform: scale(1);
    }
`

let programsTabs = new Array(8).fill(0).map((item, index) => ({
    component: <Image key={index} index={index} extra={`transform: scale(${0.75 + 0.5 * random()})`} />,
    offsetX: 50,
    offsetY: 75
}))

const Workspace = styled(Frame)`
    width: 1280px;
    height: 1182.04px;
    margin-top: 230px;
    position: relative;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: auto;
        margin-top: 10vw;
        padding-top: 30vw;
    }
`;
export default HowItWorks;

/*eslint-enable*/