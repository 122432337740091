/*eslint-disable*/
/**
 * Created by sabir on 19.07.17.
 */

import { Map, Stack, Set } from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,
    emailsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined }
}

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error }
}

const EmailsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_EMAILS:
        case types.DELETE_EMAILS:
        case types.LOAD_EMAILS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.UPDATE_EMAILS:
            return {
                ...state,
                loading: true,
                error: undefined,
                emailsMap: action.emails.reduce((map, form) => map.set(form.id, form), Map()),
            }

        case types.CREATE_EMAILS_FAIL:
        case types.UPDATE_EMAILS_FAIL:
        case types.DELETE_EMAILS_FAIL:
        case types.LOAD_EMAILS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_EMAILS_SUCCESS:
        case types.UPDATE_EMAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                emailsMap: action.emails.reduce((map, form) => map.set(form.id, form), Map()),
            }

        case types.DELETE_EMAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                emailsMap: state.emailsMap.delete(action.id),
            }

        case types.LOAD_EMAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                emailsMap: state.emailsMap.merge(action.emails.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default EmailsReducer;

/*eslint-enable*/