/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { Frame, Text, P } from '../../UIKit/styled-templates'
import ReactHelper from '../../../helpers/ReactHelper'
import { API_ENDPOINT } from '../../../constants/config'

import useCustomDispatch from '../helpers/useCustomDispatch'
import useDictionary from '../helpers/useDictionary'

let NotificationPopUp = (props) => {

    let { getText } = useDictionary()
    
    let [notifications, setNotifications] = useState([])

    useEffect(() => {
        (async () => {
            // setNotifications((await axios.get(`${API_ENDPOINT}/notifications`)).data.result)
        })()
        return(() => {})
    }, [])

    let setNotificationView = async (id) => {
        await axios.post(`${API_ENDPOINT}/notifications/${id}/view`)
        setNotifications((await axios.get(`${API_ENDPOINT}/notifications`)).data.result)
    }

    let ref = useRef()
    let [visible, setVisible] = useState(false)

    ReactHelper.useOnClickOutside(ref, () => setVisible(false));

    useCustomDispatch(`OPEN_NOTIFICATION_POP_UP`, (e) => { setVisible(true) })
    useCustomDispatch(`CLOSE_NOTIFICATION_POP_UP`, (e) => { setVisible(false) })

    return (
        <NotificationsPopUpWrapper visible={visible} ref={ref} empty={notifications.length === 0} >
            {
                notifications.map((item, index) => {
                    let message = getText(item.text || ``).split(`XXX`).map((a, b) => a + ((item.arguments || [])[b] || ``)).join(``)
                    return (
                        <NotificationWrapper key={index} onClick={() => { setNotificationView(item.id) }} >
                            <Frame row>
                                <NotificationGear />
                                <Text extra={`font-size: 16px; line-height: 48px;`} >{getText(item.type)}</Text>
                            </Frame>
                            <Text text_color={props => props.theme.text.secondary} extra={`width: 360px; font-size: 14px; line-height: 26px; margin: 5px 0; align-items: flex-start;`} >{message}</Text>
                        </NotificationWrapper>
                    )
                })
            }
            {notifications.length === 0 ? <Frame extra={props => `width: 100%; margin: 0; font-size: 16px; color: ${props.theme.text.secondary}; @media only screen and (max-width: 600px) { font-size: 3.75vw; };`} >{getText(`Empty`)}</Frame> : null}
        </NotificationsPopUpWrapper>
    )
}

const NotificationWrapper = styled(Frame)`
    align-items: flex-start;
    margin-top: 5px;

    @media only screen and (max-width: 600px) {
        
    }
`;

let NotificationGear = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/notification_gear.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 40px;
    height: 40px;
    margin-right: 10px;
`

const NotificationsPopUpWrapper = styled(Frame)`
    padding: 16px 30px 22px 30px;
    min-width: 120px;
    border-radius: 12px;
    box-shadow: 0px 20px 50px rgba(87, 138, 214, 0.2);
    background: ${props => props.theme.background.primary};
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    right: 57px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    top: ${props => 73 + 10 * +props.visible}px;
    visibility: ${props => props.visible ? `visible` : `hidden`};
    opacity: ${props => +props.visible};
    max-height: 80vh;
    overflow-y: scroll;
    
    > * {
        border-bottom: 1px solid ${props => props.theme.background.secondary};
        margin-bottom: 5px;
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    @media only screen and (max-width: 600px) {
        width: 84vw;
        top: 60px;
        right: 5vw;
        padding: 5vw 3vw;
    }
`;

export default NotificationPopUp;
/*eslint-enable*/