/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useDispatch, useMappedState } from 'redux-react-hook'

import * as actions from '../../redux/actions/UsersActions'
import { Frame, Text, convertHex } from './styled-templates'
import Input from './Input'
import Button from './Button'
import Form from './Form'
import CommonHelper from '../../helpers/CommonHelper';
import ProjectTab from '../rtu/tools/ProjectTab'
import Tags from '../rtu/tools/Tags'

import useCurrentUser from '../rtu/helpers/useCurrentUser'
import useDictionary from '../rtu/helpers/useDictionary'

let TagsInput = (props) => {

    let { getText, dict } = useDictionary()

    let { tagsArray = Object.keys(dict).filter(i => i.indexOf(`tag_`) > -1), selectedTagsArray = [], onChange = () => { }, extra = `` } = props

    let [selectedTags, setSelectedTags] = useState(selectedTagsArray)

    useEffect(() => { setSelectedTags(selectedTagsArray) }, [selectedTagsArray])

    return (
        <Wrapper extra={extra} >
            {
                tagsArray.map((item, index) => {
                    let isSelected = selectedTags.indexOf(item) > -1
                    return (
                        <Frame
                            key={index}
                            text_color={props => props.theme.text.secondary}
                            extra={props => `background: ${isSelected ? convertHex(props.theme.green, 1) : props.theme.background.primary}; padding: 9px ${isSelected ? 24 : 33}px 9px ${isSelected ? 24 : 33}px; border-radius: 4px; border: 1px solid rgba(0, 0, 0, 0.05); margin: 0 10px 5px 0; text-transform: uppercase; font-size: 16px; line-height: 22px; color: ${isSelected ? `white` : props.theme.text.secondary}; cursor: pointer; flex-direction: row;`}
                            onClick={() => {
                                let newSelectedTagsArray = [...selectedTags, item].filter(i => selectedTags.indexOf(item) > -1 ? i !== item : true)
                                // newSelectedTagsArray = newSelectedTagsArray.map(i => getText(i))
                                onChange(newSelectedTagsArray)
                            }}
                        >
                            <CheckMark visible={isSelected} />
                            {getText(item)}
                        </Frame>
                    )
                })
            }
        </Wrapper>
    );
}

const Wrapper = styled(Frame)`
    width: 730px;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 20px;
    ${props => props.extra}

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin: 0;
    }
`;

const Workspace = styled(Frame)`
    width: 730px;
    align-items: flex-start;
`;

const NumberWrapper = styled(Frame)`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: ${props => convertHex(props.theme[[`red`, `blue`, `green`][props.index % 3]], 0.1)};
    color: ${props => props.theme[[`red`, `blue`, `green`][props.index % 3]]};
    font-family: Bold;
    font-size: 24px;
`;

const LeftColumn = styled(Frame)`
    margin-right: 40px;
    width: 44px;
`;

const Block = styled(Frame)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
`;

let CheckMark = styled.img.attrs((props) => {
    let img
    try { img = require(`../../assets/images/check_mark.svg`) } catch (error) { }
    return ({ src: img, })
})` width: ${props => +props.visible * 8}px; height: ${props => +props.visible * 7}px; margin-right: ${props => +props.visible * 10}px; transition: 0.2s; transform: scale(${props => +props.visible});`

export default TagsInput;

/*eslint-enable*/