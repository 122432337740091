/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react'
import styled, { keyframes } from 'styled-components'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import * as emailsActions from '../../../redux/actions/EmailsActions'

import { Frame, Button, H, H1, P, Link } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch, { cutomHandler } from '../helpers/useCustomDispatch'

let fields = [
    { type: `string`, name: `Title` },
    { type: `string`, name: `Subject` },
    { type: `wysiwyg`, name: `html` },
]

let EmailsList = (props) => {

    let { getText } = useDictionary()
    let path = useLocation().pathname.split(`/`).slice(1)
    let { emails } = useMappedState(useCallback((state) => ({ emails: state.emails.emailsMap.toArray() }), [path[1]]))
    let dispatch = useDispatch()

    let [email, setEmail] = useState({})

    let superUser = window.location.href.indexOf(`admin`) > -1

    useEffect(() => {
        setEmail(emails.get(path[1]))
    }, [path[1]])

    let key = { title: ``, ...email }.title.split(` `).join(`_`).toLowerCase()

    let onSave = async () => {
        await dispatch(emailsActions.updateEmails({ ...email, key: key }))
        CommonHelper.linkTo(`/emails`)
    }

    let onDelete = async () => {
        await dispatch(emailsActions.deleteEmails(email.id))
        CommonHelper.linkTo(`/emails`)
    }

    let onBack = async () => {
        setEmail(emails.get(path[1]))
        CommonHelper.linkTo(`/emails`)
    }

    return (
        <Wrapper>
            <H extra={`margin: 20px 0 30px 0;`} >{getText(`Editor`)}</H>
            <KeyFrame>@{key}</KeyFrame>
            <Form
                fields={fields.filter(i => i.name === `Title` ? superUser : true)}
                data={email}
                onChange={(key, value) => { setEmail({ ...email, [key]: value }) }}
            />
            <Frame row >
                <Button onClick={onBack} background={props => props.theme.blue} shaped >{getText(`Back`)}</Button>
                <Button onClick={onSave} background={props => props.theme.blue} extra={`margin-left: 5px;`} >{getText(`Save`)}</Button>
                {superUser ? <Button onClick={onDelete} background={props => props.theme.red} extra={`margin-left: 5px;`} >{getText(`Delete`)}</Button> : null}
            </Frame>
        </Wrapper>
    )
}

const KeyFrame = styled(Frame)`
    font-size: 14px;
    color: ${props => props.theme.text.secondary};
    margin-bottom: 20px;
    overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline;
`;

const jump = keyframes`
    0% { transform: rotate(-90deg) translateY(0); }
    50% { transform: rotate(-90deg) translateY(10px); }
    100% { transform: rotate(-90deg) translateY(0); }
`

const EditButton = styled(Frame)`
    flex-direction: row;
    opacity: 0.2;
    &:hover {
        &:after {
            animation: ${jump} 0.3s linear;
        }
    }
    &:after {
        content: '';
        width: 10px;
        height: 10px;
        background: url("${require(`../../../assets/images/arrow_down_black.svg`)}") no-repeat center center;
        transform: rotate(-90deg);
    }
    &:before {
        content: '${props => props.content}';
        color: ${props => props.theme.text.primary};
        margin-right: 10px;
    }
`;

const Tab = styled(Frame)`
    width: calc((815px - 20px * 2) / 3);
    height: 200px;
    border-radius: 12px;
    border: 1px solid ${props => props.theme.background.secondary};
    cursor: pointer;
    box-sizing: border-box;
    padding: 40px;
    align-items: flex-start;
    justify-content: space-between;
    > * {
        &:last-child {
            opacity: 0;
            transform: translateY(5px);
        }
    }
    &:hover {
        box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
        > * {
            &:last-child {
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
`;

const AddTab = styled(Tab)`
    background: ${props => props.theme.blue};
    align-items: center;
    justify-content: center;
    &:hover {
        transform: rotate(2deg) scale(1.05);
        &:after {
            transition: 0.2s;
            transform: scale(1.05) rotate(90deg);
        }
    }
    &:after {
        content: '+';
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Bold;
        font-size: 62px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        color: white;
        background: rgba(255, 255, 255, 0.3);
    }
`;

const TabsWrapper = styled(Frame)`
    width: 815px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    > * {
        margin-right: 15px;
        margin-bottom: 15px;
        &:nth-child(3n) {
            margin-right: 0px;
        }
    }
`;

const Wrapper = styled(Frame)`
    width: 815px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 125px;
    min-height: calc(100vh - 300px);
`;

export default EmailsList;
/*eslint-enable*/