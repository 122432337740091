/*eslint-disable*/
import React, { useState } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation, useHistory } from 'react-router-dom'

import { Frame, Text, convertHex, H1, P } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import Tags from '../tools/Tags'
import ProjectTab from '../tools/ProjectTab'
import CommonHelper from '../../../helpers/CommonHelper'
import useCurrentUser from '../helpers/useCurrentUser'

let animatedComponents = makeAnimated();

let categories = [`First`, `Second`, `Third`].map(item => ({ value: item, label: item }))

let Gallery = (props) => {

    let { photoArray = [] } = props

    let [selectedPhoto, setSelectedPhoto] = useState(0)

    return (
        <>

            <GalleryPhoto src={photoArray[selectedPhoto]} onClick={() => { if (window.innerWidth <= 600) { setSelectedPhoto((selectedPhoto + 1) % photoArray.length) } }} >
                <Frame
                    extra={props => `cursor:pointer; &:hover { transform: scale(1.05); }; width: 60px; height: 60px; border-radius: 30px; background: ${props.theme.background.primary}; position: absolute; left: -30px; box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07); @media only screen and (max-width: 600px) { opacity: 0; };`}
                    onClick={() => { setSelectedPhoto((selectedPhoto - 1 + photoArray.length) % photoArray.length) }}
                >
                    <ArrowLeft />
                </Frame>
                <Frame
                    extra={props => `cursor:pointer; &:hover { transform: scale(1.05); }; width: 60px; height: 60px; border-radius: 30px; background: ${props.theme.background.primary}; position: absolute; right: -30px; box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07); @media only screen and (max-width: 600px) { opacity: 0; };`}
                    onClick={() => { setSelectedPhoto((selectedPhoto + 1) % photoArray.length) }}
                >
                    <ArrowRight />
                </Frame>
            </GalleryPhoto>

            <Frame row extra={`width: 100%; margin-top: 20px; position: relative; @media only screen and (max-width: 600px) { margin-top: 6.25vw; };`} >
                {
                    new Array(photoArray.length + 1).fill(0).map((item, index) => {
                        return (
                            <Frame key={index} onClick={() => { setSelectedPhoto(index < selectedPhoto ? index : index - 1) }} extra={props => `cursor: pointer; width: 15px; height: 15px; transform: scale(${index === selectedPhoto || index === selectedPhoto + 1 ? 0 : 1}); border-radius: 15px; border: 2px solid ${props.theme.red}; box-sizing: border-box; margin-right: 10px; transition: 0.2s !important;`} >

                            </Frame>
                        )
                    })
                }
                <Frame extra={props => `width: 40px; height: 15px; border-radius: 15px; background: ${props.theme.red}; position: absolute; left: calc(50% - ${(photoArray.length + 1) / 2 * 25}px + ${selectedPhoto * 25}px); transition: 0.2s !important;`} >

                </Frame>
            </Frame>

        </>
    );
}

const GalleryPhoto = styled(Frame)`
    width: 920px;
    height: 557px;
    background-image: url("${props => props.src}");
    background-position: center;
    position: relative;
    box-sizing: border-box;
    border: 1px solid ${props => props.theme.background.secondary};

    @media only screen and (max-width: 600px) {
        width: 100vw;
        height: 60vw;
        margin-left: -10vw;
        transform: translateX(5vw);
    }
`;

let ArrowLeft = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_down.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 13px; height: 13px; transform: rotate(90deg); user-select: none;`

let ArrowRight = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_down.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 13px; height: 13px; transform: rotate(-90deg); user-select: none;`

let EmptyAvatar = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/empty_avatar.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 160px; height: 160px;`

const TagWrapper = styled(Text)`
    padding: 0 22px 0 22px;
    border-radius: 4px;
    background: ${props => convertHex(props.theme.green, 0.15)};
    margin: 0 10px 5px 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 22px;
`;

const Workspace = styled(Frame)`
    width: 920px;
    align-items: flex-start;
`;

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 36px; height: 36px; margin-left: 22px; `

let LocationImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/location.png`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin: 0 15px 0 30px;`

let PhoneImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/phone.png`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin: 0 15px 0 30px;`

const AvatarWrapper = styled(Frame)`
    width: 160px;
    height: 160px;
    border-radius: 80px;
    border: 2px solid ${props => props.theme.background.primary};
    box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
    background-image: url("${props => props.src}");
    background-position: center;
`;

const HeaderWrapper = styled(Frame)`
    align-items: flex-start;
    margin-bottom: 40px;
`;

const BackButtonWrapper = styled(Frame)`
    width: 825px;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;
    cursor: pointer;
    z-index: 1;
`;

const HeaderImageWrapper = styled(Frame)`
    width: 1100px;
    height: ${props => props.src ? 256 : 100}px;
    justify-content: flex-start;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
    background-image: url("${props => props.src}");
    background-position: center;
`;

let ShareLogo = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/share_${props.name}.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 15px; height: 15px; `

let BackArrow = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/back_arrow.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 16px;
    height: 16px;
    margin-right: 3px;
`

const Wrapper = styled(Frame)`
    
`;

export default Gallery;

/*eslint-enable*/