/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react'
import styled, { keyframes } from 'styled-components'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import * as emailsActions from '../../../redux/actions/EmailsActions'

import { Frame, Button, H, H1, P, Link } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch, { cutomHandler } from '../helpers/useCustomDispatch'

let EmailsList = (props) => {

    let { getText } = useDictionary()
    let { emails } = useMappedState(useCallback((state) => ({ emails: state.emails.emailsMap.toArray() }), []))
    let dispatch = useDispatch()

    let superUser = window.location.href.indexOf(`admin`) > -1

    return (
        <Wrapper>
            <H extra={`margin: 20px 0 30px 0;`} >{getText(`Emails`)}</H>
            <TabsWrapper>
                {
                    emails.map((item, index) => {
                        return (
                            <Link to={`/emails/${item.id}/edit`} key={index} >
                                <Tab>
                                    <Frame extra={`align-items: flex-start;`} >
                                        <H1 extra={`margin: 0px !important; font-size: 16px !important; line-height: 30px !important; width: 150px; overflow: ellipsis; overflow: hidden; white-space: nowrap; display: inline;`} >{item.title}</H1>
                                        <KeyFrame>@{item.key}</KeyFrame>
                                    </Frame>
                                    <Frame extra={`width: 100%; align-items: flex-end;`} >
                                        <EditButton content={getText(`edit`, `LOWERCASE`)} />
                                    </Frame>
                                </Tab>
                            </Link>
                        )
                    })
                }
                {
                    superUser ? <AddTab onClick={async () => {
                        let id = (await dispatch(emailsActions.createEmails({}))).emails.map(i => i.id).slice(-1)[0]
                        if (id) {
                            CommonHelper.linkTo(`/emails/${id}/edit`)
                        }
                    }} /> : null
                }
            </TabsWrapper>
        </Wrapper>
    )
}

const KeyFrame = styled(Frame)`
    font-size: 12px;
    color: ${props => props.theme.text.secondary};
    width: 150px;
    overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline;
`;

const jump = keyframes`
    0% { transform: rotate(-90deg) translateY(0); }
    50% { transform: rotate(-90deg) translateY(10px); }
    100% { transform: rotate(-90deg) translateY(0); }
`

const EditButton = styled(Frame)`
    flex-direction: row;
    opacity: 0.2;
    &:hover {
        &:after {
            animation: ${jump} 0.3s linear;
        }
    }
    &:after {
        content: '';
        width: 10px;
        height: 10px;
        background: url("${require(`../../../assets/images/arrow_down_black.svg`)}") no-repeat center center;
        transform: rotate(-90deg);
    }
    &:before {
        content: '${props => props.content}';
        color: ${props => props.theme.text.primary};
        margin-right: 10px;
    }
`;

const Tab = styled(Frame)`
    width: calc((815px - 20px * 2) / 3);
    height: 200px;
    border-radius: 12px;
    border: 1px solid ${props => props.theme.background.secondary};
    cursor: pointer;
    box-sizing: border-box;
    padding: 40px;
    align-items: flex-start;
    justify-content: space-between;
    > * {
        &:last-child {
            opacity: 0;
            transform: translateY(5px);
        }
    }
    &:hover {
        box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
        > * {
            &:last-child {
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
`;

const AddTab = styled(Tab)`
    background: ${props => props.theme.blue};
    align-items: center;
    justify-content: center;
    &:hover {
        transform: rotate(2deg) scale(1.05);
        &:after {
            transition: 0.2s;
            transform: scale(1.05) rotate(90deg);
        }
    }
    &:after {
        content: '+';
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Bold;
        font-size: 62px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        color: white;
        background: rgba(255, 255, 255, 0.3);
    }
`;

const TabsWrapper = styled(Frame)`
    width: 815px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    > * {
        margin-right: 15px;
        margin-bottom: 15px;
        &:nth-child(3n) {
            margin-right: 0px;
        }
    }
`;

const Wrapper = styled(Frame)`
    width: 815px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 125px;
    min-height: calc(100vh - 300px);
`;

export default EmailsList;
/*eslint-enable*/