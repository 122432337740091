/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import { useDispatch, useMappedState } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import * as programsActions from '../../../redux/actions/ProgramsActions'

import { Frame, Input, H1, Button, AddSquare, convertHex, Checkbox, P, Textarea } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import Dropzone from '../../UIKit/Dropzone'
import PopUpWrapper from './PopUpWrapper'
import PhotoPreview from '../tools/PhotoPreview'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch from '../helpers/useCustomDispatch'
import useCurrentUser from '../helpers/useCurrentUser'

let keyFrom = (s) => s.toLowerCase().split(` `).join(`_`)

let ConfirmProgramApplicationPopUp = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let currentUser = useCurrentUser()

    let dispatch = useDispatch()
    let { program } = useMappedState(useCallback(state => {
        return ({
            program: { info: {}, ...state.programs.programsMap.get(path[1]) },
        })
    }, [path[1]]))

    let { getText, lang } = useDictionary(() => { })

    let [action, setAction] = useState({})

    let onClose = () => { window.dispatchEvent(new CustomEvent(`CLOSE_CONFIRM_PROGRAM_APPLICATION_POP_UP`)) }

    let alreadyApplied = (program.applications || []).filter(i => i.userId === currentUser.id).length > 0
    let onApplyProgram = (studentComment) => {
        if (alreadyApplied) {
            dispatch(programsActions.cancelApplicationToProgram(action.id))
        } else {
            dispatch(programsActions.applyToProgram({ id: action.id, studentComment }))
        }
    }

    let [policyChecked, setPolicyChecked] = useState(false)
    let [studentComment, setStudentComment] = useState({})

    useCustomDispatch(`OPEN_CONFIRM_PROGRAM_APPLICATION_POP_UP`, (e) => { setAction(e.detail) })
    useCustomDispatch(`CLOSE_CONFIRM_PROGRAM_APPLICATION_POP_UP`, (e) => { setAction({}) })

    // let idByKey = (key) => (program.info.questions || []).filter(i => keyFrom(i.title_lv) === key || keyFrom(i.title_eng) === key)[0].id
    let idByKey = (key) => ({ id: undefined, ...program.info.questions.filter(i => keyFrom(i.title_lv) === key || keyFrom(i.title_eng) === key)[0] }).id
    let keyById = (id) => (program.info.questions || []).get(id)[`title_${lang.toLowerCase()}`]
    let transformedComment = {}
    Object.keys(studentComment).forEach(i => { transformedComment[idByKey(i)] = studentComment[i] })
    let transformedBackComment = {}
    Object.keys(transformedComment).forEach(i => { transformedBackComment[keyFrom(keyById(i))] = transformedComment[i] })
    let isDisabled = (program.info.questions || []).filter(i => i.required === true).map(i => i.id).map(i => transformedComment[i]).map(i => i == `` || i === undefined).map(i => +i).reduce((a, b) => a + b, 0) !== 0 || !policyChecked

    let answer = {}
    Object.keys(transformedComment).forEach((a, b) => {
        let q = (program.info.questions || []).get(a)
        switch (q.type) {
            case `multicheck`:
            case `singlecheck`:
                answer[q[`title_${lang.toLowerCase()}`]] = transformedComment[a].map(i => q.options.get(i)[lang.toLowerCase()])
                break;
            default:
                answer[q[`title_${lang.toLowerCase()}`]] = transformedComment[a]
                break;
        }
    })

    return (
        <PopUpWrapper name={`CONFIRM_PROGRAM_APPLICATION`}>
            <H1 extra={`@media only screen and (max-width: 600px) { width: 90vw; };`} >{getText(`Confirm`)}</H1>
            <P extra={`width: 100%; align-items: center; text-align: center; @media only screen and (max-width: 600px) { width: 90vw; };`} >{getText(`Write why you want to participate in this program`)}</P>
            {
                (program.info.questions || []).map((item, index) => {
                    return (
                        <Form
                            key={index}
                            data={transformedBackComment}
                            onChange={(key, value) => { setStudentComment({ ...transformedBackComment, [key]: value }) }}
                            fields={[{ type: item.type, name: item[(`title_${lang}` || ``).toLowerCase()] || ``, options: item.options }]}
                        />
                    )
                })
            }
            <Frame row extra={`margin: 15px 0; @media only screen and (max-width: 600px) { flex-direction: column; };`} >
                <Checkbox checked={policyChecked} extra={`@media only screen and (max-width: 600px) { margin-bottom: 0; };`} onChange={(e) => { setPolicyChecked(e) }} />
                <P extra={`margin: 0 0 0 15px; @media only screen and (max-width: 600px) { margin: 5px 0 !important; };`} >{getText(`By clicking I agree to the`)}</P>
                <P extra={props => `margin: 0 0 0 5px; color: ${props.theme.blue}; cursor: pointer; @media only screen and (max-width: 600px) { margin: 0 0 5px 0 !important; };`} onClick={() => { window.open(`https://` + program.info[`conditions_link`].split(`https://`).join(``)) }} >{getText(`terms and conditions`)}</P>
            </Frame>
            <Frame row>
                <Button shaped background={props => props.theme.blue} onClick={() => { onClose() }} >{getText(`Go back`)}</Button>
                <Button extra={`margin-left: 5px;`} disabled={isDisabled} background={props => props.theme.red} onClick={() => { if (currentUser.userRole === `student`) { onApplyProgram(answer); } onClose() }} >{getText(`Send request`)}</Button>
            </Frame>
        </PopUpWrapper >
    );
}

const Avatar = styled(Frame)`
    width: 132px;
    height: 132px;
    background-image: url("${props => props.src}");
    background-position: center;
`;

export default ConfirmProgramApplicationPopUp;

/*eslint-enable*/