/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useDispatch } from 'redux-react-hook'

import * as usersActions from '../../../redux/actions/UsersActions'

import { Frame, Text, P, H1, H, Link } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import Button from '../../UIKit/Button'
import { API_ENDPOINT } from "../../../constants/config";
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'

let StudentRegisterFields = [
    { name: `Name`, type: `string`, short: true },
    { name: `Last name`, type: `string`, short: true },
    { name: `Email`, type: `string` },
    { name: `Create password`, type: `password`, short: true, },
    { name: `Confirm password`, type: `password`, short: true, },
]

let CompanyRegisterFields = [
    { name: `Email`, type: `string` },
    { name: `Create password`, type: `password`, short: true, },
    { name: `Confirm password`, type: `password`, short: true, },
    { name: `Company name`, type: `string` },
    { name: `What result do you want to get from the performer?`, type: `textarea` },
]

let validateEmail = (email) => (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)

let checkPassword = (password) => {
    if (!password.match(/[a-z]+/)) { return `Password sould have at least 1 letter between lowercase [ a - z ]` }
    if (!password.match(/[A-Z]+/)) { return `Password sould have at least 1 letter between uppercase [ A - Z ]` }
    if (!password.match(/[0-9]+/)) { return `Password sould have at least 1 number between [ 0 - 9 ]`; }
    if (password.length < 8) { return `Minimum number of characters is 6` }
    return `Ok`
}


let SignUpPage = (props) => {
    let path = useLocation().pathname.split(`/`).slice(1)
    let role = path[1]
    let { getText } = useDictionary()
    let [tempData, setTempData] = useState({})
    let dispatch = useDispatch()
    let onSignUp = () => {
        let info = { ...tempData }
        delete info.create_password
        delete info.repeat_password
        if (!validateEmail(info.email)) {
            window.dispatchEvent(new CustomEvent(`CALL_INPUT_ERROR`, { detail: `Email` }))
            setTimeout(() => { window.alert(`Email is not valid`) }, 200)
            return
        }
        if (checkPassword(tempData.create_password) !== `Ok`) {
            window.dispatchEvent(new CustomEvent(`CALL_INPUT_ERROR`, { detail: `Create password` }))
            setTimeout(() => { window.alert(checkPassword(tempData.create_password)) }, 200)
            return
        }
        if (tempData.create_password !== tempData.confirm_password) {
            window.dispatchEvent(new CustomEvent(`CALL_INPUT_ERROR`, { detail: `Confirm password` }))
            setTimeout(() => { window.alert(`Password doesn't match`) }, 200)
            return
        }
        // console.log('Button: tempData = ', tempData);
        axios.post(`${API_ENDPOINT}/users/create`, { email: info.email.toLowerCase(), password: tempData.create_password, userRole: role, info }).then(d => d.data).then(pld => {
            CommonHelper.linkTo(`/`)
            if (pld.error !== undefined) {
                window.alert(pld.error.message);
                return;
            }
            // console.log(pld);
            dispatch(usersActions.logIn({ email: info.email.toLowerCase(), password: tempData.create_password })).then(pld => {
                // console.log('signing in');
            });
        })
    }
    return (
        <Wrapper role={role} >
            <Photo role={role} />
            <ScrollWrapper>
                <Main>
                    <Header>
                        <Link to={`/`} >
                            <RTUlogo />
                        </Link>
                        <Link to={`/signup/${role === `company` ? `student` : `company`}`} >
                            <P extra={props => `color: ${props.theme[role === `company` ? `red` : `blue`]}; font-size: 14px; margin-bottom: 0; @media only screen and (max-width: 600px) { display: none; };`} >
                                {getText(`Sign up as a ${role === `company` ? `student` : `company`}`)}
                            </P>
                        </Link>
                    </Header>
                    <H1 extra={`margin-bottom: 33px; @media only screen and (max-width: 600px) { margin-bottom: 4.375vw; };`} >{getText(`Register ${role}`)}</H1>
                    <P extra={`font-size: 12px; @media only screen and (max-width: 600px) { display: none; };`} >{getText(`Fill out the form to register a company. We will contact you to confirm your registration`)}</P>
                    <Form
                        fields={role === `company` ? CompanyRegisterFields : StudentRegisterFields}
                        data={tempData}
                        onChange={(key, value) => { setTempData({ ...tempData, [key]: value }) }}
                    />
                    <P extra={`font-size: 12px; margin: 5px 0 20px 0 !important; @media only screen and (max-width: 600px) { font-size: 3.75vw !important; };`} >{getText(`By clicking Continue with or Sign up, you agree to  Terms of Service and Privacy Policy`)}</P>
                    <Button background={props => props.theme[role === `company` ? `red` : `blue`]} onClick={onSignUp} >{getText(`Sign up`)}</Button>
                    <Link to={`/login`} >
                        <Frame row extra={`margin-top: 10px; > * { text-transform: uppercase; font-size: 12px !important; };`} >
                            <P>{getText(`Already have an account?`)}</P>
                            <P extra={props => `margin-left: 5px; color: ${props.theme.green};`} >{getText(`Login`)}</P>
                        </Frame>
                    </Link>
                    <Link to={`/signup/${role === `company` ? `student` : `company`}`} >
                        <P extra={props => `color: ${props.theme[role === `company` ? `red` : `blue`]}; font-size: 3.75vw !important; @media only screen and (min-width: 600px) { display: none; };`} >
                            {getText(`Sign up as a ${role === `company` ? `student` : `company`}`)}
                        </P>
                    </Link>
                </Main>
            </ScrollWrapper>
        </Wrapper>
    )
}

const RTUlogo = styled(Frame)`
    width: 100px;
    height: 100px;
    background-image: url(${require('../../../assets/images/rtu_logo_green.svg')});
    background-position: center;
    background-size: contain;
    
    @media only screen and (max-width: 600px) {
        width: 55px;
        height: 55px;
        margin-bottom: 10.625vw;
    }
    `;

const Header = styled(Frame)`
    width: 730px !important;
    flex-direction: row;
    justify-content: space-between;
    
    @media only screen and (max-width: 600px) {
        width: auto !important;
    }
    `;

const ScrollWrapper = styled(Frame)`
    overflow-y: scroll;
    display: block;
    max-height: 100vh;
`;

const Main = styled(Frame)`
    width: calc(100vw - 515px);
    > * {
        width: 540px;
        text-align: center;
        &:nth-child(4) {
            text-align: left;
        }
        @media only screen and (max-width: 600px) {
            width: 90vw;
        };
    }

    @media only screen and (max-width: calc(730px + 515px)) {
        width: 100vw;
    }
    
    @media only screen and (max-width: 600px) {
        width: 100vw;
        height: auto;
    }
`;

const Photo = styled(Frame)`
    width: 515px;
    height: 100vh;
    background-image: url(${props => require(`../../../assets/images/SignUp${props.role.charAt(0).toUpperCase() + props.role.slice(1)}_0.png`)});
    background-position: center;
    /* background-size: contain; */
    background-repeat: no-repeat;
    background-size: auto;

    @media only screen and (max-width: calc(730px + 515px)) {
        display: none;
    }
`;

const Wrapper = styled(Frame)`
    width: 100vw;
    height: 100vh;
    flex-direction: ${props => `row` + (props.role === `company` ? `-reverse` : ``)};

    @media only screen and (max-width: 600px) {
        width: 100vw;
        height: auto;
    }
`;

export default SignUpPage;
/*eslint-enable*/