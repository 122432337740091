/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import { useDispatch, useMappedState } from "redux-react-hook";
import { useLocation } from 'react-router-dom'
import axios from 'axios'

import { Frame, } from '../UIKit/styled-templates'

import SignUpPage from '../rtu/pages/signup-page'
import LoginPage from '../rtu/pages/login-page'
import RecoverPasswordPage from '../rtu/pages/recover-password-page'

let LoginApp = (props) => {

    let mode = useLocation().pathname.split(`/`).slice(1)[0]

    switch (mode) {
        case `login`:
            return <LoginPage />
        case `signup`:
            return <SignUpPage />
        case `recover`:
            return <RecoverPasswordPage />
        default:
            return <AuthPages />
    }

}

export default LoginApp;

/*eslint-enable*/
