/*eslint-disable*/
import React, { Component } from 'react';
import './App.css';
import styled from 'styled-components'
import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger'
import { reducer } from './redux/reducers'
import { default as ReduxThunk } from 'redux-thunk';
import RouterApp from "./components/apps/RouterApp";
import { ThemeWrapper } from './components/UIKit/styled-templates'
import { StoreContext } from 'redux-react-hook';

// const store = createStore(reducer, undefined, compose(applyMiddleware(ReduxThunk), applyMiddleware(logger)))
const store = createStore(reducer, undefined, compose(applyMiddleware(ReduxThunk)))

let App = (props) => {
    return (
        <StoreContext.Provider value={store}>
            <ThemeWrapper>
                <RouterApp />
            </ThemeWrapper>
        </StoreContext.Provider>
    )
}

export default App;

/*eslint-enable*/