/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { useLocation } from 'react-router-dom'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { useDebouncedCallback } from 'use-debounce'

import * as wizardActions from '../../../redux/actions/WizardActions'

import { Frame, Text, convertHex, P, H1, H, Link, Input } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import Form from '../../UIKit/Form'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch, { customHandler } from '../helpers/useCustomDispatch'

let WizardEdit = () => {

    let { getText, langs, lang } = useDictionary()
    let dispatch = useDispatch()

    let { info, programs } = useMappedState(useCallback((state) => ({
        info: state.wizard.wizard,
        programs: state.programs.programsMap.toArray(),
    })))

    let setWizard = (new_wizard) => { dispatch(wizardActions.setWizard(new_wizard)) }

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (info.questions.length === 0 && updateTime > 0) {
    //             window.alert(`0 questions`)
    //         };
    //         window.location.reload()
    //     }, 2500)
    // }, [])

    useEffect(() => {
        let newWeights = Object.fromEntries(info.questions.map(question => {
            let emptyRow = Object.fromEntries(programs.map(i => [i.id, 0]))
            let matrix = Object.fromEntries((question.options || []).map(i => i.id).map(i => [i, emptyRow]))
            return [question.id, matrix]
        }))
        Object.keys(info.weights).forEach(question_id => {
            Object.keys(info.weights[question_id]).forEach(option_id => {
                try {
                    newWeights[question_id][option_id] = info.weights[question_id][option_id] || newWeights[question_id][option_id]
                } catch (error) { }
            })
        })
        setWizard({ ...info, weights: newWeights })
    }, [JSON.stringify(info), programs.length])

    let onUpdateWizard = () => {
        if (info.questions.map(i => i[`title_${langs[0].toLowerCase()}`]).unique().length < info.questions.length) {
            window.alert(`You have similar questions titles. Please, rename one of them and try again.`)
            return
        }
        if (info.questions.map(i => i[`title_${langs[1].toLowerCase()}`]).unique().length < info.questions.length) {
            window.alert(`You have similar questions titles. Please, rename one of them and try again.`)
            return
        }
        if (info.questions.length === 0) {
            window.alert(`You are trying to save empty wizard`)
            return
        }
        dispatch(wizardActions.updateWizard(info))
    }

    const [debouncedSaveProject] = useDebouncedCallback(() => { onUpdateWizard() }, 2000, []);
    useEffect(() => { debouncedSaveProject() }, [JSON.stringify(info)]);

    return (
        <Wrapper>
            {
                (info.questions || []).length > 0 ? <>
                    <H1>{getText(`Reorder questions`)}</H1>
                    {
                        (info.questions || []).map((item, index) => {
                            return (
                                <Frame key={index} row extra={props => `width: 200px; height: 30px; padding: 10px; border-radius: 12px; border: 1px solid ${props.theme.background.secondary}; justify-content: flex-start; margin-bottom: 10px; cursor: move;`} >
                                    <P extra={`margin: 0; width: 150px; text-overflow:ellipsis; overflow: hidden; white-space: nowrap; display: inline;`} >{item[`title_${lang.toLowerCase()}`]}</P>
                                    <ArrowDown onClick={() => { if (index < info.questions.length - 1) { setWizard({ ...info, questions: info.questions.swap(index, index + 1) }) } }} />
                                    <ArrowUp onClick={() => { if (index > 0) { setWizard({ ...info, questions: info.questions.swap(index, index - 1) }) } }} />
                                </Frame>
                            )
                        })
                    }
                </> : null
            }
            <H extra={`margin-top: 15px;`} >{getText(`Wizard questionary`)}</H>
            {
                (info.questions || []).map((item, index) => {
                    return (
                        <Tab key={index} >
                            <H1>{getText(`Question`)} {index + 1}</H1>
                            <Form
                                data={{ ...item, type: { value: item.type, label: item.type } }}
                                onChange={(key, value) => { setWizard({ ...info, questions: info.questions.map((a, b) => b === index ? { ...a, [key]: value, options: key === `type` && (value === `multicheck` || value === `singlecheck`) ? [] : a.options } : a) }) }}
                                fields={[
                                    { type: `select`, name: `type`, options: [`singlecheck`, `multicheck`].map(i => ({ value: i, label: i })) },
                                    { type: `checkbox`, name: `required` },
                                    ...(item.type ? langs.map((lang) => ({ type: `string`, name: `title ${lang}` })) : []),
                                ]}
                            />
                            {
                                item.type === `multicheck` || item.type === `singlecheck` ? <>
                                    {
                                        (item.options || []).map((option, option_i) => {
                                            return (
                                                <Frame key={option_i}>
                                                    <Frame row extra={`width: 100%; justify-content: space-between; margin-bottom: 15px;`} >
                                                        <P extra={`margin-bottom: 0px;`} >{getText(`Option`)} {option_i + 1}</P>
                                                        <TrashCan onClick={() => { setWizard({ ...info, questions: info.questions.map((a, b) => b === index ? { ...a, options: a.options.filter((a, b) => b !== option_i) } : a) }) }} />
                                                    </Frame>
                                                    <Form
                                                        data={option}
                                                        onChange={(key, value) => { setWizard({ ...info, questions: info.questions.map((a, b) => b === index ? { ...a, options: a.options.map((a, b) => b === option_i ? { ...a, [key]: value } : a) } : a) }) }}
                                                        fields={[...langs.map((lang) => ({ type: `string`, name: lang }))]}
                                                    />
                                                </Frame>
                                            )
                                        })
                                    }
                                    <Button background={props => props.theme.blue} onClick={() => { setWizard({ ...info, questions: info.questions.map((a, b) => b === index ? { ...a, options: [...(a.options || []), { id: window.createId(), ...Object.fromEntries(new Map(langs.map(i => [i.toLowerCase(), ``]))) }] } : a) }) }} >{getText(`Add option`)}</Button>
                                </> : null
                            }
                            <Button background={props => props.theme.red} extra={`margin-top: 5px;`} onClick={() => { setWizard({ ...info, questions: info.questions.filter((a, b) => b !== index) }) }} >{getText(`Delete question`)}</Button>
                        </Tab>
                    )
                })
            }
            <Button background={props => props.theme.blue} onClick={() => { setWizard({ ...info, questions: [...(info.questions || []), { id: window.createId() }] }) }} >{getText(`Add question`)}</Button>
            <H>{getText(`Weights matrices`)}</H>
            {
                (info.questions || []).map((question, index) => {
                    return (
                        <Frame key={question.id} extra={`align-items: flex-start;`} >
                            <H1>{question[`title_${lang.toLowerCase()}`]}</H1>
                            <MatrixWrapper>
                                <Frame row >
                                    <Frame extra={`width: calc(920px - ${programs.length} * 40px); height: 150px; > * {}`} />
                                    {
                                        programs.map((program, index) => {
                                            return (
                                                <Frame key={index} extra={`width: 40px; height: 150px; writing-mode: vertical-lr; font-size: 14px; align-items: flex-start;  transform: rotate(180deg); text-overflow:ellipsis; overflow: hidden; white-space: nowrap; display: inline; `} >{program.info[`program_name_(${lang.toLowerCase()})`]}</Frame>
                                            )
                                        })
                                    }
                                </Frame>
                                <Frame>
                                    {
                                        (question.options || []).map((option, index) => {
                                            return (
                                                <Frame key={option.id} row extra={`background: rgba(0, 0, 0, ${index % 2 ? 0.05 : 0.1});`}  >
                                                    <Frame extra={`width: calc(920px - ${programs.length} * 40px); height: 40px; font-size: 14px; align-items: flex-start;`} >
                                                        {option[lang.toLowerCase()] || ``}
                                                    </Frame>
                                                    {
                                                        programs.map((program, index) => {
                                                            if (info.weights[question.id][option.id] === undefined) {
                                                                return null
                                                            } else {
                                                                return (
                                                                    <Frame key={program.id} extra={`width: 40px; height: 40px; `} ><Cell value={info.weights[question.id][option.id][program.id]} onChange={(e) => {
                                                                        let newWeights = info.weights
                                                                        newWeights[question.id][option.id][program.id] = +e.target.value
                                                                        setWizard({ ...info, weights: newWeights })
                                                                    }} /></Frame>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </Frame>
                                            )
                                        })
                                    }
                                </Frame>
                            </MatrixWrapper>
                        </Frame>
                    )
                })
            }
            <Frame row extra={`margin-top: 15px;`} >
                <Button shaped onClick={() => { customHandler(`OPEN_WIZARD_POP_UP`, info) }} >{getText(`Preview`)}</Button>
                <Button background={props => props.theme.green} extra={`margin-left: 5px;`} onClick={() => { onUpdateWizard() }} >{getText(`Save`)}</Button>
            </Frame>
        </Wrapper >
    );
}

const ArrowUp = styled(Frame)`
    width: 15px;
    height: 15px;
    background: url("${require(`../../../assets/images/arrow_down_black.svg`)}") no-repeat center center;
    transform: rotate(180deg);
    margin-left: 5px;
    cursor: pointer;
`;

const ArrowDown = styled(Frame)`
    width: 15px;
    height: 15px;
    background: url("${require(`../../../assets/images/arrow_down_black.svg`)}") no-repeat center center;
    margin-left: 5px;
    cursor: pointer;
`;

const Cell = styled.input.attrs(props => ({
    pattern: `[0-9]*`,
    ...props,
}))`
    width: 40px;
    height: 40px;
    text-align: right;
    outline: none;
    border: 0px;
    background: transparent;
`;

const MatrixWrapper = styled(Frame)`
    margin-bottom: 30px;
`;

const Tab = styled(Frame)`
    width: calc(580px - 40px);
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 20px;
    align-items: flex-start;
    &:hover { box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2); };
    border: 1px solid ${props => props.theme.background.secondary};
`;



let TrashCan = styled(Frame)`
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url(${require(`../../../assets/images/trash_can.svg`)});
`

const Wrapper = styled(Frame)`
    width: 920px;
    align-items: flex-start;
`;


export default WizardEdit;

/*eslint-enable*/