/*eslint-disable*/
import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { HashRouter, Switch, Route, withRouter, useLocation } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook';

import * as articlesActions from '../../redux/actions/ArticlesActions'
import * as dictionaryActions from '../../redux/actions/DictionaryActions'
import * as usersActions from '../../redux/actions/UsersActions'
import * as wizardActions from '../../redux/actions/WizardActions'
import * as FAQActions from '../../redux/actions/FAQActions'

import AdminApp from "./AdminApp";
import CompanyApp from "./CompanyApp";
import PagePreloader from '../rtu/pages/preloader-page'
import LandingApp from "./LandingApp";
import StudentApp from "./StudentApp";
import CoordinatorApp from "./CoordinatorApp";
import ConfirmPopUp from '../rtu/pop-ups/confirm-pop-up'
import LogOutPopUp from '../rtu/pop-ups/LogOutPopUp'
import Cookies from '../rtu/pop-ups/cookies'

import useCurrentUser from '../rtu/helpers/useCurrentUser'

let RouterApp = () => {

    const { initialized } = useMappedState(useCallback(state => ({
        initialized: state.users.initialized && state.dictionary.loading === false,
    }), []));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(usersActions.initializeAuthorization()).then(pld => { if (pld.user !== undefined) { } });
        dispatch(dictionaryActions.loadAllDictionary())
        dispatch(wizardActions.loadAllWizard())
        dispatch(articlesActions.loadAllArticles())
        dispatch(FAQActions.loadAllFAQ())
    }, []);

    let { userRole } = useCurrentUser()

    if (initialized === false) {
        return <PagePreloader visible={true} />
    }

    let route = LandingApp;
    switch (userRole) {
        case `guest`: route = LandingApp; break;
        case `student`: route = StudentApp; break;
        case `coordinator`: route = CoordinatorApp; break;
        case `company`: route = CompanyApp; break;
        case `admin`: route = AdminApp; break;
        default: route = LandingApp; break;
    }

    return (
        <HashRouter>
            <Wrapper>
                <ConfirmPopUp />
                <LogOutPopUp />
                <Cookies />
                <Switch>
                    <Route component={route} />
                </Switch>
            </Wrapper>
        </HashRouter>
    );
}

const Wrapper = styled.div`
    background: ${props => props.theme.background.primary};

    @media only screen and (max-width: 600px) {
        
    }
`;

export default RouterApp;
/*eslint-enable*/
