/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import axios from 'axios'

import { API_ENDPOINT } from '../../../constants/config'

import { Frame, Text, convertHex, P, H1, H } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import Form from '../../UIKit/Form'
import CommonHelper from '../../../helpers/CommonHelper';
import ProjectTab from '../tools/ProjectTab'

import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'

let ProjectsListPage = () => {

    let { currentUser } = useCurrentUser()
    let { getText } = useDictionary()

    let [menuItems, setMenuItems] = useState({})
    let [headerItems, setHeaderItems] = useState({})

    let getMenuItems = () => {
        axios.get(`${API_ENDPOINT}/dictionary/menu_items`).then(d => d.data).then(pld => {
            if (pld.error != undefined) {
                return reject(pld.error);
            }
            setMenuItems(pld.result.value);
        }).catch(e => e);
    }

    let saveMenuItems = () => {
        if (menuItems === {}) { return }
        axios.put(`${API_ENDPOINT}/dictionary/menu_items`, { value: menuItems }).then(d => d.data).then(ex => {
            if (ex.error != undefined) {
                return reject(ex.error);
            }
            // setMenuItems(ex.result);
            window.location.reload()
        }).catch(e => e);
    }

    let getHeaderItems = () => {
        axios.get(`${API_ENDPOINT}/dictionary/header_items`).then(d => d.data).then(pld => {
            if (pld.error != undefined) {
                return reject(pld.error);
            }
            setHeaderItems(pld.result.value);
        }).catch(e => e);
    }

    let saveHeaderItems = () => {
        if (headerItems === {}) { return }
        axios.put(`${API_ENDPOINT}/dictionary/header_items`, { value: headerItems }).then(d => d.data).then(ex => {
            if (ex.error != undefined) {
                return reject(ex.error);
            }
            // setHeaderItems(ex.result);
            window.location.reload()
        }).catch(e => e);
    }

    useEffect(() => {
        getMenuItems()
        getHeaderItems()
    }, [])

    return (
        <Wrapper>

            {/* <H extra={`@media only screen and (max-width: 600px) { margin-top: 5vw; };`} >{getText(`Settings`)}</H> */}

            {/* <Form fields={[{ name: `Email`, type: `string` }]} editable={false} /> */}
            {/* <P>{currentUser.username || `Email`}</P> */}

            {/* <H1 extra={`@media only screen and (max-width: 600px) { margin-top: 5vw; };`} >{getText(`change_password`)}</H1>

            <Form fields={[
                { name: `Old password`, type: `password` },
                { name: `New password`, type: `password` },
                { name: `Confirm password`, type: `password` },
            ]} editable={true} />

            <Button extra={`width: 230px;`} >{getText(`Save`)}</Button> */}

            {
                currentUser.userRole === `admin` ? <>
                    <H extra={`@media only screen and (max-width: 600px) { margin-top: 5vw; };`} >{getText(`Menu configurator`)}</H>
                    {
                        [`guest`, `student`, `company`, `coordinator`, `admin`].map((role, index) => <>
                            <H1>{getText(role)}</H1>
                            <Form
                                data={menuItems[role] || {}}
                                fields={Object.keys(menuItems[role] || {}).map(i => ({ type: `checkbox`, name: i, extra: `width: 100%; justify-content: flex-start;` }))}
                                onChange={(key, value) => { setMenuItems({ ...menuItems, [role]: { ...menuItems[role], [key]: value } }) }}
                            />
                        </>)
                    }

                    <H extra={`@media only screen and (max-width: 600px) { margin-top: 5vw; };`} >{getText(`Nav bar configurator`)}</H>
                    {
                        [`guest`, `student`, `company`, `coordinator`, `admin`].map((role, index) => <>
                            <H1>{getText(role)}</H1>
                            <Form
                                data={headerItems[role] || {}}
                                fields={Object.keys(headerItems[role] || {}).map(i => ({ type: `checkbox`, name: i, extra: `width: 100%; justify-content: flex-start;` }))}
                                onChange={(key, value) => { setHeaderItems({ ...headerItems, [role]: { ...headerItems[role], [key]: value } }) }}
                            />
                        </>)
                    }
                </> : null
            }

            <Button extra={`width: 230px;`} onClick={() => {
                saveMenuItems()
                saveHeaderItems()
            }} >{getText(`Save`)}</Button>

        </Wrapper>
    );
}

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    margin-left: 3px;
`

const Tab = styled(Frame)`
    width: 535px;
    height: 300px;
    border-radius: 12px;
    margin-top: 30px;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07);
    &:hover {
        box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
    }
`;

const Wrapper = styled(Frame)`
    // position: relative;
    width: 815px;
    align-items: flex-start;
    margin-left: 125px;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin: 0;
    }
`;

const FilterToolsWrapper = styled(Frame)`
    width: 1100px;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

export default ProjectsListPage;

/*eslint-enable*/