/*eslint-disable*/
import axios from 'axios'
import { API_ENDPOINT } from "../constants/config";

const ProgramsAPI = {

    getAllPrograms() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/programs`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getProgram(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/programs/${id}`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    createProgram(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/programs`, data).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    updateApplicationStatus(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/program-applications`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                axios.get(`${API_ENDPOINT}/programs`)
                    .then(d => d.data)
                    .then(pld => { resolve(pld.result); })
            }).catch(err => reject(err));
        });
    },

    deleteProgramApplication(id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/program-application`, { data: { id: id } }).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                axios.get(`${API_ENDPOINT}/programs`)
                    .then(d => d.data)
                    .then(pld => { resolve(pld.result); })
            }).catch(err => reject(err));
        });
    },

    updateProgram(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/programs`, data).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    deleteProgram(id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/programs`, { data: { id: id } }).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    applyToProgram(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/programs/${data.id}/apply`, data).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                axios.get(`${API_ENDPOINT}/programs/${data.id}`).then(pld => { resolve(pld.data.result); })
            }).catch(err => reject(err));
        });
    },

    cancelApplicationToProgram(programId) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/programs/${programId}/cancel`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                axios.get(`${API_ENDPOINT}/programs/${programId}`)
                    .then(d => d.data)
                    .then(pld => { resolve(pld.result); })
            }).catch(err => reject(err));
        });
    },

    getProgramUsers(programId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/programs/${programId}/users`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    loadAppliedPrograms(userId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user/${userId}/applied_programs`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

}

export default ProgramsAPI;

/*eslint-enable*/