/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import { useDispatch, useMappedState } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import * as actions from '../../../redux/actions/UsersActions'
import { Frame, Text, convertHex, P, H1, AddSquare, H } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import Form from '../../UIKit/Form'
import Dropzone from '../../UIKit/Dropzone'
import CommonHelper from '../../../helpers/CommonHelper';
import ProjectTab from '../tools/ProjectTab'
import Tags from '../tools/Tags'
import PhotoPreview from '../tools/PhotoPreview'

import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'

let EditCompanyProfilePage = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let { selectedUser } = useMappedState(useCallback(state => { return { selectedUser: state.users.usersMap.get(path[1]), } }, [path]));
    let { currentUser } = useCurrentUser()
    let { getText } = useDictionary()

    let [info, setInfo] = useState(selectedUser.info);
    let [verified, setVerified] = useState(selectedUser[`verified`] === true)
    let [role, setRole] = useState(selectedUser[`userRole`])
    let [status, setStatus] = useState(selectedUser[`status`] || `active`)

    let dispatch = useDispatch()
    let onSaveProfile = async () => {
        let new_info = { ...info }
        dispatch(actions.updateUser({ ...selectedUser, verified: verified, userRole: role, info: new_info, status: status }))
    }

    return (
        <Wrapper>

            <Block>
                <LeftColumn />
                <Workspace>
                    <H extra={`margin-bottom: 30px; @media only screen and (max-width: 600px) { margin: 0; };`} >{getText(`Company profile`)}</H>
                </Workspace>
            </Block>

            {
                currentUser.userRole === `admin`
                    ? <>
                        <Block>
                            <LeftColumn>
                                <NumberWrapper index={0} >*</NumberWrapper>
                                <Frame extra={props => `width: 1px; height: 100%; background: ${props.theme.background.secondary}; @media only screen and (max-width: 600px) { display: none; };`} />
                            </LeftColumn>
                            <Workspace>
                                <H1>{getText(`Admin settings`, `UPPERCASE_FIRST_LETTER`)}</H1>
                                <Form
                                    data={{ verified: verified }}
                                    onChange={(key, value) => { setVerified(value) }}
                                    extra={`width: 730px; @media only screen and (max-width: 600px) { width: 90vw; };`}
                                    fields={[{ name: getText(`verified`), type: `checkbox` }]}
                                />
                                <Form
                                    data={{ role: { value: role, label: role } }}
                                    onChange={(key, value) => { setRole(value) }}
                                    extra={`width: 730px; @media only screen and (max-width: 600px) { width: 90vw; };`}
                                    fields={[{ name: getText(`Role`), type: `select`, options: [`admin`, `student`, `company`, `coordinator`].map(i => ({ value: i, label: i })) }]}
                                />
                                <Form
                                    data={{ status: { value: status, label: status } }}
                                    onChange={(key, value) => { setStatus(value) }}
                                    extra={`width: 730px; @media only screen and (max-width: 600px) { width: 90vw; };`}
                                    fields={[{ name: getText(`Status`), type: `select`, options: [`active`, `blocked`, `deleted`].map(i => ({ value: i, label: i })) }]}
                                />
                            </Workspace>
                        </Block>
                    </> : null
            }

            <Block>
                <LeftColumn />
                <Workspace>
                    <Frame extra={`align-items: flex-start; margin-bottom: 20px; @media only screen and (max-width: 600px) { margin: 0; };`} >
                        <Text extra={props => `font-size: 12px; margin-bottom: 4px; color: ${props.theme.text.secondary};`} >{getText(`Background photo`)}</Text>
                        {
                            info.headerUrl
                                ? <PhotoPreview onDelete={() => { let new_info = { ...info }; delete new_info.headerUrl; setInfo(new_info) }} >
                                    <HeaderImageWrapper src={info.headerUrl} />
                                </PhotoPreview>
                                : <Dropzone onUploaded={(url) => { setInfo({ ...info, headerUrl: url }) }} >
                                    <HeaderImageWrapper >
                                        <AddSquare />
                                    </HeaderImageWrapper>
                                </Dropzone>
                        }
                    </Frame>
                </Workspace>
            </Block>

            <Block>
                <LeftColumn />
                <Workspace>
                    <FirstBlockWrapper>
                        <Frame extra={`align-items: flex-start;`} >
                            <Text extra={props => `font-size: 12px; margin-bottom: 4px; color: ${props.theme.text.secondary};`} >{getText(`Profile photo`)}</Text>
                            <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.blue, 0.1)}; cursor: pointer; position: relative; overflow: hidden;`} >
                                {
                                    info.avatarUrl
                                        ? <PhotoPreview onDelete={() => { let new_info = { ...info }; delete new_info.avatarUrl; setInfo(new_info) }} >
                                            <Avatar src={info.avatarUrl} />
                                        </PhotoPreview>
                                        : <Dropzone onUploaded={(url) => { setInfo({ ...info, avatarUrl: url }) }} >
                                            <AddSquare />
                                        </Dropzone>
                                }
                            </Frame>
                        </Frame>
                        <Frame extra={`width: 540px; margin: 24px 0px 0px 58px; @media only screen and (max-width: 600px) { width: 90vw; margin: 6.25vw 0 0 0; };`} >
                            <Form
                                data={info}
                                onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                                fields={[
                                    { name: `Company name`, type: `string`, extra: `width: 506px;` },
                                    { name: `Location`, type: `string`, short: true, },
                                    { name: `Phone`, type: `number`, short: true, pattern: `[0-9]*`, },
                                ]}
                            />
                        </Frame>
                    </FirstBlockWrapper>
                    <Form
                        extra={`width: 730px; margin-top: 20px; justify-content: space-between; font-size: 20px; @media only screen and (max-width: 600px) { width: 90vw; margin-top: 6.25vw; };`}
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        fields={[
                            { name: getText(`facebook`), type: `string`, short: true, extra: `width: 316px;` },
                            { name: getText(`linkedin`), type: `string`, short: true, extra: `width: 316px;` },
                        ]}
                    />

                    <H1>{getText(`specialization_(tags)`)}</H1>
                    <Form
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        extra={`width: 730px; justify-content: flex-start; font-size: 20px; @media only screen and (max-width: 600px) { width: 90vw; margin-top: 6.25vw; };`}
                        fields={[{ name: getText(`specialization_(tags)`), type: `tagsinput`, },]}
                    />

                    <H1>{getText(`about company`)}</H1>
                    <Form
                        extra={`width: 730px; margin-top: 20px; justify-content: flex-start; font-size: 20px; @media only screen and (max-width: 600px) { width: 90vw; margin-top: 6.25vw; };`}
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        fields={[
                            { name: getText(`short description`), type: `string`, extra: `width: calc(730px - 34px);` },
                            { name: getText(`description`), type: `textarea`, extra: `width: calc(730px - 34px);` },
                        ]}
                    />

                </Workspace>
            </Block>

            <Block>
                <LeftColumn />
                <Workspace>

                    <H1>{getText(`gallery`)}</H1>

                    <Frame row extra={`width: 100%; flex-wrap: wrap; justify-content: flex-start; > * { margin: 0 30px 30px 0; };`} >
                        {
                            (info.gallery || []).map((item, index) => {
                                return (
                                    <PhotoPreview key={index} onDelete={() => { setInfo({ ...info, gallery: info.gallery.filter((a, b) => b !== index) }) }} >
                                        <GalleryPhoto src={item} />
                                    </PhotoPreview>
                                )
                            })
                        }
                        <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.blue, 0.1)}; cursor: pointer;`} >
                            <Dropzone
                                onUploaded={(url) => {
                                    let new_info = { ...info };
                                    new_info.gallery = [...(new_info.gallery || []), url];
                                    setInfo(new_info)
                                }}
                            >
                                <Frame extra={props => `width: 35px; height: 35px; border-radius: 10px; background: ${convertHex(props.theme.blue, 0.3)};`} >
                                    <Text bold text_color={props => props.theme.blue} extra={`font-size: 20px;`} >+</Text>
                                </Frame>
                            </Dropzone>
                        </Frame>
                    </Frame>

                </Workspace>
            </Block>

            <Button
                extra={`width: 230px; margin-left: 80px; @media only screen and (max-width: 600px) { width: 90vw; margin: 0; };`}
                onClick={async () => { await onSaveProfile(); CommonHelper.linkTo(`/user/${selectedUser.id}`) }}
            >
                {getText(`Save`)}
            </Button>

        </Wrapper >
    );
}

const FirstBlockWrapper = styled(Frame)`
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        width: 90vw;
         align-items: flex-start;
    }
`;

const GalleryPhoto = styled(Frame)`
    width: 132px;
    height: 132px;
    border-radius: 12px;
    border: 1px solid ${props => props.theme.background.secondary};
    box-sizing: border-box;
    background-image: url("${props => props.src}");
    background-position: center;
    position: relative;
    overflow: hidden;
`;

const HeaderImageWrapper = styled(Frame)`
    border-radius: 12px;
    ${props => props.src ? null : `background: ${convertHex(props.theme.blue, 0.1)};`}
    width: 730px;
    height: 170px;
    background-image: url("${props => props.src}");
    background-position: center;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: 33.75vw;
        border-radius: 3.75vw;
    }
`;

const Avatar = styled(Frame)`
    width: 132px;
    height: 132px;
    background-image: url("${props => props.src}");
    background-position: center;
`;

const Workspace = styled(Frame)`
    width: 730px;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin-top: 6.25vw;
    };
`;

const NumberWrapper = styled(Frame)`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: ${props => convertHex(props.theme[[`red`, `blue`, `green`][props.index % 3]], 0.1)};
    color: ${props => props.theme[[`red`, `blue`, `green`][props.index % 3]]};
    font-family: Bold;
    font-size: 24px;

    @media only screen and (max-width: 600px) {
        width: 13.75vw;
        height: 13.75vw;
        font-size: 7.5vw;
        margin-top: 5vw;
    }
`;

const LeftColumn = styled(Frame)`
    margin-right: 40px;
    width: 44px;

    @media only screen and (max-width: 600px) {
        margin: 0px;
    }
`;

const Block = styled(Frame)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        flex-direction: column;
    }
`;

let CheckMark = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/check_mark.svg`) } catch (error) { }
    return ({ src: img, })
})` width: ${props => +props.visible * 8}px; height: ${props => +props.visible * 7}px; margin-right: ${props => +props.visible * 10}px; transition: 0.2s; transform: scale(${props => +props.visible});`

let TrashCan = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/trash_can_red.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 30px; height: 30px; cursor: pointer; transition: 0.2s; &:hover { transform: scale(1.2); };`

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin-left: 3px; `

const Wrapper = styled(Frame)`
    position: relative;
    width: 815px;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

export default EditCompanyProfilePage;

/*eslint-enable*/