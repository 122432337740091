/*eslint-disable*/
import React, { useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components'
import { useLocation, Switch, Route } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'

import * as programsActions from '../../redux/actions/ProgramsActions'
import * as projectsActions from '../../redux/actions/ProjectsActions'
import * as usersActions from '../../redux/actions/UsersActions'

import Header from '../rtu/tools/Header'
import GetStartedForFree from '../rtu/tools/GetStartedForFree'
import Footer from '../rtu/tools/Footer'
import FlyingCircles from '../rtu/tools/FlyingCircles'
import LandingPage from '../rtu/pages/landing-page'
import ProjectsListPage from '../rtu/pages/projects-list'
import ProjectPage from '../rtu/pages/project-page'
import LandingLoginPopUp from '../rtu/pop-ups/landing-login-pop-up'
import NewsList from '../rtu/pages/news-list'
import NewsPage from '../rtu/pages/news-page'
import ProgramsList from '../rtu/pages/programs-list'
import ProgramsPage from '../rtu/pages/program-page'
import ConfirmPopUp from '../rtu/pop-ups/confirm-pop-up';
import WizardPopUp from '../rtu/pop-ups/wizard-pop-up';
import LoginApp from "./LoginApp";
import LogOutPopUp from '../rtu/pop-ups/LogOutPopUp';
import CalendarPage from '../rtu/pages/calendar-page'
import StudentProfile from '../rtu/pages/student-profile'
import CompaniesProfile from '../rtu/pages/company-profile'
import FAQPage from '../rtu/pages/faq-page'

import { Frame, Textarea } from '../UIKit/styled-templates'
import CommonHelper from '../../helpers/CommonHelper';

let LandingApp = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let dispatch = useDispatch()
    useEffect(() => {
        dispatch(programsActions.loadAllPrograms())
        dispatch(projectsActions.loadAllProjects())
        dispatch(usersActions.loadAllUsers())
    }, [])

    // useEffect(() => {
    //     if (path.length === 2 && path[0] === `projects`) {
    //         window.dispatchEvent(new CustomEvent(`OPEN_LANDING_LOGIN_POP_UP`, {}))
    //     } else {
    //         // window.dispatchEvent(new CustomEvent(`CLOSE_LANDING_LOGIN_POP_UP`, {}))
    //     }
    // }, [path])

    let { users } = useMappedState(useCallback((state) => ({ users: state.users.usersMap.toArray() }), [path]))

    return (
        <Switch>
            <Route exact path='/login' component={LoginApp} />
            <Route exact path='/signup/student' component={LoginApp} />
            <Route exact path='/signup/company' component={LoginApp} />
            <Route exact path='/recover' component={LoginApp} />
            <Route component={() => <>
                <LandingLoginPopUp />
                <WizardPopUp />
                <Wrapper>
                    <Header />
                    <Switch>
                        <Route exact path={`/`} component={LandingPage} />
                        <Route exact path={`/calendar`} component={CalendarPage} />
                        <Route exact path={`/projects`} component={ProjectsListPage} />
                        <Route exact path={`/projects/:key`} component={ProjectPage} />
                        <Route exact path={`/news`} component={NewsList} />
                        <Route exact path={`/news/:key`} component={NewsPage} />
                        <Route exact path={`/programs`} component={ProgramsList} />
                        <Route exact path={`/programs/:key`} component={ProgramsPage} />
                        <Route exact path={`/faq`} component={FAQPage} />
                        <Route exact path={`/faq/:key`} component={FAQPage} />

                        {
                            (() => {
                                if (path.length !== 2) { return null }
                                let selectedUser = users.filter(i => i.id === path[1])[0]
                                if (selectedUser === undefined) { return null }
                                switch (selectedUser.userRole) {
                                    case `student`:
                                    case `coordinator`:
                                        return <Route exact path={`/user/:key`} component={StudentProfile} />
                                        case `company`:
                                            return <Route exact path={`/user/:key`} component={CompaniesProfile} />
                                    default:
                                        return null
                                }
                            })()
                        }

                        <Route exact component={LandingPage} />
                    </Switch>
                    <Frame extra={`margin-top: 30px;`}>
                        <GetStartedForFree />
                    </Frame>
                    <Footer />
                    {useMemo(() => <FlyingCircles />, [null])}
                </Wrapper>
            </>} />
        </Switch>
    );
}

const Wrapper = styled(Frame)`
    position: relative;
    padding-top: 94px;
    overflow: hidden;
`;

export default LandingApp;

/*eslint-enable*/