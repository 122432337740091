/*eslint-disable*/
import ProgramsAPI from '../../api/ProgramsAPI'

import * as types from '../ActionTypes'

let loadPrograms_ = () => {
    return {
        type: types.LOAD_PROGRAMS
    }
}
let loadProgramsSuccess = (programs) => {
    return {
        type: types.LOAD_PROGRAMS_SUCCESS,
        programs: programs
    }
}
let loadProgramsFailed = (error) => {
    return {
        type: types.LOAD_PROGRAMS_FAIL,
        error: error
    }
}

//thunk
export function loadAllPrograms() {
    return (dispatch, getState) => {
        dispatch(loadPrograms_());
        return ProgramsAPI.getAllPrograms().then(
            programs => dispatch(loadProgramsSuccess(programs)),
            error => dispatch(loadProgramsFailed(error))
        )
    }
}

export function loadProgramById(id) {
    return (dispatch, getState) => {
        dispatch(loadPrograms_());
        return ProgramsAPI.getProgram(id).then(
            program => dispatch(loadProgramsSuccess([program])),
            error => dispatch(loadProgramsFailed(error))
        )
    }
}

let createProgram_ = () => {
    return {
        type: types.CREATE_PROGRAM
    }
}
let createProgramSuccess = (program) => {
    return {
        type: types.CREATE_PROGRAM_SUCCESS,
        program: program
    }
}
let createProgramFailed = (error) => {
    return {
        type: types.CREATE_PROGRAM_FAIL,
        error: error
    }
}

//thunk
export function createProgram(data) {
    return (dispatch, getState) => {
        dispatch(createProgram_());
        return ProgramsAPI.createProgram(data).then(
            program => dispatch(createProgramSuccess(program)),
            error => dispatch(createProgramFailed(error))
        )
    }
}

export let updateProgramsOrder = (programs) => {
    return {
        type: types.UPDATE_PROGRAM_ORDER,
        programs: programs
    }
}

let updateProgram_ = () => {
    return {
        type: types.UPDATE_PROGRAM
    }
}
let updateProgramSuccess = (program) => {
    return {
        type: types.UPDATE_PROGRAM_SUCCESS,
        program: program
    }
}
let updateProgramFailed = (error) => {
    return {
        type: types.UPDATE_PROGRAM_FAIL,
        error: error
    }
}

//thunk
export function updateProgram(data) {
    return (dispatch, getState) => {
        dispatch(updateProgram_());
        return ProgramsAPI.updateProgram(data).then(
            program => dispatch(updateProgramSuccess(program)),
            error => dispatch(updateProgramFailed(error))
        )
    }
}

let deleteProgram_ = () => {
    return {
        type: types.DELETE_PROGRAM
    }
}
let deleteProgramSuccess = (id) => {
    return {
        type: types.DELETE_PROGRAM_SUCCESS,
        id: id
    }
}
let deleteProgramFailed = (error) => {
    return {
        type: types.DELETE_PROGRAM_FAIL,
        error: error
    }
}

//thunk
export function deleteProgram(id) {
    return (dispatch, getState) => {
        dispatch(deleteProgram_());
        return ProgramsAPI.deleteProgram(id).then(
            () => dispatch(deleteProgramSuccess(id)),
            error => dispatch(deleteProgramFailed(error))
        )
    }
}

let applyToProgram_ = () => {
    return {
        type: types.APPLY_TO_PROGRAM
    }
}
let applyToProgramSuccess = (program) => {
    return {
        type: types.APPLY_TO_PROGRAM_SUCCESS,
        program: program
    }
}
let applyToProgramFailed = (error) => {
    return {
        type: types.APPLY_TO_PROGRAM_FAIL,
        error: error
    }
}

//thunk
export function applyToProgram(data) {
    return (dispatch, getState) => {
        dispatch(applyToProgram_());
        return ProgramsAPI.applyToProgram(data).then(
            (program) => dispatch(applyToProgramSuccess(program)),
            (error) => dispatch(applyToProgramFailed(error))
        )
    }
}

let cancelApplicationToProgram_ = () => {
    return {
        type: types.CANCEL_APPLICATION
    }
}
let cancelApplicationToProgramSuccess = (program) => {
    return {
        type: types.CANCEL_APPLICATION_SUCCESS,
        program: program
    }
}
let cancelApplicationToProgramFailed = (error) => {
    return {
        type: types.CANCEL_APPLICATION_FAIL,
        error: error
    }
}

//thunk
export function cancelApplicationToProgram(id) {
    return (dispatch, getState) => {
        dispatch(cancelApplicationToProgram_());
        return ProgramsAPI.cancelApplicationToProgram(id).then(
            (program) => dispatch(cancelApplicationToProgramSuccess(program)),
            (error) => dispatch(cancelApplicationToProgramFailed(error))
        )
    }
}

let loadAppliedPrograms_ = () => {
    return {
        type: types.LOAD_APPLIED_PROGRAMS
    }
}
let loadAppliedProgramsSuccess = (programs) => {
    return {
        type: types.LOAD_APPLIED_PROGRAMS_SUCCESS,
        programs: programs
    }
}
let loadAppliedProgramsFailed = (error) => {
    return {
        type: types.LOAD_APPLIED_PROGRAMS_FAIL,
        error: error
    }
}

//thunk
export function loadAppliedPrograms(id) {
    return (dispatch, getState) => {
        dispatch(loadAppliedPrograms_());
        return ProgramsAPI.loadAppliedPrograms(id).then(
            programs => dispatch(loadAppliedProgramsSuccess(programs)),
            error => dispatch(loadAppliedProgramsFailed(error))
        )
    }
}

let updateApplicationStatus_ = () => {
    return {
        type: types.UPDATE_PROGRAM_STATUS
    }
}
let updateApplicationStatusSuccess = (programs) => {
    return {
        type: types.UPDATE_PROGRAM_STATUS_SUCCESS,
        programs: programs
    }
}
let updateApplicationStatusFailed = (error) => {
    return {
        type: types.UPDATE_PROGRAM_STATUS_FAIL,
        error: error
    }
}

//thunk
export function updateApplicationStatus(data) {
    return (dispatch, getState) => {
        dispatch(updateApplicationStatus_());
        return ProgramsAPI.updateApplicationStatus(data).then(
            (programs) => dispatch(updateApplicationStatusSuccess(programs)),
            (error) => dispatch(updateApplicationStatusFailed(error))
        )
    }
}


let deleteProgramApplication_ = () => {
    return {
        type: types.DELETE_PROGRAM_APPLICATION
    }
}
let deleteProgramApplicationSuccess = (programs) => {
    return {
        type: types.DELETE_PROGRAM_APPLICATION_SUCCESS,
        programs: programs
    }
}
let deleteProgramApplicationFailed = (error) => {
    return {
        type: types.DELETE_PROGRAM_APPLICATION_FAIL,
        error: error
    }
}

//thunk
export function deleteProgramApplication(id) {
    return (dispatch, getState) => {
        dispatch(deleteProgramApplication_());
        return ProgramsAPI.deleteProgramApplication(id).then(
            (programs) => dispatch(deleteProgramApplicationSuccess(programs)),
            (error) => dispatch(deleteProgramApplicationFailed(error))
        )
    }
}
/*eslint-enable*/