/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useMappedState } from "redux-react-hook";
import { useLocation } from 'react-router-dom'
import axios from 'axios'

import { Frame, Text, Checkbox, H1, P, H } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import Input from '../../UIKit/Input'
import Form from '../../UIKit/Form'
import CommonHelper from '../../../helpers/CommonHelper'
import Circles from '../tools/Circles'
import * as usersActions from '../../../redux/actions/UsersActions'

import useDictionary from '../helpers/useDictionary'

let LoginFields = [
    { name: `Email`, type: `string` },
    { name: `Password`, type: `password` },
]

let LoginPage = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let { getText } = useDictionary()
    const dispatch = useDispatch();

    let [tempData, setTempData] = useState({})
    let [remember, setRemember] = useState(true)

    let onLogin = () => {
        dispatch(usersActions.logIn({ ...tempData, rememberMe: remember })).then(pld => {
            CommonHelper.linkTo(`/`)
            if (pld.error !== undefined) {
                window.alert(pld.error.message);
            }
        })
    }

    useEffect(() => {
        let handler = (e) => { if (path[0] === `login` && e.key === `Enter`) { onLogin() } }
        window.addEventListener(`keypress`, handler)
        return (() => { window.removeEventListener(`keypress`, handler) })
    }, [path])

    let Buttons = () => {
        return (
            <Frame extra={`align-items: flex-end; > * { cursor: pointer; }`} >
                <Frame row>
                    <Text text_color={props => props.theme.red} extra={`font-size: 14px;`} onClick={() => { CommonHelper.linkTo(`/signup/company`) }} >{getText(`Register company`)}</Text>
                    <Arrow color={`red`} />
                </Frame>
                <Frame row extra={`margin-top: 8px;`} >
                    <Text text_color={props => props.theme.blue} extra={`font-size: 14px;`} onClick={() => { CommonHelper.linkTo(`/signup/student`) }} >{getText(`Sign up as a student`)}</Text>
                    <Arrow color={`blue`} />
                </Frame>
            </Frame>
        )
    }

    return (
        <Frame extra={props => `width: 100vw; height: 100vh; background: ${props.theme.background.primary}; overflow: hidden;`} >
            <Workspace>
                <Frame extra={`position: absolute; top: 30px; left: 30px;`} ><Circles width={480} /> </Frame>
                <Frame extra={`position: absolute; top: 571px; left: 1400px;`} ><Circles width={480} /> </Frame>
                <Header>
                    <Logo onClick={() => { CommonHelper.linkTo(`/`) }} />
                    <Frame extra={`@media only screen and (max-width: 600px) {display: none;}`} >
                        <Buttons />
                    </Frame>
                </Header>
                <FormWrapper>
                    <Title>{getText(`Login`)}</Title>
                    <Form
                        fields={LoginFields}
                        data={tempData}
                        onChange={(key, value) => { setTempData({ ...tempData, [key]: value }) }}
                    />
                    <RememberWrapper>
                        <Frame row extra={`cursor: pointer;`} onClick={() => { setRemember(!remember) }} >
                            <Checkbox checked={remember} />
                            <Text text_color={props => props.theme.text.secondary} extra={`font-size: 14px; height: 24px; margin-left: 8px;`} >{getText(`Remember me`)}</Text>
                        </Frame>
                        <Text text_color={props => props.theme.text.secondary} extra={`font-size: 14px; height: 24px; margin-left: 8px; text-decoration: underline;`} onClick={() => { CommonHelper.linkTo(`/recover`) }} >{getText(`Forgot password`)}</Text>
                    </RememberWrapper>
                    <Button extra={`width: 540px; margin-top: 30px; @media only screen and (max-width: 600px) { width: 90vw; margin-bottom: 5vw; }`} background={props => props.theme.green} onClick={onLogin} >{getText(`Login`)}</Button>
                    <Frame extra={`@media only screen and (min-width: 600px) {display: none;}`} >
                        <Buttons />
                    </Frame>
                </FormWrapper>
            </Workspace >
        </Frame >
    )
}

const Title = styled(H)`
    color: ${props => props.theme.text.secondary};
    @media only screen and (max-width: 600px) {
        margin: 10vw 0 4vw 0;
        width: 80vw;
    };
`;

const RememberWrapper = styled(Frame)`
    width: 100%;
    justify-content: space-between;
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

let Arrow = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_${props.color}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 16px;
    height: 16px;
    margin-left: 8px;

    @media only screen and (max-width: 600px) {
        
    }
`

const FormWrapper = styled(Frame)`
    margin-top: 100px;

    @media only screen and (max-width: 600px) {
        margin-top: 0px;
    }
`;

let Logo = styled.img.attrs((props) => {
    let img
    try { img = require('../../../assets/images/rtu_logo_green.svg') } catch (error) { }
    return ({ src: img, })
})`
    width: 174.93px;
    height: 60px;
    cursor: pointer;
    margin-left: 50px;

    @media only screen and (max-width: 600px) {
        width: 17vw;
        margin-left: 0px;
    }
`

const Header = styled(Frame)`
    width: 730px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: auto;
        margin-top: 2vw;
        justify-content: center;
    }
`;

const Workspace = styled(Frame)`
    width: 1100px;
    height: 100vh;
    background: ${props => props.theme.background.primary};
    justify-content: flex-start;
    position: relative;

    @media only screen and (max-width: 600px) {
        width: 100vw;
        height: 100vh;
    }
`;

export default LoginPage;
/*eslint-enable*/
