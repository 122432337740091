/*eslint-disable*/
import React, { useState, useCallback } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation } from 'react-router-dom'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { CSVLink, CSVDownload } from 'react-csv';
import axios from 'axios'

import * as programsActions from '../../../redux/actions/ProgramsActions'
import { Frame, Text, convertHex, P, H1, Link, Checkbox } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import NewsTab from '../tools/NewsTab'
import Circles from '../tools/Circles'
import Tags from '../tools/Tags'
import Contacts from '../tools/Contacts'
import Gallery from '../tools/Gallery'
import CommonHelper from '../../../helpers/CommonHelper'
import { API_ENDPOINT } from '../../../constants/config'

import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'
import useCustomDispatch from '../helpers/useCustomDispatch'

let programs = [
    { name: `Co.Lab`, color: `#593E6C`, },
    { name: `Demola`, color: `#ED1C24`, },
    { name: `Product project`, color: `#A7A9AC`, },
    { name: `University`, color: `#FFCA37`, },
    { name: `Vertikal`, color: `#46AEB1`, },
    { name: `Doktors`, color: `#414042`, },
    { name: `IdeaLab`, color: `#FFCA37`, },
    { name: `Creative`, color: `#ED1C24`, },
]

let ProgramPage = () => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let { currentUser } = useCurrentUser()
    let { getText, lang, dict } = useDictionary()

    let dispatch = useDispatch()
    let { program, coordinators, applications, articles } = useMappedState(useCallback(state => {
        let program = { info: {}, coordinatorsIds: [], ...state.programs.programsMap.get(path[1]) }
        return ({
            program: program,
            coordinators: program.coordinatorsIds.map(i => state.users.usersMap.get(i)).filter(i => i !== undefined),
            applications: [].concat(...state.programs.programsMap.filter(i => i.id === path[1]).filter(i => ({ coordinatorsIds: [], ...i }).coordinatorsIds.indexOf(currentUser.id) > -1 || currentUser.userRole === `admin`).map(i => i.applications).toArray()).map(i => ({ ...i, user: { info: {}, ...state.users.usersMap.get(i.userId) }, program: { info: {}, coordinatorsIds: [], ...state.programs.programsMap.get(i.programId) } })),
            articles: state.articles.articlesMap.toArray().filter(i => i.tags.crossing(program.info[`specialization_(tags)`] || [])).filter(i => Object.values(i[lang]).length >= 3 && !Object.values(i[lang]).map(i => i === `` || typeof (i) !== `string`).reduce((a, b) => a && b, false)),
        })
    }, [path[1], lang]))

    let programInfo = { ...program.info }

    let alreadyApplied = (program.applications || []).filter(i => i.userId === currentUser.id).length > 0

    let statuses = (Object.keys(dict) || []).filter(i => i.indexOf(`program_status_`) > -1)

    let edit_buttons = [
        {
            image: `edit`,
            onClick: () => { CommonHelper.linkTo(`/programs/${path[1]}/edit`) },
        },
    ]

    let status = ((program.applications || []).filter(i => i.userId === currentUser.id)[0] || {}).status

    let [visibleArticles, setVisibleArticles] = useState(3)

    let openConfirmPopUp = () => { window.dispatchEvent(new CustomEvent(`OPEN_CONFIRM_PROGRAM_APPLICATION_POP_UP`, { detail: { id: program.id } })) }
    let openStudentComment = (comment) => { window.dispatchEvent(new CustomEvent(`OPEN_STUDENT_COMMENT_POP_UP`, { detail: comment })) }
    let onUpdateApplicationStatus = (id, status) => dispatch(programsActions.updateApplicationStatus({ id, status }))
    let onDeleteProgramApplication = (id) => dispatch(programsActions.deleteProgramApplication(id))
    let onUpdateProgram = (new_program) => dispatch(programsActions.updateProgram(new_program))

    return (
        <Wrapper>

            <Frame extra={`width: 580px; height: 580px; position: absolute; top: 174px; left: calc(50% + 500px); z-index: 0;`} >
                <Circles width={580} />
            </Frame>

            <Frame extra={`width: 380px; height: 380px; position: absolute; top: 950px; right: calc(50% + 500px); z-index: 0;`} >
                <Circles width={380} />
            </Frame>

            <ProgramWrapper>

                <ProgramHeader>
                    {/* <Frame row extra={`cursor: pointer;`} onClick={() => { CommonHelper.linkTo(`/programs`) }} >
                        <BackArrow />
                        <Text text_color={props => props.theme.text.secondary} extra={`text-transform: uppercase; font-size: 14px; line-height: 18px;`} >Back to list</Text>
                    </Frame> */}
                    {
                        [`admin`, `coordinator`].indexOf(currentUser.userRole) > -1 ?
                            <EditButtonsWrapper>
                                {
                                    edit_buttons.map((item, index) => <EditButton key={index} name={item.image} onClick={item.onClick} />)
                                }
                            </EditButtonsWrapper> : null
                    }
                    <InnerHeaderWrapper>
                        <ProgramImage src={programInfo.avatarUrl} />
                        <Frame extra={`align-items: flex-start; margin-left: 30px; height: 100%; width: 730px; justify-content: space-between; @media only screen and (max-width: 600px) { width: 100%; margin: 0; margin-top: 6.25vw; };`} >
                            <ProgramName>{programInfo[`program_name_(${lang})`.toLocaleLowerCase()]}</ProgramName>
                            <Frame row extra={`width: 100%; flex-wrap: wrap; justify-content: flex-start;`} >
                                <Contacts info={programInfo} />
                                {programInfo[`specialization_(tags)`] ? <Tags tagsArray={programInfo[`specialization_(tags)`]} /> : null}
                            </Frame>
                        </Frame>
                    </InnerHeaderWrapper>
                </ProgramHeader>

                <ProgramFooter visible={program.info.open_for_application === true && currentUser.userRole === `student` || currentUser.userRole === `guest`} onClick={() => {
                    if (currentUser.userRole === `guest`) {
                        CommonHelper.linkTo(`/login`)
                    } else {

                    }
                }} >
                    {
                        currentUser.userRole === `student` && !alreadyApplied && program.info.open_for_application === true ? <>
                            <Button extra={`width: 230px; `} background={props => props.theme[alreadyApplied ? `red` : `blue`]} onClick={openConfirmPopUp} >{alreadyApplied ? getText(`Remove application`) : getText(`Apply`)}</Button>
                        </> : null
                    }
                    {
                        currentUser.userRole === `student` && status !== undefined ? <>
                            <P extra={`margin: 0px;`} >{getText(`Status`)}: {getText(status)}</P>
                        </> : null
                    }
                    {
                        currentUser.userRole === `guest`
                            ? <Frame row extra={`> * { text-transform: uppercase } };`} >
                                <Text text_color={props => props.theme.text.secondary} extra={`font-size: 14px;`} >{getText(`To send an application, please`)}</Text>
                                <Text
                                    text_color={props => props.theme.green}
                                    extra={`font-size: 14px; margin-left: 5px;`}
                                >
                                    {getText(`Login`)}
                                </Text>
                            </Frame>
                            : null
                    }
                </ProgramFooter>

                {
                    programInfo[`description_(${lang})`.toLocaleLowerCase()] ? <>
                        <H1>{getText(`Full description`)}</H1>
                        <P>{programInfo[`description_(${lang})`.toLocaleLowerCase()]}</P>
                    </> : null
                }

                {
                    programInfo.terms ? <>
                        <H1>{getText(`Terms`)}</H1>
                        <P>{programInfo.terms}</P>
                    </> : null
                }

                {
                    (programInfo[`file`] || []).length > 0 ? <>
                        <H1>{getText(`File`, `UPPERCASE_FIRST_LETTER`)}</H1>
                        <FilesWrapper>
                            {
                                programInfo[`file`].map((item, index) => {
                                    let color = [`blue`, `red`, `green`][index % 3]
                                    return (
                                        <Frame
                                            row
                                            key={index}
                                            extra={`width: 50%; margin-bottom: 20px; justify-content: flex-start; cursor: pointer; @media only screen and (max-width: 600px) { width: 100%; };`}
                                            onClick={() => { window.open(item.url) }}
                                        >
                                            <Frame extra={props => `width: 65px; height: 65px; border-radius: 12px; background: ${convertHex(props.theme[color], 0.3)}; margin-right: 20px`} >
                                                <FileImage color={color} />
                                            </Frame>
                                            <Text extra={`font-size: 16px; line-height: 16px; width: 50vw; flex: 1; align-items: start; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; display: inline;`} >{item.name}</Text>
                                        </Frame>
                                    )
                                })
                            }
                        </FilesWrapper>
                    </> : null
                }

                {
                    (programInfo.gallery || []).length > 0 ? <>
                        <H1>{getText(`Gallery`, `UPPERCASE_FIRST_LETTER`)}</H1>
                        <Gallery photoArray={programInfo.gallery} />
                    </> : null
                }

                {
                    (programInfo.video || []).length > 0 ? <>
                        <H1>{getText(`Video`, `UPPERCASE_FIRST_LETTER`)}</H1>
                        <FilesWrapper>
                            {
                                programInfo.video.map((item, index) => {
                                    let color = [`blue`, `red`, `green`][index % 3]
                                    return (
                                        <Frame
                                            row
                                            key={index}
                                            extra={`width: 50%; margin-bottom: 20px; justify-content: flex-start; cursor: pointer;`}
                                            onClick={() => { window.open(`${API_ENDPOINT}/program-applications/${program.id}/get-excel`) }}
                                        >
                                            <Frame extra={props => `width: 65px; height: 65px; border-radius: 12px; background: ${convertHex(props.theme[color], 0.3)}; margin-right: 20px`} >
                                                <VideoImage color={color} />
                                            </Frame>
                                            <Text extra={`font-size: 16px; line-height: 16px;`} >{item.name}</Text>
                                        </Frame>
                                    )
                                })
                            }
                        </FilesWrapper>
                    </> : null
                }

            </ProgramWrapper>

            <Frame extra={`width: 920px; align-items: flex-start;`} >
                {
                    coordinators.length > 0 ? <>
                        <H1>{getText(`Coordinators`)}</H1>
                        {
                            coordinators.map((item, index) => {
                                return (
                                    <Link to={`/user/${item.id}`} key={index} >
                                        <Frame key={index} row extra={`margin-bottom: 20px; justify-content: flex-start; cursor: pointer;`} >
                                            <UserAvatar src={item.info.avatarUrl} />
                                            <Frame extra={`align-items: flex-start;`} >
                                                <Text extra={`font-size: 16px; line-height: 16px; @media only screen and (max-width: 600px) { font-size: 4.5vw !important; };`} >{item.info.name} {item.info.last_name}</Text>
                                                <Text extra={props => `color: ${props.theme.text.secondary}; font-size: 10px; line-height: 16px; @media only screen and (max-width: 600px) { font-size: 4.5vw !important; };`} >{item.email}</Text>
                                            </Frame>
                                            {item.info.verified ? <VerifiedSign /> : null}
                                        </Frame>
                                    </Link>
                                )
                            })
                        }
                    </> : null
                }
            </Frame>

            {
                currentUser.userRole === `admin` || currentUser.userRole === `coordinator` ? <Frame extra={`width: 920px; align-items: flex-start;`} >
                    <Frame row extra={`margin: 15px 0;`} >
                        <Checkbox checked={program.info.open_for_application === true} onChange={(e) => { onUpdateProgram({ ...program, info: { ...program.info, open_for_application: e } }) }} />
                        <Text extra={`margin-left: 15px; font-size: 16px; margin-bottom: 0px;`} >{getText(`Open for application`)}</Text>
                    </Frame>
                    <H1>{getText(`Applications`)}</H1>
                    {applications.length === 0 ? <P>{getText(`Empty`)}</P> : null}
                    {
                        applications.map((item, index) => {
                            return (
                                <ApplicationWrapper key={index} >
                                    <Frame row>
                                        <UserAvatar src={item.user.info.avatarUrl} />
                                        <Frame extra={`align-items: flex-start; width: 250px; margin-left: 10px; @media only screen and (max-width: 600px) { width: 64.5vw; margin-left: 3vw; };`} >
                                            <Text extra={props => `font-size: 12px; line-height: 16px; color: ${props.theme.text.secondary};`} >{item.user.info.name} {item.user.info.last_name}</Text>
                                            <Text extra={props => `font-size: 10px; line-height: 14px; color: ${props.theme.text.secondary}; opacity: 0.75;`} >{item.user.email}</Text>
                                        </Frame>
                                    </Frame>
                                    {/* <Frame extra={`align-items: flex-start; width: 150px; margin-left: 10px; @media only screen and (max-width: 600px) { width: 80vw; };`} >
                                        <Text extra={props => `font-size: 12px; line-height: 16px; color: ${props.theme.text.secondary};`} >{item.program.info.program_name}</Text>
                                    </Frame> */}
                                    <Frame extra={`cursor: pointer; align-items: flex-start; width: 200px; margin-left: 10px; @media only screen and (max-width: 600px) { width: 80vw; };`} onClick={() => { openStudentComment(item.studentComment) }} >
                                        <Text extra={props => `font-size: 12px; line-height: 16px; color: ${props.theme.text.secondary};`} >{getText(`Open student comment`)}</Text>
                                    </Frame>
                                    <Frame row>
                                        {
                                            statuses.map((status, status_index) => {
                                                let isSelected = getText(status).toLowerCase() === item.status.toLowerCase() || status.toLowerCase() === item.status.toLowerCase()
                                                return (
                                                    <Frame
                                                        key={status_index}
                                                        extra={`align-items: flex-start; margin-left: 10px; cursor: pointer; &:hover { opacity: 0.75; };`}
                                                        onClick={() => { onUpdateApplicationStatus(item.id, status) }}
                                                    >
                                                        <Text
                                                            bold={isSelected}
                                                            extra={props => `font-size: 12px; line-height: 16px; color: ${isSelected ? props.theme.blue : props.theme.text.secondary};`}
                                                        >{getText(status)}</Text>
                                                    </Frame>
                                                )
                                            })
                                        }
                                    </Frame>
                                    <TrashCan onClick={() => { onDeleteProgramApplication(item.id) }} />
                                </ApplicationWrapper>
                            )
                        })
                    }
                    {/* <ExcelButton onClick={async () => { window.open(`${API_ENDPOINT}/program-applications/${program.id}/get-excel`) }} /> */}
                    <ExcelButton onClick={async () => { window.open(`${API_ENDPOINT}/programs/${program.id}/export/xlsx?t=${+new Date()}`) }} />
                </Frame> : null
            }

            <ProgramFooter visible={program.info.open_for_application === true && currentUser.userRole === `student` || currentUser.userRole === `guest`} onClick={() => {
                if (currentUser.userRole === `guest`) {
                    CommonHelper.linkTo(`/login`)
                } else {

                }
            }} >
                {
                    currentUser.userRole === `student` && !alreadyApplied && program.info.open_for_application === true ? <>
                        <Button extra={`width: 230px; `} background={props => props.theme[alreadyApplied ? `red` : `blue`]} onClick={openConfirmPopUp} >{alreadyApplied ? getText(`Remove application`) : getText(`Apply`)}</Button>
                    </> : null
                }
                {
                    currentUser.userRole === `student` && status !== undefined ? <>
                        <P extra={`margin: 0px;`} >{getText(`Status`)}: {getText(status)}</P>
                    </> : null
                }
                {
                    currentUser.userRole === `guest`
                        ? <Frame row extra={`> * { text-transform: uppercase } };`} >
                            <Text text_color={props => props.theme.text.secondary} extra={`font-size: 14px;`} >{getText(`To send an application, please`)}</Text>
                            <Text
                                text_color={props => props.theme.green}
                                extra={`font-size: 14px; margin-left: 5px;`}
                            >
                                {getText(`Login`)}
                            </Text>
                        </Frame>
                        : null
                }
            </ProgramFooter>

            {
                articles.filter((a, b) => b < visibleArticles).length > 0 ? <>
                    <H1>{getText(`Program news`)}</H1>

                    <NewsListWrapper articlesNumber={Math.min(visibleArticles, articles.length, 3)} >
                        {
                            articles.filter((a, b) => b < visibleArticles).map((item, index) => {
                                return (
                                    <Frame key={index} extra={`margin-top: 30px;`} >
                                        <NewsTab articleId={item.id} />
                                    </Frame>
                                )
                            })
                        }
                    </NewsListWrapper>
                </> : null
            }

            {
                articles.length > visibleArticles ? <Button shaped extra={`margin: 70px 0`} onClick={() => { setVisibleArticles(Math.min(articles.length, visibleArticles + 3)) }} >{getText(`View more`)}</Button> : null
            }

        </Wrapper>
    );
}

let FileImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/file_${props.color}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 36px;
    height: 36px;
`

const TrashCan = styled(Frame)`
    width: 24px;
    height: 24px;
    margin: 0px 0px 0px 20px;
    cursor: pointer;
    transition: 0.2s;
    background: url("${require(`../../../assets/images/trash_can.svg`)}") no-repeat center center;

    @media only screen and (max-width: 600px) {
        width: 7.5vw;
        height: 7.5vw;
        margin: 0;
        transform: scale(1);
    }
`;

const ExcelButton = styled(Frame)`
    width: 150px;
    height: 50px;
    border-radius: 12px;
    background: rgb(21, 106, 52);
    margin-top: 15px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    &:after {
        content: 'Download .xlsx';
        color: white;
        font-size: 12px;
        font-weight: bold;
    }
`;

const ApplicationWrapper = styled(Frame)`
    margin-bottom: 10px;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        
        width: 90vw;
        padding: 5vw;
        border-radius: 3.75vw;
        border: 1px solid ${props => props.theme.background.secondary};
        box-sizing: border-box;
        overflow: hidden;
        justify-content: space-between;
        margin-bottom: 3.75vw;

        flex-direction: column;
        align-items: flex-start;
        > * {
            max-width: 80vw;
            margin-bottom: 2vw;
        };
    };
`;

const UserAvatar = styled(Frame)`
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin-right: 20px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});
    background-position: center;

    @media only screen and (max-width: 600px) {
        width: 12.5vw;
        height: 12.5vw;
    }
`;

const FilesWrapper = styled(Frame)`
    flex-direction: row;
    width: 920px;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

let VideoImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/video.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 36px;
    height: 36px;
`

const ProgramFooter = styled(Frame)`
    flex-direction: row;
    width: 920px;
    justify-content: flex-start;
    margin: ${props => props.visible ? 50 : 0}px 0px 50px 0px;
    cursor: pointer;
    /* visibility: ${props => props.visible ? `visible` : `hidden`}; */

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        width: 90vw;
        margin: ${props => props.visible ? 4.5 : 0}vw 0vw 4.5vw 0vw;
        align-items: flex-start;
    }
`;

const ProgramName = styled(Frame)`
    font-size: 48px;
    line-height: 62px;
    margin-bottom: 25px;
    color: ${props => props.theme.text.primary};
    font-family: Bold;
    width: 730px;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        font-size: 7.5vw;
        line-height: 10.5vw;
        width: 90vw;
    }
`;

const InnerHeaderWrapper = styled(Frame)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin: 0px 0 20px 0; */

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        margin: 0;
    }
`;

const EditButton = styled(Frame)`
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    border: 2px solid ${props => props.theme.text.primary};
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url(${props => require(`../../../assets/images/project-edit-button-${props.name}.svg`)}) no-repeat;
    }
`;

const EditButtonsWrapper = styled(Frame)`
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
`;

const ProgramImage = styled(Frame)`
    width: 160px !important;
    height: 160px !important;
    /* border-radius: 20px; */
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/hidden-company-1.svg`)});
    background-position: center;

    @media only screen and (max-width: 600px) {
        width: 37.5vw;
        height: 37.5vw;
    }
`;

const ProgramWrapper = styled(Frame)`
    align-items: flex-start;
    z-index: 1;
`;

const TagWrapper = styled(Text)`
    padding: 0 22px 0 22px;
    border-radius: 4px;
    background: ${props => convertHex(props.theme.green, 0.15)};
    margin: 0 10px 5px 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 22px;
`;

let ShareLogo = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/share_${props.name}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 36px;
    height: 36px;
`

let BackArrow = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/back_arrow.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 16px;
    height: 16px;
    margin-right: 3px;
`

const ProgramHeader = styled(Frame)`
    width: 920px;
    margin: 30px 0 0px 0;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

const NewsListWrapper = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: ${props => props.articlesNumber > 1 ? `space-between` : `space-around`};
    width: 920px;
    margin-top: 0px;
    cursor: pointer;
`;

const MySelect = styled(Select).attrs((props) => {
    return ({
        styles: {
            control: styles => ({
                ...styles,
                width: `444px`,
                height: `50px`,
                borderRadius: `25px`,
                border: `1px solid ${props.theme.background.secondary}`,
                background: props.theme.background.primary,
                ':hover': null,
            }),

            menuList: () => ({ borderRadius: `12px`, }),

            placeholder: style => ({ ...style, marginLeft: `12px`, }),
            input: () => ({ marginLeft: `12px` }),

            indicatorSeparator: () => ({
                opacity: 0,
            }),
            option: styles => ({
                ...styles,
                background: props.theme.background.primary,
                color: props.theme.text.primary,
                zIndex: 2000,
                ':hover': { backgroundColor: props.theme.background.support, },
            }),
        }
    })
})`
    
`;

const Wrapper = styled(Frame)`
    
`;
export default ProgramPage;

/*eslint-enable*/