/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { useLocation } from 'react-router-dom'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { useDebouncedCallback } from 'use-debounce'

import * as programsActions from '../../../redux/actions/ProgramsActions'
import { Frame, Text, convertHex, P, H1, H, Link } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import Form from '../../UIKit/Form'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch from '../helpers/useCustomDispatch'

let ProgramEdit = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let { getText, langs, lang } = useDictionary()

    let [info, setInfo] = useState({})
    let [localProgram, setLocalProgram] = useState({})

    let { program } = useMappedState(useCallback((state) => ({ program: { coordinatorsIds: [], info: {}, ...state.programs.programsMap.get(path[1]) } }), [path[1]]))

    let dispatch = useDispatch()
    let onClose = () => { window.dispatchEvent(new CustomEvent(`CLOSE_CREATE_PROGRAM_POP_UP`)) }
    let onCreateProgram = () => dispatch(programsActions.createProgram({ info: info, coordinatorsIds: [], applications: [] }))
    let onUpdateProgram = () => dispatch(programsActions.updateProgram({ ...localProgram, info: info }))
    let openConfirmPopUp = () => { window.dispatchEvent(new CustomEvent(`OPEN_CONFIRM_PROGRAM_APPLICATION_POP_UP`)) }
    let onSave = () => {
        if (localProgram.id === undefined) {
            onCreateProgram()
        } else {
            onUpdateProgram()
        }
        onClose()
        setInfo({})
        CommonHelper.linkTo(`/programs`)
    }

    useCustomDispatch(`OPEN_CREATE_PROGRAM_POP_UP`, (e) => {
        if (e.detail) {
            setInfo(e.detail.info)
            setLocalProgram(e.detail)
        }
    })
    useCustomDispatch(`CLOSE_CREATE_PROGRAM_POP_UP`, () => { setInfo({}); setLocalProgram({}) })
    useCustomDispatch(`ADD_COORDINATOR_${path[1]}`, (e) => { setLocalProgram({ ...localProgram, coordinatorsIds: [...localProgram.coordinatorsIds, e.detail.id].filter((i, b, self) => self.filter(j => j === i).length === 1) }) })

    useEffect(() => {
        setInfo(program.info)
        setLocalProgram({ ...program })
        if (path.slice(-1)[0] === `create`) {
            setInfo({})
        }
    }, [program, path.slice(-1)[0]])

    const [debouncedSaveProject] = useDebouncedCallback(() => { onUpdateProgram() }, 2000, []);
    useEffect(() => { debouncedSaveProject() }, [JSON.stringify(info)]);

    return (
        <Wrapper>
            <H extra={`margin-top: 15px;`} >{getText(`Questionary`)}</H>
            <H1>{info[`program_name_(${lang})`.toLowerCase()]}</H1>
            {
                (info.questions || []).length > 0 ? <>
                    <H1>{getText(`Reorder questions`)}</H1>
                    {
                        (info.questions || []).map((item, index) => {
                            return (
                                <Frame key={index} row extra={props => `width: 200px; height: 30px; padding: 10px; border-radius: 12px; border: 1px solid ${props.theme.background.secondary}; justify-content: flex-start; margin-bottom: 10px; cursor: move;`} >
                                    <P extra={`margin: 0; width: 150px; text-overflow:ellipsis; overflow: hidden; white-space: nowrap; display: inline;`} >{item[`title_${lang.toLowerCase()}`]}</P>
                                    <ArrowDown onClick={() => { if (index < info.questions.length - 1) { setInfo({ ...info, questions: info.questions.swap(index, index + 1) }) } }} />
                                    <ArrowUp onClick={() => { if (index > 0) { setInfo({ ...info, questions: info.questions.swap(index, index - 1) }) } }} />
                                </Frame>
                            )
                        })
                    }
                </> : null
            }
            {
                (info.questions || []).map((item, index) => {
                    return (
                        <Tab key={index} >
                            <H1>{getText(`Question`)} {index + 1}</H1>
                            <Form
                                data={{ ...item, type: { value: item.type, label: item.type } }}
                                onChange={(key, value) => { setInfo({ ...info, questions: info.questions.map((a, b) => b === index ? { ...a, [key]: value, options: key === `type` && (value === `multicheck` || value === `singlecheck`) ? [] : a.options } : a) }) }}
                                fields={[
                                    { type: `select`, name: `type`, options: [`textarea`, `singlecheck`, `multicheck`, `files`].map(i => ({ value: i, label: i })) },
                                    { type: `checkbox`, name: `required` },
                                    ...(item.type ? langs.map((lang) => ({ type: `string`, name: `title ${lang}` })) : []),
                                ]}
                            />
                            {
                                item.type === `multicheck` || item.type === `singlecheck` ? <>
                                    {
                                        (item.options || []).map((option, option_i) => {
                                            return (
                                                <Frame key={option_i}>
                                                    <Frame row extra={`width: 100%; justify-content: space-between; margin-bottom: 15px;`} >
                                                        <P extra={`margin-bottom: 0px;`} >{getText(`Option`)} {option_i + 1}</P>
                                                        <TrashCan onClick={() => { setInfo({ ...info, questions: info.questions.map((a, b) => b === index ? { ...a, options: a.options.filter((a, b) => b !== option_i) } : a) }) }} />
                                                    </Frame>
                                                    <Form
                                                        data={option}
                                                        onChange={(key, value) => { setInfo({ ...info, questions: info.questions.map((a, b) => b === index ? { ...a, options: a.options.map((a, b) => b === option_i ? { ...a, [key]: value } : a) } : a) }) }}
                                                        fields={[
                                                            ...langs.map((lang) => ({ type: `string`, name: lang }))
                                                        ]}
                                                    />
                                                </Frame>
                                            )
                                        })
                                    }
                                    <Button background={props => props.theme.blue} onClick={() => { setInfo({ ...info, questions: info.questions.map((a, b) => b === index ? { ...a, options: [...(a.options || []), { id: window.createId(), ...Object.fromEntries(new Map(langs.map(i => [i.toLowerCase(), ``]))) }] } : a) }) }} >{getText(`Add option`)}</Button>
                                </> : null
                            }
                            <Button background={props => props.theme.red} extra={`margin-top: 5px;`} onClick={() => { setInfo({ ...info, questions: info.questions.filter((a, b) => b !== index) }) }} >{getText(`Delete question`)}</Button>
                        </Tab>
                    )
                })
            }
            <Button background={props => props.theme.blue} onClick={() => { setInfo({ ...info, questions: [...(info.questions || []), { id: window.createId() }] }) }} >{getText(`Add question`)}</Button>
            <Frame row extra={`margin-top: 15px;`} >
                <Button shaped onClick={() => { onUpdateProgram(); openConfirmPopUp() }} >{getText(`Preview`)}</Button>
                <Link to={`/programs/${program.id}/edit`} ><Button background={props => props.theme.green} extra={`margin-left: 5px;`} onClick={onSave} >{getText(`Back`)}</Button></Link>
            </Frame>
        </Wrapper >
    );
}

const ArrowUp = styled(Frame)`
    width: 15px;
    height: 15px;
    background: url("${require(`../../../assets/images/arrow_down_black.svg`)}") no-repeat center center;
    transform: rotate(180deg);
    margin-left: 5px;
    cursor: pointer;
`;

const ArrowDown = styled(Frame)`
    width: 15px;
    height: 15px;
    background: url("${require(`../../../assets/images/arrow_down_black.svg`)}") no-repeat center center;
    margin-left: 5px;
    cursor: pointer;
`;

const Tab = styled(Frame)`
    width: calc(580px - 40px);
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 20px;
    align-items: flex-start;
    &:hover { box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2); };
    border: 1px solid ${props => props.theme.background.secondary};
`;



let TrashCan = styled(Frame)`
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url(${require(`../../../assets/images/trash_can.svg`)});
`



















const Wrapper = styled(Frame)`
    width: 920px;
    align-items: flex-start;
`;


export default ProgramEdit;

/*eslint-enable*/