/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation } from 'react-router-dom'
import { useMappedState, useDispatch } from 'redux-react-hook'
import moment from 'moment-timezone'

import * as articlesActions from '../../../redux/actions/ArticlesActions'

import { Frame, Text, convertHex, H1 } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import NewsTab from '../tools/NewsTab'
import ProgramTab from '../tools/program-tab'
import Tags from '../tools/Tags'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCurrentUser from '../helpers/useCurrentUser'
import useCustomDispatch from '../helpers/useCustomDispatch'

let crossing = (a, b) => [...a, ...b].some((i) => a.indexOf(i) > -1 && b.indexOf(i) > -1)

let NewsPage = () => {

    let dispatch = useDispatch()
    let ref = useRef()
    let { currentUser } = useCurrentUser()

    let path = useLocation().pathname.split(`/`).slice(1)
    let { getText, lang, langs } = useDictionary()

    let { article, articles, programs } = useMappedState(useCallback((state) => {
        let article = { LV: {}, ENG: {}, tags: [], ...state.articles.articlesMap.get(path[1]) }
        return ({
            article: article,
            articles: state.articles.articlesMap
                .filter(i => Object.values(i[lang]).length > 0)
                .filter(i => crossing([...i.tags], [...article.tags]))
                .filter(i => i.id !== path[1])
                .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
                .toArray(),
            programs: state.programs.programsMap.toArray().filter(i => crossing(i.info[`specialization_(tags)`] || [], article.tags)),
        })
    }, [path[1]]))

    let [visibleArticles, setVisibleArticles] = useState(3)

    useEffect(() => {
        try { ref.current.innerHTML = article[lang].articleHtml || `<p>${getText(`This article is empty`)}</p>` } catch { }
    }, [path])

    let openCreateNewsPopUp = (article) => { window.dispatchEvent(new CustomEvent(`OPEN_CREATE_NEWS_POP_UP`, { detail: article })) }
    let openDeleteNewsPopUp = (article) => { window.dispatchEvent(new CustomEvent(`OPEN_CONFIRM_POP_UP`, { detail: { confirm: `DELETE_ARTICLE_${path[1]}` } })) }

    useCustomDispatch(`DELETE_ARTICLE_${path[1]}`, () => {
        dispatch(articlesActions.deleteArticle(path[1]))
        CommonHelper.linkTo(`/news`)
    })

    let edit_buttons = [
        {
            image: `delete`,
            onClick: () => { openDeleteNewsPopUp(article) },
        },
        {
            image: `edit`,
            onClick: () => { openCreateNewsPopUp(article) },
        },
    ]

    if (article.tags.length === 0) {
        return (
            <Wrapper>
                <H1>{getText(`Article is not found`)}</H1>
            </Wrapper>
        )
    }

    if (Object.values(article[lang]).map(i => i === `` || typeof (i) !== `string`).reduce((a, b) => a && b, false) || Object.values(article[lang]).length < 3) {
        return (
            <Wrapper>
                <H1>{getText(`This article is available in another language`)} ({getText(langs[Math.abs(langs.indexOf(lang) - 1)])})</H1>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <NewsWrapper>

                <NewsHeader>
                    {/* <Frame row extra={`cursor: pointer;`} onClick={() => { CommonHelper.linkTo(`/news`) }} >
                        <BackArrow />
                        <Text text_color={props => props.theme.text.secondary} extra={`text-transform: uppercase; font-size: 14px; line-height: 18px;`} >Back to blog</Text>
                    </Frame> */}
                    {
                        currentUser.userRole === `admin` ?
                            <EditButtonsWrapper>
                                {
                                    edit_buttons.map((item, index) => <EditButton key={index} name={item.image} onClick={item.onClick} />)
                                }
                            </EditButtonsWrapper> : null
                    }
                    <Heading>{article[lang].heading}</Heading>
                    <HeaderBottomWrapper>
                        <Tags tagsArray={article.tags} />
                        <Text text_color={props => props.theme.text.secondary} extra={`width: 15%; align-items: flex-end; text-transform: uppercase; font-size: 14px; line-height: 18px; @media only screen and (max-width: 600px) { width: 100%; };`} >{moment(article.createdTimestamp).format(`DD MMM YYYY`)}</Text>
                    </HeaderBottomWrapper>
                </NewsHeader>

                <ProjectImage src={article[lang].imgSrc} />

                <ArticleContentWrapper ref={ref} />

            </NewsWrapper>

            {
                articles.filter((a, b) => b < visibleArticles).length > 0 ? <>
                    <H1>{getText(`Other news`)}</H1>

                    <NewsListWrapper articlesNumber={Math.min(visibleArticles, articles.length, 3)} >
                        {
                            articles.filter((a, b) => b < visibleArticles).map((item, index) => {
                                return (
                                    <Frame key={index} extra={`margin-top: 30px;`} >
                                        <NewsTab articleId={item.id} />
                                    </Frame>
                                )
                            })
                        }
                    </NewsListWrapper>
                </> : null
            }

            {
                articles.length > visibleArticles ? <Button shaped extra={`margin: 70px 0`} onClick={() => { setVisibleArticles(Math.min(articles.length, visibleArticles + 3)) }} >{getText(`View more`)}</Button> : null
            }

            {/* {
                programs.length ? <>
                    <H1>{getText(`Programs`)}</H1>
                    {
                        programs.map((item, index) => {
                            return (
                                <Frame key={index} extra={`margin-top: 30px;`} >
                                    <ProgramTab programId={item.id} />
                                </Frame>
                            )
                        })
                    }
                </> : null
            } */}

        </Wrapper>
    );
}

const HeaderBottomWrapper = styled(Frame)`
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        width: 90vw;
    }
`;

const Heading = styled(Frame)`
    font-size: 48px;
    line-height: 62px;
    margin: 0px 0 20px 0;
    font-family: Bold;
    color: ${props => props.theme.text.primary};

    @media only screen and (max-width: 600px) {
        font-size: 7.5vw;
        line-height: 10vw;
        margin: 0;
        margin-bottom: 4vw;
    }
`;

const EditButton = styled(Frame)`
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    border: 2px solid ${props => props.theme.text.primary};
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url(${props => require(`../../../assets/images/project-edit-button-${props.name}.svg`)}) no-repeat;
    }
`;

const EditButtonsWrapper = styled(Frame)`
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
`;

const ArticleContentWrapper = styled(Frame)`
    width: 825px;
    color: ${props => props.theme.text.secondary};

    * {
        max-width: 100%;
        height: auto !important;
    }

    @media only screen and (max-width: 600px) {
        width: 90vw !important;
        align-items: flex-start;
        
    }
`;

const ProjectImage = styled(Frame)`
    width: 825px;
    height: 500px;
    margin-bottom: 30px;
    background-image: url(${props => props.src ? props.src : null});
    background-size: cover;
    background-position: center;

    @media only screen and (max-width: 600px) {
        width: 100vw;
        height: 60vw;
        margin-left: -5vw;
    }
`;

const NewsWrapper = styled(Frame)`
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

const TagWrapper = styled(Text)`
    padding: 0 22px 0 22px;
    border-radius: 4px;
    background: ${props => convertHex(props.theme.green, 0.15)};
    margin: 0 10px 5px 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 22px;
`;

let ShareLogo = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/share_${props.name}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 25px;
    height: 25px;
`

let BackArrow = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/back_arrow.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 16px;
    height: 16px;
    margin-right: 3px;
`

const NewsHeader = styled(Frame)`
    width: 825px;
    margin: 30px 0 30px 0;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

const NewsListWrapper = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 1100px;
    margin-top: 0px;
    margin-bottom: 60px;
    cursor: pointer;
    > * {
        &:nth-child(3n + 2) {
            margin: 30px 20px 0 20px;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    @media only screen and (max-width: 600px) {
        width: 90vw;
        > * {
            &:nth-child(3n + 2) {
                margin: 6.25vw 0 0 0;
            }
        }
    }
`;

const MySelect = styled(Select).attrs((props) => {
    return ({
        styles: {
            control: styles => ({
                ...styles,
                width: `444px`,
                height: `50px`,
                borderRadius: `25px`,
                border: `1px solid ${props.theme.background.secondary}`,
                background: props.theme.background.primary,
                ':hover': null,
            }),

            menuList: () => ({ borderRadius: `12px`, }),

            placeholder: style => ({ ...style, marginLeft: `12px`, }),
            input: () => ({ marginLeft: `12px` }),

            indicatorSeparator: () => ({
                opacity: 0,
            }),
            option: styles => ({
                ...styles,
                background: props.theme.background.primary,
                color: props.theme.text.primary,
                zIndex: 2000,
                ':hover': { backgroundColor: props.theme.background.support, },
            }),
        }
    })
})`
    
`;

const Wrapper = styled(Frame)`
    
`;

const FilterToolsWrapper = styled(Frame)`
    width: 1100px;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

export default NewsPage;

{/* <Frame extra={`width: 60px; margin-left: 35px;`} >
    <Text text_color={props => props.theme.text.secondary} extra={`text-transform: uppercase; font-size: 12px; line-height: 15px; margin-bottom: 20px;`} >Share</Text>
    {
        [`facebook`, `twitter`, `linkedin`, `plus`].map((item, index) => {
            return (
                <Frame key={index} extra={props => `width: 60px; height: 60px; border: 2px solid ${props.theme.background.support}; border-radius: 60px; margin-bottom: 10px; cursor: pointer; &:hover { opacity: 0.8; };`} >
                    <ShareLogo name={item} />
                </Frame>
            )
        })
    }
</Frame> */}

/*eslint-enable*/