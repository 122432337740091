/*eslint-disable*/
import React, { useState, useCallback } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation } from 'react-router-dom'
import { useMappedState } from 'redux-react-hook'

import { Frame, Input, Button } from '../../UIKit/styled-templates'
import NewsTab from '../tools/NewsTab'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCurrentUser from '../helpers/useCurrentUser'

let animatedComponents = makeAnimated();

let NewsListPage = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let { getText, lang, dict } = useDictionary()

    let tags = Object.keys(dict).filter(i => i.indexOf(`tag_`) > -1)
    let categories = tags.map(i => ({ value: i, label: getText(i) }))

    let { articles } = useMappedState(useCallback((state) => ({
        articles: state.articles.articlesMap
            .filter(i => Object.values(i[lang]).filter(i => typeof (i) === `string` && i !== ``).length >= 3)
            .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
            .toArray(),
    }), [path, lang]))

    let { currentUser } = useCurrentUser()

    let [search, setSearch] = useState(``)
    let [category, setCategory] = useState(null);
    let [visibleArticles, setVisibleArticles] = useState(12)

    let openCreateNewsPopUp = () => { window.dispatchEvent(new CustomEvent(`OPEN_CREATE_NEWS_POP_UP`)) }

    let visibleArticlesList = articles
        .filter(i => category ? i.tags.indexOf(category.value) > -1 : true)
        .filter(i => search === `` || i[lang].heading.indexOf(search) > -1)

    return (
        <Wrapper>
            {
                currentUser.userRole === `admin` ? <>
                    <Frame row extra={`margin-top: 20px; width: 100%; justify-content: flex-start;`} >
                        <Button background={props => props.theme.blue} onClick={openCreateNewsPopUp} >{getText(`Create new article`)}</Button>
                    </Frame>
                </> : null
            }
            <FilterToolsWrapper>
                <Input value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder={getText(`Search`)} image={`search`} extra={`border-radius: 25px; width: 601px; @media only screen and (max-width: 600px) { border-radius: 10vw; };`} />
                <MySelect
                    value={category}
                    options={tags.map(i => ({ value: i, label: getText(i) })) || []}
                    placeholder={getText(`Category`)}
                    onChange={e => { setCategory(e) }}
                />
            </FilterToolsWrapper>

            <NewsWrapper>
                {
                    visibleArticlesList.filter(i => i[lang].heading.toLowerCase().indexOf(search) > -1).filter((a, b) => b < visibleArticles).map((item, index) => {
                        return (
                            <Frame key={index} extra={`margin-top: 30px;`} >
                                <NewsTab articleId={item.id} key={index} />
                            </Frame>
                        )
                    })
                }
            </NewsWrapper>

            {
                visibleArticlesList.length > visibleArticles && search === `` ? <Button shaped extra={`margin: 70px 0 70px 0;`} onClick={() => { setVisibleArticles(Math.min(visibleArticlesList.length, visibleArticles + 3)) }} >{getText(`View more`)}</Button> : null
            }

        </Wrapper>
    );
}

const NewsWrapper = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    width: 1100px;
    > * {
        &:nth-child(3n + 2) {
            margin: 30px 20px 0 20px;
        }
    }

    @media only screen and (max-width: 600px) {
        width: 90vw;
        > * {
            &:nth-child(3n + 2) {
                margin: 6.25vw 0 0 0;
            }
        }
    }
`;

const MySelect = styled(Select).attrs((props) => {
    return ({
        defaultValue: [],
        closeMenuOnSelect: true,
        components: animatedComponents,
        isClearable: true,
        styles: {
            singleValue: style => ({ ...style, color: props.theme.text.primary, marginLeft: `12px`, }),
            control: styles => ({
                ...styles,
                width: `444px`,
                height: `50px`,
                borderRadius: `25px`,
                border: `1px solid ${props.theme.background.secondary}`,
                background: props.theme.background.primary,
                overflow: `hidden`,
                fontSize: `14px`,
                ':hover': null,
            }),
            menuList: style => ({ borderRadius: `12px`, }),
            placeholder: style => ({ ...style, marginLeft: `12px`, color: props.theme.text.secondary, fontSize: `14px`, }),
            input: style => ({ ...style, marginLeft: `12px`, fontSize: `14px`, }),
            valueContainer: style => ({ ...style, fontSize: `14px`, color: props.theme.text.secondary, margineft: `12px`, }),
            indicatorSeparator: style => ({ ...style, opacity: 0, }),
            option: styles => ({
                ...styles,
                background: props.theme.background.secondary,
                fontSize: `14px`,
                color: props.theme.text.primary,
                zIndex: 2000,
                ':hover': { backgroundColor: props.theme.background.support, },
            }),

        }
    })
})``;

const Wrapper = styled(Frame)`
    
`;

const FilterToolsWrapper = styled(Frame)`
    width: 1100px;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin-top: 0;
        > * {
            &:not(:first-child) {
                display: none;
                visibility: hidden;
            }
        }
    }
`;

export default NewsListPage;

/*eslint-enable*/