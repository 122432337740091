/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useMappedState, useDispatch } from 'redux-react-hook'

import * as FAQActions from '../../../redux/actions/FAQActions'

import { Frame, Button, Input, Dropdown, H1, P, Link, H } from '../../UIKit/styled-templates'
import CommonHelper from '../../../helpers/CommonHelper'

import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'
import useKeyHandler from '../helpers/useKeyHandler'

// let questions = new Array(10).fill(0).map(i => ({ id: Math.random().toString(36).substring(3).split(``).map(i => i[Math.random() > 0.5 ? `toLowerCase` : `toUpperCase`]()).join(``) }))


let Question = (item) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let question_id = path[1]

    let ref = useRef()
    let { lang } = useDictionary()

    let visible = question_id !== undefined && item.id === question_id

    useEffect(() => {
        if (visible) {
            let position = ref.current.offsetTop - ref.current.scrollHeight - 50
            if (window.innerWidth < 480) {
                position = position + window.innerHeight / 2
            }
            try { document.getElementById(`scrollWrapper`).scrollTo({ top: position, behavior: "smooth", }); } catch (error) { }
            try { window.scrollTo({ top: position, behavior: "smooth", }); } catch (error) { }
        }
    }, [ref, question_id])

    let { userRole } = useCurrentUser()

    return (
        <Link to={`/faq/${visible ? `` : item.id}`} className={`faq_${item.id}`} withoutScroll={true} >
            <Wrapper ref={ref} isFirst={item.index === 0} >
                <Frame extra={`width: 100%; flex-direction: row; justify-content: space-between; align-items: flex-start;`} >
                    <Title>{item[`question_${lang === `LV` ? `lv` : `en`}`]} {item.visible === false ? `(hidden)` : ``}</Title>
                    <Arrow up={visible} />
                </Frame>
                <Answer visible={visible} >
                    {item[`answer_${lang === `LV` ? `lv` : `en`}`]}
                    {
                        userRole === `admin` ? <Link to={`/faq/${item.id}/edit`} ><Button extra={`margin: 0 !important;`} >Edit</Button></Link> : null
                    }
                </Answer>
            </Wrapper>
        </Link>
    )
}

let FAQPage = (props) => {

    let dispatch = useDispatch()

    let [search, setSearch] = useState(``)
    let { userRole } = useCurrentUser()

    let { faq } = useMappedState(useCallback((state) => ({ faq: state.faq.faq }), []))

    // useKeyHandler(`Escape`, () => { CommonHelper(`/faq`) })

    return (
        <Frame>
            <HeaderWrapper>
                <H extra={`@media (min-width: 320px) and (max-width: 480px) { margin-bottom: 3vw; };`} >FAQ</H>
                <Frame extra={`flex-direction: row; @media (min-width: 320px) and (max-width: 480px) { flex-direction: column; };`} >
                    {
                        userRole === `admin` ? <Link to={`/faq/${window.createId()}/edit`} ><Button extra={`margin-right: 10px;`} >+ Add</Button></Link> : null
                    }
                    <Input value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder={`Search`} image={`search`} extra={`border-radius: 25px; width: 350px; @media (min-width: 320px) and (max-width: 480px) { border-radius: 10vw; };`} />
                </Frame>
            </HeaderWrapper>
            <Frame extra={`width: 100%;`} >
                {
                    Object.values(faq).filter(i => i.visible || userRole === `admin`).filter(i => Object.values(i).join(``).replace(` `, ``).toLocaleLowerCase().indexOf(search.replace(` `, ``).toLocaleLowerCase()) > -1 || search === ``).map((item, index) => {
                        return <Question key={index} {...item} index={index} />
                    })
                }
            </Frame>
        </Frame>
    )
}

const HeaderWrapper = styled(Frame)`
    width: 920px;
    flex-direction: row;
    justify-content: space-between;
    margin: 45px 0;

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        width: 90vw;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 0 45px 0;;
    }
`;

const Answer = styled(Frame)`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.011em;
    color: ${props => props.theme.text.secondary};
    width: 920px;
    align-items: flex-start;

    font-size: ${props => props.visible ? 16 : 0}px;
    line-height: ${props => props.visible ? 26 : 0}px;
    opacity: ${props => props.visible ? 1 : 0};
    visibility: ${props => props.visible ? `visible` : `hidden`};
    margin-top: ${props => props.visible ? 16 : 0}px;

    > * {
        display: ${props => props.visible ? `flex` : `none`};
    }

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        width: 90vw;
    }
`;

const Arrow = styled(Frame)`
    width: 27px;
    height: 27px;
    background: url('${require(`../../../assets/images/faq-arrow.svg`)}') no-repeat center center / contain;
    cursor: pointer;

    transform: rotate(${props => props.up ? 180 : 0}deg);

    &:hover {
        transform: rotate(${props => props.up ? 180 : 0}deg) translateY(2px);
    };

    &:active {
        transform: rotate(${props => props.up ? 180 : 0}deg) translateY(-10px);
    };
`;

const Title = styled(Frame)`
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.011em;
    color: ${props => props.theme.text.primary};
    width: 800px;
    align-items: flex-start;

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        width: 80vw;
        font-size: 5vw;
        line-height: 8vw;
    }
`;

const Wrapper = styled(Frame)`
    width: 100%;
    border-top: ${props => props.isFirst ? 0 : 1}px solid ${props => props.theme.background.secondary};
    margin: 0px 0 16px 0;
    padding: 16px 0 0 0;
`;

export default FAQPage;
/*eslint-enable*/