/*eslint-disable*/
import React, { useState } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation, useHistory } from 'react-router-dom'

import { Frame, Text, convertHex, H1, P, H } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import Tags from '../tools/Tags'
import ProjectTab from '../tools/ProjectTab'
import CommonHelper from '../../../helpers/CommonHelper'
import useCompany from '../helpers/useCompany'
import Gallery from '../tools/Gallery'
import Contacts from '../tools/Contacts'

import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'

let CompaniesProfilePage = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let { currentUser } = useCurrentUser()
    let { getText } = useDictionary()

    let { company, companysProjects } = useCompany(path[1])

    let [visibleProjects, setVisibleProjects] = useState(2)

    let companyInfo = {
        ...(company || { info: {} }).info,
        email: company.email,
    }

    let links = {
        facebook: companyInfo.facebook ? companyInfo.facebook : undefined,
        linkedin: companyInfo.linkedin ? companyInfo.linkedin : undefined,
    }

    return (
        <Wrapper>

            <HeaderImageWrapper src={companyInfo.headerUrl} >
                {/* <BackButtonWrapper onClick={() => { CommonHelper.linkTo(`/companies`) }} >
                    <BackArrow />
                    <Text text_color={props => props.theme.text.secondary} extra={`text-transform: uppercase; font-size: 14px; line-height: 18px;`} >Back to blog</Text>
                </BackButtonWrapper> */}
                {
                    currentUser.userRole === `admin` ? <EditButton name={`edit`} onClick={() => { CommonHelper.linkTo(`/user/${path[1]}/edit`) }} /> : null
                }
            </HeaderImageWrapper>

            <Workspace>
                {/* <UserAvatar src={companyInfo.avatarUrl} /> */}

                <HeaderWrapper>
                    <AvatarContacts>
                        <UserAvatar src={companyInfo.avatarUrl} />
                        <MobilContacts>
                            <Frame row extra={`> * { margin-right: 16px; &:last-child { margin: 0; }; };`} >
                                {
                                    Object.keys(links).map((item, index) => {
                                        if (links[item] !== undefined) {
                                            let link = links[item].split(`https://`).filter(i => i.split(``).length > 0)[0].split(`www.`).filter(i => i.split(``).length > 0)[0]
                                            return (
                                                <Frame
                                                    key={index}
                                                    extra={props => `width: 30px; height: 30px; border-radius: 15px; box-sizing: border-box; border: 1px solid ${props.theme.background.secondary}; &:hover { opacity: 0.8; }; cursor: pointer;`}
                                                    onClick={() => { window.open(`https://${link}`); }}
                                                >
                                                    <ShareLogo name={item} />
                                                </Frame>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </Frame>
                            {companyInfo.phone ? <Frame row ><PhoneImage /><P extra={`margin: 0px; @media only screen and (max-width: 600px) { display: none; };`} >{companyInfo.phone}</P></Frame> : null}
                            {companyInfo.location ? <Frame row ><LocationImage /><P extra={`margin: 0px; @media only screen and (max-width: 600px) { display: none; };`} >{companyInfo.location}</P></Frame> : null}
                        </MobilContacts>
                    </AvatarContacts>

                    <Frame row extra={`margin-bottom: 20px;`} >
                        <H>{companyInfo[`company_name`] || getText(`Company Name`)}</H>
                        {company.verified ? <VerifiedSign /> : null}
                    </Frame>
                    <Tags tagsArray={companyInfo[`specialization_(tags)`] || []} />
                </HeaderWrapper>

                {
                    companyInfo[`short description`] ? <P bold extra={props => `color: ${props.theme.text.primary}; margin-bottom: 20px;`} >
                        {companyInfo[`short description`]}
                    </P> : null
                }

                {
                    companyInfo[`description`] ? <P>
                        {companyInfo[`description`]}
                    </P> : null
                }

                {
                    companyInfo.gallery ? <>
                        <H1>{getText(`Gallery`)}</H1>
                        <Gallery photoArray={companyInfo.gallery} />
                    </> : null
                }

                {
                    links.length > 0 ? <>
                        <H1>{getText(`Contacts`)}</H1>
                        <Frame row extra={`margin-bottom: 15px;`} >
                            {
                                Object.keys(links).map((item, index) => {
                                    if (links[item] !== undefined) {
                                        let link = links[item].split(`https://`).filter(i => i.split(``).length > 0)[0].split(`www.`).filter(i => i.split(``).length > 0)[0]
                                        return (
                                            <Frame
                                                key={index}
                                                extra={props => `margin-right: 16px; width: 30px; height: 30px; border-radius: 15px; box-sizing: border-box; border: 1px solid ${props.theme.background.secondary}; &:hover { opacity: 0.8; }; cursor: pointer;`}
                                                onClick={() => { window.open(`https://${link}`); }}
                                            >
                                                <ShareLogo name={item} />
                                            </Frame>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            }
                            <Contacts info={companyInfo} />
                        </Frame>
                    </> : null
                }

                <H1>{getText(`List of projects`)}</H1>

                {
                    companysProjects.filter((a, b) => b < visibleProjects).map((item, index) => {
                        return (
                            <ProjectTab key={index} projectId={item.id} />
                        )
                    })
                }

                {
                    companysProjects.length === 0 ? <P>{getText(`Empty`)}</P> : null
                }

                {
                    companysProjects.length > visibleProjects ? <Button shaped extra={`margin: 70px 0 70px 0;`} onClick={() => { setVisibleProjects(Math.min(companysProjects.length, visibleProjects + 5)) }} >{getText(`View more`)}</Button> : null
                }

            </Workspace>

        </Wrapper>
    );
}

const MobilContacts = styled(Frame)`
    width: calc(100% - 160px - 14px);
    height: 50%;
    flex-direction: row;
    justify-content: flex-start;

    @media only screen and (max-width: 600px) {
        width: calc(100% - 37.5vw - 3.75vw);
        margin: 0;
        justify-content: space-between;
    }
`;

const AvatarContacts = styled(Frame)`
    width: 920px;
    height: 160px;
    margin-top: -80px;
    z-index: 0;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: 37.5vw;
        margin-top: -18.75vw;
    }
`;

const EditButton = styled(Frame)`
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    border: 2px solid ${props => props.theme.text.primary};
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;

    &:hover {
        opacity: 0.8;
    }

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url(${props => require(`../../../assets/images/project-edit-button-${props.name}.svg`)}) no-repeat;
    }
`;

const UserAvatar = styled(Frame)`
    width: 160px;
    height: 160px;
    border-radius: 50%;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});
    background-position: center;
    border: 2px solid ${props => props.theme.background.primary};
    box-sizing: border-box;
    z-index: 1;

    @media only screen and (max-width: 600px) {
        width: 37.5vw;
        height: 37.5vw;
    }
`;

const GalleryPhoto = styled(Frame)`
    width: 920px;
    height: 557px;
    background-image: url("${props => props.src}");
    background-position: center;
    position: relative;
`;

let ArrowLeft = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_down.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 13px; height: 13px; transform: rotate(90deg); user-select: none;`

let ArrowRight = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_down.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 13px; height: 13px; transform: rotate(-90deg); user-select: none;`

let EmptyAvatar = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/empty_avatar.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 160px; height: 160px;`

const TagWrapper = styled(Text)`
    padding: 0 22px 0 22px;
    border-radius: 4px;
    background: ${props => convertHex(props.theme.green, 0.15)};
    margin: 0 10px 5px 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 22px;
`;

const Workspace = styled(Frame)`
    width: 920px;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 36px; height: 36px; margin-left: 22px; @media only screen and (max-width: 600px) { margin: 0; }`

let LocationImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/location.png`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin: 0 15px 0 30px; @media only screen and (max-width: 600px) { margin: 0; }`

let PhoneImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/phone.png`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin: 0 15px 0 30px; @media only screen and (max-width: 600px) { margin: 0; }`

const AvatarWrapper = styled(Frame)`
    width: 160px;
    height: 160px;
    border-radius: 80px;
    border: 2px solid ${props => props.theme.background.primary};
    box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
    background-image: url("${props => props.src}");
    background-position: center;
`;

const HeaderWrapper = styled(Frame)`
    align-items: flex-start;
    margin-bottom: 40px;
`;

const BackButtonWrapper = styled(Frame)`
    width: 825px;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;
    cursor: pointer;
    z-index: 1;
`;

const HeaderImageWrapper = styled(Frame)`
    width: 1100px;
    height: ${props => props.src ? 256 : 100}px;
    justify-content: flex-start;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
    background-image: url("${props => props.src}");
    background-position: center;
    position: relative;

    @media only screen and (max-width: 600px) {
        width: 100vw;
        height: 37.5vw;
    }
`;

let ShareLogo = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/share_${props.name}.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 15px; height: 15px; `

let BackArrow = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/back_arrow.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 16px;
    height: 16px;
    margin-right: 3px;
`

const Wrapper = styled(Frame)`
    @media only screen and (max-width: 600px) {
        margin-top: -44px;
    }
`;

export default CompaniesProfilePage;

/*eslint-enable*/