/*eslint-disable*/
/**
 * Created by sabir on 19.07.17.
 */

import { Map, Stack, Set } from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    programsMap: Map(),
    appliedProgramsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined }
}

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error }
}

const ProgramsReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.DELETE_PROGRAM_APPLICATION_SUCCESS:
            return {
                ...state,
                programsMap: action.programs.reduce((map, form) => map.set(form.id, form), Map())
            }

        case types.UPDATE_PROGRAM_ORDER:
            return {
                ...state,
                programsMap: state.programsMap.merge(action.programs.reduce((map, form) => map.set(form.id, form), Map()))
            }

        case types.CREATE_PROGRAM:
        case types.UPDATE_PROGRAM:
        case types.DELETE_PROGRAM:
        case types.LOAD_PROGRAMS:
        case types.APPLY_TO_PROGRAM:
        case types.UPDATE_PROGRAM_STATUS:
        case types.DELETE_PROGRAM_APPLICATION:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_PROGRAM_FAIL:
        case types.UPDATE_PROGRAM_FAIL:
        case types.DELETE_PROGRAM_FAIL:
        case types.LOAD_PROGRAMS_FAIL:
        case types.UPDATE_PROGRAM_STATUS_FAIL:
        case types.DELETE_PROGRAM_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_PROGRAM_SUCCESS:
        case types.UPDATE_PROGRAM_SUCCESS:
            return {
                ...state,
                loading: false,
                programsMap: state.programsMap.set(action.program.id, action.program)
            }

        case types.APPLY_TO_PROGRAM_SUCCESS:
            return {
                ...state,
                loading: false,
                programsMap: state.programsMap.set(action.program.id, action.program),
                appliedProgramsMap: state.appliedProgramsMap.merge(state.programsMap.toArray().filter(i => i.id === action.id).reduce((map, form) => map.set(form.id, form), Map()))
            }

        case types.CANCEL_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                programsMap: state.programsMap.set(action.program.id, action.program),
                appliedProgramsMap: state.appliedProgramsMap.toArray().filter(i => i.id !== action.id).reduce((map, form) => map.set(form.id, form), Map())
            }

        case types.DELETE_PROGRAM_SUCCESS:
            return {
                ...state,
                loading: false,
                programsMap: state.programsMap.delete(action.id),
            }

        case types.LOAD_APPLIED_PROGRAMS_SUCCESS:
            return {
                ...state,
                loading: false,
                appliedProgramsMap: state.appliedProgramsMap.merge(action.programs.reduce((map, form) => map.set(form.id, form), Map()))
            }

        case types.LOAD_PROGRAMS_SUCCESS:
        case types.UPDATE_PROGRAM_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                programsMap: state.programsMap.merge(action.programs.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default ProgramsReducer;

/*eslint-enable*/