/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import { useMappedState } from 'redux-react-hook'

import { Frame, Text, convertHex, P, Link } from '../../UIKit/styled-templates'
import useDictionary from '../helpers/useDictionary'

let CompanyTab = (props) => {

    let { getText, dict } = useDictionary()

    let projectStatuses = Object.keys(dict).filter(i => i.indexOf(`project_status_`) > -1)

    let { companyId } = props
    let { company } = useMappedState(useCallback(state => {
        return ({
            company: {
                applicationsNumber: state.projects.projectsMap.toArray().filter(i => i.creatorId === companyId).map(i => i.applications.length).reduce((a, b) => a + b, 0),
                completedProjects: state.projects.projectsMap.toArray().filter(i => i.creatorId === companyId).map(i => i.status).filter(i => i === projectStatuses[3] || i === projectStatuses[4]).length,
                projectsInProgress: state.projects.projectsMap.toArray().filter(i => i.creatorId === companyId).map(i => i.status).filter(i => i === projectStatuses[0] || i === projectStatuses[1]  || i === projectStatuses[2]).length,
                ...state.users.usersMap.get(companyId),
            },
        })
    }, [companyId]));
    let companyInfo = {
        ...company.info
    }

    let links = {
        facebook: companyInfo.facebook ? companyInfo.facebook : undefined,
        linkedin: companyInfo.linkedin ? companyInfo.linkedin : undefined,
    }

    return (
        <Link to={`/user/${company.id}`} >
            <Tab>
                <Upper>
                    <Frame>
                        <AvatarContacts>
                            <Avatar src={companyInfo.avatarUrl} />
                            <ContactsWrapper>
                                <Frame extra={`width: calc(100% - 80px); height: 80%; align-items: flex-start; justify-content: space-between;`} >
                                    {companyInfo.location
                                        ? <Frame row><Location /><Frame extra={props => `font-size: 16px; color: ${props.theme.text.primary};`} >{companyInfo.location}</Frame></Frame>
                                        : null
                                    }
                                    {companyInfo.phone
                                        ? <Frame row><Phone /><Frame extra={props => `font-size: 16px; color: ${props.theme.text.primary};`} >{companyInfo.phone}</Frame></Frame>
                                        : null
                                    }
                                </Frame>
                                <Frame row extra={`width: 80px; > * { margin-left: 20px; &:first-child { margin: 0; }; };`} >
                                    {
                                        Object.keys(links).filter((a, b) => b < 2).map((item, index) => {
                                            if (links[item] === undefined) {
                                                return null
                                            } else {
                                                return <SocialIcon key={index} name={item} />
                                            }
                                        })
                                    }
                                </Frame>
                            </ContactsWrapper>
                        </AvatarContacts>
                        <CompanyName>
                            {companyInfo.company_name}
                            {company.verified ? <VerifiedSign /> : null}
                        </CompanyName>
                    </Frame>
                    <Discription>
                        {companyInfo.description}
                    </Discription>
                </Upper>
                <Lower>
                    <Frame>
                        <Block>
                            <Frame>{getText(`All projects`)}</Frame>
                            <Frame>{company.applicationsNumber} {getText(`applications`)}</Frame>
                        </Block>
                        <Block>
                            <Frame>{getText(`Completed`)}</Frame>
                            <Frame>{company.completedProjects}</Frame>
                        </Block>
                        <Block>
                            <Frame>{getText(`Projects in progress`)}</Frame>
                            <Frame>{company.projectsInProgress}</Frame>
                        </Block>
                    </Frame>
                </Lower>
            </Tab>
        </Link>
    );
}

const Block = styled(Frame)`
    @media only screen and (min-width: 600px) {
        flex: 1;
    }
    align-items: flex-start;
    justify-content: flex-end;
    > * {
        &:nth-child(1) {
            font-size: 13px;
            color: ${props => props.theme.text.secondary};
            @media only screen and (max-width: 600px) {
                font-size: 4vw;
            };
        }
        &:nth-child(2) {
            font-size: 14px;
            color: ${props => props.theme.text.primary};
            @media only screen and (max-width: 600px) {
                font-size: 4.375vw;
            };
        }
    }

    @media only screen and (max-width: 600px) {
        width: 50%;
        height: 50%;
    }
`;

const Phone = styled(Frame)`
    width: 24px;
    height: 24px;
    margin-right: 15px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/phone.png`)});
`;

const Location = styled(Frame)`
    width: 24px;
    height: 24px;
    margin-right: 15px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/location.png`)});
`;

const SocialIcon = styled(Frame)`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid ${props => props.theme.background.support};

    &:after {
        content: '';
        width: 15px;
        height: 15px;
        background-image: url(${props => props.src ? props.src : require(`../../../assets/images/share_${props.name}.svg`)});
    }
`;

const ContactsWrapper = styled(Frame)`
    width: calc(100% - 100px - 30px);
    height: 80px;
    flex-direction: row;
    align-items: flex-end;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

const CompanyName = styled(Frame)`
    width: 100%;
    height: 30px;
    color: ${props => props.theme.text.primary};
    font-size: 20px;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 15px;

    @media only screen and (max-width: 600px) {
        width: calc(100% - 12.5vw - 4.5vw);
        font-size: 4.375vw;
        margin-top: 0px;
    }
`;

const AvatarContacts = styled(Frame)`
    width: 100%;
    height: 100px;
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        width: auto;
        height: auto;
    }
`;

const Avatar = styled(Frame)`
    width: 100px;
    height: 100px;
    margin-right: 30px;
    border-radius: 50%;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});

    @media only screen and (max-width: 600px) {
        width: 12.5vw;
        height: 12.5vw;
        margin-right: 4.5vw;
    }
`;

const Discription = styled(Frame)`
    width: 100%;
    height: calc(100% - 100px - 30px - 15px);
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 13px;
    line-height: 26px;
    color: ${props => props.theme.text.secondary};

    @media only screen and (max-width: 600px) {
        height: calc(100% - 12.5vw - 6.25vw);
        margin-top: 6.25vw;
        font-size: 4vw;
        line-height: 6vw;
    }
`;

let VerifiedSign = styled(Frame)`
    width: 24px;
    height: 24px;
    margin-left: 8px;
    background-image: url(${require(`../../../assets/images/approved_company.svg`)});

    @media only screen and (max-width: 600px) {
       
    }
`

const Upper = styled(Frame)`
    width: calc(100% - 60px);
    height: calc(100% - 80px - 60px);
    margin: 30px;
    align-items: flex-start;
    justify-content: space-between;

    > * {
        &:first-child {
            width: 100%;
            flex-direction: column;
            @media only screen and (max-width: 600px) {
                flex-direction: row;
            };
        }
    }

    @media only screen and (max-width: 600px) {
        width: calc(100% - 12.5vw);
        height: calc(100% - 37vw - 12.5vw);
        margin: 6.25vw;
    }
`;

const Lower = styled(Frame)`
    width: 540px;
    height: 80px;
    background: ${props => props.theme.background.secondary};

    > * {
        flex-direction: row;
        width: calc(100% - 60px);
        height: calc(100% - 34px);
        margin: 17px 30px;
        flex-wrap: wrap;
        justify-content: flex-start;
        > * {
            &:nth-child(1) { margin: 0; }
            &:nth-child(2) { margin: 0; }
            @media only screen and (max-width: 600px) {
                margin-top: 1.5vw;
            }
        }
        @media only screen and (max-width: 600px) {
            margin: 4vw 6vw;
            width: calc(100% - 8vw);
            height: calc(100% - 12vw);
        }
    }

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: 37vw;
    }
`;

const Tab = styled(Frame)`
    width: 540px;
    height: 360px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    border: 1px solid ${props => props.theme.background.secondary};
    overflow: hidden;
    justify-content: space-between;
    &:hover {
        ${props => props.theme.shadow.blue};
    }

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: 106.25vw;
    }
`;

export default CompanyTab;

/*eslint-enable*/