/*eslint-disable*/
import DictionaryAPI from '../../api/DictionaryAPI'

import * as types from '../ActionTypes'

let uuid = () => Math.random().toString(36).substring(3).split(``).map(i => Math.random() > 0.5 ? i.toUpperCase() : i).join(``)

let loadDictionary_ = () => {
    return {
        type: types.LOAD_DICTIONARY
    }
}
let loadDictionarySuccess = (dictionary) => {
    return {
        type: types.LOAD_DICTIONARY_SUCCESS,
        dictionary: dictionary
    }
}
let loadDictionaryFailed = (error) => {
    return {
        type: types.LOAD_DICTIONARY_FAIL,
        error: error
    }
}

//thunk
export function loadAllDictionary() {
    return (dispatch, getState) => {
        dispatch(loadDictionary_());
        return DictionaryAPI.getDictionary().then(
            dictionary => dispatch(loadDictionarySuccess(dictionary)),
            error => dispatch(loadDictionaryFailed(error))
        )
    }
}

let createDictionary_ = () => {
    return {
        type: types.CREATE_DICTIONARY
    }
}
let createDictionarySuccess = (dictionary) => {
    return {
        type: types.CREATE_DICTIONARY_SUCCESS,
        dictionary: dictionary
    }
}
let createDictionaryFailed = (error) => {
    return {
        type: types.CREATE_DICTIONARY_FAIL,
        error: error
    }
}

//thunk
export function createDictionary(newDictionary) {
    return (dispatch, getState) => {
        dispatch(createDictionary_());
        let old = getState().dictionary.dictionaryMap.toArray()
        return DictionaryAPI.setDictionary([...old, { ...newDictionary, id: uuid() }]).then(
            dictionary => dispatch(createDictionarySuccess(dictionary.value)),
            error => dispatch(createDictionaryFailed(error))
        )
    }
}

let updateDictionary_ = (dictionary) => {
    return {
        type: types.UPDATE_DICTIONARY,
        dictionary: dictionary
    }
}
let updateDictionarySuccess = (dictionary) => {
    return {
        type: types.UPDATE_DICTIONARY_SUCCESS,
        dictionary: dictionary
    }
}
let updateDictionaryFailed = (error) => {
    return {
        type: types.UPDATE_DICTIONARY_FAIL,
        error: error
    }
}

//thunk
export function updateDictionary(data) {
    return (dispatch, getState) => {
        let old = getState().dictionary.dictionaryMap.toArray()
        old = old.map(i => i.id === data.id ? { ...i, words: data.words } : i)
        dispatch(updateDictionary_(old));
        return DictionaryAPI.setDictionary(old).then(
            dictionary => dispatch(updateDictionarySuccess(dictionary.value)),
            error => dispatch(updateDictionaryFailed(error))
        )
    }
}

let deleteDictionary_ = () => {
    return {
        type: types.DELETE_DICTIONARY
    }
}
let deleteDictionarySuccess = (id) => {
    return {
        type: types.DELETE_DICTIONARY_SUCCESS,
        id: id
    }
}
let deleteDictionaryFailed = (error) => {
    return {
        type: types.DELETE_DICTIONARY_FAIL,
        error: error
    }
}

//thunk
export function deleteDictionary(id) {
    return (dispatch, getState) => {
        dispatch(deleteDictionary_());
        let old = getState().dictionary.dictionaryMap.toArray().filter(i => i.id !== id)
        return DictionaryAPI.setDictionary(old).then(
            () => dispatch(deleteDictionarySuccess(id)),
            error => dispatch(deleteDictionaryFailed(error))
        )
    }
}

/*eslint-enable*/