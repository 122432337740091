/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import moment from 'moment-timezone'
import { useDispatch } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import * as projectsActions from '../../../redux/actions/ProjectsActions'
import { Frame, Text, convertHex, Link } from '../../UIKit/styled-templates'
import CommonHelper from '../../../helpers/CommonHelper';
import ReactHelper from "../../../helpers/ReactHelper";
import mvConsts from '../../../constants/mvConsts'

import useCurrentUser from '../helpers/useCurrentUser'
import useProject from '../helpers/useProject'
import useDictionary from '../helpers/useDictionary'

let dDiffInDays = (d1, d2) => +moment(moment(d1).diff(moment(d2))).format(`D`)

let ProjectTab = (props) => {

    let { projectId, companyMode = false, index = 0 } = props

    let { currentUser } = useCurrentUser()
    let { getText, dict } = useDictionary()
    let { project, company } = useProject(projectId)

    let dispatch = useDispatch()

    let projectStatuses = Object.keys(dict).filter(i => i.indexOf(`project_status_`) > -1)

    let projectInfo = {
        [`title_project`]: `Project Name`,
        ...project.info
    }

    // projectInfo.duration = moment.duration(dDiffInDays(+moment(projectInfo.from, `DD.MM.YYYY`), +moment(projectInfo.to, `DD.MM.YYYY`)), `days`).humanize()
    projectInfo.duration = Math.round((+moment(projectInfo.to, `DD.MM.YYYY`) - +moment(projectInfo.from, `DD.MM.YYYY`)) / (1000 * 3600 * 24)) || 0;

    let companyInfo = {
        ...company.info
    }

    let [dropVisible, setDropVisible] = useState(false)

    let wrapperRef = useRef()
    let dropRef = useRef()
    ReactHelper.useOnClickOutside(dropRef, () => setDropVisible(false));

    // useEffect(() => {
    //     let handler = (e) => {
    //         if (!e.path.map(i => i.id === `drop_button`).reduce((a, b) => a || b)) {
    //             CommonHelper.linkTo(`/projects/${project.id}`)
    //         }
    //     }
    //     wrapperRef.current.addEventListener(`click`, handler)
    //     return (() => { wrapperRef.current.removeEventListener(`click`, handler) })
    // }, [])

    let onCompleteProject = () => {
        dispatch(projectsActions.updateProject({ ...project, status: projectStatuses[3] }))
    }

    let statusColor = 0 < projectStatuses.indexOf(project.status) && projectStatuses.indexOf(project.status) < 3 ? mvConsts.light.green : mvConsts.light.text.secondary

    return (
        <Link to={`/projects/${project.id}`} >
            <Tab ref={wrapperRef} onClick={() => { CommonHelper.linkTo(`/projects/${project.id}`) }} >
                <Body companyMode={companyMode} >
                    <Header companyMode={companyMode} >
                        <Frame row>
                            <UserAvatar src={companyInfo.avatarUrl} index={index} />
                            <Text extra={props => `font-size: 14px; margin-left: 15px; color: ${props.theme.text[companyInfo[`company_name`] ? `primary` : `support`]}`} >{companyInfo[`company_name`] || `Hidden Company`}</Text>
                            {company.verified ? <VerifiedSign /> : null}
                        </Frame>
                        <Text text_color={props => props.theme.text.secondary} extra={`font-size: 13px; line-height: 14px;`} >{(projectInfo[`specialization_(tags)`] || []).filter((a, b) => b < 2).map(i => getText(i)).join(`, `)}</Text>
                    </Header>
                    <ProjectTitle>{projectInfo[`title_project`]}</ProjectTitle>
                    <BottomWrapper>
                        <Frame extra={`align-items: flex-start;`} >
                            <Text text_color={props => props.theme.text.secondary} extra={`font-size: 13px; line-height: 14px;`} >{getText(`Applications submitted`)}</Text>
                            <Text extra={`font-size: 14px; margin-top: 10px;`} >{(project.applications || []).length} {getText(`Project applications`, `LOWERCASE`)}</Text>
                        </Frame>
                        <Frame extra={`align-items: flex-start;`} >
                            <Text text_color={props => props.theme.text.secondary} extra={`font-size: 13px; line-height: 14px;`} >{getText(`Duration`)}</Text>
                            <Text extra={`font-size: 14px; margin-top: 10px;`} >{projectInfo.duration} {getText(`day(s)`)}</Text>
                        </Frame>
                        {/* <Frame extra={`align-items: flex-start;`} >
                        <Text text_color={props => props.theme.text.secondary} extra={`font-size: 13px; line-height: 14px;`} >Submission period</Text>
                        <Text extra={`font-size: 14px; margin-top: 10px;`} >until August 19</Text>
                    </Frame> */}
                    </BottomWrapper>
                </Body>
                <Footer statusColor={statusColor} >
                    <FooterInner companyMode={companyMode} >
                        <Text bold text_color={statusColor} extra={`font-size: 13px; line-height: 18px;`} >{getText(project.status || projectStatuses[0])}</Text>
                        {project.pinned === true ? <PinSign /> : null}
                    </FooterInner>
                </Footer>
            </Tab>
        </Link>
    );
}

const FooterInner = styled(Frame)`
    flex-direction: row;
    width: ${props => props.companyMode ? 665 : 465}px;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 72vw;
    }
`;

const BottomWrapper = styled(Frame)`
    width: 465px;
    justify-content: space-between;
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        flex-wrap: wrap;
        width: 100%;
        height: 28vw;
    }
`;

const ProjectTitle = styled(Frame)`
    font-size: 20px;
    line-height: 30px;
    font-family: Bold;
    color: ${props => props.theme.text.primary};

    @media only screen and (max-width: 600px) {
        font-size: 5vw;
        height: 28vw;
        align-items: flex-start;
        justify-content: flex-start;
    }
`;

const Header = styled(Frame)`
    width: 100%;
    justify-content: space-between;
    flex-direction: row${props => props.companyMode ? `-reverse` : ``};

    @media only screen and (max-width: 600px) {
        height: 22.5vw;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
`;

const Body = styled(Frame)`
    width: ${props => props.companyMode ? 665 : 465}px;
    height: 225px;
    margin: 20px 30px 40px 30px;
    align-items: flex-start;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 78vw;
        height: 102vw;
        margin: 5vw 6vw;
    }
`;

const Footer = styled(Frame)`
    width: 100%;
    height: 33px;
    background: ${props => convertHex(props.statusColor, 0.3)};

    @media only screen and (max-width: 600px) {
        height: 10vw;
    }
`;

const UserAvatar = styled(Frame)`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/hidden-company-${props.index % 5 + 1}.svg`)});
    background-position: center;

    @media only screen and (max-width: 600px) {
        width: 12.5vw;
        height: 12.5vw;
        border-radius: 6.25vw;
    }
`;

let ArrowDown = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_down_blue.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 12px; height: 6px; margin: 3px 0px 0px 5px; transform: rotate(${props => 180 * +props.down}deg); transition: 0.2s; `

const PinSign = styled(Frame)`
    width: 24px;
    height: 24px;
    background: url(${require('../../../assets/images/project-pin.svg')});
`;

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    margin-left: 3px;
`

const DropPopUpWrapper = styled(Frame)`
    padding: 16px 30px 22px 30px;
    min-width: 80px;
    border-radius: 12px;
    box-shadow: 0px 20px 50px rgba(87, 138, 214, 0.2);
    background: ${props => props.theme.background.primary};
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    right: 0px;
    top: ${props => 16 + 10 * +props.visible}px;
    visibility: ${props => props.visible ? `visible` : `hidden`};
    opacity: ${props => +props.visible};
    align-items: flex-end;
`;

const Tab = styled(Frame)`
    // width: 535px;
    height: 300px;
    border-radius: 12px;
    margin-top: 30px;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.05);
    &:hover {
        ${props => props.theme.shadow.blue}
    }

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: 123vw;
    }
`;

const ProjectsWrapper = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 1100px;
    margin-top: 34px;
`;

const MySelect = styled(Select).attrs((props) => {
    return ({
        styles: {
            control: styles => ({
                ...styles,
                width: `255px`,
                height: `50px`,
                borderRadius: `25px`,
                border: `1px solid ${props.theme.background.secondary}`,
                background: props.theme.background.primary,
                ':hover': null,
            }),

            menuList: style => ({ borderRadius: `12px`, }),

            placeholder: style => ({ ...style, marginLeft: `12px`, }),
            input: style => ({ marginLeft: `12px` }),

            indicatorSeparator: style => ({
                opacity: 0,
            }),
            option: styles => ({
                ...styles,
                background: props.theme.background.primary,
                color: props.theme.text.primary,
                zIndex: 2000,
                ':hover': { backgroundColor: props.theme.background.support, },
            }),
        }
    })
})`
    
`;

const Wrapper = styled(Frame)`
    // position: relative;
`;

const FilterToolsWrapper = styled(Frame)`
    width: 1100px;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;
`;

export default ProjectTab;

/*eslint-enable*/