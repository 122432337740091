/*eslint-disable*/
import { useCallback } from 'react'
import { useDispatch, useMappedState } from 'redux-react-hook'

import * as usersActions from '../../../redux/actions/UsersActions'
import CommonHelper from '../../../helpers/CommonHelper'

let useProject = (id = undefined) => {

    let dispatch = useDispatch()

    return useMappedState(useCallback(state => {
        let project = { info: {}, applications: [], ...state.projects.projectsMap.get(id) }
        project.applications.forEach(i => {
            let student = state.users.usersMap.get(i.userId) || { info: {} }
            i.studentInfo = {
                id: i.userId, name: student.info.name, last_name: student.info.last_name, avatarUrl: student.info.avatarUrl, email: student.email, verified: student.verified
            }
        })
        return ({
            project,
            company: state.projects.projectsMap.get(id) ? { info: {}, ...state.users.usersMap.get(state.projects.projectsMap.get(id).creatorId) } : { info: {} },
        })
    }, [id]))
}

export default useProject;
/*eslint-enable*/