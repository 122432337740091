/*eslint-disable*/
import React, { useState, useCallback } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'

import { Frame, P } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import CompanyTab from '../tools/company-tab'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'

let animatedComponents = makeAnimated();

let categories = [`First`, `Second`, `Third`].map(item => ({ value: item, label: item }))

let CompaniesList = () => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let { getText, dict } = useDictionary()

    let [search, setSearch] = useState(``)
    let [category, setCategory] = useState(null);
    let [visibleCompanies, setVisibleCompanies] = useState(10)

    let { companies } = useMappedState(useCallback(state => ({
        companies: state.users.usersMap.toArray().filter(i => i.userRole === `company`),
    }), []))

    let tags = Object.keys(dict).filter(i => i.indexOf(`tag_`) > -1)

    let selectedCategory = { value: undefined, ...category }.value

    return (
        <Wrapper>
            <FilterToolsWrapper>
                <Input value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder={getText(`Search`)} image={`search`} extra={`border-radius: 25px; width: 221px; @media only screen and (max-width: 600px) { border-radius: 10vw; };`} />
                <MySelect
                    value={category}
                    options={tags.map(i => ({ value: i, label: getText(i) })) || []}
                    placeholder={getText(`Category`)}
                    onChange={e => { setCategory(e) }}
                />
            </FilterToolsWrapper>

            <CompaniesWrapper>
                {
                    companies
                        .filter((i, j) => search === `` ? j < visibleCompanies : (i.info.company_name || ``).toLowerCase().indexOf(search.toLowerCase()) > -1 || search === ``)
                        .filter(i => selectedCategory === undefined || (i.info[`specialization_(tags)`] || []).indexOf(selectedCategory) > -1)
                        .map((item, index) => {
                            return (
                                <Frame key={index} extra={`margin-top: 30px;`} >
                                    <CompanyTab companyId={item.id} />
                                </Frame>
                            )
                        })
                }
            </CompaniesWrapper>

            {
                companies.length > visibleCompanies && search === `` ? <Button shaped extra={`margin: 70px 0 70px 0;`} onClick={() => { setVisibleCompanies(Math.min(companies.length, visibleCompanies + 10)) }} >{getText(`View more`)}</Button> : null
            }

        </Wrapper>
    );
}

const CompaniesWrapper = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 1100px;
    margin-top: 34px;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin-top: calc(-30px + 6.25vw);
    }
`;

const MySelect = styled(Select).attrs((props) => {
    return ({
        defaultValue: [],
        closeMenuOnSelect: true,
        components: animatedComponents,
        isClearable: true,
        styles: {
            singleValue: style => ({ ...style, color: props.theme.text.primary, marginLeft: `12px`, }),
            control: styles => ({
                ...styles,
                width: `255px`,
                height: `50px`,
                borderRadius: `25px`,
                border: `1px solid ${props.theme.background.secondary}`,
                background: props.theme.background.primary,
                overflow: `hidden`,
                fontSize: `14px`,
                ':hover': null,
            }),
            menuList: style => ({ borderRadius: `12px`, }),
            placeholder: style => ({ ...style, marginLeft: `12px`, color: props.theme.text.secondary, fontSize: `14px`, }),
            input: style => ({ ...style, marginLeft: `12px`, fontSize: `14px`, }),
            valueContainer: style => ({ ...style, fontSize: `14px`, color: props.theme.text.secondary, margineft: `12px`, }),
            indicatorSeparator: style => ({ ...style, opacity: 0, }),
            option: styles => ({
                ...styles,
                background: props.theme.background.secondary,
                fontSize: `14px`,
                color: props.theme.text.primary,
                zIndex: 2000,
                ':hover': { backgroundColor: props.theme.background.support, },
            }),

        }
    })
})``;

const Wrapper = styled(Frame)`
    
`;

const FilterToolsWrapper = styled(Frame)`
    width: 1100px;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin-top: 0;
        > * {
            &:not(:first-child) {
                display: none;
                visibility: hidden;
                margin-top: 0;
            }
        }
    }
`;

export default CompaniesList;

/*eslint-enable*/