/*eslint-disable*/
/**
 * Created by sabir on 19.07.17.
 */

import { Map, Stack, Set } from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,
    faq: { },
    error: undefined,
    updateTime: 0,
}

const FAQReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.CREATE_FAQ:
        case types.DELETE_FAQ:
        case types.LOAD_FAQ:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.UPDATE_FAQ:
            return {
                ...state,
                loading: true,
                error: undefined,
                faq: action.faq,
            }

        case types.CREATE_FAQ_FAIL:
        case types.UPDATE_FAQ_FAIL:
        case types.DELETE_FAQ_FAIL:
        case types.LOAD_FAQ_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_FAQ_SUCCESS:
        case types.UPDATE_FAQ_SUCCESS:
            return {
                ...state,
                loading: false,
                faq: action.faq,
            }

        case types.DELETE_FAQ_SUCCESS:
            return {
                ...state,
                loading: false,
                faq: {},
            }

        case types.LOAD_FAQ_SUCCESS:
            return {
                ...state,
                loading: false,
                faq: action.faq || {},
                updateTime: +new Date(),
            }

        case types.SET_FAQ:
            return {
                ...state,
                faq: action.faq,
            }


        default:
            return state;
    }

}

export default FAQReducer;

/*eslint-enable*/