/*eslint-disable*/
import { useCallback } from 'react'
import { useDispatch, useMappedState } from 'redux-react-hook'

import * as usersActions from '../../../redux/actions/UsersActions'
import CommonHelper from '../../../helpers/CommonHelper'

let useCurrentUser = (id = undefined) => {

    let dispatch = useDispatch()

    return useMappedState(useCallback(state => ({
        company: id === undefined ? { info: {} } : state.users.usersMap.get(id),
        companysProjects: state.projects.projectsMap
            .filter(i => i.creatorId === id)
            .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
            .toArray(),
    }), []))
}

export default useCurrentUser;
/*eslint-enable*/