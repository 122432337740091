/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce';

import * as dictionaryActions from '../../../redux/actions/DictionaryActions'
import { Frame, Text, Button, H1, Input } from '../../UIKit/styled-templates'
import CommonHelper from '../../../helpers/CommonHelper'
import dict from '../helpers/dict'

import useDictionary from '../helpers/useDictionary'
import useCurrentUser from '../helpers/useCurrentUser'

let Dictionary = (props) => {

    let { currentUser } = useCurrentUser()
    let { getText } = useDictionary()

    let path = useLocation().pathname.split(`/`).slice(1)

    let { dictionary } = useMappedState(useCallback(state => {
        return ({
            dictionary: state.dictionary.dictionaryMap.get(path[1]) || {},
        })
    }, [path[1]]))

    let [words, setWords] = useState([])
    useEffect(() => {
        let object = { ...dictionary.words }
        setWords(Object.keys(object || []).map(i => [i, object[i].ENG, object[i].LV]))
    }, [dictionary])

    let dispatch = useDispatch()

    let onSaveDictionary = () => {
        let newDictionary = { ...dictionary, words: {} }
        words.forEach(i => { newDictionary.words[i[0]] = { ENG: i[1], LV: i[2] } })
        dispatch(dictionaryActions.updateDictionary(newDictionary))
    }

    let [search, setSearch] = useState(``)

    return (
        <Wrapper>
            <Frame row>
                <H1 extra={props => `color: ${props.theme.text.secondary}; cursor: pointer; &:hover { transform: scale(0.95); };`} onClick={() => { CommonHelper.linkTo(`/dictionary`) }} >Dictionary</H1>
                <ArrowRight />
                <H1>{dictionary.name}</H1>
            </Frame>
            <Input value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder={`Search`} image={`search`} extra={`border-radius: 25px; width: 601px;`} />
            {
                words.filter(i => search === `` || i.join(` `).toLowerCase().indexOf(search) > -1).map((item, index) => {
                    return (
                        <RowWrapper key={index} >
                            <Input
                                value={item[0]}
                                onChange={(e) => {
                                    let i = words.map(i => i[0]).indexOf(item[0])
                                    let new_words = [...words]; new_words[i][0] = e.target.value; setWords(new_words)
                                }}
                                placeholder={`id`}
                                extra={`width: 190px;`}
                            />
                            <ThrowArrow onClick={() => {
                                if (dictionary.name === `links`) { return }
                                let new_words = [...words];
                                new_words[index][0] = new_words[index][1].toLowerCase().split(` `).join(`_`);
                                // new_words[index][2] = new_words[index][1];
                                if (dictionary.name === `tags`) { new_words[index][0] = `tag_` + new_words[index][0] }
                                if (dictionary.name === `notifications`) { new_words[index][0] = `notification_` + new_words[index][0] }
                                if (dictionary.name === `project statuses`) { new_words[index][0] = `project_status_` + new_words[index][0] }
                                if (dictionary.name === `reasons to delete project`) { new_words[index][0] = `reason_to_delete_project_` + (index + 1) }
                                setWords(new_words)
                            }} />
                            <Input
                                value={item[1]}
                                onChange={(e) => {
                                    let i = words.map(i => i[0]).indexOf(item[0])
                                    let new_words = [...words]; new_words[i][1] = e.target.value; setWords(new_words)
                                }}
                                placeholder={`ENG`}
                                extra={`width: 190px;`}
                            />
                            <Input
                                value={item[2]}
                                onChange={(e) => {
                                    let i = words.map(i => i[0]).indexOf(item[0])
                                    let new_words = [...words]; new_words[i][2] = e.target.value; setWords(new_words)
                                }}
                                placeholder={`LV`}
                                extra={`width: 190px;`}
                            />
                            <TrashCan onClick={() => { setWords(words.filter(i => i[0] !== item[0])) }} />
                        </RowWrapper>
                    )
                })
            }
            <Frame row>
                <Button onClick={() => { }} extra={`margin-top: 15px;`} shaped onClick={() => { setSearch(``); setWords([...words, [``, ``, ``]]) }} >Add word</Button>
                <Button onClick={onSaveDictionary} extra={`margin-top: 15px;`} >{getText(`Save`, `UPPERCASE_FIRST_LETTER`)}</Button>
            </Frame>
        </Wrapper>
    )
}

const RowWrapper = styled(Frame)`
    width: 815px;
    justify-content: space-between;
    margin-top: 15px;
    flex-direction: row;
    &:hover {
        > * {
            &:last-child {
                transform: scale(1);
            }
        }
    }
`;

const ThrowArrow = styled(Frame)`
    &:after {
        content: '<- create ID';
        font-size: 12px;
        color: ${props => props.theme.text.primary};
        cursor: pointer;
        margin: 0 5px;
    }
`;

// let ThrowArrow = styled.img.attrs((props) => {
//     let img
//     try { img = require(`../../../assets/images/arrow_down_black.svg`) } catch (error) { }
//     return ({ src: img, })
// })` width: 12px; height: 6px; margin: -12px 10px 0px 10px; transform: rotate(90deg); transition: 0.2s; `

let ArrowRight = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_down_black.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 12px; height: 6px; margin: -12px 10px 0px 10px; transform: rotate(-90deg); transition: 0.2s; `

let TrashCan = styled(Frame)`
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 15px;
    background: url(${require(`../../../assets/images/trash_can.svg`)});
    transform: scale(0);
`

const Wrapper = styled(Frame)`
    width: 815px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 125px;
    min-height: calc(100vh - 300px);
`;

export default Dictionary;
/*eslint-enable*/