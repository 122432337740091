/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useDispatch, useMappedState } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'
import moment from 'moment-timezone'

import * as projectsActions from '../../../redux/actions/ProjectsActions'

import { Frame, Text, convertHex, H1, H } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import Form from '../../UIKit/Form'
import Dropzone from '../../UIKit/Dropzone'
import PhotoPreview from '../tools/PhotoPreview'
import CommonHelper from '../../../helpers/CommonHelper';
import ProjectTab from '../tools/ProjectTab'
import Tags from '../tools/Tags'
import useProject from '../helpers/useProject'
import mvConsts from '../../../constants/mvConsts'

import useCurrentUser from '../helpers/useCurrentUser'
import useCustomDispatch, { customHandler } from '../helpers/useCustomDispatch'
import useDictionary from '../helpers/useDictionary'

let key = (text) => text.toLowerCase().split(` `).join(`_`)

let EditProjectPage = () => {

    let { currentUser } = useCurrentUser()
    let { getText, dict } = useDictionary()

    let projectStatuses = Object.keys(dict).filter(i => i.indexOf(`project_status_`) > -1)
    let options = projectStatuses.map(item => ({ value: item, label: getText(item) }))

    let path = useLocation().pathname.split(`/`).slice(1)

    const [info, setInfo] = useState({});

    let { project } = useProject(path[1])

    let [localProject, setLocalProject] = useState({})

    useEffect(() => {
        setInfo(project.info || {})
        setLocalProject({ status: projectStatuses[0], ...project })
        if (path.slice(-1)[0] === `create`) {
            setInfo({})
        }
    }, [project, path.slice(-1)[0]])

    const dispatch = useDispatch();
    let onOpenProjectRequest = (info) => {
        dispatchEvent(new CustomEvent(`OPEN_PROJECT_CREATION_POP_UP`, { detail: info }))
    }

    return (
        <Wrapper>
            <Block>
                <LeftColumn />
                <Workspace>
                    <H>{getText(path.slice(-1)[0], `UPPERCASE_FIRST_LETTER`)} {getText(`project`)}</H>
                </Workspace>
            </Block>

            <Block>
                <LeftColumn />
                <Workspace>

                    <Form
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        extra={`width: 730px; margin-top: 20px; @media only screen and (max-width: 600px) { width: 90vw; };`}
                        fields={[
                            { name: `Title project`, type: `string`, short: true, extra: `width: 696px;` },
                            { name: `From`, type: `date`, short: true, extra: `width: 221px;` },
                            { name: `To`, type: `date`, short: true, extra: `width: 221px;` },
                            { name: `Q-participants`, type: `number`, pattern: `[0-9]*`, short: true, extra: `width: 126px;` },
                            { name: `NACE code`, type: `string`, extra: `width: 696px;` },
                        ]}
                    />

                    {
                        currentUser.userRole === `admin`
                            ? <>
                                <H1>{getText(`Admin settings`)}</H1>
                                <Form
                                    data={{ ...localProject, status: { value: localProject.status, label: getText(localProject.status || ``) }, pinned: localProject.pinned === true, }}
                                    onChange={(key, value) => { setLocalProject({ ...localProject, [key]: value }) }}
                                    extra={`width: 730px;`}
                                    fields={[
                                        { name: `Pinned`, type: `checkbox` },
                                        { name: `Status`, type: `select`, options: options, isMulti: false },
                                    ]}
                                />
                            </> : null
                    }

                    <H1>{getText(`specialization_(tags)`)}</H1>
                    <Form
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        extra={`width: 730px;`}
                        fields={[{ name: `Specialization (tags)`, type: `tagsinput`, },]}
                    />

                    <H1>{getText(`What is the essence of the project?`)}</H1>
                    <Form
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        extra={`width: 730px; margin-top: 20px; @media only screen and (max-width: 600px) { width: 90vw; };`}
                        fields={[
                            { name: `Essence of the project`, type: `textarea`, extra: `width: calc(730px - 34px);` },
                        ]}
                    />

                    <H1>{getText(`Tell us more about the task`)}</H1>
                    <Form
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        extra={`width: 730px; margin-top: 20px; @media only screen and (max-width: 600px) { width: 90vw; };`}
                        fields={[
                            { name: `Task`, type: `textarea`, extra: `width: calc(730px - 34px);` },
                        ]}
                    />

                    <H1>{getText(`What can you get for this project?`)}</H1>
                    <Form
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        extra={`width: 730px; margin-top: 20px; @media only screen and (max-width: 600px) { width: 90vw; };`}
                        fields={[
                            { name: `Description`, type: `textarea`, extra: `width: calc(730px - 34px);` },
                        ]}
                    />

                    <H1>{getText(`gallery`)}</H1>
                    <Frame row extra={`width: 100%; flex-wrap: wrap; justify-content: flex-start; > * { margin: 0 30px 30px 0; };`} >
                        {
                            (info.gallery || []).map((item, index) => {
                                return (
                                    <PhotoPreview key={index} onDelete={() => { setInfo({ ...info, gallery: info.gallery.filter((a, b) => b !== index) }) }} >
                                        <GalleryPhoto src={item} />
                                    </PhotoPreview>
                                )
                            })
                        }
                        <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.blue, 0.1)}; cursor: pointer;`} >
                            <Dropzone
                                onUploaded={(url) => {
                                    let new_info = { ...info };
                                    new_info.gallery = [...(new_info.gallery || []), url];
                                    setInfo(new_info)
                                }}
                            >
                                <Frame extra={props => `width: 35px; height: 35px; border-radius: 10px; background: ${convertHex(props.theme.blue, 0.3)};`} >
                                    <Text bold text_color={props => props.theme.blue} extra={`font-size: 20px;`} >+</Text>
                                </Frame>
                            </Dropzone>
                        </Frame>
                    </Frame>

                    <H1>{getText(`Video`, `UPPERCASE_FIRST_LETTER`)}</H1>
                    <Frame row extra={`width: 100%; flex-wrap: wrap; justify-content: flex-start; > * { margin: 0 30px 30px 0; };`} >
                        {
                            (info.video || []).map((item, index) => {
                                let name = item.name.split(`.`).filter((a, b) => b < item.name.split(`.`).length - 1).join(`.`)
                                if ((name.substring(0, 15) + `...`).split(``).length < name.split(``).length) {
                                    name = name.substring(0, 15) + `...`
                                }
                                return (
                                    <Frame key={index} extra={`margin-top: 16px;`}>
                                        <PhotoPreview onDelete={() => { setInfo({ ...info, video: info.video.filter((a, b) => b !== index) }) }} >
                                            <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.background.secondary, 0.5)}; cursor: pointer;`} >
                                                <VideoImage />
                                            </Frame>
                                        </PhotoPreview>
                                        <Text extra={props => `font-size: 12px; line-height: 14px; margin-bottom: 4px; color: ${props.theme.text.secondary};`} >
                                            {name}.{item.name.split(`.`)[item.name.split(`.`).length - 1]}
                                        </Text>
                                    </Frame>
                                )
                            })
                        }
                        <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.blue, 0.1)}; cursor: pointer;`} >
                            <Dropzone
                                onUploaded={(url, name) => {
                                    let new_info = { ...info };
                                    new_info.video = [...(new_info.video || []), { name, url }];
                                    setInfo(new_info)
                                }}
                                accept={`.mov,.mp4`}
                            >
                                <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.background.secondary, 0.5)}; cursor: pointer;`} >
                                    <VideoImage />
                                </Frame>
                            </Dropzone>
                        </Frame>
                    </Frame>

                    <H1>{getText(`file`)}</H1>
                    <Frame row extra={`width: 100%; flex-wrap: wrap; justify-content: flex-start; > * { margin: 0 30px 30px 0; };`} >
                        {
                            (info.files || []).map((item, index) => {
                                let name = item.name.split(`.`).filter((a, b) => b < item.name.split(`.`).length - 1).join(`.`)
                                if ((name.substring(0, 15) + `...`).split(``).length < name.split(``).length) {
                                    name = name.substring(0, 15) + `...`
                                }
                                return (
                                    <Frame key={index} extra={`margin-top: 16px;`}>
                                        <PhotoPreview onDelete={() => { setInfo({ ...info, files: info.files.filter((a, b) => b !== index) }) }} >
                                            <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.green, 0.1)}; cursor: pointer;`} >
                                                <DocsImage />
                                            </Frame>
                                        </PhotoPreview>
                                        <Text extra={props => `font-size: 12px; line-height: 14px; margin-bottom: 4px; color: ${props.theme.text.secondary};`} >
                                            {name}.{item.name.split(`.`)[item.name.split(`.`).length - 1]}
                                        </Text>
                                    </Frame>
                                )
                            })
                        }
                        <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.blue, 0.1)}; cursor: pointer;`} >
                            <Dropzone
                                onUploaded={(url, name) => {
                                    let new_info = { ...info };
                                    new_info.files = [...(new_info.files || []), { name, url }];
                                    setInfo(new_info)
                                }}
                                accept={`.pdf,.doc,.ppt,.xls,.png`}
                            >
                                <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.green, 0.1)}; cursor: pointer;`} >
                                    <DocsImage />
                                </Frame>
                            </Dropzone>
                        </Frame>
                    </Frame>

                </Workspace>
            </Block>

            {
                path.slice(-1)[0] === `create` ? <Button background={props => props.theme.red} extra={`width: 230px; margin: 10px 0px 0px 80px; @media only screen and (max-width: 600px) { width: 90vw; margin: 0 !important; };`} onClick={() => { onOpenProjectRequest(info) }} >{getText(`Publish`)}</Button> : null
            }
            {
                path.slice(-1)[0] === `edit` ? <Button background={props => props.theme.red} extra={`width: 230px; margin: 10px 0px 0px 80px; @media only screen and (max-width: 600px) { width: 90vw; margin: 0 !important; };`} onClick={async () => {
                    if (!moment(info.from, 'DD.MM.YYYY')._isValid) {
                        window.dispatchEvent(new CustomEvent(`CALL_INPUT_ERROR`, { detail: `From` }))
                        setTimeout(() => { window.alert(getText(`Project start date is not valid`)) }, 200)
                        return
                    }
                    if (!moment(info.to, 'DD.MM.YYYY')._isValid) {
                        window.dispatchEvent(new CustomEvent(`CALL_INPUT_ERROR`, { detail: `To` }))
                        setTimeout(() => { window.alert(getText(`Project end date is not valid`)) }, 200)
                        return
                    }
                    dispatch(projectsActions.updateProject({ ...localProject, info: info }));
                    CommonHelper.linkTo(`/projects/${project.id}`)
                }} >{getText(`Save`)}</Button> : null
            }
            <Button background={props => props.theme.red} shaped extra={`width: 230px; margin: 10px 0px 0px 80px; @media only screen and (max-width: 600px) { width: 90vw; margin: 0 !important; };`} onClick={async () => {
                window.dispatchEvent(new CustomEvent(`OPEN_PROJECT_EDITOR_CLOSE_POP_UP`, {}))
            }} >{getText(`Cancel`)}</Button>

        </Wrapper>
    );
}

const GalleryPhoto = styled(Frame)`
    width: 132px;
    height: 132px;
    border-radius: 12px;
    border: 1px solid ${props => props.theme.background.secondary};
    box-sizing: border-box;
    background-image: url("${props => props.src}");
    background-position: center;
    position: relative;
    overflow: hidden;
`;

const Workspace = styled(Frame)`
    width: 730px;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin-top: 6.25vw;
    };
`;

const NumberWrapper = styled(Frame)`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: ${props => convertHex(props.theme[[`red`, `blue`, `green`][props.index % 3]], 0.1)};
    color: ${props => props.theme[[`red`, `blue`, `green`][props.index % 3]]};
    font-family: Bold;
    font-size: 24px;
`;

const LeftColumn = styled(Frame)`
    margin-right: 40px;
    width: 44px;

    @media only screen and (max-width: 600px) {
        margin: 0px;
    }
`;

const Block = styled(Frame)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        flex-direction: column;
    }
`;

let CheckMark = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/check_mark.svg`) } catch (error) { }
    return ({ src: img, })
})` width: ${props => +props.visible * 8}px; height: ${props => +props.visible * 7}px; margin-right: ${props => +props.visible * 10}px; transition: 0.2s; transform: scale(${props => +props.visible});`

let VideoImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/video.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 42px; height: 42px;`

let DocsImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/docs.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 42px; height: 42px;`

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin-left: 3px; `

const Wrapper = styled(Frame)`
    position: relative;
    width: 815px;
    align-items: flex-start;
    margin-top: 30px;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin: 0;
    }
`;

export default EditProjectPage;

/*eslint-enable*/