/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';

import { Frame, Text, convertHex, H } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import CommonHelper from '../../../helpers/CommonHelper';
import ProjectTab from '../tools/ProjectTab'

import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'

let ProjectsListPage = () => {

    let { usersProjects } = useCurrentUser()
    let { getText } = useDictionary()

    return (
        <Wrapper>

            <H>{getText(`My projects`)}</H>

            <ProjectsWrapper>
                {
                    usersProjects.map((item, index) => <ProjectTab key={index} projectId={item.id} />)
                }
            </ProjectsWrapper>

        </Wrapper>
    );
}

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    margin-left: 3px;
`

const Tab = styled(Frame)`
    width: 535px;
    height: 300px;
    border-radius: 12px;
    margin-top: 30px;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07);
    &:hover {
        box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
    }
`;

const ProjectsWrapper = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 815px;
    margin-top: 34px;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin: 0;
    }
`;

const MySelect = styled(Select).attrs((props) => {
    return ({
        styles: {
            control: styles => ({
                ...styles,
                width: `255px`,
                height: `50px`,
                borderRadius: `25px`,
                border: `1px solid ${props.theme.background.secondary}`,
                background: props.theme.background.primary,
                ':hover': null,
            }),

            menuList: style => ({ borderRadius: `12px`, }),

            placeholder: style => ({ ...style, marginLeft: `12px`, }),
            input: style => ({ marginLeft: `12px` }),

            indicatorSeparator: style => ({
                opacity: 0,
            }),
            option: styles => ({
                ...styles,
                background: props.theme.background.primary,
                color: props.theme.text.primary,
                zIndex: 2000,
                ':hover': { backgroundColor: props.theme.background.support, },
            }),
        }
    })
})`
    
`;

const Wrapper = styled(Frame)`
    // position: relative;
    align-items: flex-start;
    margin-left: 125px;

    @media only screen and (max-width: 600px) {
        margin: 0;
        margin-top: 6.25vw;
    }
`;

const FilterToolsWrapper = styled(Frame)`
    width: 1100px;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin: 0;
    }
`;

export default ProjectsListPage;

/*eslint-enable*/