/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { useDispatch } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import * as programsActions from '../../../redux/actions/ProgramsActions'
import { Frame, Input, H1, Button, AddSquare, convertHex } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import Dropzone from '../../UIKit/Dropzone'
import PopUpWrapper from './PopUpWrapper'
import PhotoPreview from '../tools/PhotoPreview'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch from '../helpers/useCustomDispatch'

let CreateProgramPopUp = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let { getText } = useDictionary()

    let [id, setId] = useState(undefined)

    let dispatch = useDispatch()
    let onClose = () => { window.dispatchEvent(new CustomEvent(`CLOSE_DELETE_PROGRAM_POP_UP`)) }
    let onDeleteProgram = () => dispatch(programsActions.deleteProgram(path[1]))

    useCustomDispatch(`OPEN_DELETE_PROGRAM_POP_UP`, (e) => { })
    useCustomDispatch(`CLOSE_DELETE_PROGRAM_POP_UP`, (e) => { })

    return (
        <PopUpWrapper name={`DELETE_PROGRAM`}>
            <H1 extra={`@media only screen and (max-width: 600px) { width: 90vw; };`} >{getText(`Are you sure, you want tot delete this program?`)}</H1>

            <Frame row>
                <Button shaped extra={`@media only screen and (max-width: 600px) { width: 43.75vw; };`} onClick={() => {
                    onClose()
                    CommonHelper.linkTo(`/programs`)
                }} >{getText(`No. Go back`)}</Button>
                <Button extra={`margin-left: 5px; @media only screen and (max-width: 600px) { width: 43.75vw; margin-left: 2.5vw; };`} background={props => props.theme.red} onClick={() => {
                    onDeleteProgram()
                    onClose()
                    CommonHelper.linkTo(`/programs`)
                }} >{getText(`Yes. Delete this one`)}</Button>
            </Frame>
        </PopUpWrapper >
    );
}

const Avatar = styled(Frame)`
    width: 132px;
    height: 132px;
    background-image: url("${props => props.src}");
    background-position: center;
`;

export default CreateProgramPopUp;

/*eslint-enable*/