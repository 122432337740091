/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation } from 'react-router-dom'
import moment from 'moment-timezone'
import { useDispatch } from 'redux-react-hook'

import * as projectsActions from '../../../redux/actions/ProjectsActions'
import { Frame, Text, convertHex, P, H1 } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import NewsTab from '../tools/NewsTab'
import Tags from '../tools/Tags'
import Gallery from '../tools/Gallery'
import Circles from '../tools/Circles'
import CommonHelper from '../../../helpers/CommonHelper'
import Contacts from '../tools/Contacts';

import useCurrentUser from '../helpers/useCurrentUser'
import useProject from '../helpers/useProject'
import useDictionary from '../helpers/useDictionary'

let textToKey = (text) => text.toLowerCase().split(` `).join(`_`)

let ProjectsPage = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let dispatch = useDispatch()

    let { project = { id: 0 }, company } = useProject(path[1])

    let projectInfo = {
        [`title_project`]: `Project Name`,
        ...project.info
    }

    let { getText, dict } = useDictionary()

    let projectStatuses = Object.keys(dict).filter(i => i.indexOf(`project_status_`) > -1)

    // projectInfo.duration = moment.duration(dDiffInDays(+moment(projectInfo.from, `DD.MM.YYYY`), +moment(projectInfo.to, `DD.MM.YYYY`)), `days`).humanize()
    projectInfo.duration = Math.round((+moment(projectInfo.to, `DD.MM.YYYY`) - +moment(projectInfo.from, `DD.MM.YYYY`)) / (1000 * 3600 * 24)) || 0;

    let { currentUser, userRole } = useCurrentUser()
    let alreadyApplied = (project.applications || []).filter(i => i.userId === currentUser.id).length > 0

    let onCompleteProject = () => {
        dispatch(projectsActions.updateProject({ ...project, status: projectStatuses[3] }))
    }

    let companyInfo = {
        [`name`]: `Company Name`,
        email: company.email,
        ...company.info
    }

    let edit_buttons = [
        {
            image: `complete`,
            onClick: () => { onCompleteProject() },
        },
        {
            image: `edit`,
            onClick: () => { CommonHelper.linkTo(`/projects/${project.id}/edit`) },
        },
    ]

    if (project.status !== projectStatuses[-1]) {
        edit_buttons = [
            {
                image: `delete`,
                onClick: () => {
                    window.dispatchEvent(new CustomEvent(`OPEN_DELETE_PROJECT_POP_UP`, { detail: project }))
                },
            },
            ...edit_buttons
        ]
    }

    let onApplyProject = () => {
        if (alreadyApplied) {
            dispatch(projectsActions.deleteApplication(project.id))
        } else {
            window.dispatchEvent(new CustomEvent(`OPEN_APPLICATION_APPLY_POP_UP`, { detail: project.id }))
        }
    }

    let application_status = ((project.applications || []).filter(i => i.userId === currentUser.id)[0] || {}).status

    // Navigates to /projects if user is guest, student, coordinator or company that is not a project owner
    // useEffect(() => {
    //     if (currentUser.userRole === `guest` || currentUser.userRole === `student` || currentUser.userRole === `coordinator` || (currentUser.userRole === `company` && project.creatorId !== currentUser.id)) {
    //         CommonHelper.linkTo(`/projects`)
    //     }
    // }, [path])

    return (
        <Wrapper>

            <Frame extra={`width: 580px; height: 580px; position: absolute; top: 174px; left: calc(50% + 500px); z-index: 0;`} >
                <Circles width={580} />
            </Frame>

            <Frame extra={`width: 380px; height: 380px; position: absolute; top: 950px; right: calc(50% + 500px); z-index: 0;`} >
                <Circles width={380} />
            </Frame>

            <NewsWrapper>

                <ProjectHeader>
                    {/* <Frame row extra={`cursor: pointer;`} onClick={() => { CommonHelper.linkTo(`/projects`) }} >
                        <BackArrow />
                        <Text text_color={props => props.theme.text.secondary} extra={`text-transform: uppercase; font-size: 14px; line-height: 18px;`} >Back to list</Text>
                    </Frame> */}
                    {
                        currentUser.id === project.creatorId || currentUser.userRole === `admin` ?
                            <EditButtonsWrapper>
                                {
                                    edit_buttons.map((item, index) => <EditButton key={index} name={item.image} onClick={item.onClick} />)
                                }
                            </EditButtonsWrapper> : null
                    }
                    <Frame extra={`justify-content: flex-start; align-items: flex-start; margin: 0px 0 30px 0;`} >
                        <ProjectName>{projectInfo[`title_project`]}</ProjectName>
                        <NameAndTags>
                            <Frame row extra={`cursor: pointer;`} onClick={() => { CommonHelper.linkTo(`/user/${company.id}`) }} >
                                <CompanyAvatar src={companyInfo.avatarUrl} />
                                <CompanyNameText>{companyInfo[`company_name`]}</CompanyNameText>
                                {company.verified ? <VerifiedSign /> : null}
                            </Frame>
                            <Frame row extra={`max-width: 50%; flex-wrap: wrap; justify-content: flex-end; @media only screen and (max-width: 600px) { max-width: 90vw; margin-top: 6.25vw; };`} >
                                <Tags tagsArray={projectInfo[`specialization_(tags)`]} />
                            </Frame>
                        </NameAndTags>
                        <HeaderBottom>
                            <InfoBlocksWrapper>
                                <Frame extra={`align-items: flex-start;`} >
                                    <Text text_color={props => props.theme.text.secondary} extra={`font-size: 13px; line-height: 14px;`} >{getText(`Applications submitted`)}</Text>
                                    <Text extra={`font-size: 14px; margin-top: 10px;`} >{project.applications.length} {getText(`application(s)`)}</Text>
                                </Frame>
                                <Frame extra={`align-items: flex-start;`} >
                                    <Text text_color={props => props.theme.text.secondary} extra={`font-size: 13px; line-height: 14px;`} >{getText(`Duration`, `UPPERCASE_FIRST_LETTER`)}</Text>
                                    <Text extra={`font-size: 14px; margin-top: 10px;`} >{projectInfo.duration} {getText(`day(s)`)}</Text>
                                </Frame>
                                <Frame extra={`align-items: flex-start;`} >
                                    <Text text_color={props => props.theme.text.secondary} extra={`font-size: 13px; line-height: 14px;`} >{getText(`Status`, `UPPERCASE_FIRST_LETTER`)}</Text>
                                    <Text extra={`font-size: 14px; margin-top: 10px;`} >{getText(project.status || `project_status_waiting`)}</Text>
                                </Frame>
                                {/* <Frame extra={`align-items: flex-start;`} >
                                    <Text text_color={props => props.theme.text.secondary} extra={`font-size: 13px; line-height: 14px;`} >Submission period</Text>
                                    <Text extra={`font-size: 14px; margin-top: 10px;`} >until August 19</Text>
                                </Frame> */}
                            </InfoBlocksWrapper>
                            {
                                userRole === `student` && project.status === projectStatuses[1]
                                    ? <Frame extra={`@media only screen and (max-width: 600px) { align-items: flex-start; };`} >
                                        <Button
                                            extra={`width: 230px; @media only screen and (max-width: 600px) { margin-top: 6.25vw; };`}
                                            onClick={onApplyProject}
                                            background={props => props.theme[alreadyApplied ? `red` : `blue`]}
                                        >{alreadyApplied ? `Remove application` : `Apply`}
                                        </Button>
                                        {
                                            application_status !== undefined ? <>
                                                <P extra={`margin: 0 0 0 15px;`} >{getText(`Status`)}: {application_status}</P>
                                            </> : null
                                        }
                                    </Frame> : null
                            }
                        </HeaderBottom>
                        {
                            projectInfo[`nace_code`] ? <>
                                <P extra={`margin: 10px 0px 0px 0px; font-size: 12px;`} >{getText(`NACE code`)}: {projectInfo[`nace_code`]}</P>
                            </> : null
                        }
                        {
                            projectInfo[`email`] ? <>
                                <P extra={`margin: 10px 0px 0px 0px; font-size: 12px;`} >{getText(`Email`)}: {projectInfo[`email`]}</P>
                            </> : null
                        }
                    </Frame>
                    <Frame extra={props => `width: 920px; height: 1px; background: ${props.theme.background.secondary}; @media only screen and (max-width: 600px) { display: none; }; `} />
                </ProjectHeader>

                {
                    projectInfo[textToKey(`Essence of the project`)] ? <>
                        <H1>{getText(`essence_of_the_project`)}</H1>
                        <P>{projectInfo[textToKey(`Essence of the project`)]}</P>
                    </> : null
                }

                {
                    projectInfo[textToKey(`Task`)] ? <>
                        <H1>{getText(`More about the task`)}</H1>
                        <P>{projectInfo[textToKey(`task`)]}</P>
                    </> : null
                }

                {
                    projectInfo[textToKey(`description`)] ? <>
                        <H1>{getText(`description`)}</H1>
                        <P>{projectInfo[textToKey(`description`)]}</P>
                    </> : null
                }

                {/* <Frame extra={`marigin-bottom: 20px;`} >
                    {
                        list.map((item, index) => {
                            return (
                                <Frame row key={index} extra={`width: 920px; align-items: flex-start;`} >
                                    <Text bold text_color={props => props.theme.red} extra={`font-size: 18px; line-height: 30px; margin: 0 16px 0 0;`} >-</Text>
                                    <Text text_color={props => props.theme.text.secondary} extra={`font-size: 16px; line-height: 30px; width: 100%; align-items: flex-start; margin-bottom: 10px;`} >
                                        {item}
                                    </Text>
                                </Frame>
                            )
                        })
                    }
                </Frame> */}

                {
                    projectInfo.gallery ? <>
                        <H1>{getText(`Gallery`, `UPPERCASE_FIRST_LETTER`)}</H1>
                        <Gallery photoArray={projectInfo.gallery} />
                    </> : null
                }

                {
                    (projectInfo.video || []).length > 0 ? <>
                        <H1>{getText(`Video`, `UPPERCASE_FIRST_LETTER`)}</H1>
                        <FilesWrapper>
                            {
                                projectInfo.video.map((item, index) => {
                                    let color = [`blue`, `red`, `green`][index % 3]
                                    return (
                                        <Frame
                                            row
                                            key={index}
                                            extra={`width: 50%; margin-bottom: 20px; justify-content: flex-start; cursor: pointer;`}
                                            onClick={() => { window.open(item.url) }}
                                        >
                                            <Frame extra={props => `width: 65px; height: 65px; border-radius: 12px; background: ${convertHex(props.theme[color], 0.3)}; margin-right: 20px`} >
                                                <VideoImage color={color} />
                                            </Frame>
                                            <Text extra={`font-size: 16px; line-height: 16px;`} >{item.name}</Text>
                                        </Frame>
                                    )
                                })
                            }
                        </FilesWrapper>
                    </> : null
                }

                {
                    (projectInfo.files || []).length > 0 ? <>
                        <H1>{getText(`File`, `UPPERCASE_FIRST_LETTER`)}</H1>
                        <FilesWrapper>
                            {
                                projectInfo.files.map((item, index) => {
                                    let color = [`blue`, `red`, `green`][index % 3]
                                    return (
                                        <Frame
                                            row
                                            key={index}
                                            extra={`width: 50%; margin-bottom: 20px; justify-content: flex-start; cursor: pointer;`}
                                            onClick={() => { window.open(item.url) }}
                                        >
                                            <Frame extra={props => `width: 65px; height: 65px; border-radius: 12px; background: ${convertHex(props.theme[color], 0.3)}; margin-right: 20px`} >
                                                <FileImage color={color} />
                                            </Frame>
                                            <Text extra={`font-size: 16px; line-height: 16px;`} >{item.name}</Text>
                                        </Frame>
                                    )
                                })
                            }
                        </FilesWrapper>
                    </> : null
                }

                {
                    project.applications.filter(i => i.status === `accepted`).length > 0 ? <H1>{getText(`participants`)}</H1> : null
                }

                {
                    // (project.applications.filter(i => i.status === `accepted`) || []).map((item, index) => {
                    (project.applications.filter(i => true) || []).map((item, index) => {
                        return (
                            <Frame key={index} row extra={`margin-bottom: 20px; justify-content: flex-start; cursor: pointer;`} onClick={() => { CommonHelper.linkTo(`/user/${item.studentInfo.id}`) }} >
                                <UserAvatar src={item.studentInfo.avatarUrl} />
                                <Text extra={`font-size: 16px; line-height: 16px;`} >{item.studentInfo.name} {item.studentInfo.last_name}</Text>
                                {item.studentInfo.verified ? <VerifiedSign /> : null}
                            </Frame>
                        )
                    })
                }

                {
                    companyInfo[`facebook` || `linkedin` || `location` || `phone` || `email`] ? <>
                        <H1>{getText(`contacts`)}</H1>
                        <Contacts info={companyInfo} />
                    </> : null
                }

            </NewsWrapper>

            <Frame row extra={`width: 920px; justify-content: flex-start; margin: 50px 0 0px 0; cursor: pointer; @media only screen and (max-width: 600px) { width: 90vw; margin: 0; };`} onClick={() => {
                if (currentUser.userRole === `guest`) {
                    CommonHelper.linkTo(`/login`)
                } else {

                }
            }} >
                {
                    userRole === `student` && project.status === projectStatuses[1]
                        ? <Button
                            extra={`width: 230px;`}
                            onClick={onApplyProject}
                            background={props => props.theme[alreadyApplied ? `red` : `blue`]}
                        >
                            {alreadyApplied ? `Remove application` : `Apply`}
                        </Button>
                        : null
                }
                {
                    currentUser.userRole === `guest`
                        ? <Frame row extra={`margin-left: 30px; > * { text-transform: uppercase } };`} >
                            <Text text_color={props => props.theme.text.secondary} extra={`font-size: 14px;`} >{getText(`To send an application, please`)}</Text>
                            <Text
                                text_color={props => props.theme.green}
                                extra={`font-size: 14px; margin-left: 5px;`}
                            >
                                {getText(`login`, `UPPERCASE_FIRST_LETTER`)}
                            </Text>
                        </Frame>
                        : null
                }
            </Frame>

        </Wrapper>
    );
}

const CompanyNameText = styled(Frame)`
    font-size: 14px;
    margin-left: 15px;
    color: ${props => props.theme.text.primary};

    @media only screen and (max-width: 600px) {
        font-size: 4vw;
    }
`;

const InfoBlocksWrapper = styled(Frame)`
    width: 464px;
    justify-content: space-between;
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        flex-wrap: wrap;
    }
`;

const HeaderBottom = styled(Frame)`
    width: 920px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        width: 90vw;
        align-items: flex-start;
    }
`;

const NameAndTags = styled(Frame)`
    flex-direction: row;
    width: 920px;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        width: 90vw;
    }
`;

const FilesWrapper = styled(Frame)`
    flex-direction: row;
    width: 920px;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

const ProjectName = styled(Frame)`
    font-family: Bold;
    width: 920px;
    font-size: 48px;
    line-height: 62px;
    align-items: flex-start;
    color: ${props => props.theme.text.primary};

    @media only screen and (max-width: 600px) {
        width: 90vw;
        font-size: 7.5vw;
        line-height: 10vw;
    }
`;

const UserAvatar = styled(Frame)`
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin-right: 20px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});
    background-position: center;
`;

const EditButtonsWrapper = styled(Frame)`
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
`;

const EditButton = styled(Frame)`
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    border: 2px solid ${props => props.theme.text.primary};
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
        transform: scale(1.05);
    }

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url(${props => require(`../../../assets/images/project-edit-button-${props.name}.svg`)}) no-repeat;
    }
`;

const CompanyAvatar = styled(Frame)`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});
    background-position: center;

    @media only screen and (max-width: 600px) {
        width: 12.5vw;
        height: 12.5vw;
    }
`;

let VideoImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/video.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 36px;
    height: 36px;
`

let FileImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/file_${props.color}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 36px;
    height: 36px;
`

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin-left: 3px; `

let ProgramImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/landing/program_${props.name}.png`) } catch (error) { }
    return ({ src: img, })
})`
    width: 160px;
    height: 160px;
`

const NewsWrapper = styled(Frame)`
    align-items: flex-start;
    z-index: 1;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

const TagWrapper = styled(Text)`
    padding: 0 22px 0 22px;
    border-radius: 4px;
    background: ${props => convertHex(props.theme.green, 0.15)};
    margin: 0 10px 5px 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 22px;
`;

let ShareLogo = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/share_${props.name}.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 15px; height: 15px; `

let BackArrow = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/back_arrow.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 16px;
    height: 16px;
    margin-right: 3px;
`

const ProjectHeader = styled(Frame)`
    width: 920px;
    margin: 30px 0;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin: 0;
    }
`;

const Wrapper = styled(Frame)`

`;

export default ProjectsPage;

/*eslint-enable*/