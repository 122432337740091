/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import { useDispatch, useMappedState } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import * as programsActions from '../../../redux/actions/ProgramsActions'

import { Frame, Input, H1, Button, AddSquare, convertHex, Checkbox, P } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import Dropzone from '../../UIKit/Dropzone'
import PopUpWrapper from './PopUpWrapper'
import PhotoPreview from '../tools/PhotoPreview'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch from '../helpers/useCustomDispatch'
import useCurrentUser from '../helpers/useCurrentUser'

let ConfirmProjectEditorClose = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let dispatch = useDispatch()
    let { getText } = useDictionary(() => { })

    useCustomDispatch(`OPEN_PROJECT_EDITOR_CLOSE_POP_UP`, (e) => { })
    useCustomDispatch(`CLOSE_PROJECT_EDITOR_CLOSE_POP_UP`, (e) => { })

    let onClose = () => {
        window.dispatchEvent(new CustomEvent(`CLOSE_PROJECT_EDITOR_CLOSE_POP_UP`, {}))
    }

    return (
        <PopUpWrapper name={`PROJECT_EDITOR_CLOSE`}>
            <H1 extra={`@media only screen and (max-width: 600px) { width: 90vw; };`} >{getText(`You have unsaved changes`)}</H1>
            <Frame row>
                <Button shaped background={props => props.theme.blue} onClick={() => { onClose() }} >{getText(`Go back`)}</Button>
                <Button extra={`margin-left: 5px;`} background={props => props.theme.red} onClick={() => { onClose(); CommonHelper.linkTo(`/projects/${path[1]}`) }} >{getText(`Close`)}</Button>
            </Frame>
        </PopUpWrapper >
    );
}

const Avatar = styled(Frame)`
    width: 132px;
    height: 132px;
    background-image: url("${props => props.src}");
    background-position: center;
`;

export default ConfirmProjectEditorClose;

/*eslint-enable*/