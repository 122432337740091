/*eslint-disable*/
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'
import axios from 'axios'

import * as uiActions from '../../../redux/actions/UIActions'
import * as projectsActions from '../../../redux/actions/ProjectsActions'
import { Frame, Text, H1, P, Textarea } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import PopUpWrapper from './PopUpWrapper'
import CommonHelper from '../../../helpers/CommonHelper';
import mvConsts from '../../../constants/mvConsts'

import useCurrentUser from '../helpers/useCurrentUser'
import useCustomDispatch from '../helpers/useCustomDispatch'
import useComponentSize from '../helpers/useComponentSize'
import useDictionary from '../helpers/useDictionary'

let statusTypes = {
    new: {
        name: `new`,
        label: `NEW`,
        color: mvConsts.light.blue,
    },
    accepted: {
        name: `accepted`,
        label: `Accepted`,
        color: mvConsts.light.green,
    },
    deleted: {
        name: `deleted`,
        label: `Deleted`,
        color: mvConsts.light.red,
    },
    waiting: {
        name: `waiting`,
        label: `Waiting`,
        color: mvConsts.light.green,
    },
}

let ApplicationPopUp = (props) => {

    let history = useHistory()

    let { currentUser } = useCurrentUser()
    let { getText } = useDictionary()

    let [application, setApplication] = useState({})

    useCustomDispatch(`OPEN_APPLICATION_POP_UP`, (e) => { setApplication(e.detail); })
    useCustomDispatch(`CLOSE_APPLICATION_POP_UP`, (e) => { })

    let studentInfo = { ...application.studentInfo }

    let dispatch = useDispatch()

    let onCloseProjectRequest = () => {
        window.dispatchEvent(new CustomEvent(`CLOSE_APPLICATION_POP_UP`))
    }

    let onAddAdminCommentToApplication = () => {
        dispatch(projectsActions.updateApplication({ id: application.id, ...application.data }))
    }

    let onUpdateApplicationStatus = (new_application) => {
        delete new_application.studentInfo
        delete new_application.projectInfo
        dispatch(projectsActions.updateApplicationStatus(new_application))
    }

    let ondeleteProjectApplication = (id) => { dispatch(projectsActions.deleteProjectApplication(id)) }

    return (
        <PopUpWrapper name={`APPLICATION`} >
            <Frame>
                <Frame row extra={`width: 540px; justify-content: space-between; @media only screen and (max-width: 600px) { width: 90vw; align-items: flex-start; };`} >
                    <Frame extra={`flex-direction: row; @media only screen and (max-width: 600px) { align-items: flex-start; flex-direction: column; };`} >
                        <Avatar src={studentInfo.avatarUrl} />
                        <Text extra={`margin-left: 19px; font-height: 16px; @media only screen and (max-width: 600px) { font-size: 5vw !important; margin: 6.25vw 0 0 0; };`} >{studentInfo.name} {studentInfo.last_name} {studentInfo.verified ? <VerifiedSign /> : null}</Text>
                    </Frame>
                    <Text onClick={() => {
                        if (studentInfo.id !== undefined) {
                            onCloseProjectRequest()
                            CommonHelper.linkTo(`/user/${studentInfo.id}`)
                        }
                    }} extra={props => `font-size: 14px; line-height: 18px; color: ${props.theme.text.secondary}; cursor: pointer; flex-direction: row; &:hover { transform: translateX(10px); }; @media only screen and (max-width: 600px) { font-size: 5.625vw; };`} >
                        {getText(`VIEW PROFILE`, `UPPERCASE`)}
                        <ArrowRight />
                    </Text>
                </Frame>
            </Frame>
            <Frame extra={`align-items: flex-start; margin-top: 30px;`} >
                <H1>{getText(`Student letter`)}</H1>
                <P extra={`width: 540px; align-items: flex-start; @media only screen and (max-width: 600px) { width: 90vw; };`} >
                    {(application.data || {}).studentComment}
                </P>
                {
                    (application.data || {}).adminComment && currentUser.userRole !== `admin` ? <>
                        <Frame extra={props => `width: 100%; height: 1px; background: ${props.theme.background.secondary}; margin-bottom: 10px;`} />
                        <H1>{getText(`Admin letter`)}</H1>
                        <P extra={`width: 540px; align-items: flex-start; @media only screen and (max-width: 600px) { width: 90vw; };`} >
                            {(application.data || {}).adminComment}
                        </P>
                    </> : null
                }
                {
                    currentUser.userRole === `admin` && application.data
                        ? <Textarea placeholder={`Admin comment`} extra={`margin-bottom: 15px;`} value={application.data.adminComment || ``} onChange={(e) => { setApplication({ ...application, data: { ...application.data, adminComment: e.target.value } }) }} />
                        : null
                }
            </Frame>
            {
                currentUser.userRole === `admin`
                    ? <Frame row extra={`width: 540px; justify-content: flex-start;`} >
                        <Button extra={`width: 196px;`} background={props => props.theme.red} onClick={() => {
                            onCloseProjectRequest()
                            onAddAdminCommentToApplication()
                            // onAddAdminCommentToApplication()
                        }} >
                            {getText(`Update`)}
                        </Button>
                        <Button extra={`width: 196px; margin-left: 20px;`} background={props => props.theme.red} onClick={() => {
                            onCloseProjectRequest()
                            ondeleteProjectApplication(application.id)
                        }} >
                            {getText(`DESTROY`)}
                        </Button>
                    </Frame>
                    : <Button background={props => props.theme[application.status !== statusTypes.accepted.name ? `green` : `red`]} extra={`margin-top: 30px;`} onClick={() => {
                        let new_status = statusTypes[application.status !== statusTypes.accepted.name ? `accepted` : `deleted`].name
                        onCloseProjectRequest()
                        onUpdateApplicationStatus({ ...application, status: new_status })
                    }} >{application.status !== statusTypes.accepted.name ? `Accept for project` : `Remove from project`}</Button>
            }
        </PopUpWrapper>
    );
}

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 36px;
    height: 36px;
    margin-left: 22px;

    @media only screen and (max-width: 600px) {
        width: 7.5vw;
        height: 7.5vw;
    }
`

let ArrowRight = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_down_${props.selected ? `blue` : `black`}.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 12px; height: 6px; margin: 0px 0px 0px 5px; transform: rotate(-90deg); transition: 0.2s; opacity: 0.6; `

const Avatar = styled(Frame)`
    width: 65px;
    height: 65px;
    border-radius: 65px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});
    background-position: center;

    @media only screen and (max-width: 600px) {
        width: 20vw;
        height: 20vw;
    }
`;

export default ApplicationPopUp;

/*eslint-enable*/