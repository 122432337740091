/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import * as programsActions from '../../../redux/actions/ProgramsActions'
import { Frame, Input, H1, Button, AddSquare, convertHex, Text } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import Dropzone from '../../UIKit/Dropzone'
import PopUpWrapper from './PopUpWrapper'
import PhotoPreview from '../tools/PhotoPreview'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch from '../helpers/useCustomDispatch'
import useCurrentUser from '../helpers/useCurrentUser'

let CreateProgramPopUp = () => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let { getText, dict } = useDictionary()
    let { currentUser } = useCurrentUser()

    let { applications } = useMappedState(useCallback((state) => ({
        applications: [].concat(...state.programs.programsMap.filter(i => ({ coordinatorsIds: [], ...i }).coordinatorsIds.indexOf(currentUser.id) > -1 || currentUser.userRole === `admin`).map(i => i.applications).toArray()).map(i => ({ ...i, user: { info: {}, ...state.users.usersMap.get(i.userId) }, program: { info: {}, coordinatorsIds: [], ...state.programs.programsMap.get(i.programId) } })),
    }), [path]))

    let statuses = (Object.keys(dict) || []).filter(i => i.indexOf(`program_status_`) > -1)

    let dispatch = useDispatch()
    let onDeleteProgram = () => dispatch(programsActions.deleteProgram(path[1]))
    let onUpdateApplicationStatus = (id, status) => dispatch(programsActions.updateApplicationStatus({ id, status }))

    let [visible, setVisible] = useState(false)
    let name = `PROGRAM_APPLICATIONS`
    useCustomDispatch(`OPEN_${name}_POP_UP`, () => { setVisible(true) })
    useCustomDispatch(`CLOSE_${name}_POP_UP`, () => { setVisible(false) })
    let onClose = () => { window.dispatchEvent(new CustomEvent(`CLOSE_${name}_POP_UP`)) }

    return (
        <PopUpWrapper name={name} >
            {
                applications.map((item, index) => {
                    return (
                        <Frame key={index} extra={`margin-bottom: 10px; flex-direction: row; @media only screen and (max-width: 600px) { flex-direction: column; align-items: flex-start; width: 90vw; > * { margin-bottom: 2vw; }; };`} >
                            <Frame row>
                                <UserAvatar src={item.user.info.avatarUrl} />
                                <Frame extra={`align-items: flex-start; width: 150px; margin-left: 10px;`} >
                                    <Text extra={props => `font-size: 12px; line-height: 16px; color: ${props.theme.text.secondary};`} >{item.user.info.name} {item.user.info.last_name}</Text>
                                    <Text extra={props => `font-size: 10px; line-height: 14px; color: ${props.theme.text.secondary}; opacity: 0.75;`} >{item.user.email}</Text>
                                </Frame>
                            </Frame>
                            <Frame extra={`align-items: flex-start; width: 150px; margin-left: 10px; @media only screen and (max-width: 600px) { width: 90vw; };`} >
                                <Text extra={props => `font-size: 12px; line-height: 16px; color: ${props.theme.text.secondary};`} >{item.program.info.program_name}</Text>
                            </Frame>
                            <Frame row>
                                {
                                    statuses.map((status, status_index) => {
                                        let isSelected = getText(status).toLowerCase() === item.status.toLowerCase() || status.toLowerCase() === item.status.toLowerCase()
                                        return (
                                            <Frame
                                                key={status_index}
                                                extra={`align-items: flex-start; margin-left: 10px; cursor: pointer; &:hover { opacity: 0.75; };`}
                                                onClick={() => { onUpdateApplicationStatus(item.id, status) }}
                                            >
                                                <Text
                                                    bold={isSelected}
                                                    extra={props => `font-size: 12px; line-height: 16px; color: ${isSelected ? props.theme.blue : props.theme.text.secondary};`}
                                                >{getText(status)}</Text>
                                            </Frame>
                                        )
                                    })
                                }
                            </Frame>
                        </Frame>
                    )
                })
            }
        </PopUpWrapper>
    );
}

const UserAvatar = styled(Frame)`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});
    background-position: center;
`;

let Cross = styled.img.attrs(() => {
    let img
    try { img = require(`../../../assets/images/cross_w.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        transform: rotate(90deg);
    };
`

const OpenProjectTab = styled(Frame)`
    min-width: 480px;
    height: calc(90vh - 60px);
    padding: 30px 60px 30px 60px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    position: absolute;
    top: ${props => props.visible ? `5vh` : `150vh`};
    z-index: 3;
    overflow-y: scroll;
    align-items: flex-start;
    justify-content: flex-start;

    @media only screen and (max-width: 600px) {
        min-width: 90vw;
        max-width: 90vw;
        width: 90vw;
        padding: 9vw 3vw;
    }
`;

const DarkOverlay = styled(Frame)`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, ${props => props.visible * 0.75});
    visibility: ${props => props.visible ? `visible` : `hidden`};
    backdrop-filter: blur(${props => props.visible * props.blur * 8}px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
`;

export default CreateProgramPopUp;

/*eslint-enable*/