/*eslint-disable*/
import WizardAPI from '../../api/WizardAPI'

import * as types from '../ActionTypes'

let uuid = () => Math.random().toString(36).substring(3).split(``).map(i => Math.random() > 0.5 ? i.toUpperCase() : i).join(``)

export function setWizard(new_wizard) {
    return {
        type: types.SET_WIZARD,
        wizard: new_wizard
    }
}

let loadWizard_ = () => {
    return {
        type: types.LOAD_WIZARD
    }
}
let loadWizardSuccess = (wizard) => {
    return {
        type: types.LOAD_WIZARD_SUCCESS,
        wizard: wizard
    }
}
let loadWizardFailed = (error) => {
    return {
        type: types.LOAD_WIZARD_FAIL,
        error: error
    }
}

//thunk
export function loadAllWizard() {
    return (dispatch, getState) => {
        dispatch(loadWizard_());
        return WizardAPI.getWizard().then(
            wizard => dispatch(loadWizardSuccess(wizard)),
            error => dispatch(loadWizardFailed(error))
        )
    }
}

let createWizard_ = () => {
    return {
        type: types.CREATE_WIZARD
    }
}
let createWizardSuccess = (wizard) => {
    return {
        type: types.CREATE_WIZARD_SUCCESS,
        wizard: wizard
    }
}
let createWizardFailed = (error) => {
    return {
        type: types.CREATE_WIZARD_FAIL,
        error: error
    }
}

//thunk
export function createWizard(newWizard) {
    return (dispatch, getState) => {
        dispatch(createWizard_());
        return WizardAPI.setWizard({}).then(
            wizard => dispatch(createWizardSuccess(wizard.value)),
            error => dispatch(createWizardFailed(error))
        )
    }
}

let updateWizard_ = (wizard) => {
    return {
        type: types.UPDATE_WIZARD,
        wizard: wizard
    }
}
let updateWizardSuccess = (wizard) => {
    return {
        type: types.UPDATE_WIZARD_SUCCESS,
        wizard: wizard
    }
}
let updateWizardFailed = (error) => {
    return {
        type: types.UPDATE_WIZARD_FAIL,
        error: error
    }
}

//thunk
export function updateWizard(data) {
    return (dispatch, getState) => {
        dispatch(updateWizard_(data));
        return WizardAPI.setWizard(data).then(
            wizard => dispatch(updateWizardSuccess(data)),
            error => dispatch(updateWizardFailed(error))
        )
    }
}

let deleteWizard_ = () => {
    return {
        type: types.DELETE_WIZARD
    }
}
let deleteWizardSuccess = (id) => {
    return {
        type: types.DELETE_WIZARD_SUCCESS,
        id: id
    }
}
let deleteWizardFailed = (error) => {
    return {
        type: types.DELETE_WIZARD_FAIL,
        error: error
    }
}

//thunk
export function deleteWizard(id) {
    return (dispatch, getState) => {
        dispatch(deleteWizard_());
        return WizardAPI.setWizard({}).then(
            () => dispatch(deleteWizardSuccess(id)),
            error => dispatch(deleteWizardFailed(error))
        )
    }
}

/*eslint-enable*/