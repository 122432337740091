/*eslint-disable*/
import React, { useState, useEffect, useMemo } from 'react';
import styled, { keyframes } from 'styled-components'
import moment from 'moment'

import { Frame, Text } from '../../UIKit/styled-templates'

let { cos, sin, PI, random, round } = Math

let sets = new Array(10).fill(0).map(i => new Array(3).fill(0).map(i => ({ startAngle: random() * 360, speed: random(), })))

let angleFlucts = new Array(10).fill(0).map(i => random())

let rotate = (startAngle) => keyframes`
    from {
        transform: rotate(${startAngle}deg);
    }
    to {
        transform: rotate(${360 + startAngle}deg);
    }
`;


let Circles = (props) => {

    let { width = 341.25, rings = 2, contentArray = [] } = props

    let scale = (width / 2) / (682.5 / 2)

    let r = [682.5, 523.3, 401.70].map(i => i / 2 * scale)

    return useMemo(() => <Frame extra={`position: relative; @media only screen and (max-width: 600px) { ${props.exaption ? null : `display: none;`} };`} >
        {
            r.slice(0, rings).map((item, index) => {
                return <Ring key={index} radius={item} />
            })
        }
        {
            r.slice(0, rings).map((radius, index) => {
                let dots = sets[index]
                return dots.map((item, index) => {
                    return (
                        <PlanetRing key={index} item={item} >
                            <Frame extra={`position: absolute; top: ${radius * cos(2 * PI * 0) - 17}px; left: ${radius * sin(2 * PI * 0) - 17}px;`} >
                                <Planet />
                            </Frame>
                        </PlanetRing>
                    )
                })
            })
        }
        {
            contentArray.map((item, index) => {
                let radius = r[index % (r.length - 1)]
                let angleDelta = 1 / contentArray.length
                let phi = 2 * PI * (index * angleDelta - angleDelta * angleFlucts[index] * 0.3)
                return (
                    <Frame key={index} extra={`position: absolute; z-index: 1; top: ${radius * cos(phi) - item.offsetY}px; left: ${radius * sin(phi) - item.offsetX}px;`} >
                        <Frame extra={`position: relative;`} >
                            {item.component}
                        </Frame>
                    </Frame>
                )
            })
        }
    </Frame>, [width])
}

let Planet = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/landing/planet_${round(random() * 4)}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 34px;
    height: 34px;
    transition: 0.5s;
    cursor: pointer;
    &:active {
        transform: scale(1.5);
    }
`

const PlanetRing = styled(Frame)`
    width: ${props => props.radius * 2}px;
    height: ${props => props.radius * 2}px;
    border-radius: 50%;
    position: absolute;
    animation: ${props => rotate(props.item.startAngle)} ${props => props.item.speed * 200 + 10}s linear infinite;

`;

const Ring = styled(Frame)`
    width: ${props => props.radius * 2}px;
    height: ${props => props.radius * 2}px;
    border: 2px solid ${props => props.theme.blue};
    border-radius: 50%;
    position: absolute;
`;

export default Circles;
/*eslint-enable*/