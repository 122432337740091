/*eslint-disable*/
import ArticlesAPI from '../../api/ArticlesAPI'

import * as types from '../ActionTypes'

let loadArticles_ = () => {
    return {
        type: types.LOAD_ARTICLES
    }
}
let loadArticlesSuccess = (articles) => {
    return {
        type: types.LOAD_ARTICLES_SUCCESS,
        articles: articles
    }
}
let loadArticlesFailed = (error) => {
    return {
        type: types.LOAD_ARTICLES_FAIL,
        error: error
    }
}

//thunk
export function loadAllArticles() {
    return (dispatch, getState) => {
        dispatch(loadArticles_());
        return ArticlesAPI.getAllArticles().then(
            articles => dispatch(loadArticlesSuccess(articles)),
            error => dispatch(loadArticlesFailed(error))
        )
    }
}

export function loadArticleById(id) {
    return (dispatch, getState) => {
        dispatch(loadArticles_());
        return ArticlesAPI.getArticle(id).then(
            article => dispatch(loadArticlesSuccess([article])),
            error => dispatch(loadArticlesFailed(error))
        )
    }
}

let createArticle_ = () => {
    return {
        type: types.CREATE_ARTICLE
    }
}
let createArticleSuccess = (article) => {
    return {
        type: types.CREATE_ARTICLE_SUCCESS,
        article: article
    }
}
let createArticleFailed = (error) => {
    return {
        type: types.CREATE_ARTICLE_FAIL,
        error: error
    }
}

//thunk
export function createArticle(data) {
    return (dispatch, getState) => {
        dispatch(createArticle_());
        return ArticlesAPI.createArticle(data).then(
            article => dispatch(createArticleSuccess(article)),
            error => dispatch(createArticleFailed(error))
        )
    }
}

let updateArticle_ = () => {
    return {
        type: types.UPDATE_ARTICLE
    }
}
let updateArticleSuccess = (article) => {
    return {
        type: types.UPDATE_ARTICLE_SUCCESS,
        article: article
    }
}
let updateArticleFailed = (error) => {
    return {
        type: types.UPDATE_ARTICLE_FAIL,
        error: error
    }
}

//thunk
export function updateArticle(data) {
    return (dispatch, getState) => {
        dispatch(updateArticle_());
        return ArticlesAPI.updateArticle(data).then(
            article => dispatch(updateArticleSuccess(article)),
            error => dispatch(updateArticleFailed(error))
        )
    }
}

let deleteArticle_ = () => {
    return {
        type: types.DELETE_ARTICLE
    }
}
let deleteArticleSuccess = (id) => {
    return {
        type: types.DELETE_ARTICLE_SUCCESS,
        id: id
    }
}
let deleteArticleFailed = (error) => {
    return {
        type: types.DELETE_ARTICLE_FAIL,
        error: error
    }
}

//thunk
export function deleteArticle(id) {
    return (dispatch, getState) => {
        dispatch(deleteArticle_());
        return ArticlesAPI.deleteArticle(id).then(
            () => dispatch(deleteArticleSuccess(id)),
            error => dispatch(deleteArticleFailed(error))
        )
    }
}

/*eslint-enable*/