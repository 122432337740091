/*eslint-disable*/
import { useCallback } from 'react'
import { useDispatch, useMappedState } from 'redux-react-hook'

import * as usersActions from '../../../redux/actions/UsersActions'
import CommonHelper from '../../../helpers/CommonHelper'

let useCurrentUser = () => {

    let dispatch = useDispatch()

    return useMappedState(useCallback(state => {
        let currentUser = state.users.currentUserId ? state.users.usersMap.get(state.users.currentUserId) : { userRole: `guest` }
        let userRole = state.users.currentUserId ? state.users.usersMap.get(state.users.currentUserId).userRole : `guest`
        let dict = Object.assign({}, ...state.dictionary.dictionaryMap.toArray().map(i => i.words))
        let projectStatuses = Object.keys(dict).filter(i => i.indexOf(`project_status_`) > -1)
        return ({
            currentUser,
            userRole,
            isAdmin: userRole === `admin`,
            logOut: async () => {
                await dispatch(usersActions.logOut());
                CommonHelper.linkTo(`/`)
                // window.location.reload();
            },
            usersProjects: state.projects.projectsMap.filter(i => (i.applications || []).map(j => j.userId).indexOf(state.users.currentUserId) > -1)
                .toArray()
                .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
                .sort((a, b) => +(b.pinned === true) - +(a.pinned === true))
                .filter(i => userRole === `student` || userRole === `guest` ? i.status !== projectStatuses[0] && i.status !== projectStatuses[4] : true),
            updateUser: async (info) => state.users.currentUserId ? await dispatch(usersActions.updateUser({ info: { ...info }, id: state.users.currentUserId })) : () => { },

        })
    }, []))
}

export default useCurrentUser;
/*eslint-enable*/