/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useMappedState, useDispatch } from 'redux-react-hook'

import * as FAQActions from '../../../redux/actions/FAQActions'

import { Frame, Button, Input, Dropdown, H, P, Link } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'

let FAQEditPage = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let question_id = path[1]

    let [data, setData] = useState({})

    let dispatch = useDispatch()
    let { faq } = useMappedState(useCallback((state) => ({ faq: state.faq.faq }), []))

    useEffect(() => { setData({ visible: true, ...faq[question_id] }) }, [faq, question_id])

    return (
        <Frame extra={`align-items: flex-start;`} >
            <H>Edit FAQ: {question_id}</H>
            <Form
                data={data}
                fields={[
                    { type: `string`, name: `Question EN` },
                    { type: `string`, name: `Question LV` },
                    { type: `textarea`, name: `Answer EN` },
                    { type: `textarea`, name: `Answer LV` },
                    { type: `checkbox`, name: `Visible` },
                ]}
                onChange={(key, value) => { setData({ ...data, [key]: value }) }}
            />
            <Link to={`/faq`} >
                <Button onClick={() => {
                    dispatch(FAQActions.updateFAQ({ ...faq, [question_id]: { ...data, id: question_id } }))
                }} >Save</Button>
            </Link>
        </Frame>
    )
}

export default FAQEditPage;
/*eslint-enable*/