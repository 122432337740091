/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Circles from './Circles'

import { Frame, Text, P } from '../../UIKit/styled-templates'

import useDictionary from '../helpers/useDictionary'

let HowItWorks = () => {

    let { getText } = useDictionary()

    let socials = [
        { name: `facebook`, link: getText(`idejubanka_facebook`) },
        { name: `twitter`, link: getText(`idejubanka_twitter`) },
        { name: `instagram`, link: getText(`idejubanka_instagram`) },
    ]

    return (
        <Workspace>
            <Field>
                <ContactsWrapper>
                    <P extra={`cursor: pointer;`} onClick={() => { window.open(getText(`idejubanka_terms_and_comditions`)) }} >
                        {getText('Terms and Conditions')}
                    </P>
                    <P extra={`cursor: pointer;`} onClick={() => { window.open(getText(`idejubanka_privacy`)) }} >
                        {getText('Privacy')}
                    </P>
                    {/*<P>{getText(`rtu adress`)}</P>*/}
                    {/*<P>{getText(`Phone`)}: {getText(`rtu phone number`)}</P>*/}
                    {/*<P>{getText(`Email`)}:  {getText(`rtu email`)}</P>*/}
                </ContactsWrapper>
                <Frame row>
                    {
                        socials.map((item, index) => {
                            return (
                                <Frame key={index} extra={`&:hover { transform: scale(1.05); };`} onClick={() => { window.open(item.link) }} >
                                    <SocialIcon name={item.name} />
                                </Frame>
                            )
                        })
                    }
                </Frame>
            </Field>
            <SeparateLine />
            <Frame row extra={`width: 1100px; margin-bottom: 30px;`}>
                <FooterImage />
            </Frame>
            <Frame extra={props => `width: 100vw; height: 30px; background: ${props.theme.background.secondary}; @media only screen and (max-width: 600px) { height: 30vw; }`}>
                <Frame extra={`width: 1100px; flex-direction: row; justify-content: space-between; @media only screen and (max-width: 600px) { height: 30vw; width: 90vw; flex-direction: column; }`} >
                    <P extra={`font-size: 14px; cursor: pointer; margin: 0;`}>{getText(`2020 © All rights reserved`)}</P>
                    <P extra={`font-size: 14px; cursor: pointer; margin: 0;`}>{getText(`idejubanka_email`)}</P>
                </Frame>
            </Frame>
        </Workspace>
    );
}

const LowerWrapper = styled(Frame)`
    width: 1100px;
    justify-content: space-between;
    flex-direction: row;

    > * {
        flex-direction: row;
        color: ${props => props.theme.text.secondary};
        margin: 0;
        > * {
            color: ${props => props.theme.text.secondary};
            margin: 0;
        }
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        > * {
            flex-direction: column;
        }
    }
`;

const Field = styled(Frame)`
    width: 1100px;
    justify-content: space-between;
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        flex-direction: column-reverse;
        width: 100vw;
    }
`;

const SeparateLine = styled(Frame)`
    width: 1100px;
    height: 1px;
    background: ${props => props.theme.background.secondary};
    margin: 30px 0 30px 0;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin: 5vw 0;
    }
`;

const ContactsWrapper = styled(Frame)`
    flex-direction: row;
    justify-content: space-between;
    > * {
        margin: 0 30px;
    };
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        > * {
            margin: 2.5vw 0;
        };
    }
`;

let SocialIcon = styled.img.attrs((props) => {
    let img
    try {
        img = require(`../../../assets/landing/social_${props.name}.png`)
    } catch (error) {
    }
    return ({ src: img, })
})`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-left: 18px;
    cursor: pointer;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
        box-shadow: 0 20px 60px rgba(87, 138, 214, 0.5);
    }
    ${props => props.extra}
`

let FooterImage = styled.img.attrs((props) => {
    let img
    try {
        img = require(`../../../assets/landing/footer_image.svg`)
    } catch (error) {
    }
    return ({ src: img, })
})`
    // width: 350px;
    height: 60px;
    ${props => props.extra}

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`

const Workspace = styled(Frame)`
    width: 100vw;
    margin-top: 94px;
    justify-content: space-between;
`;

export default HowItWorks;

/*eslint-enable*/
