/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useDispatch, useMappedState } from 'redux-react-hook'
import moment from 'moment-timezone'

import * as projectsActions from '../../../redux/actions/ProjectsActions'
import { Frame, Text, convertHex, H, P } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import CommonHelper from '../../../helpers/CommonHelper';
import ProjectTab from '../tools/ProjectTab'
import emptyAvatar from '../../../assets/images/empty_avatar.svg'
import mvConsts from '../../../constants/mvConsts';

import useCurrentUser from '../helpers/useCurrentUser';
import useDictionary from '../helpers/useDictionary';

let statusTypes = {
    new: {
        name: `new`,
        label: `NEW`,
        color: mvConsts.light.blue,
    },
    accepted: {
        name: `accepted`,
        label: `Accepted`,
        color: mvConsts.light.green,
    },
    deleted: {
        name: `deleted`,
        label: `Deleted`,
        color: mvConsts.light.red,
    },
    waiting: {
        name: `waiting`,
        label: `Waiting`,
        color: `transparent`,
    },
}

let openApplicationPopUp = (application) => { window.dispatchEvent(new CustomEvent(`OPEN_APPLICATION_POP_UP`, { detail: application })) }

let Application = (props) => {

    let { application, onUpdateApplicationStatus } = props
    let { getText } = useDictionary()

    application = {
        ...application,
        status: application.status || statusTypes.new.name,
    }

    let studentInfo = { ...application.studentInfo }
    let projectInfo = { ...application.projectInfo }

    let { currentUser } = useCurrentUser()

    return (
        <Frame>
            <ApplicationWrapper onClick={(e) => {
                if (e.target.id === `trash_can`) return;
                openApplicationPopUp(application)
                if (application.status === statusTypes.new.name && currentUser.userRole !== `admin`) {
                    onUpdateApplicationStatus({ ...application, status: statusTypes.waiting.name })
                }
            }} >
                <ColumnContainer width={192} extra={`cursor: pointer;`} >
                    <UserAvatar src={studentInfo.avatarUrl} />
                    <Frame extra={`align-items: flex-start;`} >
                        <Text extra={props => `font-size: 12px; line-height: 16px; color: ${props.theme.text.secondary};`} >{studentInfo.name} {studentInfo.last_name}</Text>
                        <Text extra={props => `font-size: 10px; line-height: 14px; color: ${props.theme.text.secondary}; opacity: 0.75;`} >{studentInfo.email}</Text>
                    </Frame>
                </ColumnContainer>
                <ColumnContainer width={159} extra={`flex-direction: column; align-items: flex-start;`} >
                    <Text extra={props => `font-size: 12px; line-height: 16px; color: ${props.theme.text.secondary};`} >{getText(moment(application.createdTimestamp).format(`MMMM`))} {moment(application.createdTimestamp).format(`DD`)}, {moment(application.createdTimestamp).format(`YYYY`)}</Text>
                    <Text extra={props => `font-size: 10px; line-height: 14px; color: ${props.theme.text.secondary}; opacity: 0.75;`} >{moment().diff(moment(application.createdTimestamp), `days`)} {getText(`days ago`)}</Text>
                </ColumnContainer>
                <ColumnContainer width={128} >
                    <Text extra={props => `font-size: 12px; line-height: 16px; color: ${props.theme.text.secondary};`} >{projectInfo.title_project}</Text>
                </ColumnContainer>
                <ColumnContainer width={123} >
                    <Text bold extra={props => `width: 75px; font-size: 12px; line-height: 16px; color: ${statusTypes[application.status].color};`} >{application.status === statusTypes.waiting ? `` : statusTypes[application.status].label}</Text>
                    <TrashCan id={`trash_can`} onClick={() => { onUpdateApplicationStatus({ ...application, status: statusTypes.deleted.name }) }} />
                </ColumnContainer>
            </ApplicationWrapper>
            <MobileApplicationWrapper onClick={() => { openApplicationPopUp(application) }} >
                <Frame row extra={`width: 81.25vw; margin-top: 3.125vw; justify-content: space-between;`} >
                    <Frame row >
                        <UserAvatar src={studentInfo.avatarUrl} />
                        <Frame extra={`align-items: flex-start;`} >
                            <Text extra={props => `font-size: 12px; line-height: 16px; color: ${props.theme.text.secondary};`} >{studentInfo.name} {studentInfo.last_name}</Text>
                            <Text extra={props => `font-size: 10px; line-height: 14px; color: ${props.theme.text.secondary}; opacity: 0.75;`} >{studentInfo.email}</Text>
                        </Frame>
                    </Frame>
                    <TrashCan id={`trash_can`} onClick={() => { onUpdateApplicationStatus({ ...application, status: statusTypes.deleted.name }) }} />
                </Frame>
                <P extra={`width: 81.25vw; align-items: flex-start; font-size: 3.75vw !important; height: 5vw;`} >
                    {projectInfo.title_project}
                </P>
                <Frame extra={`width: 90vw; height: 10vw; background: ${convertHex(statusTypes[application.status].color, 0.1)};`} >
                    <Frame extra={`width: 81.25vw; flex-direction: row; justify-content: space-between;`} >
                        <Text extra={props => `font-size: 12px; line-height: 16px; color: ${props.theme.text.secondary};`} >{moment(application.createdTimestamp).format(`MMMM`)} {moment(application.createdTimestamp).format(`DD`)}, {moment(application.createdTimestamp).format(`YYYY`)}</Text>
                        <Text bold extra={props => `font-size: 12px; line-height: 16px; color: ${statusTypes[application.status].color};`} >{application.status === statusTypes.waiting ? `` : statusTypes[application.status].label}</Text>
                    </Frame>
                </Frame>
            </MobileApplicationWrapper>
        </Frame>
    )
}

let CompanyApplicationsPage = (props) => {

    let { applications } = useMappedState(useCallback(state => {
        let isAdmin = state.users.usersMap.get(state.users.currentUserId).userRole === `admin`
        let studentsMap = state.users.usersMap.filter(i => i.userRole === `student`)
        let stuentsWithInfoMap = studentsMap.map(i => ({ id: i.id, name: i.info.name, last_name: i.info.last_name, avatarUrl: i.info.avatarUrl, email: i.email, verified: studentsMap.verified }))
        let projectsMap = state.projects.projectsMap
        let projectsWithInfoMap = projectsMap.map(i => ({ id: i.id, title_project: i.info.title_project }))
        let myProjectsMap = state.projects.projectsMap.filter(i => i.creatorId === state.users.currentUserId || isAdmin)
        let myApplications = [].concat(...myProjectsMap.toArray().map(i => i.applications))
        let myApplicationsWithInfo = myApplications.map(i => ({ ...i, studentInfo: stuentsWithInfoMap.get(i.userId), projectInfo: projectsWithInfoMap.get(i.projectId) }))
        let myApplicationsWithStatus = myApplicationsWithInfo.map(i => ({ status: statusTypes.new.name, ...i }))
        return ({
            applications: myApplicationsWithStatus,
        })
    }, []))

    let dispatch = useDispatch()

    let onUpdateApplicationStatus = (new_application) => {
        delete new_application.studentInfo
        delete new_application.projectInfo
        dispatch(projectsActions.updateApplicationStatus(new_application))
    }

    let { getText } = useDictionary()

    let [sortBy, setSortBy] = useState(``)

    let columns = [
        {
            label: `candidate`,
            width: 192,
            sortable: false,
        },
        {
            label: `date`,
            width: 159,
            sortable: false,
        },
        {
            label: `project`,
            width: 128,
            sortable: true,
        },
        {
            label: `status`,
            width: 123,
            sortable: true,
        },
    ]

    let sort = (label) => {
        if (sortBy === label) {
            setSortBy(label + `reverse`)
            applications = applications.reverse()
        } else {
            setSortBy(label)
            applications = applications.sort((a, b) => a.status.localeCompare(b.status))
        }
    }

    // switch (sortBy) {
    //     case `status`:
    //         applications = applications.sort((a, b) => a.status.localeCompare(b.status))
    //         break;
    //     case `project`:
    //         applications = applications.sort((a, b) => a.projectInfo.title_project.localeCompare(b.projectInfo.title_project))
    //         break;
    // }

    return (
        <Wrapper>

            <H extra={`margin-top: 30px;`} >{getText(`Applications`)}</H>

            <ApplicationsWrapper>
                <Frame row extra={props => `width: 100%; border-bottom: 1px solid ${props.theme.background.secondary}; @media only screen and (max-width: 600px) { display: none; };`} >
                    {
                        columns.map((item, index) => {
                            return (
                                <ColumnTitle width={item.width} key={index} sortedBy={sortBy === item.label} onClick={() => { if (item.sortable) { sort(item.label) } }} >
                                    {getText(item.label, `UPPERCASE_FIRST_LETTER`)}
                                    {item.label === sortBy ? <ArrowDown /> : null}
                                </ColumnTitle>
                            )
                        })
                    }
                </Frame>
                {
                    applications.map((item, index) => {
                        return (
                            <Application key={index} application={item} onUpdateApplicationStatus={onUpdateApplicationStatus} />
                        )
                    })
                }
            </ApplicationsWrapper>

        </Wrapper>
    );
}

const TrashCan = styled(Frame)`
    width: 24px;
    height: 24px;
    margin: 0px 0px 0px 10px;
    cursor: pointer;
    transform: scale(0);
    opacity: 0;
    transition: 0.2s;
    background: url("${require(`../../../assets/images/trash_can.svg`)}") no-repeat center center;

    @media only screen and (max-width: 600px) {
        width: 7.5vw;
        height: 7.5vw;
        margin: 0;
        opacity: 1;
        transform: scale(1);
    }
`;

const MobileApplicationWrapper = styled(Frame)`
    width: 90vw;
    height: 36vw;
    border-radius: 3.75vw;
    border: 1px solid ${props => props.theme.background.secondary};
    box-sizing: border-box;
    overflow: hidden;
    justify-content: space-between;
    margin-bottom: 3.75vw;

    @media only screen and (min-width: 600px) {
        display: none;
    }
`;

const UserAvatar = styled(Frame)`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});
    background-position: center;

    @media only screen and (max-width: 600px) {
        width: 6.25vw;
        height: 6.25vw;
        margin-right: 2.5vw;
    }
`;

const ApplicationWrapper = styled(Frame)`
    flex-direction: row;
    border-bottom: 1px solid ${props => props.theme.background.secondary};
    &:hover {
        transform: scale(1.005);
        box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07);
        > * {
            &:last-child {
                > * {
                    &:last-child {
                        opacity: 1;
                        transform: scale(1);
                    };
                };
            };
        };
    };

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

let ArrowDown = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_down_${props.selected ? `blue` : `black`}.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 12px; height: 6px; margin: 0px 0px 0px 5px; transform: rotate(${props => 180 * +props.down}deg); transition: 0.2s; opacity: 0.6; `

const ColumnTitle = styled(Frame)`
    justify-content: flex-start;
    margin: 26px 16px 14px 16px;
    ${props => props.width ? `width: ${props.width}px;` : null}
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.sortedBy ? props.theme.blue : props.theme.text.secondary};
    flex-direction: row;
    cursor: pointer;
    ${props => props.extra}
`;

const ColumnContainer = styled(Frame)`
    margin: 13px 16px 13px 16px;
    justify-content: flex-start;
    ${props => props.width ? `width: ${props.width}px;` : null}
    flex-direction: row;
    ${props => props.extra}
`;

const ApplicationsWrapper = styled(Frame)`
    width: 730px;
    margin-top: 30px;
    border-radius: 12px;
    border: 1px solid ${props => props.theme.background.secondary};
    min-height: 336px;
    justify-content: flex-start;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        border-radius: 0;
        border: 0;
        min-height: auto;
        overflow: auto;

    }
`;

const MySelect = styled(Select).attrs((props) => {
    return ({
        styles: {
            control: styles => ({
                ...styles,
                width: `255px`,
                height: `50px`,
                borderRadius: `25px`,
                border: `1px solid ${props.theme.background.secondary}`,
                background: props.theme.background.primary,
                ':hover': null,
            }),

            menuList: style => ({ borderRadius: `12px`, }),

            placeholder: style => ({ ...style, marginLeft: `12px`, }),
            input: style => ({ marginLeft: `12px` }),

            indicatorSeparator: style => ({
                opacity: 0,
            }),
            option: styles => ({
                ...styles,
                background: props.theme.background.primary,
                color: props.theme.text.primary,
                zIndex: 2000,
                ':hover': { backgroundColor: props.theme.background.support, },
            }),
        }
    })
})`
    
`;

const Wrapper = styled(Frame)`
    // position: relative;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        
    }
`;

export default CompanyApplicationsPage;

/*eslint-enable*/