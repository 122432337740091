/*eslint-disable*/
import axios from 'axios'
import { API_ENDPOINT } from "../constants/config";

const WizardAPI = {

    getWizard() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/dictionary/wizard`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result.value);
            }).catch(err => reject(err));
        });
    },
    
    setWizard(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/dictionary/wizard`, { value: data }).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

}

export default WizardAPI;

/*eslint-enable*/