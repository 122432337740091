/*eslint-disable*/
import axios from 'axios'
import { API_ENDPOINT } from "../constants/config";

const ArticlesAPI = {

    getAllArticles() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/articles`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getArticle(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/articles/${id}`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    createArticle(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/articles`, { visible: true, ...data }).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    updateArticle(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/articles`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    deleteArticle(id) {
        // console.log('deleteArticle occured! id = ', id);
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/articles`, { data: { id: id } }).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

}

export default ArticlesAPI;

/*eslint-enable*/