/*eslint-disable*/
import React, { useState } from 'react';
import styled from 'styled-components'
import { useDispatch } from 'redux-react-hook'

import * as dictionaryActions from '../../../redux/actions/DictionaryActions'
import { Frame, Input, H1, Button } from '../../UIKit/styled-templates'
import PopUpWrapper from '../pop-ups/PopUpWrapper'

import useCustomDispatch from '../helpers/useCustomDispatch'
import useComponentSize from '../helpers/useComponentSize'
import useDictionary from '../helpers/useDictionary'

let DictionaryCreatePopUp = () => {

    let [visible, setVisible] = useState(false)
    let [name, setName] = useState(``)

    let { getText } = useDictionary()

    useCustomDispatch(`OPEN_DICTIONARY_CREATE_POP_UP`, (e) => { setVisible(true) })
    useCustomDispatch(`CLOSE_DICTIONARY_CREATE_POP_UP`, () => { setVisible(false) })

    let dispatch = useDispatch()
    let onCloseDictionaryCreatePopUp = () => {
        window.dispatchEvent(new CustomEvent(`CLOSE_DICTIONARY_CREATE_POP_UP`))
    }
    let onCreateDictionary = () => dispatch(dictionaryActions.createDictionary({ name, words: {} }))

    return (
        <PopUpWrapper name={`DICTIONARY_CREATE`} >
            <H1>{getText(`Create new dictionary`)}</H1>
            <Input placeholder={`Dictionary name`} value={name} onChange={(e) => { setName(e.target.value) }} />
            <Button extra={`margin-top: 30px;`} onClick={() => {
                onCreateDictionary()
                onCloseDictionaryCreatePopUp()
                setName(``)
            }} >{getText(`Create`)}</Button>
        </PopUpWrapper>
    );
}



let Cross = styled.img.attrs(() => {
    let img
    try { img = require(`../../../assets/images/cross_w.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    position: absolute;
    right: calc(50% - 330px);
    top: -40px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        transform: rotate(90deg);
    };
`

const OpenProjectTab = styled(Frame)`
    min-width: 480px;
    height: auto;
    padding: 30px 60px 30px 60px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    position: absolute;
    top: ${props => props.visible ? `calc(50% - 190px)` : `150%`};
    z-index: 3;
    // justify-content: space-between;

    @media only screen and (max-width: 600px) {
        min-width: 90vw;
        max-width: 90vw;
        width: 90vw;
        padding: 9vw 3vw;
    }
`;

const DarkOverlay = styled(Frame)`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, ${props => props.visible * 0.75});
    visibility: ${props => props.visible ? `visible` : `hidden`};
    backdrop-filter: blur(${props => props.visible * props.blur * 8}px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
`;




export default DictionaryCreatePopUp;

/*eslint-enable*/