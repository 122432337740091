/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Circles from '../tools/Circles'

import { Frame, P, H } from '../../UIKit/styled-templates'
import useDictionary from '../helpers/useDictionary'

let AdvantagesForStudents = () => {

    let { getText } = useDictionary()

    let title = getText(`advantages_for_students_title`)

    return (
        <Workspace>
            <PhotoWrapper />
            <Right>
                <H>{title.split(` `).filter((a, b) => b < title.split(` `).length - 1).join(` `)}</H>
                <H text_color={props => props.theme.blue} extra={`margin-bottom: 0;`} >{title.split(` `).slice(-1)[0]}</H>
                <Frame extra={`margin-top: 20px; align-items: flex-start;`} >
                    {
                        [
                            getText(`advantages_for_students_1`),
                            getText(`advantages_for_students_2`),
                            getText(`advantages_for_students_3`),
                        ].map((item, index) => {
                            return (
                                <Frame key={index} row extra={`align-items: flex-start;`} >
                                    <Frame>
                                        <Circle index={index} />
                                        <ConnectingLine index={index} />
                                    </Frame>
                                    <Frame extra={`margin-left: 10px; margin-top: -8px;`} >
                                        <P extra={`text-align: start; @media only screen and (max-width: 600px) { width: 80vw; }`} >{item}</P>
                                    </Frame>
                                </Frame>
                            )
                        })
                    }
                </Frame>
            </Right>
        </Workspace>
    );
}

const PhotoWrapper = styled(Frame)`
    width: 540px;
    height: 480px;
    border-radius: 12px;
    background-image: url(${require(`../../../assets/landing/AdvantagesForStudents.png`)});
    background-position: center;
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: 80vw;
        margin-left: 5vw;
    }
`;

const ConnectingLine = styled(Frame)`
    width: 1px;
    height: ${props => 106.5 * (props.index !== 2)}px;
    background: ${props => props.theme.blue};

    @media only screen and (max-width: 600px) {
        height: ${props => 32 * (props.index !== 2)}vw;
    }
`;

const Circle = styled(Frame)`
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: ${props => props.theme[[`red`, `green`, `blue`][props.index || 0]]};

    @media only screen and (max-width: 600px) {
        
    }
`;

const Right = styled(Frame)`
    width: 530px;
    height: 436px;
    justify-content: flex-start;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: auto;
        padding-left: 5vw;
    }
`;

const Workspace = styled(Frame)`
    width: 1100px;
    height: 480px;
    margin-top: 245px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 100px;

    @media only screen and (max-width: 600px) {
        width: 100vw;
        height: auto;
        margin-right: 0;
        margin-top: 10vw;
        flex-direction: column-reverse;
    }
`;

export default AdvantagesForStudents;

/*eslint-enable*/