/*eslint-disable*/
import React, { useState, useCallback } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation } from 'react-router-dom'
import moment from 'moment-timezone'
import { useDispatch, useMappedState } from 'redux-react-hook'

import * as usersActions from '../../../redux/actions/UsersActions'
import { Frame, Text, convertHex, P, H1, H } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import ProjectTab from '../tools/ProjectTab'
import Tags from '../tools/Tags'
import CommonHelper from '../../../helpers/CommonHelper'

import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'

let convertDate = (date) => date ? +moment(date.split(`.`).map((a, b) => b == 1 ? +a - 1 : +a).map(i => isNaN(i) ? 1 : i).reverse()) : ``

let StudentProfilePage = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let { currentUser } = useCurrentUser()
    let { getText } = useDictionary()

    let dispatch = useDispatch()

    let { selectedUser, usersProjects } = useMappedState(useCallback(state => {
        return ({
            selectedUser: state.users.usersMap.get(path[1]),
            usersProjects: state.projects.projectsMap.filter(i => i.applications.filter(j => j.userId === path[1]).length > 0).toArray()
        })
    }, []))

    let student_name = `${selectedUser.info.name || `Student`} ${selectedUser.info.last_name || `Name`}`
    let tags = selectedUser.info[`specialization_(tags)`] || []

    let links = {
        facebook: selectedUser.info.facebook ? selectedUser.info.facebook : undefined,
        linkedin: selectedUser.info.linkedin ? selectedUser.info.linkedin : undefined,
    }

    let edit_buttons = [
        {
            image: `edit`,
            onClick: () => { CommonHelper.linkTo(`/user/${selectedUser.id}/edit`) },
        },
    ]

    return (
        <Wrapper>

            {/* <BackButtonWrapper onClick={() => { CommonHelper.linkTo(`/companies`) }} >
                <BackArrow />
                <Text text_color={props => props.theme.text.secondary} extra={`text-transform: uppercase; font-size: 14px; line-height: 18px;`} >Back to blog</Text>
            </BackButtonWrapper> */}

            {
                currentUser.userRole === `admin` ?
                    <EditButtonsWrapper >
                        {
                            edit_buttons.map((item, index) => <EditButton key={index} name={item.image} onClick={item.onClick} />)
                        }
                    </EditButtonsWrapper> : null
            }

            <Workspace>

                <HeaderWrapper>

                    <UserAvatar src={selectedUser.info.avatarUrl} />

                    <Frame>
                        <Frame row >
                            <H extra={`font-size: 6vw;`}>{student_name}</H>
                            {selectedUser.verified ? <VerifiedSign /> : null}
                            <P extra={`margin: 0px 15px;`} >{selectedUser.status === `blocked` ? `(blocked)` : ``}</P>
                        </Frame>
                        <Frame row>
                            {
                                Object.keys(links).map((item, index) => {
                                    if (links[item] !== undefined) {
                                        let link = links[item].split(`https://`).filter(i => i.split(``).length > 0)[0].split(`www.`).filter(i => i.split(``).length > 0)[0]
                                        return (
                                            <Frame
                                                key={index}
                                                extra={props => `margin-right: 16px; width: 30px; height: 30px; border-radius: 15px; box-sizing: border-box; border: 1px solid ${props.theme.background.secondary}; &:hover { opacity: 0.8; }; cursor: pointer;`}
                                                onClick={() => { window.open(`https://${link}`); }}
                                            >
                                                <ShareLogo name={item} />
                                            </Frame>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            }
                            <Frame row ><EmailImage /><P extra={props => `margin: 0px; color: ${props.theme.text.primary};`} >{selectedUser.email}</P></Frame>
                        </Frame>
                        <Tags tagsArray={tags} />
                    </Frame>

                </HeaderWrapper>

                <H1>{getText(`About`, `UPPERCASE_FIRST_LETTER`)}</H1>

                <P extra={`margin-bottom: 20px;`}>{selectedUser.info[`description`] || `Still empty`}</P>

                <H1>{getText(`Education`)}</H1>

                <ExpirienceWrapper>
                    {
                        (selectedUser.info.education || []).map((item, index) => {
                            return (
                                <Frame key={index} >
                                    <Frame>
                                        <Text>{item.from ? moment(convertDate(item.from)).format(`MM.YYYY`) : ``}{item.from && item.to || item[`on-going`] ? ` - ` : ``}{item[`on-going`] ? getText(`now`) : item.to ? moment(convertDate(item.to)).format(`MM.YYYY`) : ``}</Text>
                                        <Frame extra={`align-items: flex-start;`} >
                                            <Text>{item[`educational_institution`] || `Educational institution`}</Text>
                                            {/* <P>{item[`department_or_specialty`] || `Specialty`}</P> */}
                                            {/* <P>{item[`education_level`] || `Education level`}</P> */}
                                        </Frame>
                                    </Frame>
                                    <P></P>
                                </Frame>
                            )
                        })
                    }
                    {selectedUser.info.work_experience ? null : <P>{getText(`Empty`, `UPPERCASE_FIRST_LETTER`)}</P>}
                </ExpirienceWrapper>

                <H1>{getText(`Work experience`)}</H1>

                <ExpirienceWrapper>
                    {
                        (selectedUser.info.work_experience || []).map((item, index) => {
                            return (
                                <Frame key={index} >
                                    <Frame>
                                        <Text>{item.from ? moment(convertDate(item.from)).format(`MM.YYYY`) : ``}{item.from && item.to || item[`on-going`] ? ` - ` : ``}{item[`on-going`] ? getText(`now`) : item.to ? moment(convertDate(item.to)).format(`MM.YYYY`) : ``}</Text>
                                        <Frame extra={`align-items: flex-start;`} >
                                            <Text>{item[`company_name`] || `Company Name`}</Text>
                                            <P>{item[`job_title`] || `Job title`}</P>
                                            <P>{item[`city`] || `City`}</P>
                                        </Frame>
                                    </Frame>
                                    <P>
                                        {item[`description`] || `Description`}
                                    </P>
                                </Frame>
                            )
                        })
                    }
                    {selectedUser.info.work_experience ? null : <P>{getText(`Empty`, `UPPERCASE_FIRST_LETTER`)}</P>}
                </ExpirienceWrapper>

                <H1>{getText(`List of projects`, `UPPERCASE_FIRST_LETTER`)}</H1>
                {usersProjects.map((item, index) => <ProjectTab key={index} projectId={item.id} />)}
                {usersProjects.length !== 0 ? null : <P>{getText(`Empty`, `UPPERCASE_FIRST_LETTER`)}</P>}

            </Workspace>

        </Wrapper >
    );
}

let EmailImage = styled.img.attrs(() => {
    let img
    try { img = require(`../../../assets/images/email.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin: 0 15px 0 0px; @media only screen and (max-width: 600px) { margin: 5vw 5vw 10vw 0; }`

const ExpirienceWrapper = styled(Frame)`
    > * {

        width: 920px;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        @media only screen and (max-width: 600px) {
            width: 90vw;
        }

        > * {
            &:first-child {
                width: 100%;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                > * {
                    &:nth-child(1) {
                        font-size: 16px;
                        line-height: 22px;
                        opacity: 0.75;
                        margin-right: 20px;
                        width: 150px;
                        align-items: flex-start;
                        @media only screen and (max-width: 600px) {
                            width: 30%;
                        }
                    }
                    &:nth-child(2) {
                        align-items: flex-start;
                        > * {
                            margin-bottom: 13px;
                            &:first-child {
                                font-size: 18px;
                                line-height: 21px;
                                text-transform: uppercase;
                            }
                        }
                        @media only screen and (max-width: 600px) {
                            width: 70%;
                        }
                    }
                }
            }
        }
        &:last-child {
            border-bottom: 0px;
        }
    };
`;

const VerifiedSign = styled(Frame)`
    width: 36px;
    height: 36px;
    margin-left: 22px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/approved_company.svg`)});

    @media only screen and (max-width: 600px) {
        width: 6.25vw;
        height: 6.25vw;
    }    
`;

const UserAvatar = styled(Frame)`
    width: 160px;
    height: 160px;
    border-radius: 50%;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});
    background-position: center;

    @media only screen and (max-width: 600px) {
        width: 21vw;
        height: 21vw;
    }
`;

const EditButtonsWrapper = styled(Frame)`
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 15px;
`;

const EditButton = styled(Frame)`
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    border: 2px solid ${props => props.theme.text.primary};
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
        transform: scale(1.05);
    }

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url(${props => require(`../../../assets/images/project-edit-button-${props.name}.svg`)}) no-repeat;
    }
`;

const TagWrapper = styled(Text)`
    padding: 0 22px 0 22px;
    border-radius: 4px;
    background: ${props => convertHex(props.theme.green, 0.15)};
    margin: 0 10px 5px 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 22px;
`;

const Workspace = styled(Frame)`
    width: 920px;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

let EmptyAvatar = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/empty_avatar.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 160px; height: 160px;`

let LocationImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/location.png`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin: 0 15px 0 30px;`

let PhoneImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/phone.png`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin: 0 15px 0 30px;`

const HeaderWrapper = styled(Frame)`
    width: 920px;
    justify-content: flex-start;
    margin: 40px 0 60px 0;
    flex-direction: row;
    
    > * {
        &:nth-child(2) {
            height: 160px;
            margin-left: 30px;
            justify-content: space-between;
            align-items: flex-start;

            @media only screen and (max-width: 600px) {
                width: 90vw;
                height: auto;
                max-height: 40vw;
                margin-left: 0px;
                margin-top: 5vw;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin: 4vw 0px;
        flex-direction: column;
        align-items: flex-start;
    }
`;

const BackButtonWrapper = styled(Frame)`
    width: 920px;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;
    cursor: pointer;
    z-index: 1;
`;

const HeaderImageWrapper = styled(Frame)`
    width: 920px;
    height: 256px;
    background: ${props => props.theme.background.secondary};
    justify-content: flex-start;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
`;

let ShareLogo = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/share_${props.name}.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 15px; height: 15px; `

let BackArrow = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/back_arrow.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 16px;
    height: 16px;
    margin-right: 3px;
`

const Wrapper = styled(Frame)`
    
`;

export default StudentProfilePage;

/*eslint-enable*/