/*eslint-disable*/
/**
 * Created by sabir on 19.07.17.
 */

import { Map, Stack, Set } from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,
    wizard: { questions: [], weights: {} },
    error: undefined,
    updateTime: 0,
}

const WizardReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.CREATE_WIZARD:
        case types.DELETE_WIZARD:
        case types.LOAD_WIZARD:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.UPDATE_WIZARD:
            return {
                ...state,
                loading: true,
                error: undefined,
                wizard: action.wizard,
            }

        case types.CREATE_WIZARD_FAIL:
        case types.UPDATE_WIZARD_FAIL:
        case types.DELETE_WIZARD_FAIL:
        case types.LOAD_WIZARD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_WIZARD_SUCCESS:
        case types.UPDATE_WIZARD_SUCCESS:
            return {
                ...state,
                loading: false,
                wizard: action.wizard,
            }

        case types.DELETE_WIZARD_SUCCESS:
            return {
                ...state,
                loading: false,
                wizard: {},
            }

        case types.LOAD_WIZARD_SUCCESS:
            return {
                ...state,
                loading: false,
                wizard: action.wizard || {},
                updateTime: +new Date(),
            }

        case types.SET_WIZARD:
            return {
                ...state,
                wizard: action.wizard,
            }


        default:
            return state;
    }

}

export default WizardReducer;

/*eslint-enable*/