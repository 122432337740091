/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useMappedState } from "redux-react-hook";
import axios from 'axios'

import * as usersActions from '../../../redux/actions/UsersActions'

import { Frame, Text, Checkbox, H, P, H1 } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import Input from '../../UIKit/Input'
import Form from '../../UIKit/Form'
import CommonHelper from '../../../helpers/CommonHelper'
import Circles from '../tools/Circles'
import { API_ENDPOINT } from '../../../constants/config'

import useDictionary from '../helpers/useDictionary'

let EmailFields = [
    { name: `Email`, type: `string` },
]

let PasswordFields = [
    { name: `Code from your mail`, type: `string` },
    { name: `New password`, type: `password` },
    { name: `Confirm new password`, type: `password` },
]

let validateEmail = (email) => (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)

let checkPassword = (password) => {
    if (!password.match(/[a-z]+/)) { return `Password sould have at least 1 letter between lowercase [ a - z ]` }
    if (!password.match(/[A-Z]+/)) { return `Password sould have at least 1 letter between uppercase [ A - Z ]` }
    if (!password.match(/[0-9]+/)) { return `Password sould have at least 1 number between [ 0 - 9 ]`; }
    if (password.length < 8) { return `Minimum number of characters is 6` }
    return `Ok`
}

let RecoverPasswordPage = (props) => {

    const dispatch = useDispatch();
    let { getText } = useDictionary()

    let [tempData, setTempData] = useState({})
    let [visibleFields, setVisibleFields] = useState(EmailFields)
    let [loading, setLoading] = useState(false)

    return (
        <Wrapper>
            <Frame extra={`position: absolute; top: -230px; left: -159px;`} ><Circles width={460} /></Frame>
            <Frame extra={`position: absolute; top: 571px; left: 1400px;`} ><Circles width={480} /></Frame>
            <Header>
                <Logo onClick={() => { CommonHelper.linkTo(`/`) }} />
            </Header>
            <FormWrapper>
                <Title>{getText(`Forgot your password?`)}</Title>
                <Form
                    fields={visibleFields}
                    data={tempData}
                    onChange={(key, value) => { setTempData({ ...tempData, [key]: value }) }}
                />
                <Button extra={`width: 540px; margin-top: 20px; @media only screen and (max-width: 600px) { width: 90vw; margin-bottom: 5vw; }`} background={props => props.theme.green} onClick={async () => {
                    if (visibleFields === EmailFields) {
                        setLoading(true)
                        let a = (await axios.post(`${API_ENDPOINT}/recover-pwd`, tempData)).data.result
                        setLoading(false)
                        if (a.ok === 1) {
                            setVisibleFields(PasswordFields)
                        }
                    } else {
                        if (checkPassword(tempData.new_password) !== `Ok`) {
                            window.dispatchEvent(new CustomEvent(`CALL_INPUT_ERROR`, { detail: `New password` }))
                            setTimeout(() => { window.alert(checkPassword(tempData.new_password)) }, 200)
                            return
                        }
                        if (tempData.new_password !== tempData.confirm_new_password) {
                            window.dispatchEvent(new CustomEvent(`CALL_INPUT_ERROR`, { detail: `Confirm new password` }))
                            setTimeout(() => { window.alert(`Password doesn't match`) }, 200)
                            return
                        }
                        setLoading(true)
                        axios.post(`${API_ENDPOINT}/recover-pwd/set-new-pwd`, { email: tempData.email.toLowerCase(), password: tempData.new_password, code: tempData.code_from_your_mail, }).then(d => d.data).then(pld => {
                            setLoading(false)
                            if (pld.error !== undefined) {
                                window.alert(pld.error.message);
                                return;
                            }
                            if (pld.result === false) {
                                window.alert(getText(`Password changed successfully`));
                            }
                            setLoading(true)
                            dispatch(usersActions.logIn({ email: tempData.email.toLowerCase(), password: tempData.new_password })).then(pld => {
                                setLoading(false)
                                CommonHelper.linkTo(`/`)
                                // console.log('signing in');
                            });
                        })
                    }
                }} >{loading ? getText(`Loading`) + `...` : visibleFields === EmailFields ? getText(`Send me recover code`) : getText(`Set new password`)}</Button>
                {/* <Text
                    text_color={props => props.theme.text.support}
                    extra={`margin-top: 27px; font-size: 14px; text-transform: uppercase; cursor: pointer; text-decoration: underline;`}
                >
                    {getText(`Didn't receive confirmation instructions?`)}
                </Text> */}
            </FormWrapper>
        </Wrapper >
    )
}

const Title = styled(H)`
    color: ${props => props.theme.text.secondary};
    margin-bottom: 15px;
    @media only screen and (max-width: 600px) {
        margin: 10vw 0 4vw 0;
        width: 80vw;
        text-align: center;
    };
`;

let Arrow = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_${props.color}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 16px;
    height: 16px;
    margin-left: 8px;
`

const FormWrapper = styled(Frame)`
    margin-top: 100px;
`;

let Logo = styled.img.attrs((props) => {
    let img
    try { img = require('../../../assets/images/rtu_logo_green.svg') } catch (error) { }
    return ({ src: img, })
})`
    width: 174.93px;
    height: 50px;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
        width: 17vw;
    }
`

const Header = styled(Frame)`
    width: 730px;
    flex-direction: row;
    margin-top: 40px;
`;

const Wrapper = styled(Frame)`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: ${props => props.theme.background.primary};
    justify-content: flex-start;
    position: relative;
`;

export default RecoverPasswordPage;
/*eslint-enable*/