/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { useMappedState } from 'redux-react-hook'

import { Frame, H1, H, convertHex, P, Link } from '../../UIKit/styled-templates'
import Circles from '../tools/Circles'

import useDictionary from '../helpers/useDictionary'
import ReactHelper from '../../../helpers/ReactHelper'

let only_desktop = `@media only screen and (max-width: 600px) { display: none; };`

let CalendarPage = (props) => {

    let [monthStart, setMonthStart] = useState(+moment().startOf(`month`))
    let [selectedDay, setSelectedDay] = useState(undefined)
    let { getText } = useDictionary()
    let { projects, companies } = useMappedState(useCallback((state) => {
        let dict = Object.assign({}, ...state.dictionary.dictionaryMap.toArray().map(i => i.words))
        let projectStatuses = Object.keys(dict).filter(i => i.indexOf(`project_status_`) > -1)
        return ({
            projects: state.projects.projectsMap.toArray().filter(i => [1, 2].indexOf(projectStatuses.indexOf(i.status)) > -1),
            companies: state.users.usersMap.toArray().filter(i => i.userRole === `company`),
        })
    }, []))
    let ref = useRef()

    let onClose = () => { setSelectedDay(undefined) }

    ReactHelper.useOnClickOutside(ref, () => { setSelectedDay(undefined) });

    useEffect(() => { setSelectedDay(undefined) }, [monthStart])

    let row = Math.floor(moment(selectedDay).diff(moment(monthStart).startOf(`isoWeek`), `days`) / 7)
    let column = moment(selectedDay).diff(moment(monthStart).startOf(`isoWeek`), `days`) % 7

    let selectedProjects = projects.filter(i => +moment(i.info.from, `DD.MM.YYYY`) === selectedDay || +moment(i.info.to, `DD.MM.YYYY`) === selectedDay).map(i => ({ ...i, companyInfo: ({ info: {}, ...companies.get(i.creatorId) }).info, verified: companies.get(i.creatorId) ? companies.get(i.creatorId).verified : false }))

    return (
        <Wrapper>
            <Frame extra={`position: absolute; right: 0; top: 500px;`} >
                <Circles width={500} />
            </Frame>
            <Header>
                <Frame row >
                    <H extra={`width: 260px; align-items: flex-start; &:hover { transform: scale(1.01); }; cursor: pointer; @media only screen and (max-width: 600px) { width: 40vw; };`} onClick={() => { setMonthStart(+moment().startOf(`month`)) }} >{getText(moment(monthStart).format(`MMMM`))}</H>
                    <Arrow orientation={`left`} onClick={() => { if (+moment().startOf(`month`) < monthStart) { setMonthStart(+moment(monthStart).add(-1, `month`)) } }} />
                    <Arrow orientation={`right`} onClick={() => { setMonthStart(+moment(monthStart).add(1, `month`)) }} />
                </Frame>
                <H>{moment(monthStart).format(`YYYY`)}</H>
            </Header>
            <Main ref={ref} >

                {
                    new Array(moment(monthStart).endOf(`month`).endOf(`isoWeek`).diff(moment(monthStart).startOf(`month`).startOf(`isoWeek`), `days`) + 1).fill(0).map((item, index) => {
                        let day = +moment(monthStart).startOf(`month`).startOf(`isoWeek`).add(index, `days`)
                        let same_month = +moment(day).startOf(`month`) === monthStart
                        let projects_for_day = projects.filter(i => +moment(i.info.from, `DD.MM.YYYY`) === day || +moment(i.info.to, `DD.MM.YYYY`) === day)
                        return (
                            <Day key={index} onClick={() => { setSelectedDay(day === selectedDay ? undefined : day) }} selected={day === selectedDay} >
                                <MobileDot visible={projects_for_day.length > 0} />
                                <Frame extra={props => `width: 140px; margin: 20px 0 35px 0; flex-direction: row; justify-content: space-between; > * { color: ${same_month ? props.theme.text.primary : props.theme.text.secondary}; }; @media only screen and (max-width: 600px) { width: auto; height: auto; margin: 0; };`} >
                                    <Frame extra={only_desktop + (day === selectedDay ? `color: white;` : null)} >{getText(moment(day).format(`dddd`))}</Frame>
                                    <Frame bold extra={`font-family: Bold; ${day === selectedDay ? `color: white;` : null}`} >{getText(moment(day).format(`DD`))}</Frame>
                                </Frame>
                                {
                                    projects_for_day.length > 0 ? <Frame extra={props => `width: 44px; height: 44px; border-radius: 50%; background: ${convertHex(day === selectedDay ? `#ffffff` : props.theme.blue, 0.1)}; color: ${day === selectedDay ? `#ffffff` : props.theme.blue}; font-size: 24px; font-family: Bold; ${only_desktop}`} >
                                        {projects_for_day.length}
                                    </Frame> : null
                                }
                            </Day>
                        )
                    })
                }

                <OpenProjectTab visible={selectedProjects.length > 0} position={{ row, column }} >
                    <Cross onClick={onClose} />
                    <ChildrenWrapper>
                        {
                            selectedProjects.map((item, index, self) => {
                                return (
                                    <Link key={index} to={`/projects/${item.id}`} >
                                        <Frame extra={props => `width: 360px; align-items: flex-start; margin-bottom: 15px; border-bottom: 1px solid ${index === self.length - 1 ? 0 : props.theme.background.secondary}; cursor: pointer; @media only screen and (max-width: 600px) { width: 80vw; };`}  >
                                            <Frame row >
                                                <CompanyAvatar src={item.companyInfo.avatarUrl} />
                                                <Frame extra={props => `margin-left: 15px; font-size: 14px; color: ${props.theme.text.primary};`} >
                                                    {item.companyInfo.company_name || `Hidden company`}
                                                </Frame>
                                                {item.verified ? <VerifiedSign /> : null}
                                            </Frame>
                                            <P extra={`height: 90px; justify-content: flex-start; margin: 10px 0;`} >
                                                {item.info.title_project}
                                            </P>
                                        </Frame>
                                    </Link>
                                )
                            })
                        }
                    </ChildrenWrapper>
                </OpenProjectTab>

            </Main>
        </Wrapper>
    )
}

const MobileDot = styled(Frame)`
    display: none;

    // mobile devices only
    @media only screen and (max-width: 600px) {
        display: flex;
        width: 2vw;
        height: 2vw;
        border-radius: 50%;
        background: ${props => props.visible ? props.theme.blue : `transparent`};
        margin-top: 1vw;

    }
`;

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 36px; height: 36px; margin-left: 22px; `


const CompanyAvatar = styled(Frame)`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: url('${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)}') no-repeat center center / contain;

    @media only screen and (max-width: 600px) {
        width: 12.5vw;
        height: 12.5vw;
    }
`;

const ChildrenWrapper = styled(Frame)`
    display: block;
    max-height: calc(90vh - 120px);
    overflow-y: scroll;

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        display: flex;
        max-height: none;
        overflow-y: auto;
    }
`;

let Cross = styled.img.attrs(() => {
    let img
    try { img = require(`../../../assets/images/cross_b.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 12px;
    height: 12px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 4;
    cursor: pointer;
    transition: 0.2s;
    opacity: 0.5;
    &:hover {
        transform: rotate(90deg);
    };

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        display: none;
    }
`

const OpenProjectTab = styled(Frame)`
    min-width: 360px;
    height: 300px;
    padding: 30px 30px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    position: absolute;
    top: calc(94px + 62px + 30px + 150px + 156px * ${props => props.position.row + 1} + ${props => props.position.row > 1 ? -156 - 300 : 0}px ${props => props.position.row > 1 ? `+` : `-`} 10px);
    left: calc(50% - 550px + 156px * ${props => props.position.column} + ${props => props.position.column > 3 ? -204 : 0}px ${props => props.position.column > 3 ? `-` : `+`} 10px);
    /* transform: translateY(${props => props.visible ? `-50%` : `100vh`}); */
    z-index: 3;
    box-sizing: border-box;
    box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07);
    visibility: ${props => props.visible ? `visible` : `hidden`};
    opacity: ${props => +props.visible};

    @media only screen and (max-width: 600px) {
        all: initial;
        margin-top: ${props => props.visible ? 5 : 0}vh;
    }
`;

const Day = styled(Frame)`
    width: 156px;
    height: 156px;
    justify-content: flex-start;
    background: ${props => props.selected ? props.theme.text.primary : props.theme.background.primary};
    border: 1px solid ${props => props.theme.background.secondary};
    box-sizing: border-box;
    z-index: 1;
    * {
        font-size: 16px;
    }

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        width: calc(90vw / 7);
        height: calc(90vw / 7);
    }
`;

const Main = styled(Frame)`
    flex-direction: row;
    flex-wrap: wrap;
    width: 1100px;
    height: auto;

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        width: 90vw;
    }
`;

const Arrow = styled(Frame)`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07);
    cursor: pointer;
    &:after {
        content: '';
        width: 13px;
        height: 13px;
        background: url("${require(`../../../assets/images/arrow_down_black.svg`)}") no-repeat center center;
        transform: rotate(${props => props.orientation === `left` ? `` : `-`}90deg);
    }
`;

const Header = styled(Frame)`
    width: 1100px;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0;
    > * {
        &:nth-child(1) {
            margin-right: 30px;
        }
    }

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        width: 90vw;
    }
`;

const Wrapper = styled(Frame)`
    width: 1100px;

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        width: 90vw;
    }
`;

export default CalendarPage;
/*eslint-enable*/