/*eslint-disable*/
import EmailsAPI from '../../api/EmailsAPI'

import * as types from '../ActionTypes'

let uuid = () => Math.random().toString(36).substring(3).split(``).map(i => Math.random() > 0.5 ? i.toUpperCase() : i).join(``)

let loadEmails_ = () => {
    return {
        type: types.LOAD_EMAILS
    }
}
let loadEmailsSuccess = (emails) => {
    return {
        type: types.LOAD_EMAILS_SUCCESS,
        emails: emails
    }
}
let loadEmailsFailed = (error) => {
    return {
        type: types.LOAD_EMAILS_FAIL,
        error: error
    }
}

//thunk
export function loadAllEmails() {
    return (dispatch, getState) => {
        dispatch(loadEmails_());
        return EmailsAPI.getEmails().then(
            emails => dispatch(loadEmailsSuccess(emails)),
            error => dispatch(loadEmailsFailed(error))
        )
    }
}

let createEmails_ = () => {
    return {
        type: types.CREATE_EMAILS
    }
}
let createEmailsSuccess = (emails) => {
    return {
        type: types.CREATE_EMAILS_SUCCESS,
        emails: emails
    }
}
let createEmailsFailed = (error) => {
    return {
        type: types.CREATE_EMAILS_FAIL,
        error: error
    }
}

//thunk
export function createEmails(newEmails) {
    return (dispatch, getState) => {
        dispatch(createEmails_());
        let old = getState().emails.emailsMap.toArray()
        return EmailsAPI.setEmails([...old, { ...newEmails, id: uuid() }]).then(
            emails => dispatch(createEmailsSuccess(emails.value)),
            error => dispatch(createEmailsFailed(error))
        )
    }
}

let updateEmails_ = (emails) => {
    return {
        type: types.UPDATE_EMAILS,
        emails: emails
    }
}
let updateEmailsSuccess = (emails) => {
    return {
        type: types.UPDATE_EMAILS_SUCCESS,
        emails: emails
    }
}
let updateEmailsFailed = (error) => {
    return {
        type: types.UPDATE_EMAILS_FAIL,
        error: error
    }
}

//thunk
export function updateEmails(data) {
    return (dispatch, getState) => {
        let old = getState().emails.emailsMap.toArray()
        old = old.map(i => i.id === data.id ? data : i)
        dispatch(updateEmails_(old));
        return EmailsAPI.setEmails(old).then(
            emails => dispatch(updateEmailsSuccess(emails.value)),
            error => dispatch(updateEmailsFailed(error))
        )
    }
}

let deleteEmails_ = () => {
    return {
        type: types.DELETE_EMAILS
    }
}
let deleteEmailsSuccess = (id) => {
    return {
        type: types.DELETE_EMAILS_SUCCESS,
        id: id
    }
}
let deleteEmailsFailed = (error) => {
    return {
        type: types.DELETE_EMAILS_FAIL,
        error: error
    }
}

//thunk
export function deleteEmails(id) {
    return (dispatch, getState) => {
        dispatch(deleteEmails_());
        let old = getState().emails.emailsMap.toArray().filter(i => i.id !== id)
        return EmailsAPI.setEmails(old).then(
            () => dispatch(deleteEmailsSuccess(id)),
            error => dispatch(deleteEmailsFailed(error))
        )
    }
}

/*eslint-enable*/