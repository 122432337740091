/*eslint-disable*/
import React, {  } from 'react';
import styled from 'styled-components'
import ConnectSolveDevelope from '../landing-pages/ConnectSolveDevelope'
import AboutUs from '../landing-pages/AboutUs'
import HowItWorks from '../landing-pages/HowItWorks'
import AdvantagesForStudents from '../landing-pages/AdvantagesForStudents'
import AdvantagesForCompanies from '../landing-pages/AdvantagesForCompanies'
import OurPrograms from '../landing-pages/OurPrograms'
import NewsBlog from '../landing-pages/NewsBlog'

let { round, random } = Math

import { Frame } from '../../UIKit/styled-templates'


let LandingPage = () => {

    return (
        <>
            <ConnectSolveDevelope />
            <AboutUs />
            <HowItWorks />
            <AdvantagesForStudents />
            <AdvantagesForCompanies />
            <OurPrograms />
            <NewsBlog />
        </>
    );
}





export default LandingPage;

/*eslint-enable*/