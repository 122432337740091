/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { useDispatch } from 'redux-react-hook'

import * as programsActions from '../../../redux/actions/ProgramsActions'
import { Frame, Input, H1, Button, AddSquare, convertHex } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import Dropzone from '../../UIKit/Dropzone'
import PopUpWrapper from './PopUpWrapper'
import PhotoPreview from '../tools/PhotoPreview'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch from '../helpers/useCustomDispatch'

let CreateProgramPopUp = () => {

    let { getText } = useDictionary()

    let [info, setInfo] = useState({})
    let [localProgram, setLocalProgram] = useState({})

    let dispatch = useDispatch()
    let onClose = () => { window.dispatchEvent(new CustomEvent(`CLOSE_CREATE_PROGRAM_POP_UP`)) }
    let onCreateProgram = () => dispatch(programsActions.createProgram({ info: info, applications: [] }))
    let onUpdateProgram = () => dispatch(programsActions.updateProgram({ ...localProgram, info: info }))
    let onOpenConfirmProgramDeletePopUp = () => { window.dispatchEvent(new CustomEvent(`OPEN_DELETE_PROGRAM_POP_UP`)) }

    useCustomDispatch(`OPEN_CREATE_PROGRAM_POP_UP`, (e) => {
        if (e.detail) {
            setInfo(e.detail.info)
            setLocalProgram(e.detail)
        }
    })
    useCustomDispatch(`CLOSE_CREATE_PROGRAM_POP_UP`, (e) => { setInfo({}); setLocalProgram({}) })

    return (
        <PopUpWrapper name={`CREATE_PROGRAM`}>
            <H1>{getText(`Create new program`)}</H1>
            <Frame row extra={`align-items: flex-start;`} >
                <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; margin-right: 15px; background: ${convertHex(props.theme.blue, 0.1)}; cursor: pointer; position: relative; overflow: hidden;`} >
                    {
                        info.avatarUrl
                            ? <PhotoPreview onDelete={() => { let new_info = { ...info }; delete new_info.avatarUrl; setInfo(new_info) }} >
                                <Avatar src={info.avatarUrl} />
                            </PhotoPreview>
                            : <Dropzone onUploaded={(url) => { setInfo({ ...info, avatarUrl: url }) }} >
                                <AddSquare />
                            </Dropzone>
                    }
                </Frame>
                <Form
                    data={info}
                    onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                    fields={[
                        { name: `Program name`, type: `string`, },
                        { name: `Facebook`, type: `string`, short: true, },
                        { name: `Linkedin`, type: `string`, short: true, },
                        { name: `description`, type: `textarea`, },
                        { name: `Conditions link`, type: `string`, },
                        { name: `terms`, type: `textarea`, },
                    ]}
                />
            </Frame>
            <Frame row>
                <Button
                    onClick={() => {
                        if (localProgram.id === undefined) {
                            onCreateProgram()
                        } else {
                            onUpdateProgram()
                        }
                        onClose()
                        setInfo({})
                    }}
                    background={props => props.theme.blue}
                >{localProgram.id === undefined ? `Create` : `Save`}</Button>
                {
                    localProgram.id === undefined ? null : <Button extra={`margin-left: 5px;`} shaped background={props => props.theme.red} onClick={() => {
                        onOpenConfirmProgramDeletePopUp()
                        onClose()
                        setInfo({})
                        // CommonHelper.linkTo(`/programs`)
                    }} >{getText(`Delete`)}</Button>
                }
            </Frame>
        </PopUpWrapper >
    );
}

const Avatar = styled(Frame)`
    width: 132px;
    height: 132px;
    background-image: url("${props => props.src}");
    background-position: center;
`;

export default CreateProgramPopUp;

/*eslint-enable*/