/*eslint-disable*/
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'

import * as uiActions from '../../../redux/actions/UIActions'
import * as projectsActions from '../../../redux/actions/ProjectsActions'
import { Frame, Text, H1, Textarea, P, Checkbox } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import PopUpWrapper from '../pop-ups/PopUpWrapper'
import CommonHelper from '../../../helpers/CommonHelper';
import mvConsts from '../../../constants/mvConsts'

import useCurrentUser from '../helpers/useCurrentUser'
import useCustomDispatch from '../helpers/useCustomDispatch'
import useDictionary from '../helpers/useDictionary'
import useComponentSize from '../helpers/useComponentSize'

let ApplyToProjectPopUp = (props) => {

    let { currentUser } = useCurrentUser()
    let { getText, dict } = useDictionary()

    let reasons = Object.keys(dict).filter(i => i.indexOf(`reason_to_delete_project`) > -1).map(item => ({ name: item, label: getText(item) }))
    let projectStatuses = Object.keys(dict).filter(i => i.indexOf(`project_status_`) > -1)

    let [visible, setVisible] = useState(false)
    let [id, setId] = useState(undefined)
    let [project, setProject] = useState({ info: {} })
    let [comment, setComment] = useState(``)

    let onClosePopUp = (application) => { window.dispatchEvent(new CustomEvent(`CLOSE_DELETE_PROJECT_POP_UP`, { detail: application })) }
    let onOpenPopUp = (application) => { window.dispatchEvent(new CustomEvent(`OPEN_DELETE_PROJECT_POP_UP`, { detail: application })) }

    useCustomDispatch(`OPEN_DELETE_PROJECT_POP_UP`, (e) => { setProject(e.detail); setVisible(true) })
    useCustomDispatch(`CLOSE_DELETE_PROJECT_POP_UP`, (e) => { setVisible(false) })

    let dispatch = useDispatch()

    let [checkedReasons, setCheckedReasons] = useState([])

    let onSendDeleteProjectRequest = () => {
        dispatch(projectsActions.updateProject({ ...project, status: projectStatuses[4] }))
    }

    return (
        <PopUpWrapper name={`DELETE_PROJECT`} >
            <H1 extra={`align-items: center; text-align: center;`} >{getText(`Delete`)} {project.info.title_project} </H1>
            <P extra={`width: 350px; text-align: center; @media only screen and (max-width: 600px) { width: 90vw; margin: 0 !important; };`} >{getText(`indicate_the_reason_for_deleting_the_project`)}</P>
            {
                reasons.map((item, index) => {
                    return (
                        <Frame key={index} row extra={`width: 540px; justify-content: flex-start; margin-bottom: 16px; @media only screen and (max-width: 600px) { width: 90vw; margin-bottom: 0px; };`} >
                            <Checkbox checked={checkedReasons.indexOf(index) > -1} onChange={() => {
                                let new_reasons = [...checkedReasons]
                                if (checkedReasons.indexOf(index) > -1) {
                                    new_reasons = new_reasons.filter(i => i !== index)
                                } else {
                                    new_reasons = [...new_reasons, index].sort((a, b) => a - b)
                                }
                                setCheckedReasons(new_reasons)
                            }} />
                            <P extra={`margin: 0px 0px 0px 8px; @media only screen and (max-width: 600px) { width: 75vw; text-align: left; align-items: flex-start; margin: 2vw 0 2vw 5vw !important; };`}>{item.label}</P>
                        </Frame>
                    )
                })
            }
            <Textarea placeholder={`Description`} value={comment} onChange={(e) => { setComment(e.target.value) }} />
            <Frame row extra={`margin-top: 30px;`} >
                <Button extra={`width: 196px; @media only screen and (max-width: 600px) { width: 43.75vw; };`} background={props => props.theme.red} shaped onClick={onClosePopUp} >
                    {getText(`Cancel`)}
                </Button>
                <Button extra={`width: 196px; margin-left: 20px; @media only screen and (max-width: 600px) { width: 43.75vw; margin-left: 2.5vw; };`} background={props => props.theme.blue} onClick={() => {
                    onClosePopUp()
                    onSendDeleteProjectRequest()
                    CommonHelper.linkTo(`${currentUser.id}/projects`)
                }} >
                    {getText(`Delete`)}
                </Button>
            </Frame>
        </PopUpWrapper>
    );
}

let Cross = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/cross_w.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    position: absolute;
    right: calc(50% - 300px);
    top: -40px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        transform: rotate(90deg);
    };
`

const OpenProjectTab = styled(Frame)`
    min-width: 480px;
    height: auto;
    padding: 30px 60px 30px 60px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    position: absolute;
    top: 50%;
    transform: translateY(${props => props.visible ? `-50%` : `100vh`});
    z-index: 3;
    box-sizing: border-box;

    @media only screen and (max-width: 600px) {
        min-width: 90vw;
        max-width: 90vw;
        width: 90vw;
        padding: 9vw 3vw;
    }
`;

const DarkOverlay = styled(Frame)`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, ${props => props.visible * 0.75});
    visibility: ${props => props.visible ? `visible` : `hidden`};
    backdrop-filter: blur(${props => props.visible * props.blur * 8}px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
`;

export default ApplyToProjectPopUp;

/*eslint-enable*/