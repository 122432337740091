/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { useDispatch } from 'redux-react-hook'
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios'
import { API_ENDPOINT } from '../../../constants/config'

import * as programsActions from '../../../redux/actions/ProgramsActions'
import * as articlesActions from '../../../redux/actions/ArticlesActions'
import { Frame, Input, Text, H1, Button, AddSquare, convertHex } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import TagsInput from '../../UIKit/TagsInput'
import Dropzone from '../../UIKit/Dropzone'
import PopUpWrapper from './PopUpWrapper'
import PhotoPreview from '../tools/PhotoPreview'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch from '../helpers/useCustomDispatch'

let tabs = [`Editor`, `Tags`]

let NewsCreatePopUp = () => {

    let { getText, langs, dict } = useDictionary()

    let [article, setArticle] = useState({ [langs[0]]: {}, [langs[1]]: {}, tags: [] })
    let [link, setLink] = useState(``)
    let [tab, setTab] = useState(tabs[0])
    let [localLang, setLocalLang_] = useState(langs[0])
    let setLocalLang = (e) => { setLink(``); setLocalLang_(e) }

    let dispatch = useDispatch()
    let onClose = () => { window.dispatchEvent(new CustomEvent(`CLOSE_CREATE_NEWS_POP_UP`)) }

    let importArticle = async (link) => {
        try {
            let new_article = (await axios.get(`${API_ENDPOINT}/scraper/rtu/blog_page`, { params: { url: link } })).data.result
            setArticle({ ...article, [localLang]: new_article })
        } catch (error) {
            window.dispatchEvent(new CustomEvent(`CALL_INPUT_ERROR`, { detail: `Import link` }))
            setTimeout(() => { setLink(``) }, 1000)
        }
    }

    useCustomDispatch(`OPEN_CREATE_NEWS_POP_UP`, (e) => { if (e.detail) { setArticle(e.detail) } })

    let edit_mode = article.id !== undefined

    let createArticle = (article) => { dispatch(articlesActions.createArticle(article)); }
    let updateArticle = (article) => { dispatch(articlesActions.updateArticle(article)); }
    let saveArticle = (article) => {
        if (edit_mode) {
            updateArticle(article)
        } else {
            createArticle(article)
        }
    }

    // console.log(article);

    return (
        <PopUpWrapper name={`CREATE_NEWS`} >
            <Frame extra={`align-items: flex-start;`} >
                <Frame row >
                    <H1 extra={`width: 315px; align-items: flex-start;`} >{getText(`${edit_mode ? `Edit` : `Create`} news`)}</H1>
                    <Frame row>
                        <H1 bold extra={props => `cursor: pointer; color: ${tab === tabs[0] ? props.theme.blue : props.theme.text.secondary};`} onClick={() => { setTab(tabs[0]) }} >{tabs[0]}</H1>
                        <H1 bold extra={props => `cursor: pointer; margin: 0 10px; color: ${props.theme.text.secondary};`} >/</H1>
                        <H1 bold extra={props => `cursor: pointer; margin-left: 5px; color: ${tab === tabs[1] ? props.theme.blue : props.theme.text.secondary};`} onClick={() => { setTab(tabs[1]) }} >{tabs[1]}</H1>
                    </Frame>
                </Frame>
                <Frame row>
                    <Frame extra={`align-items: flex-start; margin-right: 15px; height: 58vh; justify-content: space-between;`} >
                        <Frame row>
                            <H1 bold extra={props => `cursor: pointer; color: ${localLang === langs[0] ? props.theme.blue : props.theme.text.secondary};`} onClick={() => { setLocalLang(langs[0]) }} >{langs[0]}</H1>
                            <H1 bold extra={props => `cursor: pointer; margin: 0 10px; color: ${props.theme.text.secondary};`} >/</H1>
                            <H1 bold extra={props => `cursor: pointer; margin-left: 5px; color: ${localLang === langs[1] ? props.theme.blue : props.theme.text.secondary};`} onClick={() => { setLocalLang(langs[1]) }} >{langs[1]}</H1>
                        </Frame>
                        <Frame extra={`align-items: flex-start;`} >
                            <Text extra={props => `font-size: 12px; margin-bottom: 4px; color: ${props.theme.text.secondary};`} >Cover photo</Text>
                            <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.blue, 0.1)}; cursor: pointer; position: relative; overflow: hidden;`} >
                                {
                                    article[localLang].imgSrc
                                        ? <PhotoPreview onDelete={() => { let new_article = { ...article }; delete new_article[localLang].imgSrc; setArticle(new_article) }} >
                                            <Avatar src={article[localLang].imgSrc} />
                                        </PhotoPreview>
                                        : <Dropzone onUploaded={(url) => { setArticle({ ...article, [localLang]: { ...article[localLang], imgSrc: url } }) }} >
                                            <AddSquare />
                                        </Dropzone>
                                }
                            </Frame>
                        </Frame>
                        <Frame extra={`align-items: flex-start;`} >
                            <Input
                                placeholder={`Import link`}
                                value={link}
                                onChange={(e) => { setLink(e.target.value) }}
                                extra={`width: 266px;`}
                            />
                            <Button extra={`margin-top: 15px;`} background={props => props.theme.blue} shaped disabled={link.indexOf(`rtu.lv/`) === -1} onClick={async () => { importArticle(link) }} >Import</Button>
                        </Frame>
                        <Button
                            extra={`margin-top: 15px;`}
                            background={props => props.theme.blue}
                            onClick={() => { saveArticle(article); onClose(); setTab(tabs[1]); setTab(tabs[0]); }}
                            disabled={!(article.tags.length > 0 && (Object.values(article[langs[0]]).length === 3 || Object.values(article[langs[1]]).length === 3))}
                        >{getText(`${edit_mode ? `Edit` : `Create`} article`)}</Button>
                    </Frame>
                    <Frame extra={`align-items: flex-start;`} >
                        <Input
                            placeholder={`Heading`}
                            value={{ heading: ``, ...article[localLang] }.heading}
                            onChange={(e) => { setArticle({ ...article, [localLang]: { ...article[localLang], heading: e.target.value } }) }}
                            extra={`margin-bottom: 10px; width: 566px;`}
                        />
                        {
                            tab === tabs[0]
                                ? langs.map((item, index) => {
                                    return (
                                        <Frame key={index} extra={`display: ${item === localLang ? `flex` : `none`};`} >
                                            <Editor
                                                value={article[item].articleHtml || ``}
                                                apiKey={`u3254i8i3jcmyci068mjgrh4rjpeulx7epdcv5bevx54sjyq`}
                                                init={{
                                                    width: 600,
                                                    height: window.innerHeight / 2,
                                                    menubar: false,
                                                    branding: false,
                                                    resize: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount',
                                                        `media`
                                                    ],
                                                    toolbar:
                                                        'undo redo | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | help | media',
                                                }}
                                                onEditorChange={(e) => {
                                                    setArticle({
                                                        ...article, [item]: { ...article[item], articleHtml: e }
                                                    })
                                                }}
                                            />
                                        </Frame>
                                    )
                                })
                                : <>
                                    <TagsInput selectedTagsArray={article.tags} extra={`width: 500px; height: clac(50vh);`} onChange={(e) => { setArticle({ ...article, tags: [...e] }) }} />
                                </>
                        }
                    </Frame>
                </Frame>
            </Frame>
        </PopUpWrapper >
    );
}

const Avatar = styled(Frame)`
    width: 132px;
    height: 132px;
    background-image: url("${props => props.src}");
    background-position: center;
`;

export default NewsCreatePopUp;
/*eslint-enable*/

