/*eslint-disable*/
import {combineReducers} from 'redux';

import ArticlesReducer from './ArticlesReducer.js';
import DictionaryReducer from './DictionaryReducer.js';
import EmailsReducer from './EmailsReducer.js';
import FAQReducer from './FAQReducer.js';
import ProgramsReducer from './ProgramsReducer.js';
import ProjectsReducer from './ProjectsReducer.js';
import UIReducer from './UIReducer.js';
import UsersReducer from './UsersReducer.js';
import WizardReducer from './WizardReducer.js';

export const reducer = combineReducers({
    articles: ArticlesReducer,
    dictionary: DictionaryReducer,
    emails: EmailsReducer,
    faq: FAQReducer,
    programs: ProgramsReducer,
    projects: ProjectsReducer,
    ui: UIReducer,
    users: UsersReducer,
    wizard: WizardReducer,
});

/*eslint-enable*/
