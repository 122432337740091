/*eslint-disable*/
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'
import axios from 'axios'

import * as uiActions from '../../../redux/actions/UIActions'
import * as projectsActions from '../../../redux/actions/ProjectsActions'
import { Frame, Text, H1, P, Textarea } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import PopUpWrapper from './PopUpWrapper'
import CommonHelper from '../../../helpers/CommonHelper';
import mvConsts from '../../../constants/mvConsts'

import useCurrentUser from '../helpers/useCurrentUser'
import useCustomDispatch from '../helpers/useCustomDispatch'
import useComponentSize from '../helpers/useComponentSize'
import useDictionary from '../helpers/useDictionary'

let AddCoordinatorPopUp = (props) => {

    let { getText } = useDictionary()

    let [visible, setVisible] = useState(false)

    let name = `COOKIES`

    useCustomDispatch(`OPEN_${name}_POP_UP`, () => { setVisible(true) })
    useCustomDispatch(`CLOSE_${name}_POP_UP`, () => { setVisible(false) })

    useEffect(() => {
        document.getElementsByTagName(`body`)[0].style.overflowY = visible ? `hidden` : `scroll`
    }, [visible])

    let onClose = () => { window.dispatchEvent(new CustomEvent(`CLOSE_${name}_POP_UP`)) }

    useEffect(() => {
        if (localStorage.getItem(`cookies`) != "true") {
            setTimeout(() => {
                window.dispatchEvent(new CustomEvent(`OPEN_COOKIES_POP_UP`))
            }, 1000)
        }
    }, [])

    let onOk = () => {
        localStorage.setItem(`cookies`, true)
        window.dispatchEvent(new CustomEvent(`CLOSE_COOKIES_POP_UP`))
    }


    return (
        <Frame>
            <OpenProjectTab visible={visible} >
                <ChildrenWrapper>
                    <P extra={`width: 700px; text-align: center;`} >{getText(`cookies_text`)}</P>
                    <Frame row>
                        <Frame bold extra={props => `color: ${props.theme.blue}; margin-right: 30px; cursor: pointer;`} onClick={() => { window.open(getText(`cookies_link`)) }} >{getText(`Learn more`)}</Frame>
                        <Button background={props => props.theme.red} extra={`width: 103px;`} onClick={onOk} >{getText(`OK`)}</Button>
                    </Frame>
                </ChildrenWrapper>
            </OpenProjectTab>
        </Frame >
    );
}

const ChildrenWrapper = styled(Frame)`
    display: block;
    max-height: calc(90vh - 120px);
    overflow-y: scroll;
`;

const OpenProjectTab = styled(Frame)`
    min-width: 480px;
    height: auto;
    padding: 30px 60px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    position: fixed;
    bottom: calc(0% - 90px);
    transform: translateY(${props => props.visible ? `-50%` : `100vh`});
    border: 1px solid ${props => props.theme.background.secondary};
    z-index: 3;
    box-sizing: border-box;
    box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07);

    @media only screen and (max-width: 600px) {
        min-width: auto;
        width: 96vw;
        padding: 9vw 3vw;
        transition: 0.2s;
    }
`;

export default AddCoordinatorPopUp;

/*eslint-enable*/