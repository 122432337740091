/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { useDispatch } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import { Frame, Input, H1, Button, AddSquare, convertHex, P } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import Dropzone from '../../UIKit/Dropzone'
import PopUpWrapper from './PopUpWrapper'
import PhotoPreview from '../tools/PhotoPreview'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch from '../helpers/useCustomDispatch'

let ConfirmPopUp = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let { getText } = useDictionary(() => { })

    let onClose = () => { window.dispatchEvent(new CustomEvent(`CLOSE_LANDING_LOGIN_POP_UP`)) }

    useCustomDispatch(`OPEN_LANDING_LOGIN_POP_UP`, (e) => { })
    useCustomDispatch(`CLOSE_LANDING_LOGIN_POP_UP`, (e) => { CommonHelper.linkTo(`/projects`) })

    return (
        <PopUpWrapper name={`LANDING_LOGIN`}>
            <H1 extra={`margin-top: 43px; @media only screen and (max-width: 600px) { width: 90vw; };`}>{getText(`Please register`)}</H1>
            <P extra={`width: 350px; text-align: center; @media only screen and (max-width: 600px) { width: 90vw; };`}>{getText(`In order to see the details of the company, you need to create an account`)}</P>
            <Frame row extra={`margin-bottom: 31px;`} >
                <Button background={props => props.theme.blue} extra={`@media only screen and (max-width: 600px) { width: 43.75vw; };`} onClick={() => { CommonHelper.linkTo(`/signup/student`) }} >
                    {getText(`For students`)}
                </Button>
                <Button extra={`margin-left: 20px; @media only screen and (max-width: 600px) { width: 43.75vw; margin-left: 2.5vw; };`} background={props => props.theme.red} onClick={() => { CommonHelper.linkTo(`/signup/company`) }} >
                    {getText(`For companies`)}
                </Button>
            </Frame>
        </PopUpWrapper >
    );
}

const Avatar = styled(Frame)`
    width: 132px;
    height: 132px;
    background-image: url("${props => props.src}");
    background-position: center;
`;

export default ConfirmPopUp;

/*eslint-enable*/