/*eslint-disable*/
/**
 * Created by sabir on 19.07.17.
 */

import { Map, Stack, Set } from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    dictionaryMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined }
}

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error }
}

const DictionaryReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_DICTIONARY:
        case types.DELETE_DICTIONARY:
        case types.LOAD_DICTIONARY:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.UPDATE_DICTIONARY:
            return {
                ...state,
                loading: true,
                error: undefined,
                dictionaryMap: action.dictionary.reduce((map, form) => map.set(form.id, form), Map()),
            }

        case types.CREATE_DICTIONARY_FAIL:
        case types.UPDATE_DICTIONARY_FAIL:
        case types.DELETE_DICTIONARY_FAIL:
        case types.LOAD_DICTIONARY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_DICTIONARY_SUCCESS:
        case types.UPDATE_DICTIONARY_SUCCESS:
            return {
                ...state,
                loading: false,
                dictionaryMap: action.dictionary.reduce((map, form) => map.set(form.id, form), Map()),
            }

        case types.DELETE_DICTIONARY_SUCCESS:
            return {
                ...state,
                loading: false,
                dictionaryMap: state.dictionaryMap.delete(action.id),
            }

        case types.LOAD_DICTIONARY_SUCCESS:
            return {
                ...state,
                loading: false,
                dictionaryMap: state.dictionaryMap.merge(action.dictionary.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default DictionaryReducer;

/*eslint-enable*/