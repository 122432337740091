/*eslint-disable*/
import React from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation } from 'react-router-dom'

import { Frame, Text, convertHex } from '../../UIKit/styled-templates'
import useDicitonary from '../helpers/useDictionary'

let Tags = (props) => {

    let { getText } = useDicitonary()

    let { tagsArray = new Array(3).fill(`tag`), extra = `` } = props

    tagsArray = typeof (tagsArray) === `string` ? tagsArray.split(` `) : tagsArray
    tagsArray = tagsArray.filter(i => getText(i) !== ``).map(i => getText(i, 'UPPERCASE')).map(i => i.split(`TAG_`).slice(-1).join(``))

    return (
        <Wrapper extra={extra} >
            {
                tagsArray.map((item, index) => {
                    return (
                        <TagWrapper
                            key={index}
                            text_color={props => props.theme.text.secondary}
                        >
                            {getText(item)}
                        </TagWrapper>
                    )
                })
            }
        </Wrapper>
    );
}


const TagWrapper = styled(Text)`
    padding: 0 22px 0 22px;
    border-radius: 4px;
    background: ${props => convertHex(props.theme.green, 0.15)};
    margin: 0 10px 5px 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 22px;
`;

const Wrapper = styled(Frame)`
    width: 75%;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    ${props => props.extra}

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;


export default Tags;

/*eslint-enable*/