/*eslint-disable*/
import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'
import axios from 'axios'

import * as uiActions from '../../../redux/actions/UIActions'
import { Frame, Text, Link } from '../../UIKit/styled-templates'
import CommonHelper from '../../../helpers/CommonHelper'
import ReactHelper from '../../../helpers/ReactHelper'
import { API_ENDPOINT } from '../../../constants/config'

import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'

let Menu = (props) => {

    let path = useLocation().pathname

    let { currentUser } = useCurrentUser()
    let { getText } = useDictionary()

    const dispatch = useDispatch();
    let onOpenLogOut = () => { window.dispatchEvent(new CustomEvent(`OPEN_LOGOUT_POP_UP`, {})) }

    let ref = useRef()
    let [visible, setVisible] = useState(false)
    ReactHelper.useOnClickOutside(ref, (e) => {
        if (e.srcElement.id !== `mobile_selector`) {
            setVisible(false)
        }
    });

    let menuItems = {
        student: {
            profile: {
                image: `profile`,
                label: `My profile`,
                link: `/user/${currentUser.id}/edit`,
            },
            projects: {
                image: `projects`,
                label: `Projects`,
                link: `/user/${currentUser.id}/projects`,
            },
            settings: {
                image: `settings`,
                label: `Settings`,
                link: `/settings`,
            },
        },

        coordinator: {
            profile: {
                image: `profile`,
                label: `My profile`,
                link: `/user/${currentUser.id}/edit`,
            },
            settings: {
                image: `settings`,
                label: `Settings`,
                link: `/settings`,
            },
        },

        company: {
            company: {
                image: `company`,
                label: `Company`,
                link: `/user/${currentUser.id}/edit`,
            },
            settings: {
                image: `settings`,
                label: `Settings`,
                link: `/settings`,
            },
        },

        admin: {
            profile: {
                image: `profile`,
                label: `Profile`,
                link: `/user/${currentUser.id}/edit`,
            },
            news: {
                image: `news`,
                label: `News`,
                link: `/news`,
            },
            students: {
                image: `students`,
                label: `Students`,
                link: `/students`,
            },
            companies: {
                image: `companies`,
                label: `Companies`,
                link: `/companies`,
            },
            programs: {
                image: `programs`,
                label: `Programs`,
                link: `/programs`,
            },
            projects: {
                image: `projects`,
                label: `Projects`,
                link: `/projects`,
            },
            applications: {
                image: `applications`,
                label: `Applications`,
                link: `/applications`,
            },
            dictionary: {
                image: `dictionary`,
                label: `Dictionary`,
                link: `/dictionary`,
            },
            settings: {
                image: `settings`,
                label: `Settings`,
                link: `/settings`,
            },
            wizard: {
                image: `wizard`,
                label: `Wizard`,
                link: `/wizard/edit`,
            },
            emails: {
                image: `emails`,
                label: `Emails`,
                link: `/emails`,
            },
            faq: {
                image: `faq`,
                label: `faq`,
                link: `/faq`,
            }
        }
    }

    let [allMenuItems, setAllMenuItems] = useState({})

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/dictionary/menu_items`).then(d => d.data).then(pld => {
            if (pld.error != undefined) { return }
            setAllMenuItems(pld.result.value)
        }).catch(e => e);
    }, [])

    let items = menuItems[currentUser.userRole] || menuItems.student || {}
    items = Object.keys(items).filter(i => (allMenuItems[currentUser.userRole] || {})[i] === true).map(i => items[i])
    let selectedItem = { ...items.filter(i => i.link === path)[0] }

    return (
        <Frame>
            <MobileSelector id={`mobile_selector`} onClick={() => { setVisible(!visible) }} >
                <Frame
                    row
                    extra={`&:hover { opacity: 0.8; transform: translateX(2px); }; cursor: pointer;`}
                >
                    <MenuImage name={selectedItem.image} />
                    <ItemText selected={true} >{getText(`${selectedItem.image}`)}</ItemText>
                </Frame>
                <ArrowDown down={visible} selected={visible} />
            </MobileSelector>
            <Wrapper visible={visible} ref={ref} >
                {
                    [...items, { image: `log_out`, label: `Log out`, onClick: () => { onOpenLogOut() }, }].map((item, index) => {
                        let selected = item.link === path
                        return (
                            <Link key={index} to={item.link || path} >
                                <Frame
                                    row
                                    extra={`&:hover { opacity: 0.8; transform: translateX(2px); }; cursor: pointer;`}
                                    onClick={() => { if (item.onClick !== undefined) { item.onClick() } }}
                                >
                                    <MenuImage name={item.image} />
                                    <ItemText selected={selected} >{getText(`${item.label}`, `UPPERCASE_FIRST_LETTER`)}</ItemText>
                                </Frame>
                            </Link>
                        )
                    })
                }
            </Wrapper>
        </Frame >
    );
}

let ArrowDown = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_down_${props.selected ? `blue` : `black`}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 6.25vw;
    height: 3.125vw;
    opacity: ${props => props.down ? 1 : 0.25};
    transform: rotate(${props => 180 * +props.down}deg);
    transition: 0.2s;
`


const ItemText = styled(Frame)`
    font-size: 16px;
    color: ${props => props.selected ? props.theme.blue : props.theme.text.primary};

    @media only screen and (max-width: 600px) {
        font-size: 5vw;
    }
`;

const MobileSelector = styled(Frame)`
    width: 77.5vw;
    height: 7.5vw;
    padding: 3.75vw 6.25vw;
    border-radius: 7.5vw;
    border: 1px solid ${props => props.theme.background.secondary};
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (min-width: 600px) {
        display: none;
    }
`;

let MenuImage = styled(Frame)`
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background-image: url(${props => { let img; try { img = require(`../../../assets/images/menu_${props.name}.svg`) } catch (error) { }; return img }});

    @media only screen and (max-width: 600px) {
        width: 7.5vw;
        height: 7.5vw;
    }
`

// let MenuImage = styled.img.attrs((props) => {
//     let img
//     try { img = require(`../../../assets/images/menu_${props.name}.svg`) } catch (error) { }
//     return ({ src: img, })
// })`
//     width: 24px;
//     height: 24px;
//     margin-right: 10px;
// `


const Wrapper = styled(Frame)`
    padding: 20px;
    min-width: 120px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    /* z-index: 2; */
    align-items: flex-start;
    justify-content: flex-start;
    // box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07);
    border: 1px solid rgba(0, 0, 0, 0.05);
    &:hover {
        box-shadow: 0px 20px 50px rgba(87, 138, 214, 0.2);
    }
    > * {
        margin-top: 20px;
        &:first-child {
            margin-top: 0px;
        }
    }

    @media only screen and (max-width: 600px) {
        width: 76vw;
        padding: 7vw;

        position: absolute;
        top: calc(60px + 25vw + ${props => props.visible ? 0 : -5}vw);
        border: 0;
        ${props => props.theme.shadow.small}

        z-index: 1;

        visibility: ${props => props.visible ? `visible` : `hidden`};
        opacity: ${props => +props.visible};

        transition: 0.2s;
        
        > * {
            margin-top: 6.25vw;
            &:first-child {
                margin-top: 0px;
            }
        }
    }
`;


export default Menu;

/*eslint-enable*/