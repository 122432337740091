/*eslint-disable*/
import React, { useEffect, useState, useRef, useMemo } from 'react';
import styled, { keyframes } from 'styled-components'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

import { menuItems } from '../../../constants/mvConsts'
import { API_ENDPOINT } from '../../../constants/config'

import { Frame, Text, convertHex, Link } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import CommonHelper from '../../../helpers/CommonHelper'
import ReactHelper from "../../../helpers/ReactHelper";
import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'
import Menu from '../tools/Menu'
import ProjectsPopUp from '../pop-ups/ProjectsPopUp'
import NotificationPopUp from '../pop-ups/notification-pop-up'

let useHiddenHeader = (ref) => {
    let [y, setY] = useState(0)
    let [dy, setDy] = useState(0)
    useEffect(() => { ref.current.top = 0 }, [])
    useEffect(() => {
        let scroller = document.getElementById(`scrollWrapper`)
        let handler = () => {
            setDy(scroller.scrollTop - y)
            setY(scroller.scrollTop)
            ref.current.top = Math.max(0, Math.min(120, (ref.current.top || 0) + scroller.scrollTop - y))
        }
        if (scroller) {
            scroller.addEventListener(`scroll`, handler)
            return (() => {
                scroller.removeEventListener(`scroll`, handler)
            })
        }
    }, [y, dy])
}

let Header = () => {

    let [menuVisible, setMenuVisible] = useState(false)
    let [projectsVisible, setProjectsVisible] = useState(false)

    let { lang, setLang, langs, getText } = useDictionary()

    let ref = useRef()
    useHiddenHeader(ref)

    let menuRef = useRef()
    ReactHelper.useOnClickOutside(menuRef, () => setMenuVisible(false));
    let projectsRef = useRef()
    ReactHelper.useOnClickOutside(projectsRef, () => setProjectsVisible(false));

    let path = useLocation().pathname.split(`/`).slice(1)[0]

    let { currentUser, logOut } = useCurrentUser()

    let [mobileMenuVisible, setMobileMenuVisible] = useState(false)
    useEffect(() => { setMobileMenuVisible(false) }, [path])

    // useCustomDispatch(`CLOSE_MENU_POP_UP`, () => { setMobileMenuVisible(false) })


    // block scroll when menu is opened
    useEffect(() => {
        document.getElementsByTagName(`body`)[0].style.overflowY = mobileMenuVisible ? `hidden` : `scroll`
    }, [mobileMenuVisible])

    // download header items
    let [allHeaderItems, setAllHeaderItems] = useState({})

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/dictionary/header_items`).then(d => d.data).then(pld => {
            if (pld.error != undefined) { return }
            setAllHeaderItems(pld.result.value)
        }).catch(e => e);
    }, [])

    let headerItems = (allHeaderItems[currentUser.userRole] || allHeaderItems.guest) || {}

    let onOpenNotificationPopUp = () => {
        window.dispatchEvent(new CustomEvent(`OPEN_NOTIFICATION_POP_UP`))
        setMobileMenuVisible(false)
    }

    return (
        <Wrapper ref={ref} mobileMenu={mobileMenuVisible} >
            <Workspace>
                <Link to={currentUser.userRole === `guest` ? `/` : `/user/${currentUser.id}`} >
                    <LogoWrapper>
                        <Logo />
                    </LogoWrapper>
                </Link>
                <Frame row>
                    <CenterWrapper visible={mobileMenuVisible} menuLength={headerItems.length} >
                        {
                            [`student`, `admin`, `guest`].indexOf(currentUser.userRole) > -1 ? <Link to={`/calendar`} >
                                <CalendarImage />
                            </Link> : null
                        }
                        {
                            currentUser.userRole === `company` ? <Frame row extra={`cursor: pointer; position: relative;`} onClick={() => { if (window.innerWidth <= 600) { CommonHelper.linkTo(`/projects`); } else { setProjectsVisible(true) } }} >
                                <HeaderItem selected={useLocation().pathname === `/projects`} >{getText(`Projects`)}</HeaderItem>
                                <ArrowDown down={projectsVisible} selected={useLocation().pathname === `/projects`} />
                                <ProjectsPopUpWrapper visible={projectsVisible} ref={projectsRef} >
                                    <ProjectsPopUp />
                                </ProjectsPopUpWrapper>
                            </Frame> : null
                        }
                        {
                            Object.keys(headerItems).filter(i => headerItems[i] === true).map((item, index) => {
                                let name = getText(`${item}`, `UPPERCASE_FIRST_LETTER`)
                                return <Link to={`/` + item} key={index} >
                                    <HeaderItem selected={path === item} >{name}</HeaderItem>
                                </Link>
                            })
                        }
                        {
                            currentUser.userRole === `guest`
                                ? <Button extra={`width: 160px;`} onClick={() => { CommonHelper.linkTo(`/signup/student`) }} shaped background={props => props.theme.blue} >{getText(`Sign Up`)}</Button>
                                : null
                        }
                        <LangsWrapper>
                            {
                                langs.map((item, index) => {
                                    let selected = item === lang
                                    return (
                                        <LangText row key={index} selected={selected} >
                                            <Text onClick={() => { setLang(item) }} >{item == 'ENG' ? 'EN' : item}</Text>
                                            {
                                                index < langs.length - 1 ? <Text>/</Text> : null
                                            }
                                        </LangText>
                                    )
                                })
                            }
                        </LangsWrapper>
                    </CenterWrapper>
                    <RightIconsWrapper>
                        {
                            currentUser.userRole === `guest`
                                ? null
                                : <>
                                    {currentUser.userRole === `company` ? <Frame extra={props => `width: 24px; height: 24px; border-radius: 6px; background: ${convertHex(props.theme.green, 0.3)}; cursor: pointer; &:hover { transform: scale(1.2); };`} onClick={() => { CommonHelper.linkTo(`/projects/create`) }} >
                                        <Text bold text_color={props => props.theme.green} extra={`font-size: 15px;`} >+</Text>
                                    </Frame> : null}
                                    {/* <NotificationButton onClick={onOpenNotificationPopUp} /> */}
                                    <ProfileCircle selected={menuVisible} onClick={() => { setMobileMenuVisible(false); if (window.innerWidth > 600) { setMenuVisible(true) } else { CommonHelper.linkTo(`/user/${currentUser.id}/edit`) } }} >
                                        <ProfileImage selected={menuVisible} />
                                    </ProfileCircle>
                                </>
                        }
                    </RightIconsWrapper>
                    <MobileMenuIcon visible={mobileMenuVisible} onClick={() => { setMobileMenuVisible(!mobileMenuVisible) }} />
                    <MenuPopUpWrapper visible={menuVisible} ref={menuRef} >
                        <Menu />
                    </MenuPopUpWrapper>
                    {/* <NotificationPopUp /> */}
                </Frame>
            </Workspace>
        </Wrapper >
    );
}

const RightIconsWrapper = styled(Frame)`
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        margin-right: 23vw;
    }
`;

const LangText = styled(Frame)`
    cursor: pointer;
    &:hover { opacity: 0.75; transform: scale(1.05); };
    
    > * {
        font-size: 18px;
        &:first-child {
            font-family: bold;
            color: ${props => props.selected ? props.theme.green : props.theme.text.secondary};
        }
        &:last-child {
            cursor: default;
            margin: 0 5px;
            &:hover { };
        }
    }
    
    @media only screen and (max-width: 600px) {
        > * {
            font-size: 5.6vw;
            &:last-child {
                cursor: default;
                margin: 0 1vw;
                &:hover { };
            }
        }
    }
`;

const LangsWrapper = styled(Frame)`
    flex-direction: row;
    margin-right: 10px;

    @media only screen and (max-width: 600px) {
        position: fixed;
        bottom: 0;
        z-index: 2;
        background: ${props => props.theme.background.primary};
        height: 15vw;
        width: 90vw;
        border-top: 1px solid ${props => props.theme.background.secondary};
        margin: 0;
    }
`;

const CenterWrapper = styled(Frame)`
    align-items: space-between;
    flex-direction: row;
    > * {
        margin-left: 40px;
    };
    
    @media only screen and (max-width: 600px) {
        display: flex;
        width: 100vw;
        height: calc(100vh - 60px - 7.8vw - 37.5vw);
        flex-direction: column;
        justify-content: flex-start;

        padding-top: 7.8vw;
        padding-bottom: 37.5vw;

        background: ${props => props.theme.background.primary};

        position: fixed;
        top: 60px;
        left: 0;

        opacity: ${props => props.visible ? 1 : 0};
        visibility: ${props => props.visible ? `visible` : `hidden`};
        overflow: scroll;
        transition: 0.5s;

        > * {
            margin-bottom: 6.25vw;
            margin-left: 0;
            transition: 0.5s;
            ${props => new Array(props.menuLength).fill(0).map((item, index) => `&:nth-child(${index + 1}) { transform: translateY(${props.visible ? 0 : -10 * (index + 1)}px); };`).join(``)}
        }
    }
`;

const MobileMenuIcon = styled(Frame)`
    width: 42px;
    height: 42px;
    border-radius: 21px;
    background: ${props => props.theme.text.secondary};
    position: absolute;
    right: 15px;
    z-index: 2;

    &:after {
        content: '';
        width: 22px;
        height: 18px;
        position: absolute;
        background: url(${require('../../../assets/images/menu_icon.svg')}) center no-repeat ;
        transform: scale(${props => props.visible ? 0 : 1});
        opacity: ${props => +!props.visible};
        transition: 0.2s;
    }

    &:before {
        content: '';
        width: 15px;
        height: 15px;
        position: absolute;
        background: url(${require('../../../assets/images/menu_cros.svg')}) center no-repeat ;
        transform: scale(${props => props.visible ? 1 : 0});
        opacity: ${props => +props.visible};
        transition: 0.2s;
    }

    @media only screen and (min-width: 600px) {
        display: none;
    }
`;

const CalendarImage = styled(Frame)`
    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url("${require(`../../../assets/images/calendar.svg`)}") no-repeat center center;
    }
`;

let ArrowDown = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_down_${props.selected ? `blue` : `black`}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 12px;
    height: 6px;
    margin: 3px 0px 0px 5px;
    transform: rotate(${props => 180 * +props.down}deg);
    transition: 0.2s;
    
    @media only screen and (max-width: 600px) {
        display: none;
    }
`

let MenuImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/menu_${props.name}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    margin-right: 10px;
`

const ProjectsPopUpWrapper = styled(Frame)`
    position: absolute;
    right: 0;
    top: ${props => 44 + 10 * +props.visible}px;
    visibility: ${props => props.visible ? `visible` : `hidden`};
    opacity: ${props => +props.visible};

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

const MenuPopUpWrapper = styled(Frame)`
    position: absolute;
    right: 0;
    top: ${props => 73 + 10 * +props.visible}px;
    visibility: ${props => props.visible ? `visible` : `hidden`};
    opacity: ${props => +props.visible};
    
    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

const ProfileCircle = styled(Frame)`
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    border: 2px solid ${props => props.theme.blue};
    background: ${props => props.selected ? props.theme.blue : `transparent`};
    cursor: pointer;
    margin-left: 20px;
    &:hover { transform: scale(1.05); };
    &:active { transform: scale(1.1); };
`;

let animation = keyframes`
    0% { transform: rotate(0deg) scale(1); }
    33% { transform: rotate(-30deg) scale(1.06); }
    67% { transform: rotate(30deg) scale(1.13); }
    100% { transform: rotate(0deg) scale(1.2); }
`

let ProfileImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/profile_${props.selected ? `white` : `blue`}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;

    @media only screen and (max-width: 600px) {
        width: 7.5vw;
        height: 7.5vw;
    }
`

let NotificationButton = styled.img.attrs((props) => {
    let img
    try { img = require('../../../assets/images/notification.svg') } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: 0.2s;
    margin-left: 20px;
    &:hover {
        animation: ${animation} 0.2s linear 1; 
        transform: scale(1.2);
    };
    &:active {
        transform: scale(1.2);
        opacity: 0.8;
        transform: scale(1.1);
    };
`

const LogoWrapper = styled(Frame)`
    width: 120px;
    height: 120px;
    background: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    ${props => props.theme.shadow.small}
    &:hover {
        > * {
            transform: scale(1.05);
            ${props => props.theme.shadow.none}
        }
    }

    @media only screen and (max-width: 600px) {
        width: 55px;
        height: 44px;
        position: absolute;
        left: 26px;
        top: calc((60px - 44px) / 2);
        z-index: 2;
        ${props => props.theme.shadow.none}
        > * {
            height: 44px;
        }
    }
`;

let Logo = styled.img.attrs((props) => {
    let img
    try { img = require('../../../assets/images/rtu_logo_green.svg') } catch (error) { }
    return ({ src: img, })
})`
    transition: 0.2s;
    width: 86px;
`

const HeaderItem = styled(Frame)`
    font-size: 18px;
    cursor: pointer;
    color: ${props => props.selected ? props.theme.blue : props.theme.text.primary};

    @media only screen and (max-width: 600px) {
        font-size: 5vw;
        height: 10vw;
    }
`;

const Workspace = styled(Frame)`
    width: 1100px;
    height: 94px;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    @media only screen and (max-width: 600px) {
        width: 100vw;
        height: 60px;
    }
`;

const Wrapper = styled(Frame)`
    width: 100vw;
    box-sizing: border-box;
    z-index: 2;
    border-bottom: 1px solid ${props => props.theme.background.secondary};
    position: fixed;
    top: ${props => props.forwardedRef.current ? -props.forwardedRef.current.top : 0}px;
    background: ${props => props.theme.background.primary};
    transition: 0s;

    @media only screen and (max-width: 600px) {
        width: 100vw;
        height: 60px;
        border-bottom-left-radius: ${props => props.mobileMenu ? `0px` : `10px`};
        border-bottom-right-radius: ${props => props.mobileMenu ? `0px` : `10px`};
        ${props => props.theme.shadow[props.mobileMenu ? `none` : `small`]}
        border-bottom: 0px;
        transition: 0.2s;
    }
`;

export default Header;
/*eslint-enable*/
