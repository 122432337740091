/*eslint-disable*/
import React, { useCallback } from 'react';
import styled from 'styled-components'
import { useMappedState } from 'redux-react-hook'
import moment from 'moment-timezone'

import { Frame, Text, Link } from '../../UIKit/styled-templates'

import useDictionary from '../helpers/useDictionary'

let NewsTab = (props) => {

    let { articleId } = props

    let { getText, lang } = useDictionary()

    let { article } = useMappedState(useCallback((state) => ({
        article: { LV: {}, ENG: {}, tags: [], ...state.articles.articlesMap.get(articleId) },
    }), [articleId]))

    return (
        <Link to={`/news/${articleId}`} >
            <Tab>
                <Image src={article[lang].imgSrc} />
                <TextWrapper>
                    <Text>{moment(article.createdTimestamp).format(`DD MMMM, YYYY`)}</Text>
                    <Text>{article[lang].heading}</Text>
                    <Text>{article.tags.map((item, index) => getText(item, `UPPERCASE`).split(`TAG_`).slice(-1)[0]).join(`, `)}</Text>
                </TextWrapper>
            </Tab>
        </Link>
    );
}

const TextWrapper = styled(Frame)`
    width: 270px;
    height: 170px;
    margin: 31px;
    justify-content: space-between;
    align-items: flex-start;

    > * {
        &:nth-child(1) {
            font-size: 16px;
            line-height: 16px;
            color: ${props => props.theme.blue};
        }
        &:nth-child(2) {
            font-size: 18px;
            line-height: 30px;
        }
        &:nth-child(3) {
            font-size: 13px;
            line-height: 14px;
            color: ${props => props.theme.text.secondary};
        }
    }

    @media only screen and (max-width: 600px) {
        width: 70vw;
        height: 43vw;
        margin: 10vw;

        > * {
        &:nth-child(1) {
            font-size: 3.75vw;
            line-height: 5vw;
            color: ${props => props.theme.blue};
        }
        &:nth-child(2) {
            font-size: 4vw;
            line-height: 6vw;
        }
        &:nth-child(3) {
            font-size: 3.75vw;
            line-height: 4.3vw;
            color: ${props => props.theme.text.secondary};
        }
    }
    }
`;

const Image = styled(Frame)`
    width: 350px;
    height: 239px;
    background: ${props => props.theme.background.secondary};
    background-image: url(${props => props.src ? props.src : null});
    background-size: cover;
    background-position: center;
`;

const Tab = styled(Frame)`
    width: 350px;
    height: 470px;
    background: white;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.05);
    &:hover {
        box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
    }

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: 120vw;
    }
`;

export default NewsTab;

/*eslint-enable*/