/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Circles from '../tools/Circles'

import { Frame, Text, H, P } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import CommonHelper from '../../../helpers/CommonHelper'
import useDictionary from '../helpers/useDictionary'

let ConnectSolveDevelope = () => {

    let { getText } = useDictionary()

    return (
        <Workspace>
            <Left>
                <H>{getText(`create_solve_title`)}</H>
                <P>
                    {getText(`create_solve_text`)}
                </P>
                <ButtonsWrapper>
                    <Button background={props => props.theme.blue} extra={`margin-right: 20px;`} onClick={() => { CommonHelper.linkTo(`/signup/student`) }} >
                        {getText(`sign_up_students`)}
                    </Button>
                    <Button background={props => props.theme.red} onClick={() => { CommonHelper.linkTo(`/signup/company`) }} >
                        {getText(`sign_up_company`)}
                    </Button>
                </ButtonsWrapper>
            </Left>
            <Right>
                <Circles width={window.innerWidth > 600 ? 600 : window.innerWidth * 0.9} contentArray={orbitPictures} exaption />
                <Image index={3} extra={`position: absolute; transform: scale(2); z-index: 1;`} />
            </Right>
        </Workspace>
    );
}

const ButtonsWrapper = styled(Frame)`
    flex-direction: row;
    justify-content: flex-start;
    
    @media only screen and (max-width: 600px) {
        > * {
            font-size: 3vw;
        }
    }
`;

let Image = styled(Frame)`
    width: 200px;
    height: 200px;
    background-image: url(${props => require(`../../../assets/landing/ConnectSolveDevelope_${props.index}.png`)});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    ${props => props.extra}
    ${props => props.index === 1 ? `transform: scale(0);` : null}

    @media only screen and (max-width: 600px) {
        width: 25vw;
        height: 25vw;
        ${props => props.index < 3 ? `transform: scale(0);` : null}
    }
`

let orbitPictures = new Array(3).fill(0).map((item, index) => ({
    component: <Image index={index} />,
    offsetX: window.innerWidth > 600 ? 125 : window.innerWidth * 0.25,
    offsetY: window.innerWidth > 600 ? 125 : window.innerWidth * 0.25,
}))

const Workspace = styled(Frame)`
    width: 1100px;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 100vw;
        flex-direction: column;
    }
`;

const Left = styled(Frame)`
    width: 436px;
    height: 331px;
    align-items: flex-start;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

const Right = styled(Frame)`
    width: 630px;
    height: 630px;
    position: relative;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: 130vw;
    }
`;

export default ConnectSolveDevelope;
/*eslint-enable*/