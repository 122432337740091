/*eslint-disable*/

let additional = {
    shadow: {
        small: `box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07);`,
        none: `box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);`,
        blue: `box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);`,
    }
}

let basePalette = {

    red: `#FE7563`,
    yellow: `#FDCA21`,
    green: `#5CC956`,
    blue: `#578AD6`,
    rtu_green: `#005551`,
}

export let dayTheme = {
    ...basePalette,
    ...additional,
    background: {
        primary: `#ffffff`,
        secondary: `#EEEEEE`,
        support: `#d5d5d5`,
    },
    text: {
        primary: `#374A5E`,
        secondary: `#767676`,
        support: `#939393`,
    }
}

export let darkTheme = {
    ...basePalette,
    ...additional,
    background: {
        primary: `#3A3A3A`,
        secondary: `#2B2B2B`,
        support: `#A3A3A3`,
    },
    text: {
        primary: `#fff`,
        secondary: `#fff`,
        support: `#939393`,
    }
}

export default {
    light: dayTheme,
    dark: darkTheme,
    theme_shift: {
        system: `system`,
        disabled: `scheduled`,
    },
}

export let menuItems = {
    guest: {
        projects: false,
        programs: false,
        news: false,
        login: false,

    },
    student: {
        projects: false,
        companies: false,
        news: false,
        programs: false,

    },
    coordinator: {
        programs: false,
        news: false,

    },
    company: {
        news: false,
        applications: false,

    },
    admin: {
        dictionary: false,
    }
}
/*eslint-enable*/