/*eslint-disable*/
import React, { useState, useCallback } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'

import { Frame, P, Text } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import CompanyTab from '../tools/company-tab'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'

let animatedComponents = makeAnimated();

let categories = [`First`, `Second`, `Third`].map(item => ({ value: item, label: item }))

let StudentsList = () => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let { getText } = useDictionary()

    let [search, setSearch] = useState(``)
    let [category, setCategory] = useState([]);

    let { students } = useMappedState(useCallback(state => ({
        students: state.users.usersMap.toArray().filter(i => i.userRole === `student` || i.userRole === `admin`),
    }), []))

    return (
        <Wrapper>
            <FilterToolsWrapper>
                <Input value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder={getText(`save`)} image={`search`} extra={`border-radius: 25px; width: 601px;`} />
            </FilterToolsWrapper>

            <StudentsWrapper>
                {
                    students
                        .filter((i, j) => ((i.info.name + i.info.last_name + i.email) || ``).toLowerCase().indexOf(search.split(` `).join(``).toLowerCase()) > -1 || search === ``)
                        .map((item, index) => {
                            let studentInfo = { ...item.info }
                            return (
                                <StudentTabWrapper key={index} extra={`margin-top: 15px;`} >
                                    <Frame row onClick={() => { CommonHelper.linkTo(`/user/${item.id}`) }} >
                                        <UserAvatar src={studentInfo.avatarUrl} />
                                        <Frame extra={`align-items: flex-start;`} >
                                            <Text row extra={props => `font-size: 12px; line-height: 16px; color: ${props.theme.text.secondary};`} >
                                                {studentInfo.name} {studentInfo.last_name} {item.userRole === `admin` ? `(admin)` : ``} {item.status === `blocked` ? `(blocked)` : ``}
                                                {item.verified ? <VerifiedSign /> : null}
                                            </Text>
                                            <Text extra={props => `font-size: 10px; line-height: 14px; color: ${props.theme.text.secondary}; opacity: 0.75;`} >{item.username}</Text>
                                        </Frame>
                                    </Frame>
                                    <Frame extra={`margin-right: 15px;`} onClick={() => { CommonHelper.linkTo(`/user/${item.id}/edit`) }} >
                                        <EditButton name={`edit`} onClick={() => { CommonHelper.linkTo(`/user/${item.id}/edit`) }} />
                                    </Frame>
                                </StudentTabWrapper>
                            )
                        })
                }
            </StudentsWrapper>

        </Wrapper>
    );
}

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 18px; height: 18px; margin-left: 10px; `

const EditButton = styled(Frame)`
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border: 2px solid ${props => props.theme.text.primary};
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url(${props => require(`../../../assets/images/project-edit-button-${props.name}.svg`)}) no-repeat;
    }
`;

const UserAvatar = styled(Frame)`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 8px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});
    background-position: center;
    margin-left: 15px;
`;

const StudentTabWrapper = styled(Frame)`
    width: 49%;
    height: 80px;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 12px;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.05);
    &:hover {
        box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
    }
`;

const StudentsWrapper = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 1100px;
    margin-top: 34px;
`;

const Wrapper = styled(Frame)`
    min-height: 100vh;
    justify-content: flex-start;
`;

const FilterToolsWrapper = styled(Frame)`
    width: 1100px;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;
`;

export default StudentsList;

/*eslint-enable*/