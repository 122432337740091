/*eslint-disable*/
import React, { useCallback } from 'react';
import styled from 'styled-components'
import { useMappedState } from 'redux-react-hook'

import { Frame, Text, P, H } from '../../UIKit/styled-templates'
import Circles from '../tools/Circles'
import NewsTab from '../tools/NewsTab'

import useDictionary from '../helpers/useDictionary'

let HowItWorks = () => {

    let { getText, lang } = useDictionary()

    let { articles } = useMappedState(useCallback((state) => ({
        articles: state.articles.articlesMap
            .filter(i => Object.values(i[lang]).filter(i => typeof (i) === `string` && i !== ``).length >= 3)
            .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
            .toArray(),
    }), [lang]))

    return (
        <Workspace>
            <H>
                {getText(`news_title`)}
            </H>
            <P extra={`width: 90vw; text-align: center;`}>
                {getText(`news_text`)}
            </P>
            <ScrollWrapper>
                <NewsWrapper>
                    {
                        articles.filter((a, b) => b < 3).map((item, index) => <NewsTab articleId={item.id} key={index} />)
                    }
                </NewsWrapper>
            </ScrollWrapper>
        </Workspace>
    );
}

const ScrollWrapper = styled(Frame)`
    @media only screen and (max-width: 600px) {
        display: block;
        max-width: 100vw;
        overflow-x: scroll;
    }
`;

const NewsWrapper = styled(Frame)`
    /* flex-wrap: wrap; */
    flex-direction: row;
    justify-content: center;
    width: 1100px;
    > * {
        margin-top: 30px;
        &:nth-child(3n + 2) {
            margin: 30px 20px 0 20px;
        }
    }

    @media only screen and (max-width: 600px) {
        width: auto;
    }
`;

const Tab = styled(Frame)`
    width: 350px;
    height: 470px;
    background: white;
    border-radius: 12px;
    box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07);
    overflow: hidden;
    &:hover {
        box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
    }
`;

const Workspace = styled(Frame)`
    width: 1100px;
    height: 640px;
    margin-top: 70px;
    margin-bottom: 100px;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 100vw;
        height: auto;
        margin-bottom: 10vw;
    }
`;
export default HowItWorks;

/*eslint-enable*/