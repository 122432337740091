/*eslint-disable*/
import React, { useState } from 'react';
import styled from 'styled-components'

import { Frame, Text, convertHex, P } from '../../UIKit/styled-templates'

let Contacts = (props) => {

    let { info = {} } = props

    let links = {
        facebook: info.facebook ? info.facebook : undefined,
        linkedin: info.linkedin ? info.linkedin : undefined,
    }

    return (
        <Wrapper>
            <Frame row>
                {
                    Object.keys(links).map((item, index) => {
                        if (links[item] !== undefined) {
                            let link = links[item].split(`https://`).filter(i => i.split(``).length > 0)[0].split(`www.`).filter(i => i.split(``).length > 0)[0]
                            return (
                                <Frame
                                    key={index}
                                    extra={props => `margin-right: 16px; width: 30px; height: 30px; border-radius: 15px; box-sizing: border-box; border: 1px solid ${props.theme.background.secondary}; &:hover { opacity: 0.8; }; cursor: pointer;`}
                                    onClick={() => { window.open(`https://${link}`); }}
                                >
                                    <ShareLogo name={item} />
                                </Frame>
                            )
                        } else {
                            return null
                        }
                    })
                }
            </Frame>
            {info.phone ? <Frame row ><PhoneImage /><P extra={`margin: 0px;`} >{info.phone}</P></Frame> : null}
            {info.location ? <Frame row ><LocationImage /><P extra={`margin: 0px;`} >{info.location}</P></Frame> : null}
            {info.email ? <Frame row ><EmailImage /><P extra={props => `margin: 0px; color: ${props.theme.text.primary};`} >{info.email}</P></Frame> : null}
        </Wrapper>
    );
}

const Wrapper = styled(Frame)`
    flex-direction: row;
    margin-bottom: 15px;
    
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        width: 90vw;
        margin: 0;
        align-items: flex-start;
    }
`;

let EmailImage = styled.img.attrs(() => {
    let img
    try { img = require(`../../../assets/images/email.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin: 0 15px 0 0px; @media only screen and (max-width: 600px) { margin: 5vw 5vw 10vw 0; }`

let LocationImage = styled.img.attrs(() => {
    let img
    try { img = require(`../../../assets/images/location.png`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin: 0 15px 0 30px; @media only screen and (max-width: 600px) { margin: 5vw 5vw 10vw 0; }`

let PhoneImage = styled.img.attrs(() => {
    let img
    try { img = require(`../../../assets/images/phone.png`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin: 0 15px 0 30px; @media only screen and (max-width: 600px) { margin: 5vw 5vw 10vw 0; }`

let ShareLogo = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/share_${props.name}.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 15px; height: 15px; `

export default Contacts;

/*eslint-enable*/