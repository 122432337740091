/*eslint-disable*/
import axios from 'axios'
import { API_ENDPOINT } from "../constants/config";

const EmailsAPI = {

    getEmails() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/dictionary/emails`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result.value);
            }).catch(err => reject(err));
        });
    },
    
    setEmails(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/dictionary/emails`, { value: data }).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

}

export default EmailsAPI;

/*eslint-enable*/