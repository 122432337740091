/*eslint-disable*/
import * as types from '../ActionTypes'

const initialState = {
    lang: localStorage.getItem(`lang`) || `ENG`,
    logOutVisible: false,
    projectRequestVisible: false,
}

const UIReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.OPEN_LOG_OUT: return { ...state, logOutVisible: true }
        case types.CLOSE_LOG_OUT: return { ...state, logOutVisible: false }

        case types.OPEN_PROJECT_REQUEST: return { ...state, projectRequestVisible: { visible: true, info: action.info } }
        case types.CLOSE_PROJECT_REQUEST: return { ...state, projectRequestVisible: { visible: false, info: undefined } }

        case types.SET_LANG: return { ...state, lang: action.lang || `ENG` }

        default:
            return state;

    }

}

export default UIReducer;

/*eslint-enable*/