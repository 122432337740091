/*eslint-disable*/
/**
 * Created by sabir on 19.07.17.
 */

import { Map, Stack, Set } from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    projectsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined }
}

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error }
}

const ProjectsReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.DELETE_PROJECT_APPLICATION_SUCCESS:
            return {
                ...state,
                projectsMap: action.projects.reduce((map, form) => map.set(form.id, form), Map())
            }

        case types.CREATE_PROJECT:
        case types.UPDATE_PROJECT:
        case types.UPDATE_APPLICATION_STATUS:
        case types.DELETE_PROJECT:
        case types.LOAD_PROJECTS:
        case types.APPLY_TO_PROJECT:
        case types.DELETE_APPLICATION:
        case types.UPDATE_APPLICATION:
        case types.DELETE_PROJECT_APPLICATION:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_PROJECT_FAIL:
        case types.UPDATE_PROJECT_FAIL:
        case types.UPDATE_APPLICATION_STATUS_FAIL:
        case types.DELETE_PROJECT_FAIL:
        case types.LOAD_PROJECTS_FAIL:
        case types.APPLY_TO_PROJECT_FAIL:
        case types.DELETE_APPLICATION_FAIL:
        case types.UPDATE_APPLICATION_FAIL:
        case types.DELETE_PROJECT_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_PROJECT_SUCCESS:
        case types.UPDATE_PROJECT_SUCCESS:
        case types.UPDATE_APPLICATION_STATUS_SUCCESS:
        case types.APPLY_TO_PROJECT_SUCCESS:
        case types.DELETE_APPLICATION_SUCCESS:
        case types.UPDATE_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                projectsMap: state.projectsMap.set(action.project.id, action.project)
            }

        case types.DELETE_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                projectsMap: state.projectsMap.delete(action.id),
            }


        case types.LOAD_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                projectsMap: state.projectsMap
                    .merge(action.projects.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default ProjectsReducer;

/*eslint-enable*/