/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useMappedState, useDispatch } from 'redux-react-hook'

import * as dictionaryActions from '../../../redux/actions/DictionaryActions'
import { Frame, Text, Button } from '../../UIKit/styled-templates'
import CommonHelper from '../../../helpers/CommonHelper'

import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'

let DictionaryList = (props) => {

    let { dictionaryList } = useMappedState(useCallback(state => {
        return ({
            dictionaryList: state.dictionary.dictionaryMap.toArray(),
        })
    }, []))

    let { getText } = useDictionary()

    let dispatch = useDispatch()

    let { currentUser } = useCurrentUser()

    let goodUsers = [`mityabeldii@gmail.com`, `sha-sabir@yandex.ru`]

    let onOpenDictionaryCreatePopUp = () => {
        window.dispatchEvent(new CustomEvent(`OPEN_DICTIONARY_CREATE_POP_UP`))
    }

    let onDeleteDictionary = (id) => dispatch(dictionaryActions.deleteDictionary(id))

    return (
        <Wrapper>
            {
                goodUsers.indexOf(currentUser.email) > -1
                    ? <Button extra={`margin-bottom: 15px;`} onClick={onOpenDictionaryCreatePopUp} >{getText(`New dictionary`)}</Button>
                    : null
            }
            <Frame row extra={css`flex-wrap: wrap; width: 100%; height: 100%; justify-content: space-between; align-items: space-between;`} >
                {
                    dictionaryList.map((item, index) => {
                        return (
                            <DictionaryWrapper key={index} onClick={(e) => { if (e.target.id !== `trash-can`) { CommonHelper.linkTo(`/dictionary/${item.id}`) } }} >
                                <Text extra={`margin-left: 20px;`} >{item.name}</Text>
                                {
                                    goodUsers.indexOf(currentUser.email) > -1
                                        ? <TrashCan id={`trash-can`} onClick={() => { onDeleteDictionary(item.id) }} />
                                        : null
                                }
                            </DictionaryWrapper>
                        )
                    })
                }
            </Frame>
        </Wrapper>
    )
}

const DictionaryWrapper = styled(Frame)`
    width: 49%;
    height: 60px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    margin-bottom: 10px;
    justify-content: space-between;
    flex-direction: row;
    &:hover {
        box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
    }
    &:hover {
        > * {
            transform: scale(1);
        }
    }
`;

let TrashCan = styled(Frame)`
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 15px;
    background: url(${require(`../../../assets/images/trash_can.svg`)});
    transform: scale(0);
`

const Wrapper = styled(Frame)`
    width: 815px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 125px;
    min-height: calc(100vh - 300px);
`;

export default DictionaryList;
/*eslint-enable*/