/*eslint-disable*/
import axios from 'axios'
import { API_ENDPOINT } from "../constants/config";

const ProjectsAPI = {

    getAllProjects() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/projects`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getProject(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/projects/${id}`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    createProject(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/projects`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    updateProject(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/projects`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                axios.get(`${API_ENDPOINT}/projects/${data.id}`)
                    .then(d => d.data)
                    .then(pld => { resolve(pld.result); })
            }).catch(err => reject(err));
        });
    },

    updateApplicationStatus(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/project_applications/${data.id}/status`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                axios.get(`${API_ENDPOINT}/projects/${ex.result.projectId}`)
                    .then(d => d.data)
                    .then(pld => { resolve(pld.result); })
            }).catch(err => reject(err));
        });
    },

    updateApplication(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/project_applications/${data.id}`, { ...data }).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                axios.get(`${API_ENDPOINT}/projects/${ex.result.projectId}`)
                    .then(d => d.data)
                    .then(pld => { resolve(pld.result); })
            }).catch(err => reject(err));
        });
    },

    deleteProject(id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/projects`, { data: { id: id } }).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    applyToProject(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/projects/${data.id}/apply`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                axios.get(`${API_ENDPOINT}/projects/${data.id}`)
                    .then(d => d.data)
                    .then(pld => { resolve(pld.result); })
            }).catch(err => reject(err));
        });
    },

    deleteApplication(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/projects/${id}/remove_application`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                axios.get(`${API_ENDPOINT}/projects/${id}`)
                    .then(d => d.data)
                    .then(pld => { resolve(pld.result); })
            }).catch(err => reject(err));
        });
    },

    deleteProjectApplication(id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/project-application`, { data: { id: id } }).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                axios.get(`${API_ENDPOINT}/projects`)
                    .then(d => d.data)
                    .then(pld => { resolve(pld.result); })
            }).catch(err => reject(err));
        });
    },

}

export default ProjectsAPI;

/*eslint-enable*/