/*eslint-disable*/
import React, {  } from 'react';
import styled from 'styled-components'

import { Frame, Text, convertHex, } from '../../UIKit/styled-templates'

const PhotoPreview = (props) => {
    return (
        <PhotoPreviewWrapper>
            {props.children}
            <Frame onClick={props.onDelete} extra={`width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index: 0; position: absolute; border: 12px; opacity: 0;`} >
                <TrashCan />
            </Frame>
        </PhotoPreviewWrapper>
    )
}

const PhotoPreviewWrapper = styled(Frame)`
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    &:hover {
        > * {
            &:first-child {
                filter: blur(4px);
            };
            &:last-child {
                opacity: 1;
                transform: scale(1.2);
            };
        };
    };
`;

let TrashCan = styled.img.attrs(() => {
    let img
    try { img = require(`../../../assets/images/trash_can_red.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 30px; height: 30px; cursor: pointer; transition: 0.2s; &:hover { transform: scale(1.2); };`

export default PhotoPreview;

/*eslint-enable*/