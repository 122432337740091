/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import { useDispatch, useMappedState } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import * as programsActions from '../../../redux/actions/ProgramsActions'

import { Frame, Input, H1, Button, AddSquare, convertHex, Checkbox, P, Textarea } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import Dropzone from '../../UIKit/Dropzone'
import PopUpWrapper from './PopUpWrapper'
import PhotoPreview from '../tools/PhotoPreview'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch from '../helpers/useCustomDispatch'
import useCurrentUser from '../helpers/useCurrentUser'

let StudentCommentPopUp = () => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let currentUser = useCurrentUser()
    let { getText, lang } = useDictionary(() => { })

    let { program } = useMappedState(useCallback(state => ({
        program: { info: {}, coordinatorsIds: [], ...state.programs.programsMap.get(path[1]) },
    }), [path[1]]))

    let onClose = () => { window.dispatchEvent(new CustomEvent(`CLOSE_STUDENT_COMMENT_POP_UP`)) }

    let [studentComment, setStudentComment] = useState({})

    useCustomDispatch(`OPEN_STUDENT_COMMENT_POP_UP`, (e) => { setStudentComment(e.detail || {}) })
    useCustomDispatch(`CLOSE_STUDENT_COMMENT_POP_UP`, (e) => { setStudentComment(``) })

    return (
        <PopUpWrapper name={`STUDENT_COMMENT`}>
            <H1 extra={`@media only screen and (max-width: 600px) { width: 90vw; };`} >{getText(`Student comment`)}</H1>
            <Frame extra={`width: 100%; align-items: flex-start; * { align-items: flex-start; };`} >
                {
                    Object.keys(studentComment || {}).map((item, index) => {
                        return (
                            <Frame key={index} extra={`width: 500px; align-items: flex-start;`} >
                                <P extra={props => `font-weight: bold; color: ${props.theme.text.primary};`} >{item}</P>
                                <P>{Array.isArray(studentComment[item]) ? studentComment[item].map((a, b) => {
                                    return (
                                        <P key={b} >
                                            {
                                                typeof (a) === `object` ? <>
                                                    <FileImage onClick={() => { window.open(a.url) }} />
                                                    <P extra={`width: 120px; overflow: ellipsis; overflow: hidden; white-space: nowrap; display: inline;`} >{a.name}</P>
                                                </> : a
                                            }
                                        </P>
                                    )
                                }) : studentComment[item]}</P>
                            </Frame>
                        )
                    })
                }
            </Frame>
        </PopUpWrapper >
    );
}

const FileImage = styled(Frame)`
    width: 120px;
    height: 120px;
    border-radius: 12px;
    background: ${props => convertHex(props.theme.blue, 0.1)};
    cursor: pointer;
    &:after {
        content: '';
        width: 36px;
        height: 36px;
        background: url("${require(`../../../assets/images/file_blue.svg`)}");
    }
`;

const Avatar = styled(Frame)`
    width: 132px;
    height: 132px;
    background-image: url("${props => props.src}");
    background-position: center;
`;

export default StudentCommentPopUp;

/*eslint-enable*/