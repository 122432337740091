/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import moment from 'moment'

import { useDispatch, useMappedState } from 'redux-react-hook'

import { Frame, Text, convertHex, P, H1, H } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import TagsInput from '../../UIKit/TagsInput'
import CommonHelper from '../../../helpers/CommonHelper';

import useDictionary from '../helpers/useDictionary'
import useCurrentUser from '../helpers/useCurrentUser'

let FiltersScreen = (props) => {

    let { visible = false, setVisible = () => { }, onApplyFilters = () => { } } = props

    let { getText, dict } = useDictionary()
    let projectStatuses = Object.keys(dict).filter(i => i.indexOf(`project_status_`) > -1)

    let [duration, setDuration] = useState(null);
    let [status, setStatus] = useState(null);
    let [category, setCategory] = useState(null);

    let durations = [
        { value: 1, label: getText(`more than 1 months`) },
        { value: 3, label: getText(`more than 3 months`) },
        { value: 6, label: getText(`more than 6 months`) },
    ]

    // useEffect(() => {
    //     document.getElementsByTagName(`body`)[0].style.overflowY = visible ? `hidden` : `scroll`
    // }, [visible])

    return (
        <Wrapper visible={visible} >
            <Frame extra={`width: 100%; height: auto; align-items: flex-start;`} >
                <Frame extra={`width: 90vw; flex-direction: row; justify-content: space-between;`} >
                    <H1 extra={`margin: 0 !important;`} >{getText(`Filter`)}</H1>
                    <Cros onClick={() => { onApplyFilters({ duration, category, status }); setVisible(false); setVisible(false) }} />
                </Frame>
                <Frame extra={props => `font-size: 5vw; margin: 5.75vw 0 5vw 0; color: ${props.theme.text.primary};`} >{getText(`Category`)}</Frame>
                <TagsInput
                    selectedTagsArray={category ? [category.value] : []}
                    onChange={(e) => { setCategory(e.length > 0 ? { value: e[e.length - 1], label: getText(e[e.length - 1]) } : null) }}
                />
                <Frame extra={props => `font-size: 5vw; margin: 5.75vw 0 5vw 0; color: ${props.theme.text.primary};`} >{getText(`Duration`)}</Frame>
                {
                    durations.map((item, index) => {
                        let selected = duration && duration.label === item.label
                        return (
                            <Frame key={index} row onClick={() => { setDuration(selected ? null : item) }} >
                                <RadioButton selected={selected} />
                                <P extra={`margin: 0 !important;`} >{item.label}</P>
                            </Frame>
                        )
                    })
                }
                <Frame extra={props => `font-size: 5vw; margin: 6.75vw 0 5vw 0; color: ${props.theme.text.primary};`} >{getText(`Status`)}</Frame>
                {
                    (projectStatuses.map(i => ({ value: i, label: getText(i) })) || []).map((item, index) => {
                        let selected = status && status.label === item.label
                        return (
                            <Frame key={index} row onClick={() => { setStatus(selected ? null : item) }} >
                                <RadioButton selected={selected} />
                                <P extra={`margin: 0 !important;`} >{item.label}</P>
                            </Frame>
                        )
                    })
                }
                <Frame extra={`width: 100%; margin: 5vw 0 20vw 0;`} >
                    <Button background={props => props.theme.green} shaped extra={`width: 50vw;`} onClick={() => { onApplyFilters({ duration, category, status }); setVisible(false); }} >{getText(`Apply`)}</Button>
                </Frame>
            </Frame>
        </Wrapper>
    );
}

const RadioButton = styled(Frame)`
    width: 6.75vw;
    height: 6.75vw;
    border-radius: 50%;
    border: ${props => props.selected ? 3 : 2}px solid ${props => props.selected ? props.theme.green : props.theme.background.support};
    box-sizing: border-box;
    margin: 2.5vw 2.5vw 2.5vw 0;
    transition: 0.2s;

    &:after {
        content: '';
        width: 3.75vw;
        height: 3.75vw;
        border-radius: 50%;
        background: ${props => props.theme.green};
        position: absolute;
        transform: scale(${props => props.selected ? 1 : 0});
        opacity: ${props => +props.selected};
        transition: 0.2s;
    }
`;

const Wrapper = styled(Frame)`
    width: 90vw;
    min-height: calc(100vh - 10vw);
    height: 100vh;
    display: block;
    overflow-y: scroll;
    padding: 5vw;
    background: ${props => props.theme.background.primary};
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    align-items: flex-start;
    justify-content: flex-start;
    opacity: ${props => +props.visible};
    visibility: ${props => props.visible ? `visible` : `hidden`};
    transition: 0.2s !important;

    @media only screen and (min-width: 600px) {
        display: none;
    }
`;

const Cros = styled(Frame)`
    width: 15px;
    height: 15px;
    background: url("${require(`../../../assets/images/cros.svg`)}") no-repeat center center;
    opacity: 0.3;
`;

export default FiltersScreen;
/*eslint-enable*/