/*eslint-disable*/
import React from 'react';
import styled, { keyframes } from 'styled-components'

import { Frame } from '../../UIKit/styled-templates'

let { round, random, cos, sin, PI, abs } = Math

let animation = () => {
    let n = 360
    let startAngel = random() * PI * 2
    let r = random() * 200
    return keyframes`
        ${new Array(n + 1).fill(0).map((item, index) => {
        let phi = 2 * PI / n * index + startAngel
        return `${1 / n * 100 * index}% { opacity: ${abs(index / n - 0.5)}; transform: translate(${r * cos(phi)}px, ${r * sin(phi)}px); }`
    }).join(`\n`)}
    `;
}


let FlyingCircles = (props) => {
    if (window.innerWidth <= 600) {
        return null
    }
    return new Array(props.circlesNumber || 20).fill(0).map((item, index) => {
        let left = (window.innerWidth - 1200) / 2 * random() + (1200 / 2 + window.innerWidth / 2) * +(index % 2 === 0) - 20
        if (left > window.innerWidth) {
            return null
        }
        return <Circle
            key={index}
            duration={random() * 20 + 20}
            extra={props => `position: absolute; top: ${100 + 6596 * random()}px; left: ${left}px; width: 40px; height: 40px; border-radius: 50%; background: ${props.theme[[`blue`, `red`, `accept`, `lightblue`][round(random() * 4)]]};`}
        />
    })
}

const Circle = styled(Frame)`
    // animation: ${animation()} ${props => props.duration}s linear infinite;
    z-index: 0;
`;

export default FlyingCircles;
/*eslint-enable*/