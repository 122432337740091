/*eslint-disable*/
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'
import axios from 'axios'

import * as uiActions from '../../../redux/actions/UIActions'
import * as projectsActions from '../../../redux/actions/ProjectsActions'
import { Frame, Text, H1, P, Textarea } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import PopUpWrapper from './PopUpWrapper'
import CommonHelper from '../../../helpers/CommonHelper';
import mvConsts from '../../../constants/mvConsts'

import useCurrentUser from '../helpers/useCurrentUser'
import useCustomDispatch from '../helpers/useCustomDispatch'
import useComponentSize from '../helpers/useComponentSize'
import useDictionary from '../helpers/useDictionary'

let AddCoordinatorPopUp = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let history = useHistory()

    let { currentUser } = useCurrentUser()
    let { getText } = useDictionary()

    let [localProgram, setLocalProgram] = useState({ coordinatorsIds: [] })
    let { program } = useMappedState(useCallback((state) => ({ program: { coordinatorsIds: [], info: {}, ...state.programs.programsMap.get(path[1]) } }), [path[1]]))

    useEffect(() => { setLocalProgram({ coordinatorsIds: [], ...program }) }, [program])
    useCustomDispatch(`ADD_COORDINATOR_${path[1]}`, (e) => { setLocalProgram({ ...localProgram, coordinatorsIds: [...localProgram.coordinatorsIds, e.detail.id].filter((i, b, self) => self.filter(j => j === i).length === 1) }) })
    let { coordinators } = useMappedState(useCallback((state) => ({ coordinators: [...state.users.usersMap.toArray().filter(i => i.userRole === `coordinator`)] }), []))

    // useCustomDispatch(`OPEN_APPLICATION_POP_UP`, (e) => { setApplication(e.detail); })
    // useCustomDispatch(`CLOSE_APPLICATION_POP_UP`, (e) => { })

    let dispatch = useDispatch()

    let onAddCoordinatorToProgram = (id) => { window.dispatchEvent(new CustomEvent(`ADD_COORDINATOR_${path[1]}`, { detail: { id: id } })) }

    return (
        <PopUpWrapper name={`ADD_COORDINATOR`} >
            <H1>{getText(`Coordinators`)}</H1>
            <ListWrapper>
                {
                    coordinators.map((item, index) => {
                        let alreadyAdded = localProgram.coordinatorsIds.indexOf(item.id) > -1
                        return (
                            <CoordinatorWrapper key={index} >
                                <Frame row >
                                    <Avatar src={item.info.avatarUrl} />
                                    <CoordinatorName>
                                        {`${item.info[`name`]} ${item.info[`last_name`]}`}
                                    </CoordinatorName>
                                </Frame>
                                <AddButton alreadyAdded={alreadyAdded} onClick={() => { onAddCoordinatorToProgram(item.id) }} >{getText(alreadyAdded ? `Remove` : `Add`)}</AddButton>
                            </CoordinatorWrapper>
                        )
                    })
                }
            </ListWrapper>
        </PopUpWrapper>
    );
}

const AddButton = styled(Frame)`
    padding: 8px 16px;
    border-radius: 12px;
    font-size: 12px;
    color: ${props => props.alreadyAdded ? props.theme.red : `white`};
    background: ${props => props.alreadyAdded ? `transparent` : props.theme.green};
    border: 1px solid ${props => props.alreadyAdded ? props.theme.red : props.theme.green};
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

let TrashCan = styled(Frame)`
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url(${require(`../../../assets/images/trash_can.svg`)});
`

const CoordinatorName = styled(Frame)`
    color: ${props => props.theme.text.secondary};
    font-size: 12px;
`;

const CoordinatorWrapper = styled(Frame)`
    width: 400px;
    height: 60px;
    padding: 0 15px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    border: 1px solid ${props => props.theme.background.secondary};
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: space-between;
    &:hover {
        ${props => props.theme.shadow.blue}
    }
`;

const ListWrapper = styled(Frame)`
    display: block;
    height: 50vh;
    overflow-y: scroll;
    padding: 0 10vw;
    margin: 0 -10vw;
`;

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 36px;
    height: 36px;
    margin-left: 22px;

    @media only screen and (max-width: 600px) {
        width: 7.5vw;
        height: 7.5vw;
    }
`

let ArrowRight = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/arrow_down_${props.selected ? `blue` : `black`}.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 12px; height: 6px; margin: 0px 0px 0px 5px; transform: rotate(-90deg); transition: 0.2s; opacity: 0.6; `

const Avatar = styled(Frame)`
    width: 45px;
    height: 45px;
    border-radius: 65px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});
    background-position: center;
    margin-right: 8px;

    @media only screen and (max-width: 600px) {
        width: 20vw;
        height: 20vw;
    }
`;

export default AddCoordinatorPopUp;

/*eslint-enable*/