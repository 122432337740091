/*eslint-disable*/
import React, { useState, useCallback } from 'react';
import styled, { keyframes } from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation } from 'react-router-dom'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { ReactSortable } from "react-sortablejs";
import axios from 'axios'

import * as programsActions from '../../../redux/actions/ProgramsActions'

import { Frame, Text, Button, Input, Link, H1, H } from '../../UIKit/styled-templates'
import CommonHelper from '../../../helpers/CommonHelper'
import Circles from '../tools/Circles'
import ProgramTab from '../tools/program-tab'
import { API_ENDPOINT } from "../../../constants/config";

import useCustomDispatch from '../helpers/useCustomDispatch'
import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'

// let programs = [
//     { name: `Co.Lab`, color: `#593E6C`, },
//     { name: `Demola`, color: `#ED1C24`, },
//     { name: `Product project`, color: `#A7A9AC`, },
//     { name: `University`, color: `#FFCA37`, },
//     { name: `Vertikal`, color: `#46AEB1`, },
//     { name: `Doktors`, color: `#414042`, },
//     { name: `IdeaLab`, color: `#FFCA37`, },
//     { name: `Creative`, color: `#ED1C24`, },
// ]

let colors = [
    `#593E6C`,
    `#ED1C24`,
    `#A7A9AC`,
    `#FFCA37`,
    `#46AEB1`,
    `#414042`,
    `#FFCA37`,
    `#ED1C24`,
]

let ProgramsList = () => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let dispatch = useDispatch()
    let { currentUser } = useCurrentUser()
    let { getText } = useDictionary()

    let [reorderMode, setRoderMode] = useState(false)

    let { programs } = useMappedState(useCallback(state => {
        return ({
            programs: state.programs.programsMap.toArray().sort((a, b) => a.index - b.index).filter(i => currentUser.userRole !== `coordinator` || i.coordinatorsIds.indexOf(currentUser.id) > -1),
        })
    }, [path[1]]))

    let updateProgramsOrder = async (new_programs) => {
        if (new_programs.map(i => i.id).join(``) !== programs.map(i => i.id).join(``)) {
            new_programs = new_programs.map((i, index) => ({ ...i, index: index }))
            dispatch(programsActions.updateProgramsOrder(new_programs))
            await new_programs.forEach(async (program, index) => {
                await axios.put(`${API_ENDPOINT}/programs`, program)
            })
        }
    }

    let openCreateProgramPopUp = () => { window.dispatchEvent(new CustomEvent(`OPEN_CREATE_PROGRAM_POP_UP`)) }
    let closeCreateProgramPopUp = () => { window.dispatchEvent(new CustomEvent(`CLOSE_CREATE_PROGRAM_POP_UP`)) }

    let openProgramsApplicationsPopUp = () => { window.dispatchEvent(new CustomEvent(`OPEN_PROGRAM_APPLICATIONS_POP_UP`)) }
    let closeProgramsApplicationsPopUp = () => { window.dispatchEvent(new CustomEvent(`CLOSE_PROGRAM_APPLICATIONS_POP_UP`)) }

    return (
        <Wrapper>

            <Frame extra={`width: 800px; height: 800px; position: absolute; top: 409px; left: calc(50% + 400px); z-index: 0;`} >
                <Circles width={800} />
            </Frame>

            <Frame row extra={`width: 800px; margin-left: 175px; margin-top: 20px; justify-content: flex-start; @media only screen and (max-width: 600px) { width: 90vw; margin: 0 0 6.25vw 0; };`} >
                {
                    [`admin`, `coordinator`].indexOf(currentUser.userRole) > -1 ? <>
                        <Link to={`/programs/create`}><Button background={props => props.theme.blue} extra={`margin-right: 15px;`} >{getText(`Add program`)}</Button></Link>
                    </> : null
                }
                {
                    [`admin`].indexOf(currentUser.userRole) > -1 ? <>
                        <Button background={props => props.theme.blue} shaped={reorderMode} extra={`margin-right: 15px;`} onClick={() => { setRoderMode(!reorderMode); if (reorderMode === false) { dispatch(programsActions.loadAllPrograms()) } }} >{getText(reorderMode ? `Cancel` : `Reoder`)}</Button>
                    </> : null
                }
            </Frame>

            <TabsWrapper>

                <Frame extra={`width: 750px; align-items: flex-start; margin-bottom: 15px; @media only screen and (max-width: 600px) { width: 90vw; align-items: center; };`} >
                    <H>{getText(`Programs`)}</H>
                </Frame>

                {
                    reorderMode ?
                        <ReactSortable list={programs.filter(i => i.info.is_scholarship !== true)} setList={updateProgramsOrder}>
                            {
                                programs.filter(i => i.info.is_scholarship !== true).map((item, index, self) => {
                                    let color = item.info[`primary_color_(hex)`] ? `#` + item.info[`primary_color_(hex)`].slice(-6) : undefined
                                    return (
                                        <Frame key={index} row extra={`align-items: flex-start;`} >
                                            <Frame extra={`margin-right: 56px; opacity: ${+!reorderMode}; @media only screen and (max-width: 600px) { display: none; };`} >
                                                <Frame extra={props => `width: ${+(index > 0)}px; height: 86px; background: ${props.theme.background.secondary}; `} />
                                                <Circle color={color} />
                                                {
                                                    index !== self.length - 1 ? <Frame extra={props => `width: ${+(index < programs.length - 1)}px; height: 116px; background: ${props.theme.background.secondary}; `} /> : null
                                                }
                                            </Frame>
                                            <DangleWrapper dangle={reorderMode} >
                                                <ProgramTab programId={item.id} key={index} />
                                            </DangleWrapper>
                                        </Frame>
                                    )
                                })
                            }
                        </ReactSortable>
                        : programs.filter(i => i.info.is_scholarship !== true).map((item, index, self) => {
                            let color = item.info[`primary_color_(hex)`] ? `#` + item.info[`primary_color_(hex)`].slice(-6) : undefined
                            return (
                                <Frame key={index} row extra={`align-items: flex-start;`} >
                                    <Frame extra={`margin-right: 56px; opacity: ${+!reorderMode}; @media only screen and (max-width: 600px) { display: none; };`} >
                                        <Frame extra={props => `width: ${+(index > 0)}px; height: 86px; background: ${props.theme.background.secondary}; `} />
                                        <Circle color={color} />
                                        {
                                            index !== self.length - 1 ? <Frame extra={props => `width: ${+(index < programs.length - 1)}px; height: 116px; background: ${props.theme.background.secondary}; `} /> : null
                                        }
                                    </Frame>
                                    <DangleWrapper dangle={reorderMode} >
                                        <ProgramTab programId={item.id} key={index} />
                                    </DangleWrapper>
                                </Frame>
                            )
                        })
                }

                {
                    programs.filter(i => i.info.is_scholarship === true).length > 0 ? <Frame extra={`width: 750px; align-items: flex-start; @media only screen and (max-width: 600px) { width: 90vw; align-items: center; };`} >
                        <H extra={`margin: 20px 0;`} >{getText(`Scholarships`)}</H>
                    </Frame> : null
                }

                {
                    reorderMode ?
                        <ReactSortable list={programs.filter(i => i.info.is_scholarship === true)} setList={updateProgramsOrder}>
                            {
                                programs.filter(i => i.info.is_scholarship === true).map((item, index, self) => {
                                    let color = item.info[`primary_color_(hex)`] ? `#` + item.info[`primary_color_(hex)`].slice(-6) : undefined
                                    return (
                                        <Frame key={index} row extra={`align-items: flex-start;`} >
                                            <Frame extra={`margin-right: 56px; opacity: ${+!reorderMode}; @media only screen and (max-width: 600px) { display: none; };`} >
                                                <Frame extra={props => `width: ${+(index > 0)}px; height: 86px; background: ${props.theme.background.secondary}; `} />
                                                <Circle color={color} />
                                                {
                                                    index !== self.length - 1 ? <Frame extra={props => `width: ${+(index < programs.length - 1)}px; height: 116px; background: ${props.theme.background.secondary}; `} /> : null
                                                }
                                            </Frame>
                                            <DangleWrapper dangle={reorderMode} >
                                                <ProgramTab programId={item.id} key={index} />
                                            </DangleWrapper>
                                        </Frame>
                                    )
                                })
                            }
                        </ReactSortable>
                        : programs.filter(i => i.info.is_scholarship === true).map((item, index, self) => {
                            let color = item.info[`primary_color_(hex)`] ? `#` + item.info[`primary_color_(hex)`].slice(-6) : undefined
                            return (
                                <Frame key={index} row extra={`align-items: flex-start;`} >
                                    <Frame extra={`margin-right: 56px; opacity: ${+!reorderMode}; @media only screen and (max-width: 600px) { display: none; };`} >
                                        <Frame extra={props => `width: ${+(index > 0)}px; height: 86px; background: ${props.theme.background.secondary}; `} />
                                        <Circle color={color} />
                                        {
                                            index !== self.length - 1 ? <Frame extra={props => `width: ${+(index < programs.length - 1)}px; height: 116px; background: ${props.theme.background.secondary}; `} /> : null
                                        }
                                    </Frame>
                                    <DangleWrapper dangle={reorderMode} >
                                        <ProgramTab programId={item.id} key={index} />
                                    </DangleWrapper>
                                </Frame>
                            )
                        })
                }

            </TabsWrapper>

        </Wrapper>
    );
}

const dangle = keyframes`
    0% { transform: rotate(-0.125deg); }
    50% { transform: rotate(0.125deg); }
    100% { transform: rotate(-0.125deg); }
`;

const DangleWrapper = styled(Frame)`
    animation: ${props => props.dangle ? dangle : null} 0.15s linear infinite;
`;

const ReorderScreen = styled(Frame)`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    /* visibility: ${props => props.visible ? `visible` : `hidden`}; */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    /* transition: 0s; */
    &:after {
        content: "Loading";
        color: white;
        font-family: Bold;
    }
`;

const Circle = styled(Frame)`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: ${props => props.color};
    border: 1px solid ${props => props.color ? props.color : props.theme.background.secondary};
    box-sizing: border-box;
`;

let ProgramImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/landing/program_${props.name}.png`) } catch (error) { }
    return ({ src: img, })
})`
    width: 140px;
    height: 140px;

    @media only screen and (max-width: 600px) {
        
    }
`

const Tab = styled(Frame)`
    width: 765px;
    height: 140px;
    padding: 30px;
    border-radius: 12px;
    cursor: pointer;
    flex-direction: row;
    justify-content: space-between;
    background: ${props => props.theme.background.primary};
    border: 1px solid rgba(0, 0, 0, 0.05);
    &:hover {
        box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
    }
`;

const TabsWrapper = styled(Frame)`
    width: 1100px;
    margin-top: 30px;
    margin-bottom: 54px;
    z-index: 1;

    @media only screen and (max-width: 600px) {
        margin-top: 0;
        width: 90vw;
    }
`;

const Wrapper = styled(Frame)`
    position: relative;
    align-items: flex-start;
    width: 1100px;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

export default ProgramsList;

/*eslint-enable*/