/*eslint-disable*/
/**
 * Created by sabir on 19.07.17.
 */

//users
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL'

export const LOAD_USERS = 'LOAD_USERS'
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS'
export const LOAD_USERS_FAIL = 'LOAD_USERS_FAIL'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const INITIALIZE_AUTH = 'INITIALIZE_AUTH'
export const INITIALIZE_AUTH_SUCCESS = 'INITIALIZE_AUTH_SUCCESS'
export const INITIALIZE_AUTH_FAIL = 'INITIALIZE_AUTH_FAIL'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

// univ
export const LOAD_GROUPS_LINKS_USERS_SUCCESS = 'LOAD_GROUPS_LINKS_USERS_SUCCESS'

// articles

export const LOAD_ARTICLES = 'LOAD_ARTICLES'
export const LOAD_ARTICLES_SUCCESS = 'LOAD_ARTICLES_SUCCESS'
export const LOAD_ARTICLES_FAIL = 'LOAD_ARTICLES_FAIL'

export const CREATE_ARTICLE = 'CREATE_ARTICLE'
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_FAIL = 'CREATE_ARTICLE_FAIL'

export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS'
export const UPDATE_ARTICLE_FAIL = 'UPDATE_ARTICLE_FAIL'

export const DELETE_ARTICLE = 'DELETE_ARTICLE'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_FAIL = 'DELETE_ARTICLE_FAIL'

// projects

export const LOAD_PROJECTS = 'LOAD_PROJECTS'
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS'
export const LOAD_PROJECTS_FAIL = 'LOAD_PROJECTS_FAIL'

export const CREATE_PROJECT = 'CREATE_PROJECT'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL'

export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL'

export const UPDATE_APPLICATION_STATUS = 'UPDATE_APPLICATION_STATUS'
export const UPDATE_APPLICATION_STATUS_SUCCESS = 'UPDATE_APPLICATION_STATUS_SUCCESS'
export const UPDATE_APPLICATION_STATUS_FAIL = 'UPDATE_APPLICATION_STATUS_FAIL'

export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL'

// projects

export const LOAD_DICTIONARY = 'LOAD_DICTIONARY'
export const LOAD_DICTIONARY_SUCCESS = 'LOAD_DICTIONARY_SUCCESS'
export const LOAD_DICTIONARY_FAIL = 'LOAD_DICTIONARY_FAIL'

export const CREATE_DICTIONARY = 'CREATE_DICTIONARY'
export const CREATE_DICTIONARY_SUCCESS = 'CREATE_DICTIONARY_SUCCESS'
export const CREATE_DICTIONARY_FAIL = 'CREATE_DICTIONARY_FAIL'

export const UPDATE_DICTIONARY = 'UPDATE_DICTIONARY'
export const UPDATE_DICTIONARY_SUCCESS = 'UPDATE_DICTIONARY_SUCCESS'
export const UPDATE_DICTIONARY_FAIL = 'UPDATE_DICTIONARY_FAIL'

export const DELETE_DICTIONARY = 'DELETE_DICTIONARY'
export const DELETE_DICTIONARY_SUCCESS = 'DELETE_DICTIONARY_SUCCESS'
export const DELETE_DICTIONARY_FAIL = 'DELETE_DICTIONARY_FAIL'

// programs

export const UPDATE_PROGRAM = 'UPDATE_PROGRAM'
export const UPDATE_PROGRAM_SUCCESS = 'UPDATE_PROGRAM_SUCCESS'
export const UPDATE_PROGRAM_FAIL = 'UPDATE_PROGRAM_FAIL'

export const UPDATE_PROGRAM_ORDER = 'UPDATE_PROGRAM_ORDER'

export const CREATE_PROGRAM = 'CREATE_PROGRAM'
export const CREATE_PROGRAM_SUCCESS = 'CREATE_PROGRAM_SUCCESS'
export const CREATE_PROGRAM_FAIL = 'CREATE_PROGRAM_FAIL'

export const DELETE_PROGRAM = 'DELETE_PROGRAM'
export const DELETE_PROGRAM_SUCCESS = 'DELETE_PROGRAM_SUCCESS'
export const DELETE_PROGRAM_FAIL = 'DELETE_PROGRAM_FAIL'

export const LOAD_PROGRAMS = 'LOAD_PROGRAMS'
export const LOAD_PROGRAMS_SUCCESS = 'LOAD_PROGRAMS_SUCCESS'
export const LOAD_PROGRAMS_FAIL = 'LOAD_PROGRAMS_FAIL'

export const APPLY_TO_PROGRAM = 'APPLY_TO_PROGRAM'
export const APPLY_TO_PROGRAM_SUCCESS = 'APPLY_TO_PROGRAM_SUCCESS'
export const APPLY_TO_PROGRAM_FAIL = 'APPLY_TO_PROGRAM_FAIL'

export const CANCEL_APPLICATION = 'CANCEL_APPLICATION'
export const CANCEL_APPLICATION_SUCCESS = 'CANCEL_APPLICATION_SUCCESS'
export const CANCEL_APPLICATION_FAIL = 'CANCEL_APPLICATION_FAIL'

export const LOAD_APPLIED_PROGRAMS = 'LOAD_APPLIED_PROGRAMS'
export const LOAD_APPLIED_PROGRAMS_SUCCESS = 'LOAD_APPLIED_PROGRAMS_SUCCESS'
export const LOAD_APPLIED_PROGRAMS_FAIL = 'LOAD_APPLIED_PROGRAMS_FAIL'

export const OPEN_LOG_OUT = 'OPEN_LOG_OUT'
export const CLOSE_LOG_OUT = 'CLOSE_LOG_OUT'

export const OPEN_PROJECT_REQUEST = 'OPEN_PROJECT_REQUEST'
export const CLOSE_PROJECT_REQUEST = 'CLOSE_PROJECT_REQUEST'

export const SET_LANG = 'SET_LANG'

export const APPLY_TO_PROJECT = `APPLY_TO_PROJECT`
export const APPLY_TO_PROJECT_SUCCESS = `APPLY_TO_PROJECT_SUCCESS`
export const APPLY_TO_PROJECT_FAIL = `APPLY_TO_PROJECT_FAIL`

export const DELETE_APPLICATION = `DELETE_APPLICATION`
export const DELETE_APPLICATION_SUCCESS = `DELETE_APPLICATION_SUCCESS`
export const DELETE_APPLICATION_FAIL = `DELETE_APPLICATION_FAIL`

export const UPDATE_APPLICATION = `UPDATE_APPLICATION`
export const UPDATE_APPLICATION_SUCCESS = `UPDATE_APPLICATION_SUCCESS`
export const UPDATE_APPLICATION_FAIL = `UPDATE_APPLICATION_FAIL`

export const UPDATE_PROGRAM_STATUS = `UPDATE_PROGRAM_STATUS`
export const UPDATE_PROGRAM_STATUS_SUCCESS = `UPDATE_PROGRAM_STATUS_SUCCESS`
export const UPDATE_PROGRAM_STATUS_FAIL = `UPDATE_PROGRAM_STATUS_FAIL`

export const SET_WIZARD = `SET_WIZARD`

export const CREATE_WIZARD = `CREATE_WIZARD`
export const CREATE_WIZARD_FAIL = `CREATE_WIZARD_FAIL`
export const CREATE_WIZARD_SUCCESS = `CREATE_WIZARD_SUCCESS`

export const DELETE_WIZARD = `DELETE_WIZARD`
export const DELETE_WIZARD_FAIL = `DELETE_WIZARD_FAIL`
export const DELETE_WIZARD_SUCCESS = `DELETE_WIZARD_SUCCESS`

export const LOAD_WIZARD = `LOAD_WIZARD`
export const LOAD_WIZARD_FAIL = `LOAD_WIZARD_FAIL`
export const LOAD_WIZARD_SUCCESS = `LOAD_WIZARD_SUCCESS`

export const UPDATE_WIZARD = `UPDATE_WIZARD`
export const UPDATE_WIZARD_FAIL = `UPDATE_WIZARD_FAIL`
export const UPDATE_WIZARD_SUCCESS = `UPDATE_WIZARD_SUCCESS`

export const LOAD_EMAILS = `LOAD_EMAILS`
export const LOAD_EMAILS_SUCCESS = `LOAD_EMAILS_SUCCESS`
export const LOAD_EMAILS_FAIL = `LOAD_EMAILS_FAIL`

export const CREATE_EMAILS = `CREATE_EMAILS`
export const CREATE_EMAILS_SUCCESS = `CREATE_EMAILS_SUCCESS`
export const CREATE_EMAILS_FAIL = `CREATE_EMAILS_FAIL`

export const UPDATE_EMAILS = `UPDATE_EMAILS`
export const UPDATE_EMAILS_SUCCESS = `UPDATE_EMAILS_SUCCESS`
export const UPDATE_EMAILS_FAIL = `UPDATE_EMAILS_FAIL`

export const DELETE_EMAILS = `DELETE_EMAILS`
export const DELETE_EMAILS_SUCCESS = `DELETE_EMAILS_SUCCESS`
export const DELETE_EMAILS_FAIL = `DELETE_EMAILS_FAIL`

export const DELETE_PROGRAM_APPLICATION = `DELETE_PROGRAM_APPLICATION`
export const DELETE_PROGRAM_APPLICATION_SUCCESS = `DELETE_PROGRAM_APPLICATION_SUCCESS`
export const DELETE_PROGRAM_APPLICATION_FAIL = `DELETE_PROGRAM_APPLICATION_FAIL`

export const DELETE_PROJECT_APPLICATION = `DELETE_PROJECT_APPLICATION`
export const DELETE_PROJECT_APPLICATION_SUCCESS = `DELETE_PROJECT_APPLICATION_SUCCESS`
export const DELETE_PROJECT_APPLICATION_FAIL = `DELETE_PROJECT_APPLICATION_FAIL`

export const SET_FAQ = `SET_FAQ`
export const LOAD_FAQ = `LOAD_FAQ`
export const LOAD_FAQ_SUCCESS = `LOAD_FAQ_SUCCESS`
export const LOAD_FAQ_FAIL = `LOAD_FAQ_FAIL`
export const CREATE_FAQ = `CREATE_FAQ`
export const CREATE_FAQ_SUCCESS = `CREATE_FAQ_SUCCESS`
export const CREATE_FAQ_FAIL = `CREATE_FAQ_FAIL`
export const UPDATE_FAQ = `UPDATE_FAQ`
export const UPDATE_FAQ_SUCCESS = `UPDATE_FAQ_SUCCESS`
export const UPDATE_FAQ_FAIL = `UPDATE_FAQ_FAIL`
export const DELETE_FAQ = `DELETE_FAQ`
export const DELETE_FAQ_SUCCESS = `DELETE_FAQ_SUCCESS`
export const DELETE_FAQ_FAIL = `DELETE_FAQ_FAIL`

/*eslint-enable*/