/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { useDispatch } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import { Frame, Input, H1, Button, AddSquare, convertHex } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import Dropzone from '../../UIKit/Dropzone'
import PopUpWrapper from './PopUpWrapper'
import PhotoPreview from '../tools/PhotoPreview'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch from '../helpers/useCustomDispatch'

let ConfirmPopUp = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let { getText } = useDictionary(() => { })

    let [action, setAction] = useState({})

    let onClose = () => { window.dispatchEvent(new CustomEvent(`CLOSE_CONFIRM_POP_UP`)) }

    useCustomDispatch(`OPEN_CONFIRM_POP_UP`, (e) => { setAction(e.detail) })
    useCustomDispatch(`CLOSE_CONFIRM_POP_UP`, (e) => { setAction({}) })

    return (
        <PopUpWrapper name={`CONFIRM`}>
            <H1>{getText(action.header || `Confirm this action`)}</H1>
            {action.children}
            <Frame row>
                <Button shaped background={props => props.theme.blue} onClick={() => {
                    window.dispatchEvent(new CustomEvent(action.reject || ``))
                    onClose()
                }} >{getText(`No. Go back`)}</Button>
                <Button extra={`margin-left: 5px;`} disabled={action.confirmDisabled === true} background={props => props.theme.red} onClick={() => {
                    window.dispatchEvent(new CustomEvent(action.confirm || ``))
                    onClose()
                }} >{getText(`Yes. Continue`)}</Button>
            </Frame>
        </PopUpWrapper >
    );
}

const Avatar = styled(Frame)`
    width: 132px;
    height: 132px;
    background-image: url("${props => props.src}");
    background-position: center;
`;

export default ConfirmPopUp;

/*eslint-enable*/