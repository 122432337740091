/*eslint-disable*/
import FAQAPI from '../../api/FAQAPI'

import * as types from '../ActionTypes'

let uuid = () => Math.random().toString(36).substring(3).split(``).map(i => Math.random() > 0.5 ? i.toUpperCase() : i).join(``)

export function setFAQ(new_faq) {
    return {
        type: types.SET_FAQ,
        faq: new_faq
    }
}

let loadFAQ_ = () => {
    return {
        type: types.LOAD_FAQ
    }
}
let loadFAQSuccess = (faq) => {
    return {
        type: types.LOAD_FAQ_SUCCESS,
        faq: faq
    }
}
let loadFAQFailed = (error) => {
    return {
        type: types.LOAD_FAQ_FAIL,
        error: error
    }
}

//thunk
export function loadAllFAQ() {
    return (dispatch, getState) => {
        dispatch(loadFAQ_());
        return FAQAPI.getFAQ().then(
            faq => dispatch(loadFAQSuccess(faq)),
            error => dispatch(loadFAQFailed(error))
        )
    }
}

let createFAQ_ = () => {
    return {
        type: types.CREATE_FAQ
    }
}
let createFAQSuccess = (faq) => {
    return {
        type: types.CREATE_FAQ_SUCCESS,
        faq: faq
    }
}
let createFAQFailed = (error) => {
    return {
        type: types.CREATE_FAQ_FAIL,
        error: error
    }
}

//thunk
export function createFAQ(newFAQ) {
    return (dispatch, getState) => {
        dispatch(createFAQ_());
        return FAQAPI.setFAQ({}).then(
            faq => dispatch(createFAQSuccess(faq.value)),
            error => dispatch(createFAQFailed(error))
        )
    }
}

let updateFAQ_ = (faq) => {
    return {
        type: types.UPDATE_FAQ,
        faq: faq
    }
}
let updateFAQSuccess = (faq) => {
    return {
        type: types.UPDATE_FAQ_SUCCESS,
        faq: faq
    }
}
let updateFAQFailed = (error) => {
    return {
        type: types.UPDATE_FAQ_FAIL,
        error: error
    }
}

//thunk
export function updateFAQ(data) {
    return (dispatch, getState) => {
        dispatch(updateFAQ_(data));
        return FAQAPI.setFAQ(data).then(
            faq => dispatch(updateFAQSuccess(data)),
            error => dispatch(updateFAQFailed(error))
        )
    }
}

let deleteFAQ_ = () => {
    return {
        type: types.DELETE_FAQ
    }
}
let deleteFAQSuccess = (id) => {
    return {
        type: types.DELETE_FAQ_SUCCESS,
        id: id
    }
}
let deleteFAQFailed = (error) => {
    return {
        type: types.DELETE_FAQ_FAIL,
        error: error
    }
}

//thunk
export function deleteFAQ(id) {
    return (dispatch, getState) => {
        dispatch(deleteFAQ_());
        return FAQAPI.setFAQ({}).then(
            () => dispatch(deleteFAQSuccess(id)),
            error => dispatch(deleteFAQFailed(error))
        )
    }
}

/*eslint-enable*/