/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Circles from '../tools/Circles'
import useDictionary from '../helpers/useDictionary'

import { Frame, Text, H, P, H1, RedStrip } from '../../UIKit/styled-templates'

let HowItWorks = () => {

    let { getText } = useDictionary()

    return (
        <Workspace>
            <H>{getText(`how_it_works_title`)}</H>
            <P extra={`width: 505px; height: 55px; text-align: center; @media only screen and (max-width: 600px) { width: 90vw; }`} >
                {getText(`how_it_works_text`)}
            </P>
            <ScrollWrapper>
                <TabsWrapper>
                    {
                        new Array(4).fill(0).map((item, index) => {
                            return (
                                <Tab key={index} >
                                    <LogoWrapper index={index} >
                                        <Logo index={index} />
                                    </LogoWrapper>
                                    <H1 bold extra={`width: 30px; text-align: center; margin: 12px;`} >{getText(`how_it_works_step_${index + 1}`).split(` – `)[0]}</H1>
                                    <Text extra={`width: 186px; font-size: 13px; line-height: 26px; text-align: center;`} >{getText(`how_it_works_step_${index + 1}`).split(` – `)[1]}</Text>
                                </Tab>
                            )
                        })
                    }
                </TabsWrapper>
            </ScrollWrapper>
        </Workspace>
    );
}

const ScrollWrapper = styled(Frame)`
    @media only screen and (max-width: 600px) {
        display: block;
        max-width: 100vw;
        overflow-x: scroll;
    }
`;

const LogoWrapper = styled(Frame)`
    width: 70px;
    height: 70px;
    border-radius: 14px;
    background: ${props => props.theme[[`blue`, `green`][props.index % 2]]};
`;

let Logo = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/landing/HowItWorks_${props.index}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 45px;
    height: 45px;
`

const TabsWrapper = styled(Frame)`
    width: 1100px;
    /* height: calc(290px + 60px); */
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        height: auto;
    }
`;

const Tab = styled(Frame)`
    width: 255px;
    height: 290px;
    padding: 30px 0;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    // justify-content: space-around;
    border: 1px solid ${props => props.theme.background.secondary};
    &:hover {
        box-shadow: 0 4px 31px rgba(215, 215, 215, 0.2);
    }

    @media only screen and (max-width: 600px) {
        width: 80vw;
        height: 80vw;
        margin: 3.75vw;
        padding: 0px;
    }
`;

const Workspace = styled(Frame)`
    width: 1100px;
    height: 480px;
    margin-top: 129.51px;
    justify-content: space-between;
    position: relative;

    @media only screen and (max-width: 600px) {
        margin-top: 20vw;
        width: 100vw;
    }
`;
export default HowItWorks;

/*eslint-enable*/