/*eslint-disable*/
import React, { } from 'react';
import styled, { keyframes } from 'styled-components'
import { useLocation } from 'react-router-dom'

import { Frame, Text, Link } from '../../UIKit/styled-templates'
import CommonHelper from '../../../helpers/CommonHelper'

import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'

let ProjectsPopUp = () => {

    let { currentUser, logOut } = useCurrentUser()
    let { getText } = useDictionary()

    let items = [
        {
            image: `my_projects`,
            label: `My projects`,
            link: `/user/${currentUser.id}/projects`,
        },
        {
            image: `projects`,
            label: `All projects`,
            link: `/projects`,
        },
    ]

    return (
        <Wrapper>
            {
                items.map((item, index) => {
                    return (
                        <Link key={index} to={item.link} >
                            <Frame
                                row
                                extra={`&:hover { opacity: 0.8; transform: translateX(2px); }; cursor: pointer;`}
                                onClick={() => { CommonHelper.linkTo(item.link) }}
                            >
                                <ProjectsPopUpImage name={item.image} />
                                <Text extea={`font-size: 16px;`} >{getText(item.label)}</Text>
                            </Frame>
                        </Link>
                    )
                })
            }
        </Wrapper>
    );
}


let ProjectsPopUpImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/menu_${props.name}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    margin-right: 10px;
`


const Wrapper = styled(Frame)`
    padding: 20px;
    min-width: 130px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    align-items: flex-start;
    justify-content: flex-start;
    // box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07);
    border: 1px solid rgba(0, 0, 0, 0.05);
    &:hover {
        box-shadow: 0px 20px 50px rgba(87, 138, 214, 0.2);
    }
    > * {
        margin-top: 20px;
        &:first-child {
            margin-top: 0px;
        }
    }
`;


export default ProjectsPopUp;

/*eslint-enable*/