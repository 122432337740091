/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import moment from 'moment'

import { useDispatch, useMappedState } from 'redux-react-hook'

import { Frame, Text, convertHex, P, H1, H } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import TagsInput from '../../UIKit/TagsInput'
import CommonHelper from '../../../helpers/CommonHelper';
import ProjectTab from '../tools/ProjectTab'
import FiltersScreen from '../tools/filters-screen'

import useDictionary from '../helpers/useDictionary'
import useCurrentUser from '../helpers/useCurrentUser'

let animatedComponents = makeAnimated();

let crossing = (a = [], b = []) => [...a, ...b].some((i) => a.indexOf(i) > -1 && b.indexOf(i) > -1)

let ProjectsListPage = () => {

    let { getText, dict } = useDictionary()
    let { userRole } = useCurrentUser()

    let durations = [
        { value: 1, label: getText(`more than 1 months`) },
        { value: 3, label: getText(`more than 3 months`) },
        { value: 6, label: getText(`more than 6 months`) },
    ]

    let projectStatuses = Object.keys(dict).filter(i => i.indexOf(`project_status_`) > -1)
    let tags = Object.keys(dict).filter(i => i.indexOf(`tag_`) > -1)

    let [search, setSearch] = useState(``)
    let [duration, setDuration] = useState(null);
    let [status, setStatus] = useState(null);
    let [category, setCategory] = useState(null);

    let { projects } = useMappedState(useCallback(state => ({
        projects: state.projects.projectsMap
            .toArray()
            .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
            .sort((a, b) => +(b.pinned === true) - +(a.pinned === true))
            .filter(i => userRole === `student` || userRole === `guest` ? [projectStatuses[0], projectStatuses[3], projectStatuses[4]].indexOf(i.status) === -1 : true)
            .map(i => ({ ...i, info: { ...i.info, duration: moment(i.info.to, `DD.MM.YYYY`).diff(moment(i.info.from, `DD.MM.YYYY`), `month`) } })),
        companiesMap: state.users.usersMap.filter(i => i.userRole === `company`),
    }), [projectStatuses]))

    let [visibleProjects, setVisibleProjects] = useState(10)
    let [mobileFiltersVisible, setMobileFiltersVisible] = useState(false)

    return (
        <Wrapper>
            <FiltersScreen
                visible={mobileFiltersVisible}
                setVisible={setMobileFiltersVisible}
                onApplyFilters={(e) => {
                    setDuration(e.duration)
                    setCategory(e.category)
                    setStatus(e.status)
                }}
            />
            <FilterToolsWrapper>
                <Input value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder={getText(`Search`)} image={`search`} extra={`border-radius: 25px; width: 221px; @media only screen and (max-width: 600px) { width: calc(67vw - 34px); border-radius: 10vw; };`} />
                <MySelect
                    value={category}
                    options={tags.map(i => ({ value: i, label: getText(i) })) || []}
                    placeholder={getText(`Category`)}
                    onChange={e => { setCategory(e) }}
                />
                <MySelect
                    value={duration}
                    options={durations}
                    placeholder={getText(`Duration`)}
                    onChange={(e) => { setDuration(e) }}
                />
                <MySelect
                    value={status}
                    options={projectStatuses.map(i => ({ value: i, label: getText(i) })) || []}
                    placeholder={getText(`Status`)}
                    onChange={e => { setStatus(e) }}
                />
                <MobileFilterButton onClick={() => { setMobileFiltersVisible(true) }} >
                    {getText(`Filter`)}
                    <FilterIcon />
                </MobileFilterButton>
            </FilterToolsWrapper>

            <ProjectsWrapper>
                {
                    projects
                        .filter((i, j) => search === `` ? j < visibleProjects : (i.info.title_project || ``).toLowerCase().indexOf(search.toLowerCase()) > -1 || search === ``)
                        .filter(i => category ? (i.info[`specialization_(tags)`] || []).map(i => getText(i)).indexOf(category.label) > -1 : true)
                        .filter(i => status ? getText(i.status || ``) === status.label : true)
                        .filter(i => duration ? i.info.duration >= duration.value : true)
                        .map((item, index) => {
                            return (
                                <ProjectTab key={index} projectId={item.id} index={index} />
                            )
                        })
                }
            </ProjectsWrapper>

            {
                projects.length === 0 ? <Frame extra={`width: 1100px;`} ><P>{getText(`Empty`)}</P></Frame> :
                    projects.length > visibleProjects ? <Button shaped extra={`margin: 70px 0 70px 0;`} onClick={() => { setVisibleProjects(Math.min(projects.length, visibleProjects + 5)) }} >{getText(`View more`)}</Button> : null
            }

        </Wrapper>
    );
}

const RadioButton = styled(Frame)`
    width: 6.75vw;
    height: 6.75vw;
    border-radius: 50%;
    border: ${props => props.selected ? 3 : 2}px solid ${props => props.selected ? props.theme.green : props.theme.background.support};
    box-sizing: border-box;
    margin: 2.5vw 2.5vw 2.5vw 0;

    &:after {
        content: '';
        width: 3.75vw;
        height: 3.75vw;
        border-radius: 50%;
        background: ${props => props.theme.green};
        position: absolute;
        transform: scale(${props => props.selected ? 1 : 0});
        opacity: ${props => +props.selected};
    }
`;

const MobileFilters = styled(Frame)`
    width: 90vw;
    min-height: calc(100vh - 10vw);
    padding: 5vw;
    background: ${props => props.theme.background.primary};
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    align-items: flex-start;
    justify-content: flex-start;

    @media only screen and (min-width: 600px) {
        display: none;
    }
`;

const MobileFilterButton = styled(Frame)`
    flex-direction: row;
    font-size: 14px;
    color: ${props => props.theme.text.secondary};
    @media only screen and (min-width: 600px) {
        display: none;
    }
`;

const Cros = styled(Frame)`
    width: 15px;
    height: 15px;
    background: url("${require(`../../../assets/images/cros.svg`)}") no-repeat center center;
    opacity: 0.3;
`;

const FilterIcon = styled(Frame)`
    width: 24px;
    height: 24px;
    background: url("${require(`../../../assets/images/filter.svg`)}");
    margin-left: 2vw;
`;

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    margin-left: 3px;
`

const Tab = styled(Frame)`
    width: 535px;
    height: 300px;
    border-radius: 12px;
    margin-top: 30px;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07);
    &:hover {
        box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
    }
`;

const ProjectsWrapper = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 1100px;
    margin-top: 34px;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin-top: calc(-30px + 6.25vw);
    }
`;

const MySelect = styled(Select).attrs((props) => {
    return ({
        defaultValue: [],
        closeMenuOnSelect: true,
        components: animatedComponents,
        isClearable: true,
        styles: {
            singleValue: style => ({ ...style, color: props.theme.text.primary, marginLeft: `12px`, }),
            control: styles => ({
                ...styles,
                width: `255px`,
                height: `50px`,
                borderRadius: `25px`,
                border: `1px solid ${props.theme.background.secondary}`,
                background: props.theme.background.primary,
                overflow: `hidden`,
                fontSize: `14px`,
                ':hover': null,
            }),
            menuList: style => ({ borderRadius: `12px`, }),
            placeholder: style => ({ ...style, marginLeft: `12px`, color: props.theme.text.secondary, fontSize: `14px`, }),
            input: style => ({ ...style, marginLeft: `12px`, fontSize: `14px`, }),
            valueContainer: style => ({ ...style, fontSize: `14px`, color: props.theme.text.secondary, margineft: `12px`, }),
            indicatorSeparator: style => ({ ...style, opacity: 0, }),
            option: styles => ({
                ...styles,
                background: props.theme.background.secondary,
                fontSize: `14px`,
                color: props.theme.text.primary,
                zIndex: 2000,
                ':hover': { backgroundColor: props.theme.background.support, },
            }),

        }
    })
})``;

const Wrapper = styled(Frame)`
    // position: relative;
    margin-bottom: 30px;

    @media only screen and (max-width: 600px) {
        margin: 0;
    }
`;

const FilterToolsWrapper = styled(Frame)`
    width: 1100px;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin-top: 0;
        > * {
            &:nth-child(2) { display: none; visibility: hidden; margin-top: 0; }
            &:nth-child(3) { display: none; visibility: hidden; margin-top: 0; }
            &:nth-child(4) { display: none; visibility: hidden; margin-top: 0; }
        }
    }
`;

export default ProjectsListPage;

/*eslint-enable*/