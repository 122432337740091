/*eslint-disable*/
/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    articlesMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const ArticlesReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_ARTICLE:
        case types.UPDATE_ARTICLE:
        case types.DELETE_ARTICLE:
        case types.LOAD_ARTICLES:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_ARTICLE_FAIL:
        case types.UPDATE_ARTICLE_FAIL:
        case types.DELETE_ARTICLE_FAIL:
        case types.LOAD_ARTICLES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_ARTICLE_SUCCESS:
        case types.UPDATE_ARTICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                articlesMap: state.articlesMap.set(action.article.id, action.article)
            }


        case types.DELETE_ARTICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                articlesMap: state.articlesMap.delete(action.id),
            }


        case types.LOAD_ARTICLES_SUCCESS:
            return {
                ...state,
                loading: false,
                articlesMap: state.articlesMap
                    .merge(action.articles.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default ArticlesReducer;

/*eslint-enable*/