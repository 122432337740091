/*eslint-disable*/
import React, { useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components'
import { useLocation, Switch, Route } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'

import Header from '../rtu/tools/Header'
import Footer from '../rtu/tools/Footer'
import Menu from '../rtu/tools/Menu'
import FlyingCircles from '../rtu/tools/FlyingCircles'
import MyProjectsList from '../rtu/pages/my-projects-list'
import ProjectsList from '../rtu/pages/projects-list'
import ProjectPage from '../rtu/pages/project-page'
import ProjectLoginPage from '../rtu/pop-ups/landing-login-pop-up'
import NewsList from '../rtu/pages/news-list'
import NewsPage from '../rtu/pages/news-page'
import CalendarPage from '../rtu/pages/calendar-page'
import CompaniesList from '../rtu/pages/companies-list'
import CompaniesProfile from '../rtu/pages/company-profile'
import StudentProfile from '../rtu/pages/student-profile'
import ProgramsList from '../rtu/pages/programs-list'
import ProgramsPage from '../rtu/pages/program-page'
import SettingsPage from '../rtu/pages/settings-page'
import StudentEdit from '../rtu/pages/student-edit'
import ApplyToProjectPopUp from '../rtu/pop-ups/ApplyToProjectPopUp'
import ConfirmProgramApplicationPopUp from '../rtu/pop-ups/confirm-program-aplication-pop-up'
import FAQPage from '../rtu/pages/faq-page'

import { Frame, Text } from '../UIKit/styled-templates'
import CommonHelper from '../../helpers/CommonHelper';

import useCurrentUser from '../rtu/helpers/useCurrentUser';
import useDictionary from '../rtu/helpers/useDictionary';

import * as programsActions from '../../redux/actions/ProgramsActions'
import * as projectsActions from '../../redux/actions/ProjectsActions'
import * as usersActions from '../../redux/actions/UsersActions'

let StudentApp = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let dispatch = useDispatch()
    useEffect(() => {
        dispatch(projectsActions.loadAllProjects())
        dispatch(usersActions.loadAllUsers())
        dispatch(programsActions.loadAllPrograms())
    }, [])

    let { currentUser } = useCurrentUser()
    let { getText } = useDictionary()

    const { users } = useMappedState(useCallback(state => { return { users: state.users.usersMap.toArray(), } }, []));

    useEffect(() => {
        if (path[0] === ``) {
            CommonHelper.linkTo(`/user/${currentUser.id}`)
        }
    }, [path])

    return (
        <Frame>
            <ApplyToProjectPopUp />
            <ConfirmProgramApplicationPopUp />
            <Wrapper>

                <Header />

                <Frame extra={`height: 94px; width: 100%;`} />

                <Switch>

                    {
                        currentUser.status === `blocked` ? <Route exact component={() => <BlockedWrapper content={getText(`This user is blocked`)} />} /> : null
                    }

                    <Route exact path={`/calendar`} component={CalendarPage} />

                    <Route exact path={`/projects`} component={ProjectsList} />
                    <Route exact path={`/projects/:key`} component={ProjectPage} />

                    <Route exact path={`/companies`} component={CompaniesList} />
                    <Route exact path={`/companies/:key`} component={CompaniesProfile} />

                    <Route exact path={`/news`} component={NewsList} />
                    <Route exact path={`/news/:key`} component={NewsPage} />

                    <Route exact path={`/programs`} component={ProgramsList} />
                    <Route exact path={`/programs/:key`} component={ProgramsPage} />

                    <Route exact path={`/faq`} component={FAQPage} />
                    <Route exact path={`/faq/:key`} component={FAQPage} />

                    {
                        (() => {
                            if (path.length !== 2) { return null }
                            let selectedUser = users.filter(i => i.id === path[1])[0]
                            if (selectedUser === undefined) { return null }
                            switch (selectedUser.userRole) {
                                case `student`:
                                case `coordinator`:
                                    return <Route exact path={`/user/:key`} component={StudentProfile} />
                                case `company`:
                                    return <Route exact path={`/user/:key`} component={CompaniesProfile} />
                                default:
                                    return null
                            }
                        })()
                    }

                    <Route exact component={() => <TemplateWrapper>
                        <Menu />
                        <WorkspaceWrapper>
                            <Switch>
                                <Route exact path={`/user/:user_id/edit`} component={StudentEdit} />
                                <Route exact path={`/user/:user_id/projects`} component={MyProjectsList} />
                                <Route exact path={`/settings`} component={SettingsPage} />
                                <Route exact component={() => <Text bold extra={`font-size: 26px; width: 100%; @media only screen and (max-width: 600px) { font-size: 6.25vw; };`} >{getText(`Page is not found`)}</Text>} />
                            </Switch>
                        </WorkspaceWrapper>
                    </TemplateWrapper>} />

                </Switch>

                <Footer />

                {useMemo(() => <FlyingCircles />, [null])}

            </Wrapper>
        </Frame>
    );
}

const BlockedWrapper = styled(Frame)`
    &:after {
        content: '${props => props.content}';
        width: 100vw;
        height: calc(100vh - 94px - 209px);
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 24px;
        font-weight: bold;
        color: ${props => props.theme.text.primary};
    }
    @media only screen and (max-width: 600px) {
        
    }
`;

const WorkspaceWrapper = styled(Frame)`
    width: 815px;
    min-height: calc(100vh - 94px - 130px - 95px - 60px);
    margin-left: 40px;
    justify-content: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin-left: 0;
    }
`;

const TemplateWrapper = styled(Frame)`
    width: 1100px;
    margin-top: 37px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

const Wrapper = styled(Frame)`
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    justify-content: flex-start;
    > * {
        &:nth-child(3) {
            min-height: calc(100vh - 94px - 209px - 94px);
            justify-content: flex-start;
        }
    }

    @media only screen and (max-width: 600px) {
        
    }
`;

export default StudentApp;

/*eslint-enable*/