/*eslint-disable*/
import * as types from '../ActionTypes'

export let openLogOut = () => { return ({ type: types.OPEN_LOG_OUT, }) }
export let closeLogOut = () => { return ({ type: types.CLOSE_LOG_OUT, }) }

export let openProjectRequest = (info) => { return ({ type: types.OPEN_PROJECT_REQUEST, info: info }) }
export let closeProjectRequest = () => { return ({ type: types.CLOSE_PROJECT_REQUEST, }) }

export let setLang = (lang = `ENG`) => {
    localStorage.setItem(`lang`, lang)
    return ({ type: types.SET_LANG, lang: lang })
}

/*eslint-enable*/