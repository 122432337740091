/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation } from 'react-router-dom'
import { useMappedState, useDispatch } from 'redux-react-hook'

import * as programsActions from '../../../redux/actions/ProgramsActions'
import { Frame, Text, convertHex, P, H1, H, Link, Checkbox, AddSquare } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import Dropzone from '../../UIKit/Dropzone'
import Form from '../../UIKit/Form'
import PhotoPreview from '../tools/PhotoPreview'
import NewsTab from '../tools/NewsTab'
import Circles from '../tools/Circles'
import Tags from '../tools/Tags'
import Contacts from '../tools/Contacts'
import CommonHelper from '../../../helpers/CommonHelper'

import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'
import useCustomDispatch from '../helpers/useCustomDispatch'

let createId = () => Math.random().toString(36).substring(3).split(``).map(i => i[Math.random() > 0.5 ? `toLowerCase` : `toUpperCase`]()).join(``)

let ProgramEdit = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let { getText, langs } = useDictionary()
    let { currentUser } = useCurrentUser()

    let [info, setInfo] = useState({})
    let [localProgram, setLocalProgram] = useState({})

    let { program } = useMappedState(useCallback((state) => ({ program: { coordinatorsIds: [], info: {}, ...state.programs.programsMap.get(path[1]) } }), [path[1]]))
    let { coordinators } = useMappedState(useCallback((state) => ({ coordinators: state.users.usersMap.toArray().filter(i => localProgram.coordinatorsIds ? localProgram.coordinatorsIds.indexOf(i.id) > -1 : false) }), [localProgram]))

    let dispatch = useDispatch()
    let onClose = () => { window.dispatchEvent(new CustomEvent(`CLOSE_CREATE_PROGRAM_POP_UP`)) }
    let onCreateProgram = () => dispatch(programsActions.createProgram({ info: info, coordinatorsIds: currentUser.userRole === `coordinator` ? [currentUser.id] : [], applications: [] }))
    let onUpdateProgram = () => dispatch(programsActions.updateProgram({ ...localProgram, info: info }))
    let onOpenConfirmProgramDeletePopUp = () => { window.dispatchEvent(new CustomEvent(`OPEN_DELETE_PROGRAM_POP_UP`)) }
    let onOpenAddCoordinatorPopUp = () => { window.dispatchEvent(new CustomEvent(`OPEN_ADD_COORDINATOR_POP_UP`)) }
    let onAddCoordinatorToProgram = (id) => { window.dispatchEvent(new CustomEvent(`ADD_COORDINATOR_${path[1]}`, { detail: { id: id } })); setLocalProgram({ ...localProgram, coordinatorsIds: [...localProgram.coordinatorsIds, id].filter((i, b, self) => self.filter(j => j === i).length === 1) }) }
    let onSave = () => {
        if (localProgram.id === undefined) {
            onCreateProgram()
        } else {
            onUpdateProgram()
        }
        onClose()
        setInfo({})
        CommonHelper.linkTo(`/programs`)
    }

    useCustomDispatch(`OPEN_CREATE_PROGRAM_POP_UP`, (e) => {
        if (e.detail) {
            setInfo(e.detail.info)
            setLocalProgram(e.detail)
        }
    })
    useCustomDispatch(`CLOSE_CREATE_PROGRAM_POP_UP`, (e) => { setInfo({}); setLocalProgram({}) })
    useCustomDispatch(`ADD_COORDINATOR_${path[1]}`, (e) => { setLocalProgram({ ...localProgram, coordinatorsIds: [...localProgram.coordinatorsIds, e.detail.id].filter((i, b, self) => self.filter(j => j === i).length === 1) }) })

    useEffect(() => {
        setInfo(program.info)
        setLocalProgram({ ...program })
        if (path.slice(-1)[0] === `create`) {
            setInfo({})
        }
    }, [program, path.slice(-1)[0]])

    return (
        <Wrapper>
            <H extra={`margin: 30px 0;`} >{getText(`${path.slice(-1)[0]} program`)}</H>
            <Frame row extra={`align-items: flex-start;`} >
                <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; margin-right: 15px; background: ${convertHex(props.theme.blue, 0.1)}; cursor: pointer; position: relative; overflow: hidden;`} >
                    {
                        info.avatarUrl
                            ? <PhotoPreview onDelete={() => { let new_info = { ...info }; delete new_info.avatarUrl; setInfo(new_info) }} >
                                <Avatar src={info.avatarUrl} />
                            </PhotoPreview>
                            : <Dropzone onUploaded={(url) => { setInfo({ ...info, avatarUrl: url }) }} >
                                <AddSquare />
                            </Dropzone>
                    }
                </Frame>
                <Frame extra={`align-items: flex-start;`} >
                    <Form
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        fields={[
                            { name: `Program name (${langs[0]})`, type: `string`, },
                            { name: `Program name (${langs[1]})`, type: `string`, },
                            { name: `Primary color (hex)`, type: `string`, },
                            { name: `Facebook`, type: `string`, short: true, },
                            { name: `Linkedin`, type: `string`, short: true, },
                            { name: `Email`, type: `string`, },
                            { name: `Description (${langs[0]})`, type: `textarea`, },
                            { name: `Description (${langs[1]})`, type: `textarea`, },
                            { name: `Conditions link`, type: `string`, },
                            { name: `Specialization (tags)`, type: `tagsinput`, },
                            { name: `Terms`, type: `textarea`, },
                            { name: `File`, type: `files`, limitFilesNumber: 1, },
                        ]}
                    />
                    <H1>{getText(`Student questions`)}</H1>
                    <Link to={`/programs/${program.id}/edit/questionary`} ><Button shaped onClick={onSave} >{getText(`Edit questionary`)}</Button></Link>
                    <H1>{getText(`This program is scholarship`)}</H1>
                    <Form
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        fields={[{ name: `Is scholarship`, type: `checkbox`, },]}
                    />
                </Frame>
            </Frame>
            <Frame extra={`margin-left: calc(147px); align-items: flex-start;`} >
                <H1>{getText(`Gallery`, `UPPERCASE_FIRST_LETTER`)}</H1>
                <Frame row extra={`width: 100%; flex-wrap: wrap; justify-content: flex-start; > * { margin: 0 30px 30px 0; };`} >
                    {
                        (info.gallery || []).map((item, index) => {
                            return (
                                <PhotoPreview key={index} onDelete={() => { setInfo({ ...info, gallery: info.gallery.filter((a, b) => b !== index) }) }} >
                                    <GalleryPhoto src={item} />
                                </PhotoPreview>
                            )
                        })
                    }
                    <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.blue, 0.1)}; cursor: pointer;`} >
                        <Dropzone
                            onUploaded={(url) => {
                                let new_info = { ...info };
                                new_info.gallery = [...(new_info.gallery || []), url];
                                setInfo(new_info)
                            }}
                        >
                            <Frame extra={props => `width: 35px; height: 35px; border-radius: 10px; background: ${convertHex(props.theme.blue, 0.3)};`} >
                                <Text bold text_color={props => props.theme.blue} extra={`font-size: 20px;`} >+</Text>
                            </Frame>
                        </Dropzone>
                    </Frame>
                </Frame>

                <H1>{getText(`Video`, `UPPERCASE_FIRST_LETTER`)}</H1>
                <Frame row extra={`width: 100%; flex-wrap: wrap; justify-content: flex-start; > * { margin: 0 30px 30px 0; };`} >
                    {
                        (info.video || []).map((item, index) => {
                            let name = item.name.split(`.`).filter((a, b) => b < item.name.split(`.`).length - 1).join(`.`)
                            if ((name.substring(0, 15) + `...`).split(``).length < name.split(``).length) {
                                name = name.substring(0, 15) + `...`
                            }
                            return (
                                <Frame key={index} extra={`margin-top: 16px;`}>
                                    <PhotoPreview onDelete={() => { setInfo({ ...info, video: info.video.filter((a, b) => b !== index) }) }} >
                                        <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.background.secondary, 0.5)}; cursor: pointer;`} >
                                            <VideoImage />
                                        </Frame>
                                    </PhotoPreview>
                                    <Text extra={props => `font-size: 12px; line-height: 14px; margin-bottom: 4px; color: ${props.theme.text.secondary};`} >
                                        {name}.{item.name.split(`.`)[item.name.split(`.`).length - 1]}
                                    </Text>
                                </Frame>
                            )
                        })
                    }
                    <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.blue, 0.1)}; cursor: pointer;`} >
                        <Dropzone
                            onUploaded={(url, name) => {
                                let new_info = { ...info };
                                new_info.video = [...(new_info.video || []), { name, url }];
                                setInfo(new_info)
                            }}
                            accept={`.mov,.mp4`}
                        >
                            <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.background.secondary, 0.5)}; cursor: pointer;`} >
                                <VideoImage />
                            </Frame>
                        </Dropzone>
                    </Frame>
                </Frame>

                {
                    currentUser.userRole === `admin` ? <>
                        <H1>{getText(`Coordinators`, `UPPERCASE_FIRST_LETTER`)}</H1>
                        <Frame extra={`margin-bottom: 30px; align-items: flex-start;`} >
                            {
                                coordinators.map((item, index) => {
                                    let alreadyAdded = localProgram.coordinatorsIds.indexOf(item.id) > -1
                                    return (
                                        <CoordinatorWrapper key={index} >
                                            <Frame row >
                                                <CoordinatorAvatar src={item.info.avatarUrl} />
                                                <CoordinatorName>
                                                    {`${item.info[`name`]} ${item.info[`last_name`]}`}
                                                </CoordinatorName>
                                            </Frame>
                                            <TrashCan alreadyAdded={alreadyAdded} onClick={() => { onAddCoordinatorToProgram(item.id) }} />
                                        </CoordinatorWrapper>
                                    )
                                })
                            }
                            <Button onClick={onOpenAddCoordinatorPopUp} >{getText(`Add coordinator`)}</Button>
                        </Frame>
                    </> : null
                }
            </Frame>
            <Frame row extra={`width: 100%; justify-content: flex-start; margin-left: 150px;`} >
                <Button
                    onClick={onSave}
                    background={props => props.theme.blue}
                >{localProgram.id === undefined ? `Create` : `Save`}</Button>
                {
                    localProgram.id === undefined ? null : <Button extra={`margin-left: 5px;`} shaped background={props => props.theme.red} onClick={() => {
                        onOpenConfirmProgramDeletePopUp()
                    }} >{getText(`Delete`)}</Button>
                }
            </Frame>
        </Wrapper >
    );
}

let VideoImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/video.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 42px; height: 42px;`


let TrashCan = styled(Frame)`
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url(${require(`../../../assets/images/trash_can.svg`)});
`

const CoordinatorName = styled(Frame)`
    color: ${props => props.theme.text.secondary};
    font-size: 12px;
`;

const CoordinatorWrapper = styled(Frame)`
    width: 400px;
    height: 60px;
    padding: 0 15px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    border: 1px solid ${props => props.theme.background.secondary};
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: space-between;
    &:hover {
        ${props => props.theme.shadow.blue}
    }
`;

const CoordinatorAvatar = styled(Frame)`
    width: 45px;
    height: 45px;
    border-radius: 65px;
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});
    background-position: center;
    margin-right: 8px;

    @media only screen and (max-width: 600px) {
        width: 20vw;
        height: 20vw;
    }
`;

const Avatar = styled(Frame)`
    width: 132px;
    height: 132px;
    background-image: url("${props => props.src}");
    background-position: center;
`;

const ProgramFooter = styled(Frame)`
    flex-direction: row;
    width: 920px;
    justify-content: flex-start;
    margin: 50px 0;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        width: 90vw;
        margin: 0;
    }
`;

const ProgramName = styled(Frame)`
    font-size: 48px;
    line-height: 62px;
    margin-bottom: 25px;
    color: ${props => props.theme.text.primary};
    font-family: Bold;
    width: 730px;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        font-size: 7.5vw;
        line-height: 10.5vw;
        width: 90vw;
    }
`;

const InnerHeaderWrapper = styled(Frame)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0px 0 20px 0;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        margin: 0;
    }
`;

const EditButton = styled(Frame)`
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    border: 2px solid ${props => props.theme.text.primary};
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url(${props => require(`../../../assets/images/project-edit-button-${props.name}.svg`)}) no-repeat;
    }
`;

const GalleryPhoto = styled(Frame)`
    width: 132px;
    height: 132px;
    border-radius: 12px;
    border: 1px solid ${props => props.theme.background.secondary};
    box-sizing: border-box;
    background-image: url("${props => props.src}");
    background-position: center;
    position: relative;
    overflow: hidden;
`;

const EditButtonsWrapper = styled(Frame)`
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
`;

const ProgramImage = styled(Frame)`
    width: 160px !important;
    height: 160px !important;
    /* border-radius: 20px; */
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/hidden-company-1.svg`)});
    background-position: center;

    @media only screen and (max-width: 600px) {
        width: 37.5vw;
        height: 37.5vw;
    }
`;

const ProgramWrapper = styled(Frame)`
    align-items: flex-start;
    z-index: 1;
`;

const TagWrapper = styled(Text)`
    padding: 0 22px 0 22px;
    border-radius: 4px;
    background: ${props => convertHex(props.theme.green, 0.15)};
    margin: 0 10px 5px 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 22px;
`;

let ShareLogo = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/share_${props.name}.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 36px;
    height: 36px;
`

let BackArrow = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/back_arrow.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 16px;
    height: 16px;
    margin-right: 3px;
`

const ProgramHeader = styled(Frame)`
    width: 920px;
    margin: 30px 0 30px 0;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

const NewsListWrapper = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 1100px;
    margin-top: 0px;
    cursor: pointer;
`;

const MySelect = styled(Select).attrs((props) => {
    return ({
        styles: {
            control: styles => ({
                ...styles,
                width: `444px`,
                height: `50px`,
                borderRadius: `25px`,
                border: `1px solid ${props.theme.background.secondary}`,
                background: props.theme.background.primary,
                ':hover': null,
            }),

            menuList: () => ({ borderRadius: `12px`, }),

            placeholder: style => ({ ...style, marginLeft: `12px`, }),
            input: () => ({ marginLeft: `12px` }),

            indicatorSeparator: () => ({
                opacity: 0,
            }),
            option: styles => ({
                ...styles,
                background: props.theme.background.primary,
                color: props.theme.text.primary,
                zIndex: 2000,
                ':hover': { backgroundColor: props.theme.background.support, },
            }),
        }
    })
})`
    
`;

const Wrapper = styled(Frame)`
    width: 920px;
    align-items: flex-start;
`;

const FilterToolsWrapper = styled(Frame)`
    width: 1100px;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;
`;

export default ProgramEdit;

/*eslint-enable*/