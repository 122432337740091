/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import { useDispatch, useMappedState } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import { Frame, Input, H1, Button, AddSquare, convertHex, Checkbox, P, Textarea, Link } from '../../UIKit/styled-templates'
import Form from '../../UIKit/Form'
import Dropzone from '../../UIKit/Dropzone'
import PopUpWrapper from './PopUpWrapper'
import PhotoPreview from '../tools/PhotoPreview'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch, { customHandler } from '../helpers/useCustomDispatch'
import useCurrentUser from '../helpers/useCurrentUser'

let keyFrom = (s) => s.toLowerCase().split(` `).join(`_`)

let WizardPopUp = () => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let currentUser = useCurrentUser()

    let { wizardRedux, programs } = useMappedState(useCallback(state => ({
        wizardRedux: state.wizard.wizard,
        programs: state.programs.programsMap.toArray(),
    }), [path[1]]))

    let { getText, lang } = useDictionary(() => { })

    let onClose = () => { customHandler(`CLOSE_WIZARD_POP_UP`) }

    let [wizard, setWizard] = useState({ questions: [], weights: {} })
    let [studentComment, setStudentComment] = useState({})

    useEffect(() => { setWizard(wizardRedux) }, [wizardRedux])

    useCustomDispatch(`OPEN_WIZARD_POP_UP`, (e) => { if (e.detail) { setWizard(e.detail) } })
    useCustomDispatch(`CLOSE_WIZARD_POP_UP`, (e) => { })

    let [selectedQuestion, setSelectedQuestion] = useState(undefined)
    useEffect(() => {
        setSelectedQuestion((wizard.questions || []).length > 0 ? wizard.questions[0].id : undefined)
    }, [JSON.stringify(wizard)])

    let { questions } = wizard
    questions = questions || []

    let idByKey = (key) => ({ id: undefined, ...questions.filter(i => keyFrom(i.title_lv) === key || keyFrom(i.title_eng) === key)[0] }).id
    let keyById = (id) => questions.get(id)[`title_${lang.toLowerCase()}`]
    let transformedComment = {}
    Object.keys(studentComment).forEach(i => { if (idByKey(i) !== undefined) { transformedComment[idByKey(i)] = studentComment[i] } })
    let transformedBackComment = {}
    Object.keys(transformedComment).forEach(i => { transformedBackComment[keyFrom(keyById(i))] = transformedComment[i] })
    let isDisabled = (questions || []).map(i => i.id).filter(i => i === selectedQuestion).map(i => transformedComment[i]).map(i => i == `` || i === undefined).map(i => +i).reduce((a, b) => a + b, 0) !== 0

    let answer = {}
    Object.keys(transformedComment).forEach((a, b) => {
        let q = (questions || []).get(a)
        switch (q.type) {
            case `multicheck`:
            case `singlecheck`:
                answer[q[`title_${lang.toLowerCase()}`]] = transformedComment[a].map(i => q.options.get(i)[lang.toLowerCase()])
                break;
            default:
                answer[q[`title_${lang.toLowerCase()}`]] = transformedComment[a]
                break;
        }
    })

    let [showPrograms, setShowPrograms] = useState(false)

    let programsLimit = window.innerWidth > 600 ? 4 : 3
    let bestPrograms = Object.entries(Object.keys(transformedComment).map(question_id => transformedComment[question_id].map(option_id => wizard.weights[question_id][option_id])).reduce((a, b) => [...a, ...b], []).reduce((a, b) => Object.fromEntries(Object.keys(a).map(i => [i, a[i] + b[i]])), Object.fromEntries(programs.map(i => [i.id, 0]))) || {}).sort((a, b) => b[1] - a[1]).slice(0, programsLimit).map(i => programs.get(i[0]))

    return (
        <PopUpWrapper name={`WIZARD`}>
            {
                showPrograms ? <Frame>
                    <H1 extra={`width: 314px; font-size: 14px; align-items: center; text-align: center; @media only screen and (max-width: 600px) { width: 80vw };`} >{getText(`These programs are best for you`)}</H1>
                    <Frame extra={`margin: 20px 0; flex-direction: row; justify-content: flex-start; @media only screen and (max-width: 600px) { width: 80vw };`} >
                        <Frame row extra={`@media only screen and (max-width: 600px) { width: 90vw; overflow: hidden; };`} >
                            {
                                bestPrograms.map((item, index) => {
                                    return (
                                        <Link to={`/programs/${item.id}`} key={index} onClick={onClose} >
                                            <Program name={item.info[`program_name_(${lang.toLowerCase()})`]} avatar={item.info.avatarUrl} />
                                        </Link>
                                    )
                                })
                            }
                        </Frame>
                    </Frame>
                    <Button extra={`width: 136px;`} disabled={isDisabled} background={props => props.theme.blue} onClick={onClose} >{getText(`Next`)}</Button>
                </Frame>
                    : <>
                        <Frame extra={`flex-direction: row; width: 420px; justify-content: flex-start; @media only screen and (max-width: 600px) { width: 80vw };`} >
                            <Frame row >
                                <Frame extra={props => `@media only screen and (max-width: 600px) { font-size: 10vw; }; font-size: 36px; color: ${props.theme.red}; font-family: Bold;`} >{(questions || []).map(i => i.id).indexOf(selectedQuestion) + 1}</Frame>
                                <Frame extra={props => `@media only screen and (max-width: 600px) { font-size: 9vw; }; font-size: 24px; color: ${props.theme.text.secondary};`} >/</Frame>
                                <Frame extra={props => `@media only screen and (max-width: 600px) { font-size: 8vw; }; font-size: 18px; color: ${props.theme.text.secondary};`} >{(questions || []).length}</Frame>
                            </Frame>
                        </Frame>
                        {
                            (questions || []).filter(i => i.id === selectedQuestion).map((item, index) => {
                                return (
                                    <Form
                                        key={index}
                                        data={transformedBackComment}
                                        onChange={(key, value) => { setStudentComment({ ...transformedBackComment, [key]: value }) }}
                                        fields={[{ type: item.type, name: item[(`title_${lang}` || ``).toLowerCase()] || ``, options: item.options }]}
                                    />
                                )
                            })
                        }
                        <Frame extra={`flex-direction: row; width: 100%; justify-content: space-between; @media only screen and (max-width: 600px) { width: 80vw };`} >
                            <BackButton visible={questions.map(i => i.id).indexOf(selectedQuestion) > 0} onClick={() => { setSelectedQuestion(questions[Math.max(questions.map(i => i.id).indexOf(selectedQuestion) - 1, 0)].id) }} />
                            <Button extra={`width: 136px;`} disabled={isDisabled} background={props => props.theme.blue} onClick={() => {
                                if (questions.map(i => i.id).indexOf(selectedQuestion) === questions.length - 1) {
                                    setShowPrograms(true)
                                } else {
                                    setSelectedQuestion(questions[questions.map(i => i.id).indexOf(selectedQuestion) + 1].id)
                                }
                            }} >{getText(`Next`)}</Button>
                        </Frame>
                    </>
            }
        </PopUpWrapper >
    );
}

const Program = styled(Frame)`
    padding: 5px;
    border-radius: 12px;
    border: 1px solid ${props => props.theme.background.secondary};
    margin: 7.5px;
    &:hover {
        box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.07);
    }
    &:before {
        content: '';
        width: 80px;
        height: 80px;
        border-radius: 12px;
        background: url(${props => props.avatar ? props.avatar : require(`../../../assets/images/hidden-company-1.svg`)}) no-repeat center center;
        background-size: contain;
    }
    &:after {
        content: '${props => props.name}';
        width: 70px;
        margin-top: 5px;
        font-size: 12px;
        text-align: left;
        color: ${props => props.theme.text.primary};
        text-decoration: none !important;
    }
`;

const BackButton = styled(Frame)`
    flex-direction: row;
    opacity: ${props => props.visible ? 0.5 : 0};
    visibility: ${props => props.visible ? `visible` : `hidden`};
    cursor: pointer;
    &:hover {
        transform: translateX(5px);
    }
    &:before {
        content: '';
        width: 10px;
        height: 10px;
        margin-bottom: 2px;
        background: url("${require(`../../../assets/images/arrow_down_black.svg`)}") no-repeat center center;
        transform: rotate(90deg);
    }
    &:after {
        content: 'back';
        margin-left: 10px;
        font-size: 14px;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 600px) {
        
    }
`;

export default WizardPopUp;

/*eslint-enable*/