/*eslint-disable*/
import React, { useState, useCallback } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation } from 'react-router-dom'
import { useMappedState, useDipatch } from 'redux-react-hook'

import * as programsActions from '../../../redux/actions/ProgramsActions'

import { Frame, Text, Button, Input, P, Link, convertHex } from '../../UIKit/styled-templates'
import Tags from '../tools/Tags'
import CommonHelper from '../../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'

let ProgramTab = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let { getText, lang } = useDictionary()

    let { programId } = props

    let { program } = useMappedState(useCallback(state => {
        return ({
            program: { info: {}, ...state.programs.programsMap.get(programId) },
        })
    }, [programId]))

    let programInfo = { ...program.info }

    let description = programInfo[`description_(${lang})`.toLowerCase()] || ``

    return (
        <Link to={`/programs/${program.id}`} >
            <Tab>
                <Frame row>
                    <ProgramImage src={programInfo.avatarUrl} />
                    <MobileCompanyName>{programInfo.program_name}</MobileCompanyName>
                </Frame>

                <TextWrapper>
                    <Frame row extra={`width: 100%; justify-content: space-between; @media only screen and (max-width: 600px) { display: none; };`} >
                        <Text extra={`font-size: 20px; height: 100%; align-items: flex-start; justify-content: flex-start; line-height: 30px;`} >{programInfo[`program_name_(${lang})`.toLowerCase()]}</Text>
                    </Frame>
                    <P extra={`line-height: normal;`} >
                        {description.substring(0, description.length > 218 ? 215 : 218)}{description.length > 218 ? `...` : null}
                    </P>
                </TextWrapper>
            </Tab>
        </Link>
    );
}

const MobileCompanyName = styled(Frame)`
    font-size: 6.25vw;
    margin-left: 2vw;
    color: ${props => props.theme.text.primary};
    width: calc(68vw - 18.75vw);
    align-items: flex-start;
    
    @media only screen and (min-width: 600px) {
        display: none;
    };
`;

const TextWrapper = styled(Frame)`
    width: 605px;
    height: 140px;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
        width: 68.75vw;
        height: auto;
        align-items: flex-start;
        justify-content: flex-start;
    }
`;

const ProgramImage = styled(Frame)`
    width: 140px;
    height: 140px;
    /* border-radius: 20px; */
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/hidden-company-1.svg`)});
    background-position: center;

    @media only screen and (max-width: 600px) {
        width: 18.75vw;
        height: 18.75vw;
    }
`;

const Tab = styled(Frame)`
    width: 765px;
    height: 140px;
    padding: 30px;
    border-radius: 12px;
    cursor: pointer;
    flex-direction: row;
    justify-content: space-between;
    background: ${props => props.theme.background.primary};
    border: 1px solid rgba(0, 0, 0, 0.05);
    &:hover {
        box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
    }

    @media only screen and (max-width: 600px) {
        width: calc(82vw - 6vw);
        height: calc(104vw - 6vw);
        border-radius: 3.75vw;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 6.25vw;
        padding: 6vw;
    }
`;

const TabsWrapper = styled(Frame)`
    width: 1100px;
    margin-top: 30px;
    margin-bottom: 54px;
    z-index: 1;
`;

const Wrapper = styled(Frame)`
    position: relative;
    align-items: flex-start;
    width: 100%;
`;

export default ProgramTab;

/*eslint-enable*/