/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useDispatch, useMappedState } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'
import moment from 'moment-timezone'

import * as actions from '../../../redux/actions/UsersActions'
import { Frame, Text, convertHex, AddSquare, H1, P, Checkbox, H } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import Dropzone from '../../UIKit/Dropzone'
import Form from '../../UIKit/Form'
import CommonHelper from '../../../helpers/CommonHelper';
import ProjectTab from '../tools/ProjectTab'
import Tags from '../tools/Tags'
import PhotoPreview from '../tools/PhotoPreview'
import SimpleControledUploadPanel from '../../upload/SimpleControledUploadPanel'

import useCurrentUser from '../helpers/useCurrentUser'
import useDictionary from '../helpers/useDictionary'

let educationFields = [
    { name: `Educational institution`, type: `string`, extra: `width: 694px;` },
    // { name: `Department or specialty`, type: `select`, short: true, extra: { width: window.innerWidth > 600 ? `445px` : `90vw` } },
    // { name: `Education level`, type: `select`, short: true, extra: { width: window.innerWidth > 600 ? `255px` : `90vw`, marginLeft: window.innerWidth > 600 ? `30px` : `0px`, } },
    { name: `From`, type: `date`, short: true, extra: `width: 253px; margin-right: 30px;`, },
    { name: `To`, type: `date`, short: true, extra: `width: 253px;`, },
    { name: `On-going`, type: `checkbox`, extra: `margin-right: 30px;`, },
]

let workexperienceFields = [
    { name: `Company name`, type: `string`, extra: `width: 694px;` },
    { name: `City`, type: `string`, extra: `width: 314px; margin-right: 30px;`, },
    { name: `Company site`, type: `string`, extra: `width: 314px`, },
    { name: `Job title`, type: `string`, extra: `width: 314px; margin-right: 30px;`, },
    { name: `Company industry`, type: `string`, extra: `width: 314px`, },
    { name: `From`, type: `date`, short: true, extra: `width: 253px; margin-right: 30px;`, },
    { name: `To`, type: `date`, short: true, extra: `width: 253px;`, },
    { name: `On-going`, type: `checkbox`, extra: `margin-right: 30px;`, },
    { name: `Description`, type: `textarea`, extra: `width: calc(730px - 34px);` },
]

let isObject = (object) => {
    return object != null && typeof object === 'object';
}

let deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

    return true;
}

let pastvalidateDate = (date) => {
    date = date.split(`.`)
    if (!isNaN(+date[0])) { date[0] = (`0` + Math.max(1, Math.min(31, +date[0]))).slice(-2) } else { date[0] = `01` }
    if (!isNaN(+date[1])) { date[1] = (`0` + Math.max(1, Math.min(12, +date[1]))).slice(-2) } else { date[1] = `01` }
    if (isNaN(+date[2])) { date[1] = moment().formay(`YYYY`) }
    // if (!isNaN(+date[2])) { date[2] = Math.max(+moment().format(`YYYY`), Math.min(2120, +date[2])) }
    return date.join(`.`)
}

let EditStudentsProfile = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let { selectedUser } = useMappedState(useCallback(state => { return { selectedUser: state.users.usersMap.get(path[1]), } }, [path]));
    let { currentUser } = useCurrentUser()
    let { getText } = useDictionary()

    let [info, setInfo] = useState(selectedUser.info);
    let [verified, setVerified] = useState(selectedUser[`verified`] === true)
    let [role, setRole] = useState(selectedUser[`userRole`])
    let [status, setStatus] = useState(selectedUser[`status`] || `active`)

    let dispatch = useDispatch()
    let onSaveProfile = async () => {
        let new_info = { ...info }
        if (Object.keys(new_info.education[0]).length === 0) { delete new_info.education }
        if (Object.keys(new_info.work_experience[0]).length === 0) { delete new_info.work_experience }
        // (new_info.education || []).forEach((item, index) => {
        //     item.from = pastvalidateDate(item.from)
        //     item.to = pastvalidateDate(item.to)
        // })
        // (new_info.work_experience || []).forEach((item, index) => {
        //     item.from = pastvalidateDate(item.from)
        //     item.to = pastvalidateDate(item.to)
        // })
        dispatch(actions.updateUser({ ...selectedUser, verified: verified, userRole: role, info: new_info, status: status }))
    }

    useEffect(() => {
        if (info.education === undefined || info.education === null || info.education.length === 0) {
            setInfo({ ...info, education: [{}] })
        }
        if (info.work_experience === undefined || info.work_experience === null || info.work_experience.length === 0) {
            setInfo({ ...info, work_experience: [{}] })
        }
    }, [info.education, info.work_experience])

    return (
        <Wrapper>

            <Block>
                <LeftColumn />
                <Workspace>
                    <H>{getText(`My profile`, `UPPERCASE_FIRST_LETTER`)}</H>
                </Workspace>
            </Block>

            {
                currentUser.userRole === `admin`
                    ? <>
                        <Block>
                            <LeftColumn>
                                <NumberWrapper index={0} >*</NumberWrapper>
                                <Frame extra={props => `width: 1px; height: 100%; background: ${props.theme.background.secondary}; @media only screen and (max-width: 600px) { display: none; };`} />
                            </LeftColumn>
                            <Workspace>
                                <H1>{getText(`Admin settings`, `UPPERCASE_FIRST_LETTER`)}</H1>
                                <Form
                                    data={{ verified: verified }}
                                    onChange={(key, value) => { setVerified(value) }}
                                    extra={`width: 730px; @media only screen and (max-width: 600px) { width: 90vw; };`}
                                    fields={[{ name: getText(`verified`), type: `checkbox` }]}
                                />
                                <Form
                                    data={{ role: { value: role, label: role } }}
                                    onChange={(key, value) => { setRole(value) }}
                                    extra={`width: 730px; @media only screen and (max-width: 600px) { width: 90vw; };`}
                                    fields={[{ name: getText(`Role`), type: `select`, options: [`admin`, `student`, `company`, `coordinator`].map(i => ({ value: i, label: i })) }]}
                                />
                                <Form
                                    data={{ status: { value: status, label: status } }}
                                    onChange={(key, value) => { setStatus(value) }}
                                    extra={`width: 730px; @media only screen and (max-width: 600px) { width: 90vw; };`}
                                    fields={[{ name: getText(`Status`), type: `select`, options: [`active`, `blocked`, `deleted`].map(i => ({ value: i, label: i })) }]}
                                />
                            </Workspace>
                        </Block>
                    </> : null
            }

            <Block>
                <LeftColumn>
                    <NumberWrapper index={0} >{1}</NumberWrapper>
                    <Frame extra={props => `width: 1px; height: 100%; background: ${props.theme.background.secondary}; @media only screen and (max-width: 600px) { display: none; };`} />
                </LeftColumn>
                <Workspace>
                    <FirstBlockWrapper>
                        <Frame extra={`align-items: flex-start; @media only screen and (max-width: 600px) { width: 90vw; };`} >
                            <Text extra={props => `font-size: 12px; margin-bottom: 4px; color: ${props.theme.text.secondary}; @media only screen and (max-width: 600px) { font-size: 5vw; margin-bottom: 1.25vw; };`} >{getText(`Profile photo`)}</Text>
                            <Frame extra={props => `width: 132px; height: 132px; border-radius: 12px; background: ${convertHex(props.theme.blue, 0.1)}; cursor: pointer; position: relative; overflow: hidden;`} >
                                {
                                    info.avatarUrl
                                        ? <PhotoPreview onDelete={() => { let new_info = { ...info }; delete new_info.avatarUrl; setInfo(new_info) }} >
                                            <Avatar src={info.avatarUrl} />
                                        </PhotoPreview>
                                        : <Dropzone onUploaded={(url) => { setInfo({ ...info, avatarUrl: url }) }} >
                                            <AddSquare />
                                        </Dropzone>
                                }
                            </Frame>
                        </Frame>
                        <Frame extra={`width: 540px; margin: 24px 0px 0px 58px; @media only screen and (max-width: 600px) { width: 90vw; margin: 5vw 0 0 0; };`} >
                            <Form
                                data={info}
                                onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                                fields={[
                                    { name: `Name`, type: `string`, short: true, },
                                    { name: `Last name`, type: `string`, short: true, },
                                    { name: `Date of birth`, type: `date`, short: true, },
                                    { name: `Student id`, type: `string`, short: true, },
                                ]}
                            />
                        </Frame>
                    </FirstBlockWrapper>
                    <Form
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        extra={`width: 730px; margin-top: 20px; @media only screen and (max-width: 600px) { width: 90vw; margin-top: 6.25vw; };`}
                        fields={[
                            { name: `Facebook`, type: `string`, short: true, extra: `width: 316px;` },
                            { name: `Linkedin`, type: `string`, short: true, extra: `width: 316px;` },
                        ]}
                    />

                    <H1>{getText(`Specialization (tags)`)}</H1>
                    <Form
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        extra={`width: 730px; margin-top: 20px; @media only screen and (max-width: 600px) { width: 90vw; margin-top: 6.25vw; };`}
                        fields={[{ name: `Specialization (tags)`, type: `tagsinput`, },]}
                    />

                    <H1>{getText(`About yourself`)}</H1>
                    <Form
                        data={info}
                        onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                        extra={`width: 730px; margin-top: 20px; @media only screen and (max-width: 600px) { width: 90vw; margin-top: 6.25vw; };`}
                        fields={[
                            { name: `Description`, type: `textarea`, extra: `width: calc(730px - 34px);` },
                        ]}
                    />

                </Workspace>
            </Block>

            <Block>
                <LeftColumn>
                    <NumberWrapper index={1} >{2}</NumberWrapper>
                    <Frame extra={props => `width: 1px; height: 100%; background: ${props.theme.background.secondary}; @media only screen and (max-width: 600px) { display: none; };`} />
                </LeftColumn>
                <Workspace>

                    <Frame row extra={`width: 100%; justify-content: space-between;`} >
                        <H1>{getText(`Education`)}</H1>
                        <AddButton onClick={() => {
                            setInfo({ ...info, education: [...info.education, {}] })
                        }} />
                    </Frame>

                    {
                        (info.education || []).map((item, index) => {
                            return <Frame key={index} extra={`align-items: flex-start;`} >
                                <Form
                                    key={index}
                                    data={item}
                                    extra={`width: 730px; margin-top: 20px; justify-content: flex-start; font-size: 20px; @media only screen and (max-width: 600px) { width: 90vw; margin-top: 6.25vw; };`}
                                    fields={educationFields}
                                    onChange={(key, value) => {
                                        let new_education = [...info.education]
                                        new_education[index][key] = value
                                        setInfo({ ...info, education: new_education })
                                    }}
                                />
                                <DeleteButtonWrapper onClick={() => { setInfo({ ...info, education: info.education.filter((a, b) => b !== index) }) }} >
                                    <TrashCan />
                                    <Text extra={`font-size: 16px; color: #E85B5B; @media only screen and (max-width: 600px) { font-size: 5vw; };`} >{getText(`Delete education`)}</Text>
                                </DeleteButtonWrapper>
                            </Frame>
                        })
                    }

                </Workspace>
            </Block>

            <Block>
                <LeftColumn>
                    <NumberWrapper index={2} >{3}</NumberWrapper>
                    <Frame extra={props => `width: 1px; height: 100%;`} />
                </LeftColumn>
                <Workspace>

                    <Frame row extra={`width: 100%; justify-content: space-between;`} >
                        <H1>{getText(`Work experience`)}</H1>
                        <AddButton onClick={() => {
                            setInfo({ ...info, work_experience: [...info.work_experience, {}] })
                        }} />
                    </Frame>

                    {
                        (info.work_experience || []).map((item, index) => {
                            return <Frame key={index} extra={`align-items: flex-start;`} >
                                <Form
                                    key={index}
                                    data={item}
                                    extra={`width: 730px; margin-top: 20px; justify-content: flex-start; font-size: 20px; @media only screen and (max-width: 600px) { width: 90vw; margin-top: 6.25vw; };`}
                                    fields={workexperienceFields}
                                    onChange={(key, value) => {
                                        let new_work_experience = [...info.work_experience]
                                        new_work_experience[index][key] = value
                                        setInfo({ ...info, work_experience: new_work_experience })
                                    }}
                                />
                                <DeleteButtonWrapper onClick={() => { setInfo({ ...info, work_experience: info.education.filter((a, b) => b !== index) }) }} >
                                    <TrashCan />
                                    <Text extra={`font-size: 16px; color: #E85B5B; @media only screen and (max-width: 600px) { font-size: 6.25vw; };`} >{getText(`Delete work experience`)}</Text>
                                </DeleteButtonWrapper>
                            </Frame>
                        })
                    }

                </Workspace>
            </Block>

            <Button
                extra={`width: 230px; margin-left: 80px; @media only screen and (max-width: 600px) { width: 90vw; margin-left: 0; }; `}
                onClick={async () => { await onSaveProfile(); CommonHelper.linkTo(`/user/${selectedUser.id}`) }}
            >
                {getText(`save`)}
            </Button>

        </Wrapper>
    );
}

const FirstBlockWrapper = styled(Frame)`
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

const DeleteButtonWrapper = styled(Frame)`
    flex-direction: row;
    margin: 0px 0 20px 0;
    cursor: pointer;
    &:hover {
        > * {
            &:first-child {
                transform: scale(1.2);
            }
            &:last-child {
                opacity: 0.8;
            }
        }
    }
`;

let AddButton = (props) => {
    let { getText } = useDictionary()
    return (
        <AddButtonWrapper
            {...props}
            extra={`cursor: pointer;`}
        >
            <Frame extra={props => `width: 20px; height: 20px; border-radius: 5px; background: ${convertHex(props.theme.blue, 0.3)}; @media only screen and (max-width: 600px) { width: 6.25vw; height: 6.25vw; border-radius: 2vw; font-size: 2.5vw; }; @media only screen and (max-width: 600px) { margin-bottom: 4.5vw; }; `} >
                <Text bold text_color={props => props.theme.blue} extra={`font-size: 12px;`} >+</Text>
            </Frame>
            <Text extra={`font-size: 16px; margin-left: 5px; @media only screen and (max-width: 600px) { display: none; };`} >{getText(`Add more`)}</Text>
        </AddButtonWrapper>
    )
}

const AddButtonWrapper = styled(Frame)`
    flex-direction: row;
    &:hover {
        > * {
            &:first-child {
                transform: scale(1.2);
                > * {
                    &:first-child {
                        transform: rotate(90deg);
                    }
                }
            }
            &:last-child {
                opacity: 0.8;
            }
        }
    }
`;

const Workspace = styled(Frame)`
    width: 730px;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin-top: 6.25vw;
    };
`;

const NumberWrapper = styled(Frame)`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: ${props => convertHex(props.theme[[`red`, `blue`, `green`][props.index % 3]], 0.1)};
    color: ${props => props.theme[[`red`, `blue`, `green`][props.index % 3]]};
    font-family: Bold;
    font-size: 24px;

    @media only screen and (max-width: 600px) {
        width: 13.75vw;
        height: 13.75vw;
        font-size: 7.5vw;
        margin-top: 5vw;
    }
`;

const LeftColumn = styled(Frame)`
    margin-right: 40px;
    width: 44px;

    @media only screen and (max-width: 600px) {
        margin: 0px;
    }
`;

const Block = styled(Frame)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        flex-direction: column;
    }
`;

// let Avatar = styled.img.attrs((props) => { })` width: 132px; height: 132px; position: absolute;`
const Avatar = styled(Frame)`
    width: 132px;
    height: 132px;
    background-image: url("${props => props.src}");
    background-position: center;
`;

let CheckMark = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/check_mark.svg`) } catch (error) { }
    return ({ src: img, })
})` width: ${props => +props.visible * 8}px; height: ${props => +props.visible * 7}px; margin-right: ${props => +props.visible * 10}px; transition: 0.2s; transform: scale(${props => +props.visible});`

let TrashCan = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/trash_can_red.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin-right: 10px; transition: 0.2s; `

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin-left: 3px; `

const Wrapper = styled(Frame)`
    position: relative;
    width: 815px;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

export default EditStudentsProfile;

/*eslint-enable*/