/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Circles from './Circles'
import CommonHelper from '../../../helpers/CommonHelper'

import { Frame, Text, RedStrip, H1, H, P } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import useDictionary from '../helpers/useDictionary'

let tabs = [
    {},
    {},
    {},
]

let HowItWorks = () => {

    let { getText } = useDictionary()

    return (
        <Workspace>
            <RedStrip />
            <Tab>
                <H>{getText(`Get started for free`)}</H>
                <P extra={`font-size: 16px; line-height: 26px; width: 505px; margin-top: 25px; margin-bottom: 51px;`} >{getText(`get_started_text`)}</P>
                <ButtonWrapper>
                    <Button background={props => props.theme.blue} onClick={() => { CommonHelper.linkTo(`/signup/student`) }} >
                        {getText(`sign_up_students`)}
                    </Button>
                    <Button background={props => props.theme.red} onClick={() => { CommonHelper.linkTo(`/signup/company`) }} >
                        {getText(`sign_up_company`)}
                    </Button>
                </ButtonWrapper>
            </Tab>
        </Workspace>
    );
}

const ButtonWrapper = styled(Frame)`
    flex-direction: row;
    justify-content: space-between;

    > * {
        width: 230px;
        &:nth-child(1) {
            margin-right: 40px;
        }
    }

    @media only screen and (max-width: 600px) {
        width: 80vw;
        justify-content: center;

        > * {
            font-size: 3vw;
            margin: 0;
            width: 40vw;
        }
    }
`;

const Tab = styled(Frame)`
    width: 880px;
    height: 450px;
    border-radius: 20px;
    box-shadow: 8px 8px 65px rgba(0, 0, 0, 0.04);
    background: ${props => props.theme.background.primary};

    @media only screen and (max-width: 600px) {
        width: 90vw;

        > * {
            &:nth-child(1) {
                width: 50vw;
                text-align: center;
            }
            &:nth-child(2) {
                width: 80vw;
                text-align: center;
            }
        }
    }
`;

const Workspace = styled(Frame)`
    width: 1100px;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 600px) {
        width: 100vw;
    }
`;

export default HowItWorks;
/*eslint-enable*/