/*eslint-disable*/
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'

import * as uiActions from '../../../redux/actions/UIActions'
import * as projectsActions from '../../../redux/actions/ProjectsActions'
import { Frame, Text, H1, Textarea, P } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import PopUpWrapper from '../pop-ups/PopUpWrapper'
import CommonHelper from '../../../helpers/CommonHelper';

import useCurrentUser from '../helpers/useCurrentUser'
import useCustomDispatch from '../helpers/useCustomDispatch'
import useComponentSize from '../helpers/useComponentSize'
import useDictionary from '../helpers/useDictionary'

let ApplyToProjectPopUp = (props) => {

    let { getText } = useDictionary()

    let [id, setId] = useState(undefined)
    let [studentComment, setStudentComment] = useState(``)

    let onClosePopUp = (application) => { window.dispatchEvent(new CustomEvent(`CLOSE_APPLICATION_APPLY_POP_UP`, { detail: application })) }
    let onOpenPopUp = (application) => { window.dispatchEvent(new CustomEvent(`OPEN_APPLICATION_APPLY_POP_UP`, { detail: application })) }

    useCustomDispatch(`OPEN_APPLICATION_APPLY_POP_UP`, (e) => { setId(e.detail); })
    useCustomDispatch(`CLOSE_APPLICATION_APPLY_POP_UP`, (e) => { })

    let dispatch = useDispatch()

    let onApplyToProject = () => {
        dispatch(projectsActions.applyToProject({ id, studentComment }))
    }

    return (
        <PopUpWrapper name={`APPLICATION_APPLY`} >
            <H1 extra={`align-items: center; text-align: center;`} >{getText(`Add information`)}</H1>
            <P extra={`width: 350px; text-align: center; @media only screen and (max-width: 600px) { width: 90vw; };`} >{getText(`Write why you want to participate in this project`)}</P>
            <Textarea placeholder={`Description`} value={studentComment} onChange={(e) => { setStudentComment(e.target.value) }} />
            <Frame row extra={`margin-top: 30px;`} >
                <Button extra={`width: 196px; @media only screen and (max-width: 600px) { width: 43.75vw; };`} background={props => props.theme.blue} shaped onClick={onClosePopUp} >
                    Cancel
                    </Button>
                <Button extra={`width: 196px; margin-left: 20px; @media only screen and (max-width: 600px) { width: 43.75vw; margin-left: 2.5vw; };`} background={props => props.theme.red} onClick={() => {
                    onClosePopUp()
                    onApplyToProject()
                }} >
                    Send
                </Button>
            </Frame>
        </PopUpWrapper>
    );
}

let Cross = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/cross_w.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    position: absolute;
    right: calc(50% - 300px);
    top: -40px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        transform: rotate(90deg);
    };
`

const OpenProjectTab = styled(Frame)`
    width: 600px;
    /* height: 250px; */
    height: auto;
    border-radius: 12px;
    padding: 30px 0 30px 0;
    background: ${props => props.theme.background.primary};
    position: absolute;
    top: ${props => props.visible ? `calc(50% - 190px)` : `150%`};
    z-index: 3;
    // justify-content: space-between;

    @media only screen and (max-width: 600px) {
        min-width: 90vw;
        max-width: 90vw;
        width: 90vw;
        padding: 9vw 3vw;
    }
`;

const DarkOverlay = styled(Frame)`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, ${props => props.visible * 0.75});
    visibility: ${props => props.visible ? `visible` : `hidden`};
    backdrop-filter: blur(${props => props.visible * props.blur * 8}px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
`;

export default ApplyToProjectPopUp;

/*eslint-enable*/