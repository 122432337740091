/*eslint-disable*/
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'

import * as uiActions from '../../../redux/actions/UIActions'
import * as projectsActions from '../../../redux/actions/ProjectsActions'
import { Frame, Text, H1 } from '../../UIKit/styled-templates'
import Button from '../../UIKit/Button'
import PopUpWrapper from '../pop-ups/PopUpWrapper'
import CommonHelper from '../../../helpers/CommonHelper';
import mvConsts from '../../../constants/mvConsts'

import useCurrentUser from '../helpers/useCurrentUser'
import useCustomDispatch from '../helpers/useCustomDispatch'
import useDictionary from '../helpers/useDictionary'
import useComponentSize from '../helpers/useComponentSize'

let ProjectCreationPopUp = (props) => {

    let { getText, dict } = useDictionary()

    let history = useHistory()
    let { currentUser } = useCurrentUser()

    let projectStatuses = Object.keys(dict).filter(i => i.indexOf(`project_status_`) > -1)

    // const { visible, info } = useMappedState(useCallback(state => { return { visible: state.ui.projectRequestVisible.visible, info: state.ui.projectRequestVisible.info } }, []));

    let [visible, setVisible] = useState(false)
    let [info, setInfo] = useState({})

    const dispatch = useDispatch();
    let onClosePopUp = () => {
        dispatchEvent(new CustomEvent(`CLOSE_PROJECT_CREATION_POP_UP`, { detail: info }))
        // CommonHelper.linkTo(`/`)
        history.goBack()
    }

    useCustomDispatch(`OPEN_PROJECT_CREATION_POP_UP`, (e) => { setVisible(true); setInfo(e.detail) })
    useCustomDispatch(`CLOSE_PROJECT_CREATION_POP_UP`, (e) => { setVisible(false); })

    return (
        <PopUpWrapper name={`PROJECT_CREATION`} >
            <H1>{getText(`your_project_is_sent_for_review`)}</H1>
            <Text text_color={props => props.theme.text.seoncdary} extra={`font-size: 16px; line-height: 26px; width: 350px; text-align: center;`} >{getText(`After the approval of the administrations, your project will be published`)}</Text>
            <Frame row extra={`margin-top: 30px;`} >
                <Button extra={`width: 196px; margin-left: 20px;`} background={props => props.theme.blue} onClick={async () => {
                    if (currentUser.userRole !== `company`) {
                        window.alert(getText(`This profile is not a company profile`))
                    } else {
                        await dispatch(projectsActions.createProject({ status: projectStatuses[0], info: info, applications: [], creatorId: useCurrentUser.id }));
                    }
                    onClosePopUp()
                }} >
                    {getText(`ok_thanks`)}
                </Button>
            </Frame>
        </PopUpWrapper>
    );
}

let Cross = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/cross_w.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    position: absolute;
    right: calc(50% - 300px);
    top: -40px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        transform: rotate(90deg);
    };
`

const OpenProjectTab = styled(Frame)`
    min-width: 480px;
    height: 190px;
    padding: 30px 60px 30px 60px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    position: absolute;
    top: ${props => props.visible ? `calc(50% - 190px)` : `150%`};
    z-index: 3;
    // justify-content: space-between;

    @media only screen and (max-width: 600px) {
        min-width: 90vw;
        max-width: 90vw;
        width: 90vw;
        padding: 9vw 3vw;
    }
`;

const DarkOverlay = styled(Frame)`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, ${props => props.visible * 0.75});
    visibility: ${props => props.visible ? `visible` : `hidden`};
    backdrop-filter: blur(${props => props.visible * props.blur * 8}px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
`;


const Wrapper = styled(Frame)`
    position: relative;
    padding-top: 94px;
    overflow: hidden;
`;

export default ProjectCreationPopUp;

/*eslint-enable*/