/*eslint-disable*/
import React, { useState, useCallback } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import { makeAnimated } from 'react-select';
import { useLocation } from 'react-router-dom'
import moment from 'moment-timezone'
import { useDispatch, useMappedState } from 'redux-react-hook'

import { Frame, Text, convertHex, P, H1 } from '../../UIKit/styled-templates'
import Input from '../../UIKit/Input'
import Button from '../../UIKit/Button'
import ProjectTab from '../tools/ProjectTab'
import Tags from '../tools/Tags'
import CommonHelper from '../../../helpers/CommonHelper'

import useCurrentUser from '../helpers/useCurrentUser'

let animatedComponents = makeAnimated();

let categories = [`First`, `Second`, `Third`].map(item => ({ value: item, label: item }))

let AdminProfile = () => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let { selectedUser, usersProjects } = useMappedState(useCallback(state => {
        return ({
            selectedUser: state.users.usersMap.get(path[1]),
            usersProjects: state.projects.projectsMap.filter(i => i.applications.filter(j => j.userId === path[1]).length > 0).toArray()
        })
    }, []))

    let student_name = `${selectedUser.info.name || `Student`} ${selectedUser.info.last_name || `Name`}`
    let tags = selectedUser.info[`specialization_(tags)`] || []

    return (
        <Wrapper>
            <Workspace>

                <HeaderWrapper>

                    <Frame row extra={`width: 920px; justify-content: flex-start; margin: 40px 0 20px 0;`} >
                        <UserAvatar src={selectedUser.info.avatarUrl} />
                        <Frame extra={`height: 160px; margin-left: 30px; justify-content: space-between; align-items: flex-start;`} >
                            <Frame row>
                                <Text bold extra={`font-size: 48px; line-height: 62px;`} >{student_name}</Text>
                                {selectedUser.verified ? <VerifiedSign /> : null}
                            </Frame>
                            <Tags tagsArray={tags} />
                        </Frame>
                    </Frame>

                </HeaderWrapper>

            </Workspace>

        </Wrapper>
    );
}

const TagWrapper = styled(Text)`
    padding: 0 22px 0 22px;
    border-radius: 4px;
    background: ${props => convertHex(props.theme.green, 0.15)};
    margin: 0 10px 5px 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 22px;
`;

const Workspace = styled(Frame)`
    width: 920px;
    align-items: flex-start;
`;

let EmptyAvatar = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/empty_avatar.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 160px; height: 160px;`

let VerifiedSign = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/approved_company.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 36px; height: 36px; margin-left: 22px; `

let LocationImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/location.png`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin: 0 15px 0 30px;`

let PhoneImage = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/phone.png`) } catch (error) { }
    return ({ src: img, })
})` width: 24px; height: 24px; margin: 0 15px 0 30px;`

const UserAvatar = styled(Frame)`
    width: 160px;
    height: 160px;
    border-radius: 80px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    background-image: url(${props => props.src ? props.src : require(`../../../assets/images/empty_avatar.svg`)});
    background-position: center;
`;

const HeaderWrapper = styled(Frame)`
    align-items: flex-start;
    margin-bottom: 40px;
`;

const BackButtonWrapper = styled(Frame)`
    width: 920px;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;
    cursor: pointer;
    z-index: 1;
`;

const HeaderImageWrapper = styled(Frame)`
    width: 920px;
    height: 256px;
    background: ${props => props.theme.background.secondary};
    justify-content: flex-start;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
`;

let ShareLogo = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/share_${props.name}.svg`) } catch (error) { }
    return ({ src: img, })
})` width: 15px; height: 15px; `

let BackArrow = styled.img.attrs((props) => {
    let img
    try { img = require(`../../../assets/images/back_arrow.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 16px;
    height: 16px;
    margin-right: 3px;
`

const Wrapper = styled(Frame)`
    
`;

export default AdminProfile;

/*eslint-enable*/