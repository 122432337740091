/*eslint-disable*/
import ProjectsAPI from '../../api/ProjectsAPI'

import * as types from '../ActionTypes'

let loadProjects_ = () => {
    return {
        type: types.LOAD_PROJECTS
    }
}
let loadProjectsSuccess = (projects) => {
    return {
        type: types.LOAD_PROJECTS_SUCCESS,
        projects: projects
    }
}
let loadProjectsFailed = (error) => {
    return {
        type: types.LOAD_PROJECTS_FAIL,
        error: error
    }
}

//thunk
export function loadAllProjects() {
    return (dispatch, getState) => {
        dispatch(loadProjects_());
        return ProjectsAPI.getAllProjects().then(
            projects => dispatch(loadProjectsSuccess(projects)),
            error => dispatch(loadProjectsFailed(error))
        )
    }
}

export function loadProjectById(id) {
    return (dispatch, getState) => {
        dispatch(loadProjects_());
        return ProjectsAPI.getProject(id).then(
            project => dispatch(loadProjectsSuccess([project])),
            error => dispatch(loadProjectsFailed(error))
        )
    }
}

let createProject_ = () => {
    return {
        type: types.CREATE_PROJECT
    }
}
let createProjectSuccess = (project) => {
    return {
        type: types.CREATE_PROJECT_SUCCESS,
        project: project
    }
}
let createProjectFailed = (error) => {
    return {
        type: types.CREATE_PROJECT_FAIL,
        error: error
    }
}

//thunk
export function createProject(data) {
    return (dispatch, getState) => {
        dispatch(createProject_());
        return ProjectsAPI.createProject(data).then(
            project => dispatch(createProjectSuccess(project)),
            error => dispatch(createProjectFailed(error))
        )
    }
}

let updateProject_ = () => {
    return {
        type: types.UPDATE_PROJECT
    }
}
let updateProjectSuccess = (project) => {
    return {
        type: types.UPDATE_PROJECT_SUCCESS,
        project: project
    }
}
let updateProjectFailed = (error) => {
    return {
        type: types.UPDATE_PROJECT_FAIL,
        error: error
    }
}

//thunk
export function updateProject(data) {
    return (dispatch, getState) => {
        dispatch(updateProject_());
        return ProjectsAPI.updateProject(data).then(
            project => dispatch(updateProjectSuccess(project)),
            error => dispatch(updateProjectFailed(error))
        )
    }
}

let updateApplicationStatus_ = () => {
    return {
        type: types.UPDATE_APPLICATION_STATUS
    }
}
let updateApplicationStatusSuccess = (project) => {
    return {
        type: types.UPDATE_APPLICATION_STATUS_SUCCESS,
        project: project
    }
}
let updateApplicationStatusFailed = (error) => {
    return {
        type: types.UPDATE_APPLICATION_STATUS_FAIL,
        error: error
    }
}

//thunk
export function updateApplicationStatus(data) {
    return (dispatch, getState) => {
        dispatch(updateApplicationStatus_());
        return ProjectsAPI.updateApplicationStatus(data).then(
            project => dispatch(updateApplicationStatusSuccess(project)),
            error => dispatch(updateApplicationStatusFailed(error))
        )
    }
}

let deleteProject_ = () => {
    return {
        type: types.DELETE_PROJECT
    }
}
let deleteProjectSuccess = (id) => {
    return {
        type: types.DELETE_PROJECT_SUCCESS,
        id: id
    }
}
let deleteProjectFailed = (error) => {
    return {
        type: types.DELETE_PROJECT_FAIL,
        error: error
    }
}

//thunk
export function deleteProject(id) {
    return (dispatch, getState) => {
        dispatch(deleteProject_());
        return ProjectsAPI.deleteProject(id).then(
            () => dispatch(deleteProjectSuccess(id)),
            error => dispatch(deleteProjectFailed(error))
        )
    }
}

let applyToProject_ = () => {
    return {
        type: types.APPLY_TO_PROJECT
    }
}
let applyToProjectSuccess = (project) => {
    return {
        type: types.APPLY_TO_PROJECT_SUCCESS,
        project: project
    }
}
let applyToProjectFailed = (error) => {
    return {
        type: types.APPLY_TO_PROJECT_FAIL,
        error: error
    }
}

//thunk
export function applyToProject(data) {
    return (dispatch, getState) => {
        dispatch(applyToProject_());
        return ProjectsAPI.applyToProject(data).then(
            project => dispatch(applyToProjectSuccess(project)),
            error => dispatch(applyToProjectFailed(error))
        )
    }
}

let deleteApplication_ = () => {
    return {
        type: types.DELETE_APPLICATION
    }
}
let deleteApplicationSuccess = (project) => {
    return {
        type: types.DELETE_APPLICATION_SUCCESS,
        project: project
    }
}
let deleteApplicationFailed = (error) => {
    return {
        type: types.DELETE_APPLICATION_FAIL,
        error: error
    }
}

//thunk
export function deleteApplication(id) {
    return (dispatch, getState) => {
        dispatch(deleteApplication_());
        return ProjectsAPI.deleteApplication(id).then(
            project => dispatch(deleteApplicationSuccess(project)),
            error => dispatch(deleteApplicationFailed(error))
        )
    }
}

let updateApplication_ = () => {
    return {
        type: types.UPDATE_APPLICATION
    }
}
let updateApplicationSuccess = (project) => {
    return {
        type: types.UPDATE_APPLICATION_SUCCESS,
        project: project
    }
}
let updateApplicationFailed = (error) => {
    return {
        type: types.UPDATE_APPLICATION_FAIL,
        error: error
    }
}

//thunk
export function updateApplication(application) {
    return (dispatch, getState) => {
        dispatch(updateApplication_());
        return ProjectsAPI.updateApplication(application).then(
            project => dispatch(updateApplicationSuccess(project)),
            error => dispatch(updateApplicationFailed(error))
        )
    }
}

let deleteProjectApplication_ = () => {
    return {
        type: types.DELETE_PROJECT_APPLICATION
    }
}
let deleteProjectApplicationSuccess = (projects) => {
    return {
        type: types.DELETE_PROJECT_APPLICATION_SUCCESS,
        projects: projects
    }
}
let deleteProjectApplicationFailed = (error) => {
    return {
        type: types.DELETE_PROJECT_APPLICATION_FAIL,
        error: error
    }
}

//thunk
export function deleteProjectApplication(id) {
    return (dispatch, getState) => {
        dispatch(deleteProjectApplication_());
        return ProjectsAPI.deleteProjectApplication(id).then(
            (projects) => dispatch(deleteProjectApplicationSuccess(projects)),
            (error) => dispatch(deleteProjectApplicationFailed(error))
        )
    }
}
/*eslint-enable*/